@use '@angular/material' as mat;

// mixin name will be used in main style.scss
@mixin bracelit-email-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .email-chip {
    color: mat.get-color-from-palette($primary) !important;
    background-color: mat.get-color-from-palette($primary, 0.15) !important;
    .mat-chip-remove {
      color: mat.get-color-from-palette($primary) !important;
      opacity: 1 !important;
    }
  }
  .files-tip {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }
}
