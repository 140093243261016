/*// BRACELIT PRIMARY
$bracelit-bracelit: (
    50 : #e3ebef, // #e3ebef // border primary_50
    100 : #baced8, // #baced8 // border primary_100
    200 : #8bacbe, // #8cadbe  border primary_200
    300 : #5d8ca4,
    400 : #3b7391,
    500 : #185a7d, // primary
    600 : #155275,
    700 : #11486a,
    800 : #0e3f60, // #0e3f60 // fill_primary_800
    900 : #082e4d,
  //A100 : #82bfff, // A SON LOS ACCENT COLORS
  //A200 : #4fa6ff,
  //A400 : #1c8cff,
  //A700 : #037fff,
  // A SON LOS ACCENT COLORS ADAPTADOS AL DISEÑO
    A100 : #6cc4cd, // tertiary
    A200 : #4fafba,
    A400 : #00a5b5, // secondary
    A700 : #008a9d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);*/

// NUEVOS COLORES
// BRACELIT PRIMARY
$bracelit-bracelit: (
  50 : #e2e4e8,
  100 : #b7bdc5,
  200 : #87919f,
  300 : #576478,
  400 : #33435b,
  500 : #0f223e,
  600 : #0d1e38,
  700 : #0b1930,
  800 : #081428,
  900 : #040c1b,
  A100 : #18A1B9,
  A200 : #2660ff,
  // A400 : #0041f2,
  A400 : #5df897,
  A700 : #5DF897,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

//BRACELIT SECONDARY
$bracelit-bracelit-secondary: (
    50 : #e0f4f6,
    100 : #b3e4e9,
    200 : #80d2da,
    300 : #4dc0cb,
    400 : #26b3c0,
    500 : #00a5b5, // SECONDARY used as A400 in bracelit-bracelit
    600 : #009dae,
    700 : #0093a5,
    800 : #008a9d, // used as A700 in bracelit-bracelit
    900 : #00798d,
    A100 : #b9f3ff,
    A200 : #86eaff,
    A400 : #53e1ff,
    A700 : #3addff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// BRACELIT TERTIARY
$bracelit-bracelit-tertiary: (
    50 : #edf8f9,
    100 : #d3edf0,
    200 : #b6e2e6,
    300 : #98d6dc,
    400 : #82cdd5,
    500 : #6cc4cd, // TERTIARY // used as A100 in bracelit-bracelit
    600 : #64bec8,
    700 : #59b6c1,
    800 : #4fafba, // used as A200 in bracelit-bracelit
    900 : #3da2ae,
    A100 : #feffff,
    A200 : #cbf9ff,
    A400 : #98f3ff,
    A700 : #7ff0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// BRACELIT ACCENT
$bracelit-bracelit-accent: (
    50 : #fffbf2,
    100 : #fff5df,
    200 : #ffeec9, // #ffeec9 accent_200
    300 : #fee7b3,
    400 : #fee2a3,
    500 : #5df897, // #fedd93 // accent
    600 : #fed98b,
    700 : #fed480,
    800 : #fedd93,
    900 : #fdc764,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #fff8eb,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-titeam: (
    50 : #e5f2f4,
    100 : #bddee4,
    200 : #92c9d3,
    300 : #66b3c1,
    400 : #45a2b3,
    500 : #2492a6,
    600 : #208a9e,
    700 : #1b7f95,
    800 : #16758b,
    900 : #0d637b,
    A100 : #adebff,
    A200 : #7adfff,
    A400 : #47d2ff,
    A700 : #2dccff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-red: (
    50 : #fce7e7,
    100 : #f7c4c2,
    200 : #f29c9a,
    300 : #ed7472,
    400 : #e95753,
    500 : #e53935,
    600 : #e23330,
    700 : #de2c28,
    800 : #da2422,
    900 : #d31716,
    A100 : #ffffff,
    A200 : #ffd1d1,
    A400 : #ff9f9e,
    A700 : #ff8585,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-pink: (
    50 : #fae4ec,
    100 : #f3bbcf,
    200 : #ec8db0,
    300 : #e45f90,
    400 : #de3d78,
    500 : #d81b60,
    600 : #d41858,
    700 : #ce144e,
    800 : #c81044,
    900 : #bf0833,
    A100 : #ffe9ed,
    A200 : #ffb6c4,
    A400 : #ff839a,
    A700 : #ff6986,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-purple: (
    50 : #f1e5f5,
    100 : #ddbde6,
    200 : #c792d5,
    300 : #b066c4,
    400 : #9f45b7,
    500 : #8e24aa,
    600 : #8620a3,
    700 : #7b1b99,
    800 : #711690,
    900 : #5f0d7f,
    A100 : #e6b1ff,
    A200 : #d67eff,
    A400 : #c64bff,
    A700 : #be31ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$bracelit-deep-purple: (
    50 : #ebe8f1,
    100 : #cdc5db,
    200 : #ab9ec3,
    300 : #8977ab,
    400 : #705999,
    500 : #573c87,
    600 : #4f367f,
    700 : #462e74,
    800 : #3c276a,
    900 : #2c1a57,
    A100 : #b195ff,
    A200 : #8b62ff,
    A400 : #662fff,
    A700 : #5315ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bracelit-deep-green: (
    50 : #e2ede6,
    100 : #b6d3c0,
    200 : #85b696,
    300 : #54986c,
    400 : #2f824d,
    500 : #0a6c2d,
    600 : #096428,
    700 : #075922,
    800 : #054f1c,
    900 : #033d11,
    A100 : #73ff89,
    A200 : #40ff5d,
    A400 : #0dff32,
    A700 : #00f226,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-green: (
    50 : #e8f4e9,
    100 : #c7e3c8,
    200 : #a1d0a3,
    300 : #7bbd7e,
    400 : #5fae63,
    500 : #43a047,
    600 : #3d9840,
    700 : #348e37,
    800 : #2c842f,
    900 : #1e7320,
    A100 : #b0ffb2,
    A200 : #7dff80,
    A400 : #4aff4e,
    A700 : #30ff35,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-turquoise: (
    50 : #e0f1ef,
    100 : #b3dcd7,
    200 : #80c4bd,
    300 : #4daca3,
    400 : #269b8f,
    500 : #00897b,
    600 : #008173,
    700 : #007668,
    800 : #006c5e,
    900 : #00594b,
    A100 : #8bffe9,
    A200 : #58ffdf,
    A400 : #25ffd5,
    A700 : #0bffd0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-cyan: (
    50 : #e1f5f8,
    100 : #b3e6ec,
    200 : #80d6e0,
    300 : #4dc5d4,
    400 : #27b8ca,
    500 : #01acc1,
    600 : #01a5bb,
    700 : #019bb3,
    800 : #0192ab,
    900 : #00829e,
    A100 : #c9f4ff,
    A200 : #96e9ff,
    A400 : #63dfff,
    A700 : #49daff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-blue: (
    50 : #e4f1fc,
    100 : #bcdbf7,
    200 : #8fc4f2,
    300 : #62aced,
    400 : #409ae9,
    500 : #1e88e5,
    600 : #1a80e2,
    700 : #1675de,
    800 : #126bda,
    900 : #0a58d3,
    A100 : #fdfeff,
    A200 : #cadcff,
    A400 : #97bbff,
    A700 : #7eaaff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-deep-blue: (
    50 : #e7e9f5,
    100 : #c4c8e6,
    200 : #9ca4d5,
    300 : #747fc4,
    400 : #5763b8,
    500 : #3948ab,
    600 : #3341a4,
    700 : #2c389a,
    800 : #243091,
    900 : #172180,
    A100 : #b8bdff,
    A200 : #858eff,
    A400 : #515fff,
    A700 : #3848ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bracelit-yellow: (
    50 : #fffae7,
    100 : #fef3c2,
    200 : #feec9a,
    300 : #fee472,
    400 : #fdde53,
    500 : #fdd835,
    600 : #fdd430,
    700 : #fcce28,
    800 : #fcc822,
    900 : #fcbf16,
    A100 : #ffffff,
    A200 : #fffdf8,
    A400 : #ffeec5,
    A700 : #ffe7ac,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-amber: (
    50 : #f9f2e1,
    100 : #f1dfb5,
    200 : #e7c983,
    300 : #ddb351,
    400 : #d6a32c,
    500 : #cf9307,
    600 : #ca8b06,
    700 : #c38005,
    800 : #bd7604,
    900 : #b26402,
    A100 : #ffeddb,
    A200 : #ffd4a8,
    A400 : #ffba75,
    A700 : #ffad5c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-orange: (
    50 : #f9efe1,
    100 : #f1d6b3,
    200 : #e8bb81,
    300 : #df9f4f,
    400 : #d88b29,
    500 : #d17603,
    600 : #cc6e03,
    700 : #c66302,
    800 : #c05902,
    900 : #b54601,
    A100 : #ffe8de,
    A200 : #ffc5ab,
    A400 : #ffa278,
    A700 : #ff915e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-deep-orange: (
    50 : #f7e7e1,
    100 : #ebc2b5,
    200 : #de9984,
    300 : #d07052,
    400 : #c6522d,
    500 : #bc3308,
    600 : #b62e07,
    700 : #ad2706,
    800 : #a52004,
    900 : #971402,
    A100 : #ffc6c2,
    A200 : #ff978f,
    A400 : #ff685c,
    A700 : #ff5042,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-black: (
    50 : #e4e4e4,
    100 : #bcbcbc,
    200 : #8f8f8f,
    300 : #626262,
    400 : #404040,
    500 : #1e1e1e,
    600 : #1a1a1a,
    700 : #161616,
    800 : #121212,
    900 : #0a0a0a,
    A100 : #e07171,
    A200 : #d74747,
    A400 : #eb0000,
    A700 : #d10000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bracelit-deep-grey: (
    50 : #eaeaea,
    100 : #cacaca,
    200 : #a7a7a7,
    300 : #838383,
    400 : #696969,
    500 : #4e4e4e,
    600 : #474747,
    700 : #3d3d3d,
    800 : #353535,
    900 : #252525,
    A100 : #f18282,
    A200 : #ec5454,
    A400 : #ff0e0e,
    A700 : #f30000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bracelit-blue-grey: (
    50 : #eaeeef,
    100 : #ccd4d7,
    200 : #aab7bd,
    300 : #879aa2,
    400 : #6e848e,
    500 : #546e7a,
    600 : #4d6672,
    700 : #435b67,
    800 : #3a515d,
    900 : #293f4a,
    A100 : #95d9ff,
    A200 : #62c7ff,
    A400 : #2fb5ff,
    A700 : #15acff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$bracelit-grey: (
    50 : #eeeeee,
    100 : #d6d6d6,
    200 : #bababa,
    300 : #9e9e9e,
    400 : #8a8a8a,
    500 : #757575,
    600 : #6d6d6d,
    700 : #626262,
    800 : #585858,
    900 : #454545,
    A100 : #f6afaf,
    A200 : #f18282,
    A400 : #ff4141,
    A700 : #ff2727,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bracelit-brown: (
    50 : #edeae8,
    100 : #d3c9c6,
    200 : #b6a6a0,
    300 : #99827a,
    400 : #83675e,
    500 : #6d4c41,
    600 : #65453b,
    700 : #5a3c32,
    800 : #50332a,
    900 : #3e241c,
    A100 : #ff9a81,
    A200 : #ff724e,
    A400 : #ff491b,
    A700 : #ff3402,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$bracelit-warn: (
    50 : #fce9e7,
    100 : #f8c8c3,
    200 : #f3a39b,
    300 : #ee7e73,
    400 : #eb6355,
    500 : #e74737,
    600 : #e44031,
    700 : #e0372a,
    800 : #dd2f23,
    900 : #d72016,
    A100 : #ffffff,
    A200 : #ffd6d4,
    A400 : #ffa5a1,
    A700 : #ff8d88,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
