@use '@angular/material' as mat;
@use "sass:map";

// mixin name will be used in main style.scss
@mixin bracelit-email-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  .email-chip {
    color: mat.m2-get-color-from-palette($primary) !important;
    background-color: mat.m2-get-color-from-palette($primary, 0.15) !important;
    .mat-chip-remove {
      color: mat.m2-get-color-from-palette($primary) !important;
      opacity: 1 !important;
    }
  }
  .files-tip {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
}
