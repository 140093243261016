@font-face {
    font-family: Calibri;
    font-weight: normal;
    src: url("calibri/Calibri.ttf") format("truetype");
}

@font-face {
    font-family: Calibri;
    font-weight: bold;
    src: url("calibri/Calibrib.ttf") format("truetype");
}

@font-face {
    font-family: Calibri;
    font-weight: normal;
    font-style: italic;
    src: url("calibri/Calibrii.ttf") format("truetype");
}

@font-face {
    font-family: Calibri;
    font-weight: 300;
    src: url("calibri/Calibril.ttf") format("truetype");
}

@font-face {
    font-family: Calibri;
    font-weight: bold;
    font-style: italic;
    src: url("calibri/Calibriz.ttf") format("truetype");
}


