@use '@angular/material' as mat;
@use "sass:map";

@mixin bracelit-image-preview-theme($theme) {
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  .move-image-action-container {
    &:hover {
      color: mat.m2-get-color-from-palette($primary);
    }
  }

}
