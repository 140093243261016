@use '@angular/material' as mat;

@mixin theming-classes($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);


  $primary-50: mat.get-color-from-palette($primary, 50);
  $primary-100: mat.get-color-from-palette($primary, 100);
  $primary-200: mat.get-color-from-palette($primary, 200);
  $primary-800: mat.get-color-from-palette($primary, 800);
  $secondary: mat.get-color-from-palette($primary, A100);
  $secondary-opacity-01: mat.get-color-from-palette($primary, A100, 0.1);
  $tertiary: mat.get-color-from-palette($primary, A400);
  $accent-200: mat.get-color-from-palette($accent, 200);
  $accent-800: mat.get-color-from-palette($accent, 800);
  $primary-contrast: mat.define-palette($primary, 100);

  .primary-contrast-text-color {
    color: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

  .primary-color {
    color: mat.get-color-from-palette($primary) !important;
  }

  .primary-color-200 {
    color: $primary-200 !important;
  }

  .primary-fill {
    fill: mat.get-color-from-palette($primary) !important;
  }

  .secondary-fill {
    fill: $secondary !important;
  }

  .tertiary-fill {
    fill: mat.get-color-from-palette($accent) !important;
  }

  .primary-border-50 {
    border-color: $primary-50 !important;
  }

  .primary-border-100 {
    border-color: $primary-100 !important;
  }

  .primary-border-200 {
    border-color: $primary-200 !important;
  }

  .primary-indicator {
    .mat-expansion-indicator:after {
      color: mat.get-color-from-palette($primary);
    }
  }

  .contrast-background {
    background-color: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

  .primary-color-small {
    color: mat.get-color-from-palette($primary, 0.6);
  }

  .primary-background {
    background-color: mat.get-color-from-palette($primary) !important;
  }

  .primary-background-50 {
    background-color: $primary-50 !important;
  }

  .primary-background-200 {
    background-color: $primary-200 !important;
  }

  .primary-background-800 {
    background-color: $primary-800 !important;
  }

  .primary-background-800-2 {
    background-color: $primary-800 !important;
    color: white !important;
  }

  .primary-background-opacity-01 {
    background-color: mat.get-color-from-palette($primary, 0.1) !important;
  }

  .secondary-color {
    color: $secondary !important;
  }

  .secondary-background {
    background-color: $secondary !important;
  }

  .secondary-background-opacity-01 {
    background-color: $secondary-opacity-01 !important;
  }

  .secondary-border {
    border-color: $secondary !important;
  }

  .tertiary-color {
    color: $tertiary !important;
  }

  .tertiary-background {
    // background-color: $tertiary !important;
    background-image: linear-gradient(
        45deg, $secondary, $tertiary) !important;
  }

  .tertiary-border {
    border-color: $tertiary !important;
  }

  .dark-background {
    background-color: black !important;
  }

  .white-opacity-background {
    background-color: rgba(255, 255, 255, 0.94);
  }

  .white-background {
    background-color: rgba(255, 255, 255, 1);
  }

  .primary-soft-background {
    background-color: mat.get-color-from-palette($primary, 0.1) !important;

    &:hover {
      background-color: mat.get-color-from-palette($primary, 0.15) !important;
    }
  }

  .primary-border {
    border-color: mat.get-color-from-palette($primary) !important;
  }

  .default-background {
    background-color: mat.get-color-from-palette($background, background) !important;
  }

  .hover-primary {
    &:hover {
      color: mat.get-color-from-palette($primary) !important;
    }
  }

  .hover-primary-background {
    &:hover {
      background-color: mat.get-color-from-palette($primary) !important;
    }
  }

  .hover-background {
    &:hover {
      background: mat.get-color-from-palette($background, hover, 0.03) !important;
    }
  }

  .warn-color {
    color: mat.get-color-from-palette($warn) !important;
  }

  .warn-border {
    border-color: mat.get-color-from-palette($warn) !important;
  }

  .accent-color {
    color: mat.get-color-from-palette($accent) !important;
  }

  .accent-background {
    background-color: mat.get-color-from-palette($accent) !important;
  }

  .accent-background-800 {
    background-color: $accent-800 !important;
  }

  .foreground-text-color {
    color: mat.get-color-from-palette($foreground, text) !important;
  }

  .foreground-secondary-text-color {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }

  .foreground-secondary-text-color-soft {
    color: mat.get-color-from-palette($foreground, secondary-text, 0.36);
  }

  .divider-color {
    border-color: mat.get-color-from-palette($foreground, divider);
  }

  .dialog-table {
    tr {
      border-top-color: mat.get-color-from-palette($foreground, divider);

      .action-icon {
        color: transparent;
      }

      .dialog-custom-input {
        border-bottom-color: mat.get-color-from-palette($foreground, secondary-text, 0.25);

        &:hover {
          border-bottom-color: mat.get-color-from-palette($foreground, text);
        }

        &:focus {
          border-bottom-color: mat.get-color-from-palette($primary);
        }
      }

      &:hover {
        background: mat.get-color-from-palette($background, hover, 0.03);

        .action-icon {
          color: mat.get-color-from-palette($foreground, secondary-text);
        }

        .dialog-custom-input {
          background: transparent;
        }
      }
    }
  }

  .dialog-div-container {
    .dialog-div-container-remove {
      color: transparent;
    }

    &:hover {
      .dialog-div-container-remove {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
    }
  }

  .card-table {
    tr {
      border-top-color: mat.get-color-from-palette($foreground, divider);

      .action-icon {
        color: transparent;

        button {
          color: transparent;
        }
      }

      &:hover {
        background: mat.get-color-from-palette($background, hover, 0.03);

        .action-icon {
          color: mat.get-color-from-palette($foreground, secondary-text);

          button {
            color: mat.get-color-from-palette($primary);
          }

          &:hover {
            color: mat.get-color-from-palette($primary);
          }
        }
      }
    }
  }

  .dashboard-card-content {
    tr {
      border-top-color: mat.get-color-from-palette($foreground, divider);

      .action-icon {
        color: transparent;
      }

      &:hover {
        background: mat.get-color-from-palette($background, hover, 0.03);

        .action-icon {
          color: mat.get-color-from-palette($foreground, secondary-text);

          &:hover {
            color: mat.get-color-from-palette($primary);
          }
        }
      }
    }

    .resume-table {
      border-top-color: mat.get-color-from-palette($foreground, divider);
    }
  }

  .no-hover-table {
    tr {
      &:hover {
        background: mat.get-color-from-palette($background, 'card');
      }
    }
  }

  .custom-standard-chip {
    background-color: mat.get-color-from-palette($primary, 0.1) !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .help-dashboard-card {
    .mat-icon {
      color: mat.get-color-from-palette($primary);
    }

    &:hover {
      background-color: mat.get-color-from-palette($primary);

      .mat-icon, .help-dashboard-card-text {
        color: white;
      }
    }
  }

  .room-open {
    background-color: mat.get-color-from-palette($primary) !important;
    color: white !important;

    &:hover {
      background-color: mat.get-color-from-palette($primary) !important;
      color: white !important;
    }
  }

  #public-office-page-groups {
    .public-group-button-hover {
      background-color: mat.get-color-from-palette($primary) !important;
      color: white !important;
    }
  }

  .notification-container {
    border-color: mat.get-color-from-palette($foreground, divider);

    .show-tip {
      color: transparent;
    }

    &:hover {
      background: mat.get-color-from-palette($background, hover, 0.03);

      .show-tip {
        color: mat.get-color-from-palette($primary);
      }
    }

    .notification-subject {
      color: mat.get-color-from-palette($foreground, text, 0.75);

      .mat-icon {
        color: mat.get-color-from-palette($primary);
      }
    }

    .notification-date {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }

  .message-container {
    border-color: mat.get-color-from-palette($foreground, divider);

    .show-tip {
      color: transparent;
    }

    &:hover {
      background: mat.get-color-from-palette($background, hover, 0.03);

      .show-tip {
        color: mat.get-color-from-palette($primary);
      }
    }

    .message-subject {
      color: mat.get-color-from-palette($foreground, text, 0.75);

      .mat-icon {
        color: mat.get-color-from-palette($primary);
      }
    }

    .message-date {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }

  .no-results-container {
    .no-results-icon-container {
      background: mat.get-color-from-palette($foreground, divider, 0.06);

      .mat-icon {
        color: mat.get-color-from-palette($foreground, divider, 0.12);
      }
    }

    .no-results-title {
      color: mat.get-color-from-palette($foreground, divider, 0.22);
    }
  }

  //CUSTOMIZING INPUTS FOR BRACELIT

  // INPUT OUTLINE color primary - 200
  .primary-200-outline {
    input {
      caret-color: $primary-200;
      color: mat.get-color-from-palette($primary);
    }

    label {
      color: $primary-200;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: $primary-200 !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: $primary-200;
    }
  }

  // OUTLINE PRIMARY
  mat-form-field .primary-outline {
    label {
      color: mat.get-color-from-palette($primary) !important;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: mat.get-color-from-palette($primary) !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: mat.get-color-from-palette($primary) !important;
    }
  }

  // INPUT PRIMARY (use in a mat-form-field)
  .input-primary {
    .mat-form-field-wrapper {
      .mat-form-field-underline {
        background-color: $primary-200 !important;
      }
    }

    input {
      caret-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($primary);
    }

    label {
      color: mat.get-color-from-palette($primary);
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: mat.get-color-from-palette($primary) !important;
    }

    //.mat-form-field-appearance-outline .mat-form-field-outline {
    //  color: $primary-200;
  }

  // CHECKBOX
  .bracelit-primary-checkbox {
    label {
      .mat-checkbox-inner-container {
        height: 20px;
        width: 20px;

        .mat-checkbox-frame {
          border-color: mat.get-color-from-palette($primary);
          border-width: 1px;
        }

        .mat-checkbox-ripple {
          .mat-checkbox-persistent-ripple {
            background-color: transparent !important;
          }
        }
      }

      .mat-checkbox-label {
        padding-top: 3px;
      }
    }
  }

  // BRACELIT-HEADER
  .header-primary-background {
    .mobile-header-container {
      background-color: mat.get-color-from-palette($primary);
    }
  }

}
