$easing: cubic-bezier(.6, .05, .28, .91);

html {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  min-height: 100%;
  height: 100%;
  font-size: 14px !important;
}

body {
  //margin: 64px 0 0 0;
  //height: calc(100% - 64px);
  //min-height: calc(100% - 64px);
  height: 100%;
  min-height: 100%;
  margin: 0;
}

main {
  min-height: 100%;
  position: relative;
}

#theme-container {
  height: 100%;
  //min-height: calc(100% - 64px);
  min-height: 100%;
}

section {
  position: relative;
  z-index: 1;
}

#app-content {
  padding: 1.5rem;
  height: 100%;
}

@media screen and (max-width: 721px) {
  #app-content {
    padding: 1rem 0.5rem 0 0.5rem;
  }
}

a, img, div {
  outline: 0;
}

ul {
  padding-left: 0;
}

a {
  text-decoration: none;
}

.small-error-message {
  font-size: 75%;
  padding-left: 1em;
  height: 30px;
}

.hidden {
  display: none;
}

.margin-bottom-verification {
  margin-bottom: 1.5rem !important;
}

.valign-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table-fixed {
  table-layout: fixed;
}

.dialog-secondary-header {
  font-size: 18px;
  font-weight: 500;
}

.dialog-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 1.5rem;

  th {
    height: 35px;
    text-align: left;
    padding-right: 24px;
  }

  td {
    height: 40px;
    padding-right: 24px;

    &:last-child {
      text-align: center;
    }
  }

  .action-icon {
    cursor: pointer;
  }
}

.dialog-div-container {
  padding: 0 2rem 1.5rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px dashed;
  position: relative;

  .dialog-div-container-remove {
    position: absolute;
    right: 0;
    top: 50%;
    cursor: pointer;
  }
}

.dialog-table-buttons {
  margin-bottom: 2rem;

  .button-primary-border {
    border-width: 1px;
    border-style: solid;
    text-transform: uppercase;
    overflow: hidden;
  }
}

.space {
  width: 100%;
  height: 20px;
}

.bold-text {
  font-weight: 500;
}

.full-height {
  height: 100% !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.crop-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.5em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  //text-align: justify;
  text-align: end;
  //margin-right: -1em;
  //padding-right: 1em;
  margin-right: -1.3em;
  padding-right: 2.2em;
  &:before {
    content: '...';
    position: absolute;
    //right: 0;
    right: 1.5em;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    //right: 0;
    right: 1.2em;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

.block-with-text {
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  @include multiLineEllipsis($lineHeight: 1.5em, $lineCount: 2, $bgColor: white);
}

.margin-right {
  margin-right: 30px;
}

.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

.center-margin {
  margin: 0 auto !important;
}

.move-content {
  margin-left: 250px;
  transition: all 350ms $easing;
}

.not-move-content {
  margin-left: 58px;
  transition: all 350ms $easing;
}

@media only screen and (max-width: 993px) {
  .move-content {
    margin-left: 0;
  }
}

.admin-panel {
  padding: 20px 120px 115px 120px;
}

@media screen and (max-width: 1400px) {
  .admin-panel {
    padding: 20px 90px 115px 90px;
  }
}

@media screen and (max-width: 1200px) {
  .admin-panel {
    padding: 20px 60px 115px 60px;
  }
}

@media screen and (max-width: 800px) {
  .admin-panel {
    padding: 20px 30px 115px 30px;
  }
}

@media screen and (max-width: 600px) {
  .admin-panel {
    padding: 10px 10px 95px 10px;
  }
}

/* Angular Material tweaks */

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon.help svg {
  height: 15px;
  width: 15px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

.mat-fab .mat-button-wrapper {
  padding: 0 !important;
}

.mat-form-field-prefix {
  margin-right: 0.65rem;
}

.icon-container {
  margin-bottom: 1rem;
}

.mat-button, .mat-raised-button {
  .mat-icon {
    margin-right: 10px;
  }
}

.mat-tab-nav-bar {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
}

.mat-tab-links {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
}

.button-border {
  border-width: 1px !important;
  border-style: solid !important;
  text-transform: uppercase;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.custom-standard-chip {
  font-size: 12px !important;
  font-weight: 300 !important;
  margin: 4px 2px 4px 6px !important;
}

.mat-standard-chip {
  min-height: 26px !important;
}

@media only screen and (max-width: 600px) {
  .mat-button, .mat-raised-button {
    padding: 0 8px !important;

    .mat-icon {
      margin-right: 0 !important;
    }
  }
}

// CSS TO REMOVE CHROMES YELLOW BACKGROUND COLOR ON AUTOFILLED INPUTS

$c-white: white;
$c-blue: none;

input:-webkit-autofill {
  -webkit-text-fill-color: $c-white !important;
  background-color: $c-white !important;
  -webkit-box-shadow: 0 0 0px 10px $c-blue inset;
  transition: background-color 5000s ease-in-out 0s;
}

.mat-input-element[type=datetime-local]:after {
  content: none !important;
}
