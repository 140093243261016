bracelit-form-item {
 .mat-form-field {
   ::placeholder {
     color: #8BACBE !important;
   }

   .mat-form-field-wrapper {
     .mat-form-field-flex {
       height: 40px;
       margin: 0 !important;

       .mat-form-field-outline {
         color: #88A9BB;
         height: 40px;
       }

       .mat-form-field-infix {
         top: 3px;
         padding: 0 !important;
         border: 0 !important;
         height: 40px;

         input {
           margin: 0 !important;
           height: 40px;
           font-size: 16px;
           font-family: Calibri, sans-serif;
           font-weight: 300;
           color: #0F223E !important;
           -webkit-text-fill-color: #0F223E !important;
         }

         input[name="email"] {
           margin: 0 !important;
           height: 40px;
           font-size: 16px;
           font-family: Calibri, sans-serif;
           font-weight: 300;
           color: white !important;
           -webkit-text-fill-color: white !important;
         }

         input[name="password"] {
           margin: 0 !important;
           height: 40px;
           font-size: 16px;
           font-family: Calibri, sans-serif;
           font-weight: 300;
           color: white !important;
           -webkit-text-fill-color: white !important;
         }

         .mat-select {
           .mat-select-trigger {
             // top: 10px;
             margin: 0 !important;
             height: 40px;
             font-size: 16px;
             font-family: Calibri, sans-serif;
             font-weight: 300;
             color: #0F223E !important;
             -webkit-text-fill-color: #0F223E !important;
           }
         }
       }
     }
   }
 }
}

.input-label {
  font-weight: 300;
}

button {
  &.default, &.secondary {
    border-radius: 8px;
    height: 45px;
    font-family: Calibri, sans-serif;
    font-size: 16px;
    letter-spacing: -0.39px;
    padding: 0 40px !important;
    text-transform: uppercase;
  }

  &.default {
    background-color: rgba(201, 201, 201, 0.15) !important;
  }

  &.secondary {
    background-color: #00A5B5 !important;
    box-shadow: 0 2px 4px 1px #00707B;
    color: white !important;

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  &.secondary2 {
    border-radius: 8px;
    height: 45px;
    font-family: Calibri, sans-serif;
    font-size: 13px;
    padding: 0 -10px !important;
    text-transform: uppercase;
    background-color: #00A5B5 !important;
    box-shadow: 0 2px 4px 1px #00707B;
    color: white !important;
    width: 33.33%;
  }
}

mat-dialog-container {
  border-radius: 7px !important;
  box-shadow: 0 2px 9px 4px rgba(24, 90, 125, 0.19) !important;
}
