@use '@angular/material' as mat;
@import 'node_modules/@angular/material/theming';

@mixin bracelit-selector-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $is-dark-theme: map-get($theme, is-dark);
  $label-color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.7, 0.6));

  .general-container {
    .label-wrapper {
      label {
        color: mat.get-color-from-palette($primary) !important;
        background-color: white;
        &.disabled {
          color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.4, 0.38));
        }
        &.selector-error {
          color: mat.get-color-from-palette($warn);
        }
      }
      .background-label {
        background-color: mat.get-color-from-palette($background, background);
      }
    }

    .bracelit-selector {
      .bracelit-selector-container {
        border-color: mat.get-color-from-palette($primary) !important;
        > div.single {
          > div.placeholder {
            color: #0f223e !important;
          }
          > div.clear,
          > div.toggle {
            color: mat.get-color-from-palette($foreground, secondary-text);
            &:hover {
              color: mat.get-color-from-palette($foreground, text);
              // background: mat-color($background, hover, 0.12);
            }
          }
        }
        &:hover {
          border-color: mat.get-color-from-palette($foreground, text);
        }
        &:focus, &.open {
          border-color: mat.get-color-from-palette($primary);
          > div.single {
            > div.toggle {
              color: mat.get-color-from-palette($primary);
              &:hover {
                color: mat.get-color-from-palette($primary);
              }
            }
          }
        }
        &.error-border {
          border-color: mat.get-color-from-palette($warn);
        }
        &.disabled {
          border-color: mat.get-color-from-palette($foreground, divider, 0.06);
          color: mat.get-color-from-palette($foreground, disabled-text);
        }
      }
    }

    .bracelit-dropdown {
      background: mat.get-color-from-palette($background, card);
      .selected {
        background: mat.get-color-from-palette($background, hover, 0.12);
        color: mat.get-color-from-palette($primary);
        &.highlighted {
          background: mat.get-color-from-palette($background, hover, 0.12);
        }
      }
      .highlighted {
        background: mat.get-color-from-palette($background, hover, 0.04);
      }
    }

    .selector-error {
      color: mat.get-color-from-palette($warn);
    }

    .disabled {
      color: mat.get-color-from-palette($foreground, disabled-text);
    }

    &.standard {
      .bracelit-selector {
        .bracelit-selector-container {
          border-color: mat.get-color-from-palette($foreground, divider, 0.42);
          &:hover {
            border-color: mat.get-color-from-palette($foreground, text);
          }
          &:focus, &.open {
            border-color: mat.get-color-from-palette($primary);
          }
          &.error-border {
            border-color: mat.get-color-from-palette($warn);
          }
          &.disabled {
            border-color: mat.get-color-from-palette($foreground, divider, 0.06);
            color: mat.get-color-from-palette($foreground, disabled-text);
          }
        }
      }
    }
  }
}
