@use '@angular/material' as mat;
@use "sass:map";

@mixin bracelit-text-classes($theme) {
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  $accent: map.get($theme, accent);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);
  $primary-200: mat.m2-get-color-from-palette($primary, 200);
  $primary-contrast: mat.m2-get-contrast-color-from-palette($primary, 500);
  $secondary: mat.m2-get-color-from-palette($primary, A100);
  $tertiary: mat.m2-get-color-from-palette($primary, A400);

  .light-14px-left-primary {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.34px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-14px-right-primary {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.34px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-14px-center-primary {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.34px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .bold-16px-left-primary {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .bold-16px-right-primary {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: right !important;
    letter-spacing: -0.39px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .bold-16px-center-primary {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .regular-16px-left-primary {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .regular-16px-center-primary {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-16px-left-primary {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-16px-right-primary {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.39px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-16px-left-primary-200 {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: $primary-200 !important;
  }

  .light-16px-center-primary {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-16px-left-white {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.39px !important;
    color: $primary-contrast !important;
  }

  .light-16px-center-white {
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.39px !important;
    color: $primary-contrast !important;
  }

  .regular-16px-center-white {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: $primary-contrast !important;
  }

  .bold-18px-right-primary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: right !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .bold-18px-left-primary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .bold-400-18px-left-primary {
    font-size: 18px !important;
    font-weight: 400 !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .bold-400-18px-center-primary {
    font-size: 18px !important;
    font-weight: 400 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-18px-left-primary {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-18px-left-white {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: $primary-contrast !important;
  }

  .light-18px-right-white {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.43px !important;
    color: $primary-contrast !important;
  }

  .bold-18px-right-primary {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: right !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .regular-18px-center-primary {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .regular-18px-center-accent {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($accent) !important;
  }

  .light-18px-center-accent {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($accent) !important;
  }

  .light-18px-center-primary {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .regular-18px-center-primary {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .regular-18px-left-primary {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-18px-center-primary {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .regular-18px-center-white {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-18px-center-white {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: $primary-contrast !important;
  }

  .regular-18px-left-primary-200 {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: $primary-200 !important;
  }

  .regular-18px-right-primary {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: right !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-18px-left-secondary {
    font-size: 18px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.43px !important;
    color: $secondary !important;
  }
  .bold-20px-center-primary {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .bold-20px-center-warn {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: mat.m2-get-color-from-palette($warn) !important;
  }

  .regular-18px-center-warn {
    font-size: 18px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.43px !important;
    color: mat.m2-get-color-from-palette($warn) !important;
  }

  .bold-20px-left-primary {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-20px-left-primary {
    font-size: 20px !important;
    font-weight: 300 !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .regular-20px-left-secondary {
    font-size: 20px !important;
    font-weight: normal !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: $secondary !important;
  }

  .regular-20px-center-secondary {
    font-size: 20px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: $secondary !important;
  }

  .regular-20px-center-tertiary {
    font-size: 20px !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: -0.48px !important;
    color: $tertiary !important;
  }

  .bold-20px-left-tertiary {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.48px !important;
    color: $tertiary !important;
  }

  .bold-22px-left-primary {
    font-size: 22px !important;
    font-weight: bold !important;
    text-align: left !important;
    letter-spacing: -0.53px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-22px-center-primary {
    font-size: 22px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.53px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-22px-center-white {
    font-size: 22px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.53px !important;
    line-height: 30px !important;
    color: $primary-contrast !important;
  }

  .light-30px-right-primary {
    font-size: 30px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.96px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-30px-center-primary {
    font-size: 30px !important;
    font-weight: 300 !important;
    text-align: center !important;
    letter-spacing: -0.96px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

  .light-40px-right-primary {
    font-size: 40px !important;
    font-weight: 300 !important;
    text-align: right !important;
    letter-spacing: -0.96px !important;
    color: mat.m2-get-color-from-palette($primary) !important;
  }

}

* {
  font-family: Calibri;
}

