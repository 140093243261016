@charset "UTF-8";
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/*// BRACELIT PRIMARY
$bracelit-bracelit: (
    50 : #e3ebef, // #e3ebef // border primary_50
    100 : #baced8, // #baced8 // border primary_100
    200 : #8bacbe, // #8cadbe  border primary_200
    300 : #5d8ca4,
    400 : #3b7391,
    500 : #185a7d, // primary
    600 : #155275,
    700 : #11486a,
    800 : #0e3f60, // #0e3f60 // fill_primary_800
    900 : #082e4d,
  //A100 : #82bfff, // A SON LOS ACCENT COLORS
  //A200 : #4fa6ff,
  //A400 : #1c8cff,
  //A700 : #037fff,
  // A SON LOS ACCENT COLORS ADAPTADOS AL DISEÑO
    A100 : #6cc4cd, // tertiary
    A200 : #4fafba,
    A400 : #00a5b5, // secondary
    A700 : #008a9d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);*/
* {
  font-family: Calibri;
}

.bracelit-bracelit-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-bracelit-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-bracelit-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-bracelit-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-bracelit-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0f223e;
}
.bracelit-bracelit-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-bracelit-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-bracelit-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-bracelit-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0f223e;
}
.bracelit-bracelit-theme .mat-pseudo-checkbox-checked,
.bracelit-bracelit-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-bracelit-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-bracelit-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #5df897;
}
.bracelit-bracelit-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-bracelit-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-bracelit-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-bracelit-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-bracelit-theme .mat-app-background, .bracelit-bracelit-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-bracelit-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-bracelit-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-badge {
  position: relative;
}
.bracelit-bracelit-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-bracelit-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-bracelit-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-bracelit-theme .ng-animate-disabled .mat-badge-content,
.bracelit-bracelit-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-bracelit-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-bracelit-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-bracelit-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-bracelit-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-bracelit-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-bracelit-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-bracelit-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-bracelit-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-bracelit-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-bracelit-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-bracelit-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-bracelit-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-bracelit-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-bracelit-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-bracelit-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-bracelit-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-bracelit-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-bracelit-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-bracelit-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-bracelit-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-bracelit-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-bracelit-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-bracelit-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-bracelit-theme .mat-badge-content {
  color: white;
  background: #0f223e;
}
.cdk-high-contrast-active .bracelit-bracelit-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-bracelit-theme .mat-badge-accent .mat-badge-content {
  background: #5df897;
  color: black;
}
.bracelit-bracelit-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-bracelit-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-button, .bracelit-bracelit-theme .mat-icon-button, .bracelit-bracelit-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-bracelit-theme .mat-button.mat-primary, .bracelit-bracelit-theme .mat-icon-button.mat-primary, .bracelit-bracelit-theme .mat-stroked-button.mat-primary {
  color: #0f223e;
}
.bracelit-bracelit-theme .mat-button.mat-accent, .bracelit-bracelit-theme .mat-icon-button.mat-accent, .bracelit-bracelit-theme .mat-stroked-button.mat-accent {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-button.mat-warn, .bracelit-bracelit-theme .mat-icon-button.mat-warn, .bracelit-bracelit-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-bracelit-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-bracelit-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-bracelit-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-bracelit-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-bracelit-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-bracelit-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-bracelit-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-bracelit-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-bracelit-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-bracelit-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-bracelit-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-bracelit-theme .mat-button .mat-ripple-element, .bracelit-bracelit-theme .mat-icon-button .mat-ripple-element, .bracelit-bracelit-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-bracelit-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-bracelit-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-flat-button, .bracelit-bracelit-theme .mat-raised-button, .bracelit-bracelit-theme .mat-fab, .bracelit-bracelit-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-bracelit-theme .mat-flat-button.mat-primary, .bracelit-bracelit-theme .mat-raised-button.mat-primary, .bracelit-bracelit-theme .mat-fab.mat-primary, .bracelit-bracelit-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-bracelit-theme .mat-flat-button.mat-accent, .bracelit-bracelit-theme .mat-raised-button.mat-accent, .bracelit-bracelit-theme .mat-fab.mat-accent, .bracelit-bracelit-theme .mat-mini-fab.mat-accent {
  color: black;
}
.bracelit-bracelit-theme .mat-flat-button.mat-warn, .bracelit-bracelit-theme .mat-raised-button.mat-warn, .bracelit-bracelit-theme .mat-fab.mat-warn, .bracelit-bracelit-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-bracelit-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-bracelit-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-bracelit-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-bracelit-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-bracelit-theme .mat-flat-button.mat-primary, .bracelit-bracelit-theme .mat-raised-button.mat-primary, .bracelit-bracelit-theme .mat-fab.mat-primary, .bracelit-bracelit-theme .mat-mini-fab.mat-primary {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-flat-button.mat-accent, .bracelit-bracelit-theme .mat-raised-button.mat-accent, .bracelit-bracelit-theme .mat-fab.mat-accent, .bracelit-bracelit-theme .mat-mini-fab.mat-accent {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-flat-button.mat-warn, .bracelit-bracelit-theme .mat-raised-button.mat-warn, .bracelit-bracelit-theme .mat-fab.mat-warn, .bracelit-bracelit-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-bracelit-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-bracelit-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-bracelit-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-bracelit-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-bracelit-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-bracelit-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-bracelit-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-bracelit-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-bracelit-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-bracelit-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-bracelit-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-bracelit-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-bracelit-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-bracelit-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-bracelit-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-bracelit-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-bracelit-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-bracelit-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-bracelit-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-bracelit-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-bracelit-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-bracelit-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-bracelit-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-bracelit-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-bracelit-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-bracelit-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-bracelit-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-bracelit-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-bracelit-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-bracelit-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-bracelit-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-bracelit-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-bracelit-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-bracelit-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-bracelit-theme .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.bracelit-bracelit-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-bracelit-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-bracelit-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-bracelit-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-bracelit-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-bracelit-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-bracelit-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-bracelit-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-bracelit-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-bracelit-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-bracelit-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-bracelit-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0f223e;
}
.bracelit-bracelit-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-bracelit-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #5df897;
}
.bracelit-bracelit-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-bracelit-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0f223e;
  color: white;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #5df897;
  color: black;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.bracelit-bracelit-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-bracelit-theme .mat-table {
  background: white;
}
.bracelit-bracelit-theme .mat-table thead, .bracelit-bracelit-theme .mat-table tbody, .bracelit-bracelit-theme .mat-table tfoot,
.bracelit-bracelit-theme mat-header-row, .bracelit-bracelit-theme mat-row, .bracelit-bracelit-theme mat-footer-row,
.bracelit-bracelit-theme [mat-header-row], .bracelit-bracelit-theme [mat-row], .bracelit-bracelit-theme [mat-footer-row],
.bracelit-bracelit-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-bracelit-theme mat-row, .bracelit-bracelit-theme mat-header-row, .bracelit-bracelit-theme mat-footer-row,
.bracelit-bracelit-theme th.mat-header-cell, .bracelit-bracelit-theme td.mat-cell, .bracelit-bracelit-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-cell, .bracelit-bracelit-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-datepicker-toggle,
.bracelit-bracelit-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-bracelit-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-calendar-table-header,
.bracelit-bracelit-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-calendar-body-cell-content,
.bracelit-bracelit-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-bracelit-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-bracelit-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-bracelit-theme .mat-calendar-body-in-range::before {
  background: rgba(15, 34, 62, 0.2);
}
.bracelit-bracelit-theme .mat-calendar-body-comparison-identical,
.bracelit-bracelit-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-bracelit-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-bracelit-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(15, 34, 62, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-bracelit-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-bracelit-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(15, 34, 62, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-bracelit-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-bracelit-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-bracelit-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-bracelit-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-bracelit-theme .mat-calendar-body-selected {
  background-color: #0f223e;
  color: white;
}
.bracelit-bracelit-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(15, 34, 62, 0.4);
}
.bracelit-bracelit-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-bracelit-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-bracelit-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(15, 34, 62, 0.3);
}
@media (hover: hover) {
  .bracelit-bracelit-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(15, 34, 62, 0.3);
  }
}
.bracelit-bracelit-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(93, 248, 151, 0.2);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(93, 248, 151, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(93, 248, 151, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #5df897;
  color: black;
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(93, 248, 151, 0.4);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-bracelit-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(93, 248, 151, 0.3);
}
@media (hover: hover) {
  .bracelit-bracelit-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(93, 248, 151, 0.3);
  }
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-bracelit-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-bracelit-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-bracelit-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-datepicker-toggle-active {
  color: #0f223e;
}
.bracelit-bracelit-theme .mat-datepicker-toggle-active.mat-accent {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-bracelit-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-bracelit-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-bracelit-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-bracelit-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-expansion-panel-header-description,
.bracelit-bracelit-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-bracelit-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-bracelit-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-bracelit-theme .mat-expansion-panel-header {
  height: 48px;
}
.bracelit-bracelit-theme .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.bracelit-bracelit-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-bracelit-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-bracelit-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #0f223e;
}
.bracelit-bracelit-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-focused .mat-form-field-required-marker {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0f223e;
}
.bracelit-bracelit-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-bracelit-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-bracelit-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-error {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-bracelit-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-bracelit-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-bracelit-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-bracelit-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-bracelit-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0f223e;
}
.bracelit-bracelit-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-bracelit-theme .mat-icon.mat-primary {
  color: #0f223e;
}
.bracelit-bracelit-theme .mat-icon.mat-accent {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-input-element:disabled,
.bracelit-bracelit-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-input-element {
  caret-color: #0f223e;
}
.bracelit-bracelit-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #5df897;
}
.bracelit-bracelit-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-bracelit-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-list-option:hover, .bracelit-bracelit-theme .mat-list-option:focus,
.bracelit-bracelit-theme .mat-nav-list .mat-list-item:hover,
.bracelit-bracelit-theme .mat-nav-list .mat-list-item:focus,
.bracelit-bracelit-theme .mat-action-list .mat-list-item:hover,
.bracelit-bracelit-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-bracelit-theme .mat-list-single-selected-option, .bracelit-bracelit-theme .mat-list-single-selected-option:hover, .bracelit-bracelit-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-menu-panel {
  background: white;
}
.bracelit-bracelit-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-menu-item[disabled],
.bracelit-bracelit-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-bracelit-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-menu-item .mat-icon-no-color,
.bracelit-bracelit-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-menu-item:hover:not([disabled]),
.bracelit-bracelit-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-bracelit-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-bracelit-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-bracelit-theme .mat-paginator {
  background: white;
}
.bracelit-bracelit-theme .mat-paginator,
.bracelit-bracelit-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-paginator-decrement,
.bracelit-bracelit-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-paginator-first,
.bracelit-bracelit-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-bracelit-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-bracelit-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-bracelit-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-paginator-container {
  min-height: 56px;
}
.bracelit-bracelit-theme .mat-progress-bar-background {
  fill: #bfc4cb;
}
.bracelit-bracelit-theme .mat-progress-bar-buffer {
  background-color: #bfc4cb;
}
.bracelit-bracelit-theme .mat-progress-bar-fill::after {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d3fae1;
}
.bracelit-bracelit-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d3fae1;
}
.bracelit-bracelit-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-bracelit-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-bracelit-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-progress-spinner circle, .bracelit-bracelit-theme .mat-spinner circle {
  stroke: #0f223e;
}
.bracelit-bracelit-theme .mat-progress-spinner.mat-accent circle, .bracelit-bracelit-theme .mat-spinner.mat-accent circle {
  stroke: #5df897;
}
.bracelit-bracelit-theme .mat-progress-spinner.mat-warn circle, .bracelit-bracelit-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-bracelit-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0f223e;
}
.bracelit-bracelit-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-bracelit-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-bracelit-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-bracelit-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5df897;
}
.bracelit-bracelit-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-bracelit-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-bracelit-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-bracelit-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-bracelit-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-bracelit-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-bracelit-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-bracelit-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-bracelit-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-bracelit-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-bracelit-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-select-panel {
  background: white;
}
.bracelit-bracelit-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0f223e;
}
.bracelit-bracelit-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #5df897;
}
.bracelit-bracelit-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-bracelit-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-bracelit-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-bracelit-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(93, 248, 151, 0.54);
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(15, 34, 62, 0.54);
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-bracelit-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-bracelit-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-bracelit-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-bracelit-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-bracelit-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-bracelit-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-bracelit-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(15, 34, 62, 0.2);
}
.bracelit-bracelit-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-bracelit-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-bracelit-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.bracelit-bracelit-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(93, 248, 151, 0.2);
}
.bracelit-bracelit-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-bracelit-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-bracelit-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-bracelit-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-bracelit-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-bracelit-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-bracelit-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-bracelit-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-bracelit-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-bracelit-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-bracelit-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-bracelit-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-bracelit-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-bracelit-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-bracelit-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-bracelit-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-bracelit-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-bracelit-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-bracelit-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-bracelit-theme .mat-step-header.cdk-keyboard-focused, .bracelit-bracelit-theme .mat-step-header.cdk-program-focused, .bracelit-bracelit-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-bracelit-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-bracelit-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-bracelit-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-bracelit-theme .mat-step-header .mat-step-label,
.bracelit-bracelit-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-bracelit-theme .mat-step-header .mat-step-icon-selected,
.bracelit-bracelit-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-bracelit-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #0f223e;
  color: white;
}
.bracelit-bracelit-theme .mat-step-header.mat-accent .mat-step-icon {
  color: black;
}
.bracelit-bracelit-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-bracelit-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-bracelit-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #5df897;
  color: black;
}
.bracelit-bracelit-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-bracelit-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-bracelit-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-bracelit-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-bracelit-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-bracelit-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-bracelit-theme .mat-stepper-horizontal, .bracelit-bracelit-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-bracelit-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-horizontal-stepper-header::before,
.bracelit-bracelit-theme .mat-horizontal-stepper-header::after,
.bracelit-bracelit-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-horizontal-stepper-header {
  height: 72px;
}
.bracelit-bracelit-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.bracelit-bracelit-theme .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.bracelit-bracelit-theme .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.bracelit-bracelit-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .bracelit-bracelit-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.bracelit-bracelit-theme .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.bracelit-bracelit-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-bracelit-theme .mat-tab-nav-bar,
.bracelit-bracelit-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-bracelit-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-bracelit-theme .mat-tab-label, .bracelit-bracelit-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-tab-label.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-bracelit-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-bracelit-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 189, 197, 0.3);
}
.bracelit-bracelit-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-bracelit-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 245, 223, 0.3);
}
.bracelit-bracelit-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.bracelit-bracelit-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-bracelit-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 189, 197, 0.3);
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 245, 223, 0.3);
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #5df897;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-bracelit-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-bracelit-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-toolbar.mat-primary {
  background: #0f223e;
  color: white;
}
.bracelit-bracelit-theme .mat-toolbar.mat-accent {
  background: #5df897;
  color: black;
}
.bracelit-bracelit-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-bracelit-theme .mat-toolbar .mat-form-field-underline,
.bracelit-bracelit-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-bracelit-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-bracelit-theme .mat-toolbar .mat-form-field-label,
.bracelit-bracelit-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-bracelit-theme .mat-toolbar .mat-select-value,
.bracelit-bracelit-theme .mat-toolbar .mat-select-arrow,
.bracelit-bracelit-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-bracelit-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-bracelit-theme .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.bracelit-bracelit-theme .mat-toolbar-row, .bracelit-bracelit-theme .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .bracelit-bracelit-theme .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .bracelit-bracelit-theme .mat-toolbar-row, .bracelit-bracelit-theme .mat-toolbar-single-row {
    height: 56px;
  }
}
.bracelit-bracelit-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-bracelit-theme .mat-tree {
  background: white;
}
.bracelit-bracelit-theme .mat-tree-node,
.bracelit-bracelit-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .mat-tree-node {
  min-height: 48px;
}
.bracelit-bracelit-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .mat-simple-snackbar-action {
  color: #5df897;
}
.bracelit-bracelit-theme .back-container {
  color: #0f223e;
}
.bracelit-bracelit-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-bracelit-theme .warn-text {
  color: #e74737;
}
.bracelit-bracelit-theme .inside-modal-title.primary-colored-title, .bracelit-bracelit-theme .form-title.primary-colored-title {
  color: #0f223e;
}
.bracelit-bracelit-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-bracelit-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-bracelit-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-bracelit-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .box-checkbox.active-box-checkbox {
  background-color: #0f223e;
  color: white;
}
.bracelit-bracelit-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .box-radio.active-box-radio {
  background-color: #0f223e;
  color: white;
}
.bracelit-bracelit-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .error {
  color: #e74737;
}
.bracelit-bracelit-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-bracelit-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-bracelit-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-bracelit-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-bracelit-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-bracelit-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-bracelit-theme .ql-snow .ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:hover,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:hover,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:focus,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:focus,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-bracelit-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #0f223e;
  height: 38px !important;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-bracelit-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-bracelit-theme .general-container .label-wrapper label {
  color: #0f223e !important;
  background-color: white;
}
.bracelit-bracelit-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-bracelit-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #0f223e !important;
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #0f223e;
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #0f223e;
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #0f223e;
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-bracelit-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-bracelit-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #0f223e;
}
.bracelit-bracelit-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-bracelit-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-bracelit-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-bracelit-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-bracelit-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #0f223e;
}
.bracelit-bracelit-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-bracelit-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-bracelit-theme .move-image-action-container:hover {
  color: #0f223e;
}
.bracelit-bracelit-theme .info-icon {
  color: #0f223e;
}
.bracelit-bracelit-theme .email-chip {
  color: #0f223e !important;
  background-color: rgba(15, 34, 62, 0.15) !important;
}
.bracelit-bracelit-theme .email-chip .mat-chip-remove {
  color: #0f223e !important;
  opacity: 1 !important;
}
.bracelit-bracelit-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-bracelit-theme .primary-color {
  color: #0f223e !important;
}
.bracelit-bracelit-theme .primary-color-200 {
  color: #87919f !important;
}
.bracelit-bracelit-theme .primary-fill {
  fill: #0f223e !important;
}
.bracelit-bracelit-theme .secondary-fill {
  fill: #18a1b9 !important;
}
.bracelit-bracelit-theme .tertiary-fill {
  fill: #5df897 !important;
}
.bracelit-bracelit-theme .primary-border-50 {
  border-color: #e2e4e8 !important;
}
.bracelit-bracelit-theme .primary-border-100 {
  border-color: #b7bdc5 !important;
}
.bracelit-bracelit-theme .primary-border-200 {
  border-color: #87919f !important;
}
.bracelit-bracelit-theme .primary-indicator .mat-expansion-indicator:after {
  color: #0f223e;
}
.bracelit-bracelit-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-bracelit-theme .primary-color-small {
  color: rgba(15, 34, 62, 0.6);
}
.bracelit-bracelit-theme .primary-background {
  background-color: #0f223e !important;
}
.bracelit-bracelit-theme .primary-background-50 {
  background-color: #e2e4e8 !important;
}
.bracelit-bracelit-theme .primary-background-200 {
  background-color: #87919f !important;
}
.bracelit-bracelit-theme .primary-background-800 {
  background-color: #081428 !important;
}
.bracelit-bracelit-theme .primary-background-800-2 {
  background-color: #081428 !important;
  color: white !important;
}
.bracelit-bracelit-theme .primary-background-opacity-01 {
  background-color: rgba(15, 34, 62, 0.1) !important;
}
.bracelit-bracelit-theme .secondary-color {
  color: #18a1b9 !important;
}
.bracelit-bracelit-theme .secondary-background {
  background-color: #18a1b9 !important;
}
.bracelit-bracelit-theme .secondary-background-opacity-01 {
  background-color: rgba(24, 161, 185, 0.1) !important;
}
.bracelit-bracelit-theme .secondary-border {
  border-color: #18a1b9 !important;
}
.bracelit-bracelit-theme .tertiary-color {
  color: #5df897 !important;
}
.bracelit-bracelit-theme .tertiary-background {
  background-image: linear-gradient(45deg, #18a1b9, #5df897) !important;
}
.bracelit-bracelit-theme .tertiary-border {
  border-color: #5df897 !important;
}
.bracelit-bracelit-theme .dark-background {
  background-color: black !important;
}
.bracelit-bracelit-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-bracelit-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-bracelit-theme .primary-soft-background {
  background-color: rgba(15, 34, 62, 0.1) !important;
}
.bracelit-bracelit-theme .primary-soft-background:hover {
  background-color: rgba(15, 34, 62, 0.15) !important;
}
.bracelit-bracelit-theme .primary-border {
  border-color: #0f223e !important;
}
.bracelit-bracelit-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-bracelit-theme .hover-primary:hover {
  color: #0f223e !important;
}
.bracelit-bracelit-theme .hover-primary-background:hover {
  background-color: #0f223e !important;
}
.bracelit-bracelit-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-bracelit-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-bracelit-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-bracelit-theme .accent-color {
  color: #5df897 !important;
}
.bracelit-bracelit-theme .accent-background {
  background-color: #5df897 !important;
}
.bracelit-bracelit-theme .accent-background-800 {
  background-color: #fedd93 !important;
}
.bracelit-bracelit-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-bracelit-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-bracelit-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-bracelit-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-bracelit-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-bracelit-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #0f223e;
}
.bracelit-bracelit-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-bracelit-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-bracelit-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-bracelit-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-bracelit-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-bracelit-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-bracelit-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .card-table tr:hover .action-icon button {
  color: #0f223e;
}
.bracelit-bracelit-theme .card-table tr:hover .action-icon:hover {
  color: #0f223e;
}
.bracelit-bracelit-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-bracelit-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-bracelit-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #0f223e;
}
.bracelit-bracelit-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-bracelit-theme .custom-standard-chip {
  background-color: rgba(15, 34, 62, 0.1) !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .help-dashboard-card .mat-icon {
  color: #0f223e;
}
.bracelit-bracelit-theme .help-dashboard-card:hover {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .help-dashboard-card:hover .mat-icon, .bracelit-bracelit-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-bracelit-theme .room-open {
  background-color: #0f223e !important;
  color: white !important;
}
.bracelit-bracelit-theme .room-open:hover {
  background-color: #0f223e !important;
  color: white !important;
}
.bracelit-bracelit-theme #public-office-page-groups .public-group-button-hover {
  background-color: #0f223e !important;
  color: white !important;
}
.bracelit-bracelit-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-bracelit-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-bracelit-theme .notification-container:hover .show-tip {
  color: #0f223e;
}
.bracelit-bracelit-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-bracelit-theme .notification-container .notification-subject .mat-icon {
  color: #0f223e;
}
.bracelit-bracelit-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-bracelit-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-bracelit-theme .message-container:hover .show-tip {
  color: #0f223e;
}
.bracelit-bracelit-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-bracelit-theme .message-container .message-subject .mat-icon {
  color: #0f223e;
}
.bracelit-bracelit-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-bracelit-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-bracelit-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-bracelit-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-bracelit-theme .primary-200-outline input {
  caret-color: #87919f;
  color: #0f223e;
}
.bracelit-bracelit-theme .primary-200-outline label {
  color: #87919f;
}
.bracelit-bracelit-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #87919f !important;
}
.bracelit-bracelit-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #87919f;
}
.bracelit-bracelit-theme mat-form-field .primary-outline label {
  color: #0f223e !important;
}
.bracelit-bracelit-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #0f223e !important;
}
.bracelit-bracelit-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #0f223e !important;
}
.bracelit-bracelit-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #87919f !important;
}
.bracelit-bracelit-theme .input-primary input {
  caret-color: #0f223e;
  color: #0f223e;
}
.bracelit-bracelit-theme .input-primary label {
  color: #0f223e;
}
.bracelit-bracelit-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #0f223e !important;
}
.bracelit-bracelit-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-bracelit-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #0f223e;
  border-width: 1px;
}
.bracelit-bracelit-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-bracelit-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-bracelit-theme .header-primary-background .mobile-header-container {
  background-color: #0f223e;
}
.bracelit-bracelit-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #87919f !important;
}
.bracelit-bracelit-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-bracelit-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-bracelit-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-bracelit-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-bracelit-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-bracelit-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #5df897 !important;
}
.bracelit-bracelit-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #5df897 !important;
}
.bracelit-bracelit-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-bracelit-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #87919f !important;
}
.bracelit-bracelit-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #18a1b9 !important;
}
.bracelit-bracelit-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-bracelit-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-bracelit-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #18a1b9 !important;
}
.bracelit-bracelit-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #18a1b9 !important;
}
.bracelit-bracelit-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #5df897 !important;
}
.bracelit-bracelit-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #5df897 !important;
}
.bracelit-bracelit-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-bracelit-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #0f223e !important;
}
.bracelit-bracelit-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #0f223e !important;
}

.bracelit-titeam-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-titeam-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-titeam-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-titeam-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-titeam-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2492a6;
}
.bracelit-titeam-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-titeam-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-titeam-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-titeam-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-titeam-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2492a6;
}
.bracelit-titeam-theme .mat-pseudo-checkbox-checked,
.bracelit-titeam-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-titeam-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-titeam-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-titeam-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-titeam-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-titeam-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-titeam-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-titeam-theme .mat-app-background, .bracelit-titeam-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-titeam-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-titeam-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-badge {
  position: relative;
}
.bracelit-titeam-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-titeam-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-titeam-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-titeam-theme .ng-animate-disabled .mat-badge-content,
.bracelit-titeam-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-titeam-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-titeam-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-titeam-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-titeam-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-titeam-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-titeam-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-titeam-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-titeam-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-titeam-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-titeam-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-titeam-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-titeam-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-titeam-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-titeam-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-titeam-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-titeam-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-titeam-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-titeam-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-titeam-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-titeam-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-titeam-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-titeam-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-titeam-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-titeam-theme .mat-badge-content {
  color: white;
  background: #2492a6;
}
.cdk-high-contrast-active .bracelit-titeam-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-titeam-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-titeam-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-button, .bracelit-titeam-theme .mat-icon-button, .bracelit-titeam-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-titeam-theme .mat-button.mat-primary, .bracelit-titeam-theme .mat-icon-button.mat-primary, .bracelit-titeam-theme .mat-stroked-button.mat-primary {
  color: #2492a6;
}
.bracelit-titeam-theme .mat-button.mat-accent, .bracelit-titeam-theme .mat-icon-button.mat-accent, .bracelit-titeam-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-button.mat-warn, .bracelit-titeam-theme .mat-icon-button.mat-warn, .bracelit-titeam-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-titeam-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-titeam-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-titeam-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-titeam-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-titeam-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-titeam-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-titeam-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-titeam-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-titeam-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-titeam-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-titeam-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-titeam-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-titeam-theme .mat-button .mat-ripple-element, .bracelit-titeam-theme .mat-icon-button .mat-ripple-element, .bracelit-titeam-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-titeam-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-titeam-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-flat-button, .bracelit-titeam-theme .mat-raised-button, .bracelit-titeam-theme .mat-fab, .bracelit-titeam-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-titeam-theme .mat-flat-button.mat-primary, .bracelit-titeam-theme .mat-raised-button.mat-primary, .bracelit-titeam-theme .mat-fab.mat-primary, .bracelit-titeam-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-titeam-theme .mat-flat-button.mat-accent, .bracelit-titeam-theme .mat-raised-button.mat-accent, .bracelit-titeam-theme .mat-fab.mat-accent, .bracelit-titeam-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-flat-button.mat-warn, .bracelit-titeam-theme .mat-raised-button.mat-warn, .bracelit-titeam-theme .mat-fab.mat-warn, .bracelit-titeam-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-titeam-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-titeam-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-titeam-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-titeam-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-titeam-theme .mat-flat-button.mat-primary, .bracelit-titeam-theme .mat-raised-button.mat-primary, .bracelit-titeam-theme .mat-fab.mat-primary, .bracelit-titeam-theme .mat-mini-fab.mat-primary {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-flat-button.mat-accent, .bracelit-titeam-theme .mat-raised-button.mat-accent, .bracelit-titeam-theme .mat-fab.mat-accent, .bracelit-titeam-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-flat-button.mat-warn, .bracelit-titeam-theme .mat-raised-button.mat-warn, .bracelit-titeam-theme .mat-fab.mat-warn, .bracelit-titeam-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-titeam-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-titeam-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-titeam-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-titeam-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-titeam-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-titeam-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-titeam-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-titeam-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-titeam-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-titeam-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-titeam-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-titeam-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-titeam-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-titeam-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-titeam-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-titeam-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-titeam-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-titeam-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-titeam-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-titeam-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-titeam-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-titeam-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-titeam-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-titeam-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-titeam-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-titeam-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-titeam-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-titeam-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-titeam-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-titeam-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-titeam-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-titeam-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-titeam-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-titeam-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-titeam-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-titeam-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-titeam-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-titeam-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-titeam-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-titeam-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-titeam-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-titeam-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-titeam-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-titeam-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-titeam-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-titeam-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #2492a6;
}
.bracelit-titeam-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-titeam-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-titeam-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-titeam-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2492a6;
  color: white;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-titeam-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-titeam-theme .mat-table {
  background: white;
}
.bracelit-titeam-theme .mat-table thead, .bracelit-titeam-theme .mat-table tbody, .bracelit-titeam-theme .mat-table tfoot,
.bracelit-titeam-theme mat-header-row, .bracelit-titeam-theme mat-row, .bracelit-titeam-theme mat-footer-row,
.bracelit-titeam-theme [mat-header-row], .bracelit-titeam-theme [mat-row], .bracelit-titeam-theme [mat-footer-row],
.bracelit-titeam-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-titeam-theme mat-row, .bracelit-titeam-theme mat-header-row, .bracelit-titeam-theme mat-footer-row,
.bracelit-titeam-theme th.mat-header-cell, .bracelit-titeam-theme td.mat-cell, .bracelit-titeam-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-cell, .bracelit-titeam-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-datepicker-toggle,
.bracelit-titeam-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-titeam-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-calendar-table-header,
.bracelit-titeam-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-calendar-body-cell-content,
.bracelit-titeam-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-titeam-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-titeam-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-titeam-theme .mat-calendar-body-in-range::before {
  background: rgba(36, 146, 166, 0.2);
}
.bracelit-titeam-theme .mat-calendar-body-comparison-identical,
.bracelit-titeam-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-titeam-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-titeam-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(36, 146, 166, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-titeam-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-titeam-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(36, 146, 166, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-titeam-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-titeam-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-titeam-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-titeam-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-titeam-theme .mat-calendar-body-selected {
  background-color: #2492a6;
  color: white;
}
.bracelit-titeam-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(36, 146, 166, 0.4);
}
.bracelit-titeam-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-titeam-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-titeam-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(36, 146, 166, 0.3);
}
@media (hover: hover) {
  .bracelit-titeam-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(36, 146, 166, 0.3);
  }
}
.bracelit-titeam-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-titeam-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-titeam-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-titeam-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-titeam-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-titeam-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-titeam-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-titeam-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-titeam-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-titeam-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-datepicker-toggle-active {
  color: #2492a6;
}
.bracelit-titeam-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-titeam-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-titeam-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-titeam-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-titeam-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-titeam-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-expansion-panel-header-description,
.bracelit-titeam-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-titeam-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-titeam-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-titeam-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-titeam-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-titeam-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #2492a6;
}
.bracelit-titeam-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-titeam-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #2492a6;
}
.bracelit-titeam-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-titeam-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-titeam-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-error {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-titeam-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-titeam-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-titeam-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-titeam-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-titeam-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2492a6;
}
.bracelit-titeam-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-titeam-theme .mat-icon.mat-primary {
  color: #2492a6;
}
.bracelit-titeam-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-input-element:disabled,
.bracelit-titeam-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-input-element {
  caret-color: #2492a6;
}
.bracelit-titeam-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-titeam-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-titeam-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-titeam-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-titeam-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-list-option:hover, .bracelit-titeam-theme .mat-list-option:focus,
.bracelit-titeam-theme .mat-nav-list .mat-list-item:hover,
.bracelit-titeam-theme .mat-nav-list .mat-list-item:focus,
.bracelit-titeam-theme .mat-action-list .mat-list-item:hover,
.bracelit-titeam-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-titeam-theme .mat-list-single-selected-option, .bracelit-titeam-theme .mat-list-single-selected-option:hover, .bracelit-titeam-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-menu-panel {
  background: white;
}
.bracelit-titeam-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-menu-item[disabled],
.bracelit-titeam-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-titeam-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-menu-item .mat-icon-no-color,
.bracelit-titeam-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-menu-item:hover:not([disabled]),
.bracelit-titeam-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-titeam-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-titeam-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-titeam-theme .mat-paginator {
  background: white;
}
.bracelit-titeam-theme .mat-paginator,
.bracelit-titeam-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-paginator-decrement,
.bracelit-titeam-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-paginator-first,
.bracelit-titeam-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-titeam-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-titeam-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-titeam-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-progress-bar-background {
  fill: #c5e0e5;
}
.bracelit-titeam-theme .mat-progress-bar-buffer {
  background-color: #c5e0e5;
}
.bracelit-titeam-theme .mat-progress-bar-fill::after {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-titeam-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-titeam-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-titeam-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-titeam-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-progress-spinner circle, .bracelit-titeam-theme .mat-spinner circle {
  stroke: #2492a6;
}
.bracelit-titeam-theme .mat-progress-spinner.mat-accent circle, .bracelit-titeam-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-titeam-theme .mat-progress-spinner.mat-warn circle, .bracelit-titeam-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-titeam-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2492a6;
}
.bracelit-titeam-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-titeam-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-titeam-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-titeam-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-titeam-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-titeam-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-titeam-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-titeam-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-titeam-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-titeam-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-titeam-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-titeam-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-titeam-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-titeam-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-titeam-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-select-panel {
  background: white;
}
.bracelit-titeam-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2492a6;
}
.bracelit-titeam-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-titeam-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-titeam-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-titeam-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-titeam-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-titeam-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-titeam-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-titeam-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(36, 146, 166, 0.54);
}
.bracelit-titeam-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-titeam-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-titeam-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-titeam-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-titeam-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-titeam-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-titeam-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-titeam-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(36, 146, 166, 0.2);
}
.bracelit-titeam-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-titeam-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-titeam-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-titeam-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-titeam-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-titeam-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-titeam-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-titeam-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-titeam-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-titeam-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-titeam-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-titeam-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-titeam-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-titeam-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-titeam-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-titeam-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-titeam-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-titeam-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-titeam-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-titeam-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-titeam-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-titeam-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-titeam-theme .mat-step-header.cdk-keyboard-focused, .bracelit-titeam-theme .mat-step-header.cdk-program-focused, .bracelit-titeam-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-titeam-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-titeam-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-titeam-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-titeam-theme .mat-step-header .mat-step-label,
.bracelit-titeam-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-titeam-theme .mat-step-header .mat-step-icon-selected,
.bracelit-titeam-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-titeam-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #2492a6;
  color: white;
}
.bracelit-titeam-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-titeam-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-titeam-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-titeam-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-titeam-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-titeam-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-titeam-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-titeam-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-titeam-theme .mat-stepper-horizontal, .bracelit-titeam-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-titeam-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-horizontal-stepper-header::before,
.bracelit-titeam-theme .mat-horizontal-stepper-header::after,
.bracelit-titeam-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-titeam-theme .mat-tab-nav-bar,
.bracelit-titeam-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-titeam-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-titeam-theme .mat-tab-label, .bracelit-titeam-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-tab-label.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-titeam-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-titeam-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(189, 222, 228, 0.3);
}
.bracelit-titeam-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-titeam-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-titeam-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-titeam-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-titeam-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-titeam-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-titeam-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-titeam-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(189, 222, 228, 0.3);
}
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #2492a6;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-titeam-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-titeam-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-toolbar.mat-primary {
  background: #2492a6;
  color: white;
}
.bracelit-titeam-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-titeam-theme .mat-toolbar .mat-form-field-underline,
.bracelit-titeam-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-titeam-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-titeam-theme .mat-toolbar .mat-form-field-label,
.bracelit-titeam-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-titeam-theme .mat-toolbar .mat-select-value,
.bracelit-titeam-theme .mat-toolbar .mat-select-arrow,
.bracelit-titeam-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-titeam-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-titeam-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-titeam-theme .mat-tree {
  background: white;
}
.bracelit-titeam-theme .mat-tree-node,
.bracelit-titeam-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-titeam-theme .back-container {
  color: #2492a6;
}
.bracelit-titeam-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-titeam-theme .warn-text {
  color: #e74737;
}
.bracelit-titeam-theme .inside-modal-title.primary-colored-title, .bracelit-titeam-theme .form-title.primary-colored-title {
  color: #2492a6;
}
.bracelit-titeam-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-titeam-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-titeam-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-titeam-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .box-checkbox.active-box-checkbox {
  background-color: #2492a6;
  color: white;
}
.bracelit-titeam-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .box-radio.active-box-radio {
  background-color: #2492a6;
  color: white;
}
.bracelit-titeam-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .error {
  color: #e74737;
}
.bracelit-titeam-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-titeam-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-titeam-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-titeam-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-titeam-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-titeam-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-titeam-theme .ql-snow .ql-fill,
.bracelit-titeam-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .ql-snow.ql-toolbar button:hover,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:hover,
.bracelit-titeam-theme .ql-snow.ql-toolbar button:focus,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:focus,
.bracelit-titeam-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-titeam-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-titeam-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-titeam-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-titeam-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-titeam-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-titeam-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #2492a6;
  height: 38px !important;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-titeam-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-titeam-theme .general-container .label-wrapper label {
  color: #2492a6 !important;
  background-color: white;
}
.bracelit-titeam-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-titeam-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #2492a6 !important;
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #2492a6;
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #2492a6;
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #2492a6;
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-titeam-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-titeam-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #2492a6;
}
.bracelit-titeam-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-titeam-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-titeam-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-titeam-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-titeam-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #2492a6;
}
.bracelit-titeam-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-titeam-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-titeam-theme .move-image-action-container:hover {
  color: #2492a6;
}
.bracelit-titeam-theme .info-icon {
  color: #2492a6;
}
.bracelit-titeam-theme .email-chip {
  color: #2492a6 !important;
  background-color: rgba(36, 146, 166, 0.15) !important;
}
.bracelit-titeam-theme .email-chip .mat-chip-remove {
  color: #2492a6 !important;
  opacity: 1 !important;
}
.bracelit-titeam-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-titeam-theme .primary-color {
  color: #2492a6 !important;
}
.bracelit-titeam-theme .primary-color-200 {
  color: #92c9d3 !important;
}
.bracelit-titeam-theme .primary-fill {
  fill: #2492a6 !important;
}
.bracelit-titeam-theme .secondary-fill {
  fill: #adebff !important;
}
.bracelit-titeam-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-titeam-theme .primary-border-50 {
  border-color: #e5f2f4 !important;
}
.bracelit-titeam-theme .primary-border-100 {
  border-color: #bddee4 !important;
}
.bracelit-titeam-theme .primary-border-200 {
  border-color: #92c9d3 !important;
}
.bracelit-titeam-theme .primary-indicator .mat-expansion-indicator:after {
  color: #2492a6;
}
.bracelit-titeam-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-titeam-theme .primary-color-small {
  color: rgba(36, 146, 166, 0.6);
}
.bracelit-titeam-theme .primary-background {
  background-color: #2492a6 !important;
}
.bracelit-titeam-theme .primary-background-50 {
  background-color: #e5f2f4 !important;
}
.bracelit-titeam-theme .primary-background-200 {
  background-color: #92c9d3 !important;
}
.bracelit-titeam-theme .primary-background-800 {
  background-color: #16758b !important;
}
.bracelit-titeam-theme .primary-background-800-2 {
  background-color: #16758b !important;
  color: white !important;
}
.bracelit-titeam-theme .primary-background-opacity-01 {
  background-color: rgba(36, 146, 166, 0.1) !important;
}
.bracelit-titeam-theme .secondary-color {
  color: #adebff !important;
}
.bracelit-titeam-theme .secondary-background {
  background-color: #adebff !important;
}
.bracelit-titeam-theme .secondary-background-opacity-01 {
  background-color: rgba(173, 235, 255, 0.1) !important;
}
.bracelit-titeam-theme .secondary-border {
  border-color: #adebff !important;
}
.bracelit-titeam-theme .tertiary-color {
  color: #47d2ff !important;
}
.bracelit-titeam-theme .tertiary-background {
  background-image: linear-gradient(45deg, #adebff, #47d2ff) !important;
}
.bracelit-titeam-theme .tertiary-border {
  border-color: #47d2ff !important;
}
.bracelit-titeam-theme .dark-background {
  background-color: black !important;
}
.bracelit-titeam-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-titeam-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-titeam-theme .primary-soft-background {
  background-color: rgba(36, 146, 166, 0.1) !important;
}
.bracelit-titeam-theme .primary-soft-background:hover {
  background-color: rgba(36, 146, 166, 0.15) !important;
}
.bracelit-titeam-theme .primary-border {
  border-color: #2492a6 !important;
}
.bracelit-titeam-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-titeam-theme .hover-primary:hover {
  color: #2492a6 !important;
}
.bracelit-titeam-theme .hover-primary-background:hover {
  background-color: #2492a6 !important;
}
.bracelit-titeam-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-titeam-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-titeam-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-titeam-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-titeam-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-titeam-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-titeam-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-titeam-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-titeam-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-titeam-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-titeam-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-titeam-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #2492a6;
}
.bracelit-titeam-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-titeam-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-titeam-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-titeam-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-titeam-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-titeam-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-titeam-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .card-table tr:hover .action-icon button {
  color: #2492a6;
}
.bracelit-titeam-theme .card-table tr:hover .action-icon:hover {
  color: #2492a6;
}
.bracelit-titeam-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-titeam-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-titeam-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #2492a6;
}
.bracelit-titeam-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-titeam-theme .custom-standard-chip {
  background-color: rgba(36, 146, 166, 0.1) !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .help-dashboard-card .mat-icon {
  color: #2492a6;
}
.bracelit-titeam-theme .help-dashboard-card:hover {
  background-color: #2492a6;
}
.bracelit-titeam-theme .help-dashboard-card:hover .mat-icon, .bracelit-titeam-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-titeam-theme .room-open {
  background-color: #2492a6 !important;
  color: white !important;
}
.bracelit-titeam-theme .room-open:hover {
  background-color: #2492a6 !important;
  color: white !important;
}
.bracelit-titeam-theme #public-office-page-groups .public-group-button-hover {
  background-color: #2492a6 !important;
  color: white !important;
}
.bracelit-titeam-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-titeam-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-titeam-theme .notification-container:hover .show-tip {
  color: #2492a6;
}
.bracelit-titeam-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-titeam-theme .notification-container .notification-subject .mat-icon {
  color: #2492a6;
}
.bracelit-titeam-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-titeam-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-titeam-theme .message-container:hover .show-tip {
  color: #2492a6;
}
.bracelit-titeam-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-titeam-theme .message-container .message-subject .mat-icon {
  color: #2492a6;
}
.bracelit-titeam-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-titeam-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-titeam-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-titeam-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-titeam-theme .primary-200-outline input {
  caret-color: #92c9d3;
  color: #2492a6;
}
.bracelit-titeam-theme .primary-200-outline label {
  color: #92c9d3;
}
.bracelit-titeam-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #92c9d3 !important;
}
.bracelit-titeam-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #92c9d3;
}
.bracelit-titeam-theme mat-form-field .primary-outline label {
  color: #2492a6 !important;
}
.bracelit-titeam-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #2492a6 !important;
}
.bracelit-titeam-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #2492a6 !important;
}
.bracelit-titeam-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #92c9d3 !important;
}
.bracelit-titeam-theme .input-primary input {
  caret-color: #2492a6;
  color: #2492a6;
}
.bracelit-titeam-theme .input-primary label {
  color: #2492a6;
}
.bracelit-titeam-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #2492a6 !important;
}
.bracelit-titeam-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-titeam-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #2492a6;
  border-width: 1px;
}
.bracelit-titeam-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-titeam-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-titeam-theme .header-primary-background .mobile-header-container {
  background-color: #2492a6;
}
.bracelit-titeam-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #92c9d3 !important;
}
.bracelit-titeam-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-titeam-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-titeam-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-titeam-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-titeam-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-titeam-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-titeam-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-titeam-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-titeam-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #92c9d3 !important;
}
.bracelit-titeam-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #adebff !important;
}
.bracelit-titeam-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-titeam-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-titeam-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #adebff !important;
}
.bracelit-titeam-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #adebff !important;
}
.bracelit-titeam-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #47d2ff !important;
}
.bracelit-titeam-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #47d2ff !important;
}
.bracelit-titeam-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-titeam-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #2492a6 !important;
}
.bracelit-titeam-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #2492a6 !important;
}

.bracelit-red-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-red-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-red-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-red-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-red-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e53935;
}
.bracelit-red-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-red-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-red-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-red-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-red-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-red-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #e53935;
}
.bracelit-red-theme .mat-pseudo-checkbox-checked,
.bracelit-red-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-red-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-red-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-red-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-red-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-red-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-red-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-red-theme .mat-app-background, .bracelit-red-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-red-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-red-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-badge {
  position: relative;
}
.bracelit-red-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-red-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-red-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-red-theme .ng-animate-disabled .mat-badge-content,
.bracelit-red-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-red-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-red-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-red-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-red-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-red-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-red-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-red-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-red-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-red-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-red-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-red-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-red-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-red-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-red-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-red-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-red-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-red-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-red-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-red-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-red-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-red-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-red-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-red-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-red-theme .mat-badge-content {
  color: white;
  background: #e53935;
}
.cdk-high-contrast-active .bracelit-red-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-red-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-red-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-button, .bracelit-red-theme .mat-icon-button, .bracelit-red-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-red-theme .mat-button.mat-primary, .bracelit-red-theme .mat-icon-button.mat-primary, .bracelit-red-theme .mat-stroked-button.mat-primary {
  color: #e53935;
}
.bracelit-red-theme .mat-button.mat-accent, .bracelit-red-theme .mat-icon-button.mat-accent, .bracelit-red-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-red-theme .mat-button.mat-warn, .bracelit-red-theme .mat-icon-button.mat-warn, .bracelit-red-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-red-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-red-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-red-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-red-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-red-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-red-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #e53935;
}
.bracelit-red-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-red-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-red-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-red-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-red-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-red-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-red-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-red-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-red-theme .mat-button .mat-ripple-element, .bracelit-red-theme .mat-icon-button .mat-ripple-element, .bracelit-red-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-red-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-red-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-flat-button, .bracelit-red-theme .mat-raised-button, .bracelit-red-theme .mat-fab, .bracelit-red-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-red-theme .mat-flat-button.mat-primary, .bracelit-red-theme .mat-raised-button.mat-primary, .bracelit-red-theme .mat-fab.mat-primary, .bracelit-red-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-red-theme .mat-flat-button.mat-accent, .bracelit-red-theme .mat-raised-button.mat-accent, .bracelit-red-theme .mat-fab.mat-accent, .bracelit-red-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-flat-button.mat-warn, .bracelit-red-theme .mat-raised-button.mat-warn, .bracelit-red-theme .mat-fab.mat-warn, .bracelit-red-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-red-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-red-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-red-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-red-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-red-theme .mat-flat-button.mat-primary, .bracelit-red-theme .mat-raised-button.mat-primary, .bracelit-red-theme .mat-fab.mat-primary, .bracelit-red-theme .mat-mini-fab.mat-primary {
  background-color: #e53935;
}
.bracelit-red-theme .mat-flat-button.mat-accent, .bracelit-red-theme .mat-raised-button.mat-accent, .bracelit-red-theme .mat-fab.mat-accent, .bracelit-red-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-flat-button.mat-warn, .bracelit-red-theme .mat-raised-button.mat-warn, .bracelit-red-theme .mat-fab.mat-warn, .bracelit-red-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-red-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-red-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-red-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-red-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-red-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-red-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-red-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-red-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-red-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-red-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-red-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-red-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-red-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-red-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-red-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-red-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-red-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-red-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-red-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-red-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-red-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-red-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-red-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-red-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-red-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-red-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-red-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-red-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-red-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-red-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-red-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-red-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-red-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-red-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-red-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-red-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-red-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-red-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-red-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-red-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #e53935;
}
.bracelit-red-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-red-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-red-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-red-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-red-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-red-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-red-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-red-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-red-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #e53935;
}
.bracelit-red-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-red-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-red-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-red-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-red-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-red-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-red-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #e53935;
  color: white;
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-red-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-red-theme .mat-table {
  background: white;
}
.bracelit-red-theme .mat-table thead, .bracelit-red-theme .mat-table tbody, .bracelit-red-theme .mat-table tfoot,
.bracelit-red-theme mat-header-row, .bracelit-red-theme mat-row, .bracelit-red-theme mat-footer-row,
.bracelit-red-theme [mat-header-row], .bracelit-red-theme [mat-row], .bracelit-red-theme [mat-footer-row],
.bracelit-red-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-red-theme mat-row, .bracelit-red-theme mat-header-row, .bracelit-red-theme mat-footer-row,
.bracelit-red-theme th.mat-header-cell, .bracelit-red-theme td.mat-cell, .bracelit-red-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-cell, .bracelit-red-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-datepicker-toggle,
.bracelit-red-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-red-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-calendar-table-header,
.bracelit-red-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-calendar-body-cell-content,
.bracelit-red-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-red-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-red-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-red-theme .mat-calendar-body-in-range::before {
  background: rgba(229, 57, 53, 0.2);
}
.bracelit-red-theme .mat-calendar-body-comparison-identical,
.bracelit-red-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-red-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-red-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 57, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-red-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-red-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 57, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-red-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-red-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-red-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-red-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-red-theme .mat-calendar-body-selected {
  background-color: #e53935;
  color: white;
}
.bracelit-red-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 57, 53, 0.4);
}
.bracelit-red-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-red-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-red-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 57, 53, 0.3);
}
@media (hover: hover) {
  .bracelit-red-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 57, 53, 0.3);
  }
}
.bracelit-red-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-red-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-red-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-red-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-red-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-red-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-red-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-red-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-red-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-red-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-red-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-datepicker-toggle-active {
  color: #e53935;
}
.bracelit-red-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-red-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-red-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-red-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-red-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-red-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-red-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-expansion-panel-header-description,
.bracelit-red-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-red-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-red-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-red-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-red-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-red-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #e53935;
}
.bracelit-red-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-red-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-red-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-red-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #e53935;
}
.bracelit-red-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-red-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #e53935;
}
.bracelit-red-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-red-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-red-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-red-theme .mat-error {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-red-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-red-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-red-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-red-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-red-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #e53935;
}
.bracelit-red-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-red-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-red-theme .mat-icon.mat-primary {
  color: #e53935;
}
.bracelit-red-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-red-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-input-element:disabled,
.bracelit-red-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-input-element {
  caret-color: #e53935;
}
.bracelit-red-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-red-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-red-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-red-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-red-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-list-option:hover, .bracelit-red-theme .mat-list-option:focus,
.bracelit-red-theme .mat-nav-list .mat-list-item:hover,
.bracelit-red-theme .mat-nav-list .mat-list-item:focus,
.bracelit-red-theme .mat-action-list .mat-list-item:hover,
.bracelit-red-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-red-theme .mat-list-single-selected-option, .bracelit-red-theme .mat-list-single-selected-option:hover, .bracelit-red-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-menu-panel {
  background: white;
}
.bracelit-red-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-menu-item[disabled],
.bracelit-red-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-red-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-menu-item .mat-icon-no-color,
.bracelit-red-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-menu-item:hover:not([disabled]),
.bracelit-red-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-red-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-red-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-red-theme .mat-paginator {
  background: white;
}
.bracelit-red-theme .mat-paginator,
.bracelit-red-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-paginator-decrement,
.bracelit-red-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-paginator-first,
.bracelit-red-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-red-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-red-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-red-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-progress-bar-background {
  fill: #f5cac9;
}
.bracelit-red-theme .mat-progress-bar-buffer {
  background-color: #f5cac9;
}
.bracelit-red-theme .mat-progress-bar-fill::after {
  background-color: #e53935;
}
.bracelit-red-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-red-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-red-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-red-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-red-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-red-theme .mat-progress-spinner circle, .bracelit-red-theme .mat-spinner circle {
  stroke: #e53935;
}
.bracelit-red-theme .mat-progress-spinner.mat-accent circle, .bracelit-red-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-red-theme .mat-progress-spinner.mat-warn circle, .bracelit-red-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-red-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e53935;
}
.bracelit-red-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-red-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-red-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-red-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #e53935;
}
.bracelit-red-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-red-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-red-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-red-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-red-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-red-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-red-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-red-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-red-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-red-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-red-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-red-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-red-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-select-panel {
  background: white;
}
.bracelit-red-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #e53935;
}
.bracelit-red-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-red-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-red-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-red-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-red-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-red-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-red-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-red-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #e53935;
}
.bracelit-red-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 57, 53, 0.54);
}
.bracelit-red-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #e53935;
}
.bracelit-red-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-red-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-red-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-red-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-red-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-red-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-red-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-red-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-red-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #e53935;
}
.bracelit-red-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-red-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(229, 57, 53, 0.2);
}
.bracelit-red-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-red-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-red-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-red-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-red-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-red-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-red-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-red-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-red-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-red-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-red-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-red-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-red-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-red-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-red-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-red-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-red-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-red-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-red-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-red-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-red-theme .mat-step-header.cdk-keyboard-focused, .bracelit-red-theme .mat-step-header.cdk-program-focused, .bracelit-red-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-red-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-red-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-red-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-red-theme .mat-step-header .mat-step-label,
.bracelit-red-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-red-theme .mat-step-header .mat-step-icon-selected,
.bracelit-red-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-red-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #e53935;
  color: white;
}
.bracelit-red-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-red-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-red-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-red-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-red-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-red-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-red-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-red-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-red-theme .mat-stepper-horizontal, .bracelit-red-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-red-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-horizontal-stepper-header::before,
.bracelit-red-theme .mat-horizontal-stepper-header::after,
.bracelit-red-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-red-theme .mat-tab-nav-bar,
.bracelit-red-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-red-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-red-theme .mat-tab-label, .bracelit-red-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-tab-label.mat-tab-disabled, .bracelit-red-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-red-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-red-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(247, 196, 194, 0.3);
}
.bracelit-red-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #e53935;
}
.bracelit-red-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-red-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-red-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-red-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-red-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-red-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-red-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-red-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-red-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-red-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(247, 196, 194, 0.3);
}
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #e53935;
}
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-red-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-red-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-red-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-red-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-red-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-red-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-red-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-red-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-toolbar.mat-primary {
  background: #e53935;
  color: white;
}
.bracelit-red-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-red-theme .mat-toolbar .mat-form-field-underline,
.bracelit-red-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-red-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-red-theme .mat-toolbar .mat-form-field-label,
.bracelit-red-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-red-theme .mat-toolbar .mat-select-value,
.bracelit-red-theme .mat-toolbar .mat-select-arrow,
.bracelit-red-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-red-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-red-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-red-theme .mat-tree {
  background: white;
}
.bracelit-red-theme .mat-tree-node,
.bracelit-red-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-red-theme .back-container {
  color: #e53935;
}
.bracelit-red-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-red-theme .warn-text {
  color: #e74737;
}
.bracelit-red-theme .inside-modal-title.primary-colored-title, .bracelit-red-theme .form-title.primary-colored-title {
  color: #e53935;
}
.bracelit-red-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-red-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-red-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-red-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .box-checkbox.active-box-checkbox {
  background-color: #e53935;
  color: white;
}
.bracelit-red-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .box-radio.active-box-radio {
  background-color: #e53935;
  color: white;
}
.bracelit-red-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .error {
  color: #e74737;
}
.bracelit-red-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-red-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-red-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-red-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-red-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-red-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-red-theme .ql-snow .ql-fill,
.bracelit-red-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .ql-snow.ql-toolbar button:hover,
.bracelit-red-theme .ql-snow .ql-toolbar button:hover,
.bracelit-red-theme .ql-snow.ql-toolbar button:focus,
.bracelit-red-theme .ql-snow .ql-toolbar button:focus,
.bracelit-red-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-red-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-red-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-red-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-red-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-red-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-red-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-red-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-red-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-red-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-red-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-red-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-red-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-red-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-red-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-red-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-red-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #e53935;
  height: 38px !important;
}
.bracelit-red-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-red-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-red-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-red-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-red-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-red-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-red-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-red-theme .general-container .label-wrapper label {
  color: #e53935 !important;
  background-color: white;
}
.bracelit-red-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-red-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #e53935 !important;
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #e53935;
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #e53935;
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #e53935;
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-red-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-red-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #e53935;
}
.bracelit-red-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-red-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-red-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-red-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-red-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #e53935;
}
.bracelit-red-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-red-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-red-theme .move-image-action-container:hover {
  color: #e53935;
}
.bracelit-red-theme .info-icon {
  color: #e53935;
}
.bracelit-red-theme .email-chip {
  color: #e53935 !important;
  background-color: rgba(229, 57, 53, 0.15) !important;
}
.bracelit-red-theme .email-chip .mat-chip-remove {
  color: #e53935 !important;
  opacity: 1 !important;
}
.bracelit-red-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-red-theme .primary-color {
  color: #e53935 !important;
}
.bracelit-red-theme .primary-color-200 {
  color: #f29c9a !important;
}
.bracelit-red-theme .primary-fill {
  fill: #e53935 !important;
}
.bracelit-red-theme .secondary-fill {
  fill: white !important;
}
.bracelit-red-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-red-theme .primary-border-50 {
  border-color: #fce7e7 !important;
}
.bracelit-red-theme .primary-border-100 {
  border-color: #f7c4c2 !important;
}
.bracelit-red-theme .primary-border-200 {
  border-color: #f29c9a !important;
}
.bracelit-red-theme .primary-indicator .mat-expansion-indicator:after {
  color: #e53935;
}
.bracelit-red-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-red-theme .primary-color-small {
  color: rgba(229, 57, 53, 0.6);
}
.bracelit-red-theme .primary-background {
  background-color: #e53935 !important;
}
.bracelit-red-theme .primary-background-50 {
  background-color: #fce7e7 !important;
}
.bracelit-red-theme .primary-background-200 {
  background-color: #f29c9a !important;
}
.bracelit-red-theme .primary-background-800 {
  background-color: #da2422 !important;
}
.bracelit-red-theme .primary-background-800-2 {
  background-color: #da2422 !important;
  color: white !important;
}
.bracelit-red-theme .primary-background-opacity-01 {
  background-color: rgba(229, 57, 53, 0.1) !important;
}
.bracelit-red-theme .secondary-color {
  color: white !important;
}
.bracelit-red-theme .secondary-background {
  background-color: white !important;
}
.bracelit-red-theme .secondary-background-opacity-01 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.bracelit-red-theme .secondary-border {
  border-color: white !important;
}
.bracelit-red-theme .tertiary-color {
  color: #ff9f9e !important;
}
.bracelit-red-theme .tertiary-background {
  background-image: linear-gradient(45deg, white, #ff9f9e) !important;
}
.bracelit-red-theme .tertiary-border {
  border-color: #ff9f9e !important;
}
.bracelit-red-theme .dark-background {
  background-color: black !important;
}
.bracelit-red-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-red-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-red-theme .primary-soft-background {
  background-color: rgba(229, 57, 53, 0.1) !important;
}
.bracelit-red-theme .primary-soft-background:hover {
  background-color: rgba(229, 57, 53, 0.15) !important;
}
.bracelit-red-theme .primary-border {
  border-color: #e53935 !important;
}
.bracelit-red-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-red-theme .hover-primary:hover {
  color: #e53935 !important;
}
.bracelit-red-theme .hover-primary-background:hover {
  background-color: #e53935 !important;
}
.bracelit-red-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-red-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-red-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-red-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-red-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-red-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-red-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-red-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-red-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-red-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-red-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-red-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #e53935;
}
.bracelit-red-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-red-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-red-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-red-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-red-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-red-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-red-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .card-table tr:hover .action-icon button {
  color: #e53935;
}
.bracelit-red-theme .card-table tr:hover .action-icon:hover {
  color: #e53935;
}
.bracelit-red-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-red-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-red-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #e53935;
}
.bracelit-red-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-red-theme .custom-standard-chip {
  background-color: rgba(229, 57, 53, 0.1) !important;
  color: #e53935 !important;
}
.bracelit-red-theme .help-dashboard-card .mat-icon {
  color: #e53935;
}
.bracelit-red-theme .help-dashboard-card:hover {
  background-color: #e53935;
}
.bracelit-red-theme .help-dashboard-card:hover .mat-icon, .bracelit-red-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-red-theme .room-open {
  background-color: #e53935 !important;
  color: white !important;
}
.bracelit-red-theme .room-open:hover {
  background-color: #e53935 !important;
  color: white !important;
}
.bracelit-red-theme #public-office-page-groups .public-group-button-hover {
  background-color: #e53935 !important;
  color: white !important;
}
.bracelit-red-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-red-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-red-theme .notification-container:hover .show-tip {
  color: #e53935;
}
.bracelit-red-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-red-theme .notification-container .notification-subject .mat-icon {
  color: #e53935;
}
.bracelit-red-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-red-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-red-theme .message-container:hover .show-tip {
  color: #e53935;
}
.bracelit-red-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-red-theme .message-container .message-subject .mat-icon {
  color: #e53935;
}
.bracelit-red-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-red-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-red-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-red-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-red-theme .primary-200-outline input {
  caret-color: #f29c9a;
  color: #e53935;
}
.bracelit-red-theme .primary-200-outline label {
  color: #f29c9a;
}
.bracelit-red-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #f29c9a !important;
}
.bracelit-red-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #f29c9a;
}
.bracelit-red-theme mat-form-field .primary-outline label {
  color: #e53935 !important;
}
.bracelit-red-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #e53935 !important;
}
.bracelit-red-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #e53935 !important;
}
.bracelit-red-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #f29c9a !important;
}
.bracelit-red-theme .input-primary input {
  caret-color: #e53935;
  color: #e53935;
}
.bracelit-red-theme .input-primary label {
  color: #e53935;
}
.bracelit-red-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #e53935 !important;
}
.bracelit-red-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-red-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #e53935;
  border-width: 1px;
}
.bracelit-red-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-red-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-red-theme .header-primary-background .mobile-header-container {
  background-color: #e53935;
}
.bracelit-red-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #f29c9a !important;
}
.bracelit-red-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-red-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-red-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-red-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-red-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-red-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-red-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-red-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-red-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #f29c9a !important;
}
.bracelit-red-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: white !important;
}
.bracelit-red-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-red-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-red-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: white !important;
}
.bracelit-red-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: white !important;
}
.bracelit-red-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ff9f9e !important;
}
.bracelit-red-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ff9f9e !important;
}
.bracelit-red-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-red-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #e53935 !important;
}
.bracelit-red-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #e53935 !important;
}

.bracelit-pink-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-pink-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-pink-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-pink-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-pink-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d81b60;
}
.bracelit-pink-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-pink-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-pink-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-pink-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-pink-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-pink-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #d81b60;
}
.bracelit-pink-theme .mat-pseudo-checkbox-checked,
.bracelit-pink-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-pink-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-pink-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-pink-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-pink-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-pink-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-pink-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-pink-theme .mat-app-background, .bracelit-pink-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-pink-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-pink-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-badge {
  position: relative;
}
.bracelit-pink-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-pink-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-pink-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-pink-theme .ng-animate-disabled .mat-badge-content,
.bracelit-pink-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-pink-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-pink-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-pink-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-pink-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-pink-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-pink-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-pink-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-pink-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-pink-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-pink-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-pink-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-pink-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-pink-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-pink-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-pink-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-pink-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-pink-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-pink-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-pink-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-pink-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-pink-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-pink-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-pink-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-pink-theme .mat-badge-content {
  color: white;
  background: #d81b60;
}
.cdk-high-contrast-active .bracelit-pink-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-pink-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-pink-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-button, .bracelit-pink-theme .mat-icon-button, .bracelit-pink-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-pink-theme .mat-button.mat-primary, .bracelit-pink-theme .mat-icon-button.mat-primary, .bracelit-pink-theme .mat-stroked-button.mat-primary {
  color: #d81b60;
}
.bracelit-pink-theme .mat-button.mat-accent, .bracelit-pink-theme .mat-icon-button.mat-accent, .bracelit-pink-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-pink-theme .mat-button.mat-warn, .bracelit-pink-theme .mat-icon-button.mat-warn, .bracelit-pink-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-pink-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-pink-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-pink-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-pink-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-pink-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-pink-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-pink-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-pink-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-pink-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-pink-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-pink-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-pink-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-pink-theme .mat-button .mat-ripple-element, .bracelit-pink-theme .mat-icon-button .mat-ripple-element, .bracelit-pink-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-pink-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-pink-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-flat-button, .bracelit-pink-theme .mat-raised-button, .bracelit-pink-theme .mat-fab, .bracelit-pink-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-pink-theme .mat-flat-button.mat-primary, .bracelit-pink-theme .mat-raised-button.mat-primary, .bracelit-pink-theme .mat-fab.mat-primary, .bracelit-pink-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-pink-theme .mat-flat-button.mat-accent, .bracelit-pink-theme .mat-raised-button.mat-accent, .bracelit-pink-theme .mat-fab.mat-accent, .bracelit-pink-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-flat-button.mat-warn, .bracelit-pink-theme .mat-raised-button.mat-warn, .bracelit-pink-theme .mat-fab.mat-warn, .bracelit-pink-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-pink-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-pink-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-pink-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-pink-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-pink-theme .mat-flat-button.mat-primary, .bracelit-pink-theme .mat-raised-button.mat-primary, .bracelit-pink-theme .mat-fab.mat-primary, .bracelit-pink-theme .mat-mini-fab.mat-primary {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-flat-button.mat-accent, .bracelit-pink-theme .mat-raised-button.mat-accent, .bracelit-pink-theme .mat-fab.mat-accent, .bracelit-pink-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-flat-button.mat-warn, .bracelit-pink-theme .mat-raised-button.mat-warn, .bracelit-pink-theme .mat-fab.mat-warn, .bracelit-pink-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-pink-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-pink-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-pink-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-pink-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-pink-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-pink-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-pink-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-pink-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-pink-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-pink-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-pink-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-pink-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-pink-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-pink-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-pink-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-pink-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-pink-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-pink-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-pink-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-pink-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-pink-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-pink-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-pink-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-pink-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-pink-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-pink-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-pink-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-pink-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-pink-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-pink-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-pink-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-pink-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-pink-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-pink-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-pink-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-pink-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-pink-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-pink-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-pink-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-pink-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-pink-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-pink-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-pink-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-pink-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-pink-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-pink-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #d81b60;
}
.bracelit-pink-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-pink-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-pink-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-pink-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #d81b60;
  color: white;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-pink-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-pink-theme .mat-table {
  background: white;
}
.bracelit-pink-theme .mat-table thead, .bracelit-pink-theme .mat-table tbody, .bracelit-pink-theme .mat-table tfoot,
.bracelit-pink-theme mat-header-row, .bracelit-pink-theme mat-row, .bracelit-pink-theme mat-footer-row,
.bracelit-pink-theme [mat-header-row], .bracelit-pink-theme [mat-row], .bracelit-pink-theme [mat-footer-row],
.bracelit-pink-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-pink-theme mat-row, .bracelit-pink-theme mat-header-row, .bracelit-pink-theme mat-footer-row,
.bracelit-pink-theme th.mat-header-cell, .bracelit-pink-theme td.mat-cell, .bracelit-pink-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-cell, .bracelit-pink-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-datepicker-toggle,
.bracelit-pink-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-pink-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-calendar-table-header,
.bracelit-pink-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-calendar-body-cell-content,
.bracelit-pink-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-pink-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-pink-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-pink-theme .mat-calendar-body-in-range::before {
  background: rgba(216, 27, 96, 0.2);
}
.bracelit-pink-theme .mat-calendar-body-comparison-identical,
.bracelit-pink-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-pink-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-pink-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(216, 27, 96, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-pink-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-pink-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(216, 27, 96, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-pink-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-pink-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-pink-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-pink-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-pink-theme .mat-calendar-body-selected {
  background-color: #d81b60;
  color: white;
}
.bracelit-pink-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(216, 27, 96, 0.4);
}
.bracelit-pink-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-pink-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-pink-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(216, 27, 96, 0.3);
}
@media (hover: hover) {
  .bracelit-pink-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(216, 27, 96, 0.3);
  }
}
.bracelit-pink-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-pink-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-pink-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-pink-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-pink-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-pink-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-pink-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-pink-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-pink-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-pink-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-pink-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-datepicker-toggle-active {
  color: #d81b60;
}
.bracelit-pink-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-pink-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-pink-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-pink-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-pink-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-pink-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-pink-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-expansion-panel-header-description,
.bracelit-pink-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-pink-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-pink-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-pink-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-pink-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-pink-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #d81b60;
}
.bracelit-pink-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-pink-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-pink-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-pink-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #d81b60;
}
.bracelit-pink-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-pink-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-pink-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-pink-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-error {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-pink-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-pink-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-pink-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-pink-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-pink-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #d81b60;
}
.bracelit-pink-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-pink-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-pink-theme .mat-icon.mat-primary {
  color: #d81b60;
}
.bracelit-pink-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-pink-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-input-element:disabled,
.bracelit-pink-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-input-element {
  caret-color: #d81b60;
}
.bracelit-pink-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-pink-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-pink-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-pink-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-pink-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-list-option:hover, .bracelit-pink-theme .mat-list-option:focus,
.bracelit-pink-theme .mat-nav-list .mat-list-item:hover,
.bracelit-pink-theme .mat-nav-list .mat-list-item:focus,
.bracelit-pink-theme .mat-action-list .mat-list-item:hover,
.bracelit-pink-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-pink-theme .mat-list-single-selected-option, .bracelit-pink-theme .mat-list-single-selected-option:hover, .bracelit-pink-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-menu-panel {
  background: white;
}
.bracelit-pink-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-menu-item[disabled],
.bracelit-pink-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-pink-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-menu-item .mat-icon-no-color,
.bracelit-pink-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-menu-item:hover:not([disabled]),
.bracelit-pink-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-pink-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-pink-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-pink-theme .mat-paginator {
  background: white;
}
.bracelit-pink-theme .mat-paginator,
.bracelit-pink-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-paginator-decrement,
.bracelit-pink-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-paginator-first,
.bracelit-pink-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-pink-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-pink-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-pink-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-progress-bar-background {
  fill: #f2c2d4;
}
.bracelit-pink-theme .mat-progress-bar-buffer {
  background-color: #f2c2d4;
}
.bracelit-pink-theme .mat-progress-bar-fill::after {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-pink-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-pink-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-pink-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-pink-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-progress-spinner circle, .bracelit-pink-theme .mat-spinner circle {
  stroke: #d81b60;
}
.bracelit-pink-theme .mat-progress-spinner.mat-accent circle, .bracelit-pink-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-pink-theme .mat-progress-spinner.mat-warn circle, .bracelit-pink-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-pink-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d81b60;
}
.bracelit-pink-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-pink-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-pink-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-pink-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-pink-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-pink-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-pink-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-pink-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-pink-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-pink-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-pink-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-pink-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-pink-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-pink-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-pink-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-select-panel {
  background: white;
}
.bracelit-pink-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #d81b60;
}
.bracelit-pink-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-pink-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-pink-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-pink-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-pink-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-pink-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-pink-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-pink-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(216, 27, 96, 0.54);
}
.bracelit-pink-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-pink-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-pink-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-pink-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-pink-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-pink-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-pink-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-pink-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(216, 27, 96, 0.2);
}
.bracelit-pink-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-pink-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-pink-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-pink-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-pink-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-pink-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-pink-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-pink-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-pink-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-pink-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-pink-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-pink-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-pink-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-pink-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-pink-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-pink-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-pink-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-pink-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-pink-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-pink-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-pink-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-pink-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-pink-theme .mat-step-header.cdk-keyboard-focused, .bracelit-pink-theme .mat-step-header.cdk-program-focused, .bracelit-pink-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-pink-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-pink-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-pink-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-pink-theme .mat-step-header .mat-step-label,
.bracelit-pink-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-pink-theme .mat-step-header .mat-step-icon-selected,
.bracelit-pink-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-pink-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #d81b60;
  color: white;
}
.bracelit-pink-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-pink-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-pink-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-pink-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-pink-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-pink-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-pink-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-pink-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-pink-theme .mat-stepper-horizontal, .bracelit-pink-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-pink-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-horizontal-stepper-header::before,
.bracelit-pink-theme .mat-horizontal-stepper-header::after,
.bracelit-pink-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-pink-theme .mat-tab-nav-bar,
.bracelit-pink-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-pink-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-pink-theme .mat-tab-label, .bracelit-pink-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-tab-label.mat-tab-disabled, .bracelit-pink-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-pink-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-pink-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 187, 207, 0.3);
}
.bracelit-pink-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-pink-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-pink-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-pink-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-pink-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-pink-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-pink-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-pink-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-pink-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 187, 207, 0.3);
}
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #d81b60;
}
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-pink-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-pink-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-pink-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-pink-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-pink-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-pink-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-pink-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-toolbar.mat-primary {
  background: #d81b60;
  color: white;
}
.bracelit-pink-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-pink-theme .mat-toolbar .mat-form-field-underline,
.bracelit-pink-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-pink-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-pink-theme .mat-toolbar .mat-form-field-label,
.bracelit-pink-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-pink-theme .mat-toolbar .mat-select-value,
.bracelit-pink-theme .mat-toolbar .mat-select-arrow,
.bracelit-pink-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-pink-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-pink-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-pink-theme .mat-tree {
  background: white;
}
.bracelit-pink-theme .mat-tree-node,
.bracelit-pink-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-pink-theme .back-container {
  color: #d81b60;
}
.bracelit-pink-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-pink-theme .warn-text {
  color: #e74737;
}
.bracelit-pink-theme .inside-modal-title.primary-colored-title, .bracelit-pink-theme .form-title.primary-colored-title {
  color: #d81b60;
}
.bracelit-pink-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-pink-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-pink-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-pink-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .box-checkbox.active-box-checkbox {
  background-color: #d81b60;
  color: white;
}
.bracelit-pink-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .box-radio.active-box-radio {
  background-color: #d81b60;
  color: white;
}
.bracelit-pink-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .error {
  color: #e74737;
}
.bracelit-pink-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-pink-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-pink-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-pink-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-pink-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-pink-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-pink-theme .ql-snow .ql-fill,
.bracelit-pink-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .ql-snow.ql-toolbar button:hover,
.bracelit-pink-theme .ql-snow .ql-toolbar button:hover,
.bracelit-pink-theme .ql-snow.ql-toolbar button:focus,
.bracelit-pink-theme .ql-snow .ql-toolbar button:focus,
.bracelit-pink-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-pink-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-pink-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-pink-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-pink-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-pink-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-pink-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-pink-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-pink-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-pink-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-pink-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-pink-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-pink-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-pink-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-pink-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #d81b60;
  height: 38px !important;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-pink-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-pink-theme .general-container .label-wrapper label {
  color: #d81b60 !important;
  background-color: white;
}
.bracelit-pink-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-pink-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #d81b60 !important;
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #d81b60;
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #d81b60;
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #d81b60;
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-pink-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-pink-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #d81b60;
}
.bracelit-pink-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-pink-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-pink-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-pink-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-pink-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #d81b60;
}
.bracelit-pink-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-pink-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-pink-theme .move-image-action-container:hover {
  color: #d81b60;
}
.bracelit-pink-theme .info-icon {
  color: #d81b60;
}
.bracelit-pink-theme .email-chip {
  color: #d81b60 !important;
  background-color: rgba(216, 27, 96, 0.15) !important;
}
.bracelit-pink-theme .email-chip .mat-chip-remove {
  color: #d81b60 !important;
  opacity: 1 !important;
}
.bracelit-pink-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-pink-theme .primary-color {
  color: #d81b60 !important;
}
.bracelit-pink-theme .primary-color-200 {
  color: #ec8db0 !important;
}
.bracelit-pink-theme .primary-fill {
  fill: #d81b60 !important;
}
.bracelit-pink-theme .secondary-fill {
  fill: #ffe9ed !important;
}
.bracelit-pink-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-pink-theme .primary-border-50 {
  border-color: #fae4ec !important;
}
.bracelit-pink-theme .primary-border-100 {
  border-color: #f3bbcf !important;
}
.bracelit-pink-theme .primary-border-200 {
  border-color: #ec8db0 !important;
}
.bracelit-pink-theme .primary-indicator .mat-expansion-indicator:after {
  color: #d81b60;
}
.bracelit-pink-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-pink-theme .primary-color-small {
  color: rgba(216, 27, 96, 0.6);
}
.bracelit-pink-theme .primary-background {
  background-color: #d81b60 !important;
}
.bracelit-pink-theme .primary-background-50 {
  background-color: #fae4ec !important;
}
.bracelit-pink-theme .primary-background-200 {
  background-color: #ec8db0 !important;
}
.bracelit-pink-theme .primary-background-800 {
  background-color: #c81044 !important;
}
.bracelit-pink-theme .primary-background-800-2 {
  background-color: #c81044 !important;
  color: white !important;
}
.bracelit-pink-theme .primary-background-opacity-01 {
  background-color: rgba(216, 27, 96, 0.1) !important;
}
.bracelit-pink-theme .secondary-color {
  color: #ffe9ed !important;
}
.bracelit-pink-theme .secondary-background {
  background-color: #ffe9ed !important;
}
.bracelit-pink-theme .secondary-background-opacity-01 {
  background-color: rgba(255, 233, 237, 0.1) !important;
}
.bracelit-pink-theme .secondary-border {
  border-color: #ffe9ed !important;
}
.bracelit-pink-theme .tertiary-color {
  color: #ff839a !important;
}
.bracelit-pink-theme .tertiary-background {
  background-image: linear-gradient(45deg, #ffe9ed, #ff839a) !important;
}
.bracelit-pink-theme .tertiary-border {
  border-color: #ff839a !important;
}
.bracelit-pink-theme .dark-background {
  background-color: black !important;
}
.bracelit-pink-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-pink-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-pink-theme .primary-soft-background {
  background-color: rgba(216, 27, 96, 0.1) !important;
}
.bracelit-pink-theme .primary-soft-background:hover {
  background-color: rgba(216, 27, 96, 0.15) !important;
}
.bracelit-pink-theme .primary-border {
  border-color: #d81b60 !important;
}
.bracelit-pink-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-pink-theme .hover-primary:hover {
  color: #d81b60 !important;
}
.bracelit-pink-theme .hover-primary-background:hover {
  background-color: #d81b60 !important;
}
.bracelit-pink-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-pink-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-pink-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-pink-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-pink-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-pink-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-pink-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-pink-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-pink-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-pink-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-pink-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-pink-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #d81b60;
}
.bracelit-pink-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-pink-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-pink-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-pink-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-pink-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-pink-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-pink-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .card-table tr:hover .action-icon button {
  color: #d81b60;
}
.bracelit-pink-theme .card-table tr:hover .action-icon:hover {
  color: #d81b60;
}
.bracelit-pink-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-pink-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-pink-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #d81b60;
}
.bracelit-pink-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-pink-theme .custom-standard-chip {
  background-color: rgba(216, 27, 96, 0.1) !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .help-dashboard-card .mat-icon {
  color: #d81b60;
}
.bracelit-pink-theme .help-dashboard-card:hover {
  background-color: #d81b60;
}
.bracelit-pink-theme .help-dashboard-card:hover .mat-icon, .bracelit-pink-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-pink-theme .room-open {
  background-color: #d81b60 !important;
  color: white !important;
}
.bracelit-pink-theme .room-open:hover {
  background-color: #d81b60 !important;
  color: white !important;
}
.bracelit-pink-theme #public-office-page-groups .public-group-button-hover {
  background-color: #d81b60 !important;
  color: white !important;
}
.bracelit-pink-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-pink-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-pink-theme .notification-container:hover .show-tip {
  color: #d81b60;
}
.bracelit-pink-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-pink-theme .notification-container .notification-subject .mat-icon {
  color: #d81b60;
}
.bracelit-pink-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-pink-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-pink-theme .message-container:hover .show-tip {
  color: #d81b60;
}
.bracelit-pink-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-pink-theme .message-container .message-subject .mat-icon {
  color: #d81b60;
}
.bracelit-pink-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-pink-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-pink-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-pink-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-pink-theme .primary-200-outline input {
  caret-color: #ec8db0;
  color: #d81b60;
}
.bracelit-pink-theme .primary-200-outline label {
  color: #ec8db0;
}
.bracelit-pink-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #ec8db0 !important;
}
.bracelit-pink-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ec8db0;
}
.bracelit-pink-theme mat-form-field .primary-outline label {
  color: #d81b60 !important;
}
.bracelit-pink-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #d81b60 !important;
}
.bracelit-pink-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #d81b60 !important;
}
.bracelit-pink-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #ec8db0 !important;
}
.bracelit-pink-theme .input-primary input {
  caret-color: #d81b60;
  color: #d81b60;
}
.bracelit-pink-theme .input-primary label {
  color: #d81b60;
}
.bracelit-pink-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #d81b60 !important;
}
.bracelit-pink-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-pink-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #d81b60;
  border-width: 1px;
}
.bracelit-pink-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-pink-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-pink-theme .header-primary-background .mobile-header-container {
  background-color: #d81b60;
}
.bracelit-pink-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ec8db0 !important;
}
.bracelit-pink-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-pink-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-pink-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-pink-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-pink-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-pink-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-pink-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-pink-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-pink-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ec8db0 !important;
}
.bracelit-pink-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffe9ed !important;
}
.bracelit-pink-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-pink-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-pink-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ffe9ed !important;
}
.bracelit-pink-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ffe9ed !important;
}
.bracelit-pink-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ff839a !important;
}
.bracelit-pink-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ff839a !important;
}
.bracelit-pink-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-pink-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #d81b60 !important;
}
.bracelit-pink-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #d81b60 !important;
}

.bracelit-purple-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-purple-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-purple-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-purple-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-purple-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #8e24aa;
}
.bracelit-purple-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-purple-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-purple-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-purple-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-purple-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-purple-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #8e24aa;
}
.bracelit-purple-theme .mat-pseudo-checkbox-checked,
.bracelit-purple-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-purple-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-purple-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-purple-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-purple-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-purple-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-purple-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-purple-theme .mat-app-background, .bracelit-purple-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-purple-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-purple-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-badge {
  position: relative;
}
.bracelit-purple-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-purple-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-purple-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-purple-theme .ng-animate-disabled .mat-badge-content,
.bracelit-purple-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-purple-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-purple-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-purple-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-purple-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-purple-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-purple-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-purple-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-purple-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-purple-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-purple-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-purple-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-purple-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-purple-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-purple-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-purple-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-purple-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-purple-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-purple-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-purple-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-purple-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-purple-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-purple-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-purple-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-purple-theme .mat-badge-content {
  color: white;
  background: #8e24aa;
}
.cdk-high-contrast-active .bracelit-purple-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-purple-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-purple-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-button, .bracelit-purple-theme .mat-icon-button, .bracelit-purple-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-purple-theme .mat-button.mat-primary, .bracelit-purple-theme .mat-icon-button.mat-primary, .bracelit-purple-theme .mat-stroked-button.mat-primary {
  color: #8e24aa;
}
.bracelit-purple-theme .mat-button.mat-accent, .bracelit-purple-theme .mat-icon-button.mat-accent, .bracelit-purple-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-purple-theme .mat-button.mat-warn, .bracelit-purple-theme .mat-icon-button.mat-warn, .bracelit-purple-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-purple-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-purple-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-purple-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-purple-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-purple-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-purple-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-purple-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-purple-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-purple-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-purple-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-purple-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-purple-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-purple-theme .mat-button .mat-ripple-element, .bracelit-purple-theme .mat-icon-button .mat-ripple-element, .bracelit-purple-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-purple-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-purple-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-flat-button, .bracelit-purple-theme .mat-raised-button, .bracelit-purple-theme .mat-fab, .bracelit-purple-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-purple-theme .mat-flat-button.mat-primary, .bracelit-purple-theme .mat-raised-button.mat-primary, .bracelit-purple-theme .mat-fab.mat-primary, .bracelit-purple-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-purple-theme .mat-flat-button.mat-accent, .bracelit-purple-theme .mat-raised-button.mat-accent, .bracelit-purple-theme .mat-fab.mat-accent, .bracelit-purple-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-flat-button.mat-warn, .bracelit-purple-theme .mat-raised-button.mat-warn, .bracelit-purple-theme .mat-fab.mat-warn, .bracelit-purple-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-purple-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-purple-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-purple-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-purple-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-purple-theme .mat-flat-button.mat-primary, .bracelit-purple-theme .mat-raised-button.mat-primary, .bracelit-purple-theme .mat-fab.mat-primary, .bracelit-purple-theme .mat-mini-fab.mat-primary {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-flat-button.mat-accent, .bracelit-purple-theme .mat-raised-button.mat-accent, .bracelit-purple-theme .mat-fab.mat-accent, .bracelit-purple-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-flat-button.mat-warn, .bracelit-purple-theme .mat-raised-button.mat-warn, .bracelit-purple-theme .mat-fab.mat-warn, .bracelit-purple-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-purple-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-purple-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-purple-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-purple-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-purple-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-purple-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-purple-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-purple-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-purple-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-purple-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-purple-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-purple-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-purple-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-purple-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-purple-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-purple-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-purple-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-purple-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-purple-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-purple-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-purple-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-purple-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-purple-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-purple-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-purple-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-purple-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-purple-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-purple-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-purple-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-purple-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-purple-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-purple-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-purple-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-purple-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-purple-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-purple-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-purple-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-purple-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-purple-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-purple-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-purple-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-purple-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-purple-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-purple-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #8e24aa;
}
.bracelit-purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #8e24aa;
  color: white;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-purple-theme .mat-table {
  background: white;
}
.bracelit-purple-theme .mat-table thead, .bracelit-purple-theme .mat-table tbody, .bracelit-purple-theme .mat-table tfoot,
.bracelit-purple-theme mat-header-row, .bracelit-purple-theme mat-row, .bracelit-purple-theme mat-footer-row,
.bracelit-purple-theme [mat-header-row], .bracelit-purple-theme [mat-row], .bracelit-purple-theme [mat-footer-row],
.bracelit-purple-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-purple-theme mat-row, .bracelit-purple-theme mat-header-row, .bracelit-purple-theme mat-footer-row,
.bracelit-purple-theme th.mat-header-cell, .bracelit-purple-theme td.mat-cell, .bracelit-purple-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-cell, .bracelit-purple-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-datepicker-toggle,
.bracelit-purple-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-purple-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-calendar-table-header,
.bracelit-purple-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-calendar-body-cell-content,
.bracelit-purple-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-purple-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-purple-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-purple-theme .mat-calendar-body-in-range::before {
  background: rgba(142, 36, 170, 0.2);
}
.bracelit-purple-theme .mat-calendar-body-comparison-identical,
.bracelit-purple-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-purple-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-purple-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(142, 36, 170, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-purple-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-purple-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(142, 36, 170, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-purple-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-purple-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-purple-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-purple-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-purple-theme .mat-calendar-body-selected {
  background-color: #8e24aa;
  color: white;
}
.bracelit-purple-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(142, 36, 170, 0.4);
}
.bracelit-purple-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-purple-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-purple-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(142, 36, 170, 0.3);
}
@media (hover: hover) {
  .bracelit-purple-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(142, 36, 170, 0.3);
  }
}
.bracelit-purple-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-purple-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-purple-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-purple-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-purple-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-purple-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-purple-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-purple-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-purple-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-datepicker-toggle-active {
  color: #8e24aa;
}
.bracelit-purple-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-purple-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-purple-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-purple-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-purple-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-purple-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-purple-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-expansion-panel-header-description,
.bracelit-purple-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-purple-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-purple-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-purple-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-purple-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-purple-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #8e24aa;
}
.bracelit-purple-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-purple-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-purple-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-purple-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #8e24aa;
}
.bracelit-purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-error {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-purple-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-purple-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-purple-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-purple-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #8e24aa;
}
.bracelit-purple-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-purple-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-purple-theme .mat-icon.mat-primary {
  color: #8e24aa;
}
.bracelit-purple-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-purple-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-input-element:disabled,
.bracelit-purple-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-input-element {
  caret-color: #8e24aa;
}
.bracelit-purple-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-purple-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-purple-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-purple-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-purple-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-list-option:hover, .bracelit-purple-theme .mat-list-option:focus,
.bracelit-purple-theme .mat-nav-list .mat-list-item:hover,
.bracelit-purple-theme .mat-nav-list .mat-list-item:focus,
.bracelit-purple-theme .mat-action-list .mat-list-item:hover,
.bracelit-purple-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-purple-theme .mat-list-single-selected-option, .bracelit-purple-theme .mat-list-single-selected-option:hover, .bracelit-purple-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-menu-panel {
  background: white;
}
.bracelit-purple-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-menu-item[disabled],
.bracelit-purple-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-purple-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-menu-item .mat-icon-no-color,
.bracelit-purple-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-menu-item:hover:not([disabled]),
.bracelit-purple-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-purple-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-purple-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-purple-theme .mat-paginator {
  background: white;
}
.bracelit-purple-theme .mat-paginator,
.bracelit-purple-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-paginator-decrement,
.bracelit-purple-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-paginator-first,
.bracelit-purple-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-purple-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-purple-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-purple-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-progress-bar-background {
  fill: #dfc5e6;
}
.bracelit-purple-theme .mat-progress-bar-buffer {
  background-color: #dfc5e6;
}
.bracelit-purple-theme .mat-progress-bar-fill::after {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-progress-spinner circle, .bracelit-purple-theme .mat-spinner circle {
  stroke: #8e24aa;
}
.bracelit-purple-theme .mat-progress-spinner.mat-accent circle, .bracelit-purple-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-purple-theme .mat-progress-spinner.mat-warn circle, .bracelit-purple-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-purple-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e24aa;
}
.bracelit-purple-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-purple-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-purple-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-purple-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-purple-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-purple-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-purple-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-purple-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-purple-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-purple-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-purple-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-purple-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-purple-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-select-panel {
  background: white;
}
.bracelit-purple-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #8e24aa;
}
.bracelit-purple-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-purple-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-purple-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-purple-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-purple-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-purple-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-purple-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-purple-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(142, 36, 170, 0.54);
}
.bracelit-purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-purple-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-purple-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-purple-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-purple-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-purple-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-purple-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(142, 36, 170, 0.2);
}
.bracelit-purple-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-purple-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-purple-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-purple-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-purple-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-purple-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-purple-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-purple-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-purple-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-purple-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-purple-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-purple-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-purple-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-purple-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-purple-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-purple-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-purple-theme .mat-step-header.cdk-keyboard-focused, .bracelit-purple-theme .mat-step-header.cdk-program-focused, .bracelit-purple-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-purple-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-purple-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-purple-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-purple-theme .mat-step-header .mat-step-label,
.bracelit-purple-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-purple-theme .mat-step-header .mat-step-icon-selected,
.bracelit-purple-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-purple-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #8e24aa;
  color: white;
}
.bracelit-purple-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-purple-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-purple-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-purple-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-purple-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-purple-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-purple-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-purple-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-purple-theme .mat-stepper-horizontal, .bracelit-purple-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-purple-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-horizontal-stepper-header::before,
.bracelit-purple-theme .mat-horizontal-stepper-header::after,
.bracelit-purple-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-purple-theme .mat-tab-nav-bar,
.bracelit-purple-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-purple-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-purple-theme .mat-tab-label, .bracelit-purple-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-tab-label.mat-tab-disabled, .bracelit-purple-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-purple-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-purple-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(221, 189, 230, 0.3);
}
.bracelit-purple-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-purple-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-purple-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-purple-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-purple-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-purple-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-purple-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-purple-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-purple-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(221, 189, 230, 0.3);
}
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #8e24aa;
}
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-purple-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-purple-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-purple-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-toolbar.mat-primary {
  background: #8e24aa;
  color: white;
}
.bracelit-purple-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-purple-theme .mat-toolbar .mat-form-field-underline,
.bracelit-purple-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-purple-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-purple-theme .mat-toolbar .mat-form-field-label,
.bracelit-purple-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-purple-theme .mat-toolbar .mat-select-value,
.bracelit-purple-theme .mat-toolbar .mat-select-arrow,
.bracelit-purple-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-purple-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-purple-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-purple-theme .mat-tree {
  background: white;
}
.bracelit-purple-theme .mat-tree-node,
.bracelit-purple-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-purple-theme .back-container {
  color: #8e24aa;
}
.bracelit-purple-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-purple-theme .warn-text {
  color: #e74737;
}
.bracelit-purple-theme .inside-modal-title.primary-colored-title, .bracelit-purple-theme .form-title.primary-colored-title {
  color: #8e24aa;
}
.bracelit-purple-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-purple-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-purple-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-purple-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .box-checkbox.active-box-checkbox {
  background-color: #8e24aa;
  color: white;
}
.bracelit-purple-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .box-radio.active-box-radio {
  background-color: #8e24aa;
  color: white;
}
.bracelit-purple-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .error {
  color: #e74737;
}
.bracelit-purple-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-purple-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-purple-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-purple-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-purple-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-purple-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-purple-theme .ql-snow .ql-fill,
.bracelit-purple-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .ql-snow.ql-toolbar button:hover,
.bracelit-purple-theme .ql-snow .ql-toolbar button:hover,
.bracelit-purple-theme .ql-snow.ql-toolbar button:focus,
.bracelit-purple-theme .ql-snow .ql-toolbar button:focus,
.bracelit-purple-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-purple-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-purple-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-purple-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-purple-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-purple-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-purple-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-purple-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-purple-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-purple-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-purple-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-purple-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-purple-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #8e24aa;
  height: 38px !important;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-purple-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-purple-theme .general-container .label-wrapper label {
  color: #8e24aa !important;
  background-color: white;
}
.bracelit-purple-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-purple-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #8e24aa !important;
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #8e24aa;
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #8e24aa;
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #8e24aa;
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-purple-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-purple-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #8e24aa;
}
.bracelit-purple-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-purple-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-purple-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #8e24aa;
}
.bracelit-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-purple-theme .move-image-action-container:hover {
  color: #8e24aa;
}
.bracelit-purple-theme .info-icon {
  color: #8e24aa;
}
.bracelit-purple-theme .email-chip {
  color: #8e24aa !important;
  background-color: rgba(142, 36, 170, 0.15) !important;
}
.bracelit-purple-theme .email-chip .mat-chip-remove {
  color: #8e24aa !important;
  opacity: 1 !important;
}
.bracelit-purple-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-purple-theme .primary-color {
  color: #8e24aa !important;
}
.bracelit-purple-theme .primary-color-200 {
  color: #c792d5 !important;
}
.bracelit-purple-theme .primary-fill {
  fill: #8e24aa !important;
}
.bracelit-purple-theme .secondary-fill {
  fill: #e6b1ff !important;
}
.bracelit-purple-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-purple-theme .primary-border-50 {
  border-color: #f1e5f5 !important;
}
.bracelit-purple-theme .primary-border-100 {
  border-color: #ddbde6 !important;
}
.bracelit-purple-theme .primary-border-200 {
  border-color: #c792d5 !important;
}
.bracelit-purple-theme .primary-indicator .mat-expansion-indicator:after {
  color: #8e24aa;
}
.bracelit-purple-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-purple-theme .primary-color-small {
  color: rgba(142, 36, 170, 0.6);
}
.bracelit-purple-theme .primary-background {
  background-color: #8e24aa !important;
}
.bracelit-purple-theme .primary-background-50 {
  background-color: #f1e5f5 !important;
}
.bracelit-purple-theme .primary-background-200 {
  background-color: #c792d5 !important;
}
.bracelit-purple-theme .primary-background-800 {
  background-color: #711690 !important;
}
.bracelit-purple-theme .primary-background-800-2 {
  background-color: #711690 !important;
  color: white !important;
}
.bracelit-purple-theme .primary-background-opacity-01 {
  background-color: rgba(142, 36, 170, 0.1) !important;
}
.bracelit-purple-theme .secondary-color {
  color: #e6b1ff !important;
}
.bracelit-purple-theme .secondary-background {
  background-color: #e6b1ff !important;
}
.bracelit-purple-theme .secondary-background-opacity-01 {
  background-color: rgba(230, 177, 255, 0.1) !important;
}
.bracelit-purple-theme .secondary-border {
  border-color: #e6b1ff !important;
}
.bracelit-purple-theme .tertiary-color {
  color: #c64bff !important;
}
.bracelit-purple-theme .tertiary-background {
  background-image: linear-gradient(45deg, #e6b1ff, #c64bff) !important;
}
.bracelit-purple-theme .tertiary-border {
  border-color: #c64bff !important;
}
.bracelit-purple-theme .dark-background {
  background-color: black !important;
}
.bracelit-purple-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-purple-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-purple-theme .primary-soft-background {
  background-color: rgba(142, 36, 170, 0.1) !important;
}
.bracelit-purple-theme .primary-soft-background:hover {
  background-color: rgba(142, 36, 170, 0.15) !important;
}
.bracelit-purple-theme .primary-border {
  border-color: #8e24aa !important;
}
.bracelit-purple-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-purple-theme .hover-primary:hover {
  color: #8e24aa !important;
}
.bracelit-purple-theme .hover-primary-background:hover {
  background-color: #8e24aa !important;
}
.bracelit-purple-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-purple-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-purple-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-purple-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-purple-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-purple-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-purple-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-purple-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-purple-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-purple-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-purple-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-purple-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #8e24aa;
}
.bracelit-purple-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-purple-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-purple-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-purple-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-purple-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-purple-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-purple-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .card-table tr:hover .action-icon button {
  color: #8e24aa;
}
.bracelit-purple-theme .card-table tr:hover .action-icon:hover {
  color: #8e24aa;
}
.bracelit-purple-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-purple-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-purple-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #8e24aa;
}
.bracelit-purple-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-purple-theme .custom-standard-chip {
  background-color: rgba(142, 36, 170, 0.1) !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .help-dashboard-card .mat-icon {
  color: #8e24aa;
}
.bracelit-purple-theme .help-dashboard-card:hover {
  background-color: #8e24aa;
}
.bracelit-purple-theme .help-dashboard-card:hover .mat-icon, .bracelit-purple-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-purple-theme .room-open {
  background-color: #8e24aa !important;
  color: white !important;
}
.bracelit-purple-theme .room-open:hover {
  background-color: #8e24aa !important;
  color: white !important;
}
.bracelit-purple-theme #public-office-page-groups .public-group-button-hover {
  background-color: #8e24aa !important;
  color: white !important;
}
.bracelit-purple-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-purple-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-purple-theme .notification-container:hover .show-tip {
  color: #8e24aa;
}
.bracelit-purple-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-purple-theme .notification-container .notification-subject .mat-icon {
  color: #8e24aa;
}
.bracelit-purple-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-purple-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-purple-theme .message-container:hover .show-tip {
  color: #8e24aa;
}
.bracelit-purple-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-purple-theme .message-container .message-subject .mat-icon {
  color: #8e24aa;
}
.bracelit-purple-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-purple-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-purple-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-purple-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-purple-theme .primary-200-outline input {
  caret-color: #c792d5;
  color: #8e24aa;
}
.bracelit-purple-theme .primary-200-outline label {
  color: #c792d5;
}
.bracelit-purple-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #c792d5 !important;
}
.bracelit-purple-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #c792d5;
}
.bracelit-purple-theme mat-form-field .primary-outline label {
  color: #8e24aa !important;
}
.bracelit-purple-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #8e24aa !important;
}
.bracelit-purple-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #8e24aa !important;
}
.bracelit-purple-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #c792d5 !important;
}
.bracelit-purple-theme .input-primary input {
  caret-color: #8e24aa;
  color: #8e24aa;
}
.bracelit-purple-theme .input-primary label {
  color: #8e24aa;
}
.bracelit-purple-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #8e24aa !important;
}
.bracelit-purple-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-purple-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #8e24aa;
  border-width: 1px;
}
.bracelit-purple-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-purple-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-purple-theme .header-primary-background .mobile-header-container {
  background-color: #8e24aa;
}
.bracelit-purple-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #c792d5 !important;
}
.bracelit-purple-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-purple-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-purple-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-purple-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-purple-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-purple-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-purple-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-purple-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-purple-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #c792d5 !important;
}
.bracelit-purple-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #e6b1ff !important;
}
.bracelit-purple-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-purple-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-purple-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #e6b1ff !important;
}
.bracelit-purple-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e6b1ff !important;
}
.bracelit-purple-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #c64bff !important;
}
.bracelit-purple-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #c64bff !important;
}
.bracelit-purple-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-purple-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #8e24aa !important;
}
.bracelit-purple-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #8e24aa !important;
}

.bracelit-deep-purple-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-purple-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-deep-purple-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-purple-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-purple-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #573c87;
}
.bracelit-deep-purple-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-deep-purple-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-deep-purple-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-deep-purple-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #573c87;
}
.bracelit-deep-purple-theme .mat-pseudo-checkbox-checked,
.bracelit-deep-purple-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-deep-purple-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-deep-purple-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-deep-purple-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-deep-purple-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-deep-purple-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-deep-purple-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-deep-purple-theme .mat-app-background, .bracelit-deep-purple-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-deep-purple-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-deep-purple-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-badge {
  position: relative;
}
.bracelit-deep-purple-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-deep-purple-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-deep-purple-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-deep-purple-theme .ng-animate-disabled .mat-badge-content,
.bracelit-deep-purple-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-deep-purple-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-deep-purple-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-deep-purple-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-deep-purple-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-deep-purple-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-deep-purple-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-deep-purple-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-deep-purple-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-deep-purple-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-deep-purple-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-deep-purple-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-deep-purple-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-deep-purple-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-deep-purple-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-deep-purple-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-deep-purple-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-deep-purple-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-deep-purple-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-deep-purple-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-deep-purple-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-deep-purple-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-deep-purple-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-deep-purple-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-deep-purple-theme .mat-badge-content {
  color: white;
  background: #573c87;
}
.cdk-high-contrast-active .bracelit-deep-purple-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-deep-purple-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-deep-purple-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-button, .bracelit-deep-purple-theme .mat-icon-button, .bracelit-deep-purple-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-deep-purple-theme .mat-button.mat-primary, .bracelit-deep-purple-theme .mat-icon-button.mat-primary, .bracelit-deep-purple-theme .mat-stroked-button.mat-primary {
  color: #573c87;
}
.bracelit-deep-purple-theme .mat-button.mat-accent, .bracelit-deep-purple-theme .mat-icon-button.mat-accent, .bracelit-deep-purple-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-button.mat-warn, .bracelit-deep-purple-theme .mat-icon-button.mat-warn, .bracelit-deep-purple-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-purple-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-purple-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-purple-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-purple-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-purple-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-purple-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-purple-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-purple-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-purple-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-purple-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-purple-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-deep-purple-theme .mat-button .mat-ripple-element, .bracelit-deep-purple-theme .mat-icon-button .mat-ripple-element, .bracelit-deep-purple-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-deep-purple-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-deep-purple-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-flat-button, .bracelit-deep-purple-theme .mat-raised-button, .bracelit-deep-purple-theme .mat-fab, .bracelit-deep-purple-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-deep-purple-theme .mat-flat-button.mat-primary, .bracelit-deep-purple-theme .mat-raised-button.mat-primary, .bracelit-deep-purple-theme .mat-fab.mat-primary, .bracelit-deep-purple-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-deep-purple-theme .mat-flat-button.mat-accent, .bracelit-deep-purple-theme .mat-raised-button.mat-accent, .bracelit-deep-purple-theme .mat-fab.mat-accent, .bracelit-deep-purple-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-flat-button.mat-warn, .bracelit-deep-purple-theme .mat-raised-button.mat-warn, .bracelit-deep-purple-theme .mat-fab.mat-warn, .bracelit-deep-purple-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-deep-purple-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-purple-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-purple-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-purple-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-purple-theme .mat-flat-button.mat-primary, .bracelit-deep-purple-theme .mat-raised-button.mat-primary, .bracelit-deep-purple-theme .mat-fab.mat-primary, .bracelit-deep-purple-theme .mat-mini-fab.mat-primary {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-flat-button.mat-accent, .bracelit-deep-purple-theme .mat-raised-button.mat-accent, .bracelit-deep-purple-theme .mat-fab.mat-accent, .bracelit-deep-purple-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-flat-button.mat-warn, .bracelit-deep-purple-theme .mat-raised-button.mat-warn, .bracelit-deep-purple-theme .mat-fab.mat-warn, .bracelit-deep-purple-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-purple-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-purple-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-purple-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-purple-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-purple-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-purple-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-deep-purple-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-deep-purple-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-deep-purple-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-purple-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-deep-purple-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-deep-purple-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-deep-purple-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-purple-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-deep-purple-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-deep-purple-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-deep-purple-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-purple-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-deep-purple-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-deep-purple-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-deep-purple-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-deep-purple-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-deep-purple-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-deep-purple-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-deep-purple-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-deep-purple-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-deep-purple-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-deep-purple-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-deep-purple-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-deep-purple-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-deep-purple-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-deep-purple-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-deep-purple-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-deep-purple-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-deep-purple-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-deep-purple-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-deep-purple-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-deep-purple-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-deep-purple-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-deep-purple-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-deep-purple-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-deep-purple-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-deep-purple-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-deep-purple-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-deep-purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #573c87;
}
.bracelit-deep-purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-deep-purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-deep-purple-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-deep-purple-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #573c87;
  color: white;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-purple-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-purple-theme .mat-table {
  background: white;
}
.bracelit-deep-purple-theme .mat-table thead, .bracelit-deep-purple-theme .mat-table tbody, .bracelit-deep-purple-theme .mat-table tfoot,
.bracelit-deep-purple-theme mat-header-row, .bracelit-deep-purple-theme mat-row, .bracelit-deep-purple-theme mat-footer-row,
.bracelit-deep-purple-theme [mat-header-row], .bracelit-deep-purple-theme [mat-row], .bracelit-deep-purple-theme [mat-footer-row],
.bracelit-deep-purple-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-deep-purple-theme mat-row, .bracelit-deep-purple-theme mat-header-row, .bracelit-deep-purple-theme mat-footer-row,
.bracelit-deep-purple-theme th.mat-header-cell, .bracelit-deep-purple-theme td.mat-cell, .bracelit-deep-purple-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-cell, .bracelit-deep-purple-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-datepicker-toggle,
.bracelit-deep-purple-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-deep-purple-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-calendar-table-header,
.bracelit-deep-purple-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-calendar-body-cell-content,
.bracelit-deep-purple-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-deep-purple-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-deep-purple-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-deep-purple-theme .mat-calendar-body-in-range::before {
  background: rgba(87, 60, 135, 0.2);
}
.bracelit-deep-purple-theme .mat-calendar-body-comparison-identical,
.bracelit-deep-purple-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-purple-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-purple-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(87, 60, 135, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-purple-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-purple-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(87, 60, 135, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-purple-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-purple-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-purple-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-purple-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-purple-theme .mat-calendar-body-selected {
  background-color: #573c87;
  color: white;
}
.bracelit-deep-purple-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(87, 60, 135, 0.4);
}
.bracelit-deep-purple-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-purple-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-purple-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(87, 60, 135, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-purple-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(87, 60, 135, 0.3);
  }
}
.bracelit-deep-purple-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-purple-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-purple-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-deep-purple-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-datepicker-toggle-active {
  color: #573c87;
}
.bracelit-deep-purple-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-deep-purple-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-deep-purple-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-deep-purple-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-deep-purple-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-expansion-panel-header-description,
.bracelit-deep-purple-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-purple-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-deep-purple-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-deep-purple-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-purple-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #573c87;
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #573c87;
}
.bracelit-deep-purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-deep-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-deep-purple-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-error {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-purple-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-purple-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-deep-purple-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #573c87;
}
.bracelit-deep-purple-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-purple-theme .mat-icon.mat-primary {
  color: #573c87;
}
.bracelit-deep-purple-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-input-element:disabled,
.bracelit-deep-purple-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-input-element {
  caret-color: #573c87;
}
.bracelit-deep-purple-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-deep-purple-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-list-option:hover, .bracelit-deep-purple-theme .mat-list-option:focus,
.bracelit-deep-purple-theme .mat-nav-list .mat-list-item:hover,
.bracelit-deep-purple-theme .mat-nav-list .mat-list-item:focus,
.bracelit-deep-purple-theme .mat-action-list .mat-list-item:hover,
.bracelit-deep-purple-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-purple-theme .mat-list-single-selected-option, .bracelit-deep-purple-theme .mat-list-single-selected-option:hover, .bracelit-deep-purple-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-menu-panel {
  background: white;
}
.bracelit-deep-purple-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-menu-item[disabled],
.bracelit-deep-purple-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-deep-purple-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-menu-item .mat-icon-no-color,
.bracelit-deep-purple-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-menu-item:hover:not([disabled]),
.bracelit-deep-purple-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-deep-purple-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-deep-purple-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-purple-theme .mat-paginator {
  background: white;
}
.bracelit-deep-purple-theme .mat-paginator,
.bracelit-deep-purple-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-paginator-decrement,
.bracelit-deep-purple-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-paginator-first,
.bracelit-deep-purple-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-deep-purple-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-deep-purple-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-deep-purple-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-progress-bar-background {
  fill: #d1cbdd;
}
.bracelit-deep-purple-theme .mat-progress-bar-buffer {
  background-color: #d1cbdd;
}
.bracelit-deep-purple-theme .mat-progress-bar-fill::after {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-deep-purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-deep-purple-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-deep-purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-deep-purple-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-progress-spinner circle, .bracelit-deep-purple-theme .mat-spinner circle {
  stroke: #573c87;
}
.bracelit-deep-purple-theme .mat-progress-spinner.mat-accent circle, .bracelit-deep-purple-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-deep-purple-theme .mat-progress-spinner.mat-warn circle, .bracelit-deep-purple-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-deep-purple-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #573c87;
}
.bracelit-deep-purple-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-deep-purple-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-purple-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-purple-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-deep-purple-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-purple-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-purple-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-deep-purple-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-deep-purple-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-purple-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-purple-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-deep-purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-deep-purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-purple-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-select-panel {
  background: white;
}
.bracelit-deep-purple-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #573c87;
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-deep-purple-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-deep-purple-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-purple-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-purple-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(87, 60, 135, 0.54);
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-deep-purple-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-purple-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-deep-purple-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-purple-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-deep-purple-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-deep-purple-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-purple-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(87, 60, 135, 0.2);
}
.bracelit-deep-purple-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-deep-purple-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-deep-purple-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-purple-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-deep-purple-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-deep-purple-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-purple-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-purple-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-deep-purple-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-deep-purple-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-deep-purple-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-purple-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-purple-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-deep-purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-deep-purple-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-deep-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-deep-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-deep-purple-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-purple-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-deep-purple-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-purple-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-purple-theme .mat-step-header.cdk-keyboard-focused, .bracelit-deep-purple-theme .mat-step-header.cdk-program-focused, .bracelit-deep-purple-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-deep-purple-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-purple-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-deep-purple-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-deep-purple-theme .mat-step-header .mat-step-label,
.bracelit-deep-purple-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-deep-purple-theme .mat-step-header .mat-step-icon-selected,
.bracelit-deep-purple-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-deep-purple-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #573c87;
  color: white;
}
.bracelit-deep-purple-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-deep-purple-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-deep-purple-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-deep-purple-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-deep-purple-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-deep-purple-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-purple-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-deep-purple-theme .mat-stepper-horizontal, .bracelit-deep-purple-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-deep-purple-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-horizontal-stepper-header::before,
.bracelit-deep-purple-theme .mat-horizontal-stepper-header::after,
.bracelit-deep-purple-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-deep-purple-theme .mat-tab-nav-bar,
.bracelit-deep-purple-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-deep-purple-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-deep-purple-theme .mat-tab-label, .bracelit-deep-purple-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-tab-label.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-deep-purple-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(205, 197, 219, 0.3);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(205, 197, 219, 0.3);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-purple-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-purple-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-toolbar.mat-primary {
  background: #573c87;
  color: white;
}
.bracelit-deep-purple-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-deep-purple-theme .mat-toolbar .mat-form-field-underline,
.bracelit-deep-purple-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-deep-purple-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-deep-purple-theme .mat-toolbar .mat-form-field-label,
.bracelit-deep-purple-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-deep-purple-theme .mat-toolbar .mat-select-value,
.bracelit-deep-purple-theme .mat-toolbar .mat-select-arrow,
.bracelit-deep-purple-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-deep-purple-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-deep-purple-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-deep-purple-theme .mat-tree {
  background: white;
}
.bracelit-deep-purple-theme .mat-tree-node,
.bracelit-deep-purple-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-deep-purple-theme .back-container {
  color: #573c87;
}
.bracelit-deep-purple-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-deep-purple-theme .warn-text {
  color: #e74737;
}
.bracelit-deep-purple-theme .inside-modal-title.primary-colored-title, .bracelit-deep-purple-theme .form-title.primary-colored-title {
  color: #573c87;
}
.bracelit-deep-purple-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-deep-purple-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-purple-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-purple-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .box-checkbox.active-box-checkbox {
  background-color: #573c87;
  color: white;
}
.bracelit-deep-purple-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .box-radio.active-box-radio {
  background-color: #573c87;
  color: white;
}
.bracelit-deep-purple-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .error {
  color: #e74737;
}
.bracelit-deep-purple-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-deep-purple-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-deep-purple-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-deep-purple-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-deep-purple-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-deep-purple-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-deep-purple-theme .ql-snow .ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:hover,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:hover,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:focus,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:focus,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-deep-purple-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #573c87;
  height: 38px !important;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-deep-purple-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-deep-purple-theme .general-container .label-wrapper label {
  color: #573c87 !important;
  background-color: white;
}
.bracelit-deep-purple-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-deep-purple-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #573c87 !important;
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #573c87;
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #573c87;
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #573c87;
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-purple-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-deep-purple-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #573c87;
}
.bracelit-deep-purple-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-purple-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-deep-purple-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #573c87;
}
.bracelit-deep-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-purple-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-purple-theme .move-image-action-container:hover {
  color: #573c87;
}
.bracelit-deep-purple-theme .info-icon {
  color: #573c87;
}
.bracelit-deep-purple-theme .email-chip {
  color: #573c87 !important;
  background-color: rgba(87, 60, 135, 0.15) !important;
}
.bracelit-deep-purple-theme .email-chip .mat-chip-remove {
  color: #573c87 !important;
  opacity: 1 !important;
}
.bracelit-deep-purple-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-deep-purple-theme .primary-color {
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .primary-color-200 {
  color: #ab9ec3 !important;
}
.bracelit-deep-purple-theme .primary-fill {
  fill: #573c87 !important;
}
.bracelit-deep-purple-theme .secondary-fill {
  fill: #b195ff !important;
}
.bracelit-deep-purple-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-deep-purple-theme .primary-border-50 {
  border-color: #ebe8f1 !important;
}
.bracelit-deep-purple-theme .primary-border-100 {
  border-color: #cdc5db !important;
}
.bracelit-deep-purple-theme .primary-border-200 {
  border-color: #ab9ec3 !important;
}
.bracelit-deep-purple-theme .primary-indicator .mat-expansion-indicator:after {
  color: #573c87;
}
.bracelit-deep-purple-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-deep-purple-theme .primary-color-small {
  color: rgba(87, 60, 135, 0.6);
}
.bracelit-deep-purple-theme .primary-background {
  background-color: #573c87 !important;
}
.bracelit-deep-purple-theme .primary-background-50 {
  background-color: #ebe8f1 !important;
}
.bracelit-deep-purple-theme .primary-background-200 {
  background-color: #ab9ec3 !important;
}
.bracelit-deep-purple-theme .primary-background-800 {
  background-color: #3c276a !important;
}
.bracelit-deep-purple-theme .primary-background-800-2 {
  background-color: #3c276a !important;
  color: white !important;
}
.bracelit-deep-purple-theme .primary-background-opacity-01 {
  background-color: rgba(87, 60, 135, 0.1) !important;
}
.bracelit-deep-purple-theme .secondary-color {
  color: #b195ff !important;
}
.bracelit-deep-purple-theme .secondary-background {
  background-color: #b195ff !important;
}
.bracelit-deep-purple-theme .secondary-background-opacity-01 {
  background-color: rgba(177, 149, 255, 0.1) !important;
}
.bracelit-deep-purple-theme .secondary-border {
  border-color: #b195ff !important;
}
.bracelit-deep-purple-theme .tertiary-color {
  color: #662fff !important;
}
.bracelit-deep-purple-theme .tertiary-background {
  background-image: linear-gradient(45deg, #b195ff, #662fff) !important;
}
.bracelit-deep-purple-theme .tertiary-border {
  border-color: #662fff !important;
}
.bracelit-deep-purple-theme .dark-background {
  background-color: black !important;
}
.bracelit-deep-purple-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-deep-purple-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-deep-purple-theme .primary-soft-background {
  background-color: rgba(87, 60, 135, 0.1) !important;
}
.bracelit-deep-purple-theme .primary-soft-background:hover {
  background-color: rgba(87, 60, 135, 0.15) !important;
}
.bracelit-deep-purple-theme .primary-border {
  border-color: #573c87 !important;
}
.bracelit-deep-purple-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-deep-purple-theme .hover-primary:hover {
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .hover-primary-background:hover {
  background-color: #573c87 !important;
}
.bracelit-deep-purple-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-deep-purple-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-deep-purple-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-deep-purple-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-deep-purple-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-deep-purple-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-deep-purple-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-deep-purple-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-deep-purple-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-purple-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-deep-purple-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-purple-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #573c87;
}
.bracelit-deep-purple-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-purple-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-deep-purple-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-deep-purple-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-purple-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-deep-purple-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-purple-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .card-table tr:hover .action-icon button {
  color: #573c87;
}
.bracelit-deep-purple-theme .card-table tr:hover .action-icon:hover {
  color: #573c87;
}
.bracelit-deep-purple-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-deep-purple-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-purple-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #573c87;
}
.bracelit-deep-purple-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-deep-purple-theme .custom-standard-chip {
  background-color: rgba(87, 60, 135, 0.1) !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .help-dashboard-card .mat-icon {
  color: #573c87;
}
.bracelit-deep-purple-theme .help-dashboard-card:hover {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .help-dashboard-card:hover .mat-icon, .bracelit-deep-purple-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-deep-purple-theme .room-open {
  background-color: #573c87 !important;
  color: white !important;
}
.bracelit-deep-purple-theme .room-open:hover {
  background-color: #573c87 !important;
  color: white !important;
}
.bracelit-deep-purple-theme #public-office-page-groups .public-group-button-hover {
  background-color: #573c87 !important;
  color: white !important;
}
.bracelit-deep-purple-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-deep-purple-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-purple-theme .notification-container:hover .show-tip {
  color: #573c87;
}
.bracelit-deep-purple-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-purple-theme .notification-container .notification-subject .mat-icon {
  color: #573c87;
}
.bracelit-deep-purple-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-deep-purple-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-purple-theme .message-container:hover .show-tip {
  color: #573c87;
}
.bracelit-deep-purple-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-purple-theme .message-container .message-subject .mat-icon {
  color: #573c87;
}
.bracelit-deep-purple-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-purple-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-purple-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-purple-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-deep-purple-theme .primary-200-outline input {
  caret-color: #ab9ec3;
  color: #573c87;
}
.bracelit-deep-purple-theme .primary-200-outline label {
  color: #ab9ec3;
}
.bracelit-deep-purple-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #ab9ec3 !important;
}
.bracelit-deep-purple-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ab9ec3;
}
.bracelit-deep-purple-theme mat-form-field .primary-outline label {
  color: #573c87 !important;
}
.bracelit-deep-purple-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #573c87 !important;
}
.bracelit-deep-purple-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #ab9ec3 !important;
}
.bracelit-deep-purple-theme .input-primary input {
  caret-color: #573c87;
  color: #573c87;
}
.bracelit-deep-purple-theme .input-primary label {
  color: #573c87;
}
.bracelit-deep-purple-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #573c87 !important;
}
.bracelit-deep-purple-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-deep-purple-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #573c87;
  border-width: 1px;
}
.bracelit-deep-purple-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-deep-purple-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-deep-purple-theme .header-primary-background .mobile-header-container {
  background-color: #573c87;
}
.bracelit-deep-purple-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ab9ec3 !important;
}
.bracelit-deep-purple-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-purple-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-purple-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-purple-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-purple-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-purple-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-purple-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-purple-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-purple-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ab9ec3 !important;
}
.bracelit-deep-purple-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #b195ff !important;
}
.bracelit-deep-purple-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-deep-purple-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-deep-purple-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #b195ff !important;
}
.bracelit-deep-purple-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #b195ff !important;
}
.bracelit-deep-purple-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #662fff !important;
}
.bracelit-deep-purple-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #662fff !important;
}
.bracelit-deep-purple-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-deep-purple-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #573c87 !important;
}
.bracelit-deep-purple-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #573c87 !important;
}

.bracelit-deep-green-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-green-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-deep-green-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-green-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-green-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-deep-green-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-deep-green-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-deep-green-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0a6c2d;
}
.bracelit-deep-green-theme .mat-pseudo-checkbox-checked,
.bracelit-deep-green-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-deep-green-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-deep-green-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-deep-green-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-deep-green-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-deep-green-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-deep-green-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-deep-green-theme .mat-app-background, .bracelit-deep-green-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-deep-green-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-deep-green-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-badge {
  position: relative;
}
.bracelit-deep-green-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-deep-green-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-deep-green-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-deep-green-theme .ng-animate-disabled .mat-badge-content,
.bracelit-deep-green-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-deep-green-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-deep-green-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-deep-green-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-deep-green-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-deep-green-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-deep-green-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-deep-green-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-deep-green-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-deep-green-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-deep-green-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-deep-green-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-deep-green-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-deep-green-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-deep-green-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-deep-green-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-deep-green-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-deep-green-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-deep-green-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-deep-green-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-deep-green-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-deep-green-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-deep-green-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-deep-green-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-deep-green-theme .mat-badge-content {
  color: white;
  background: #0a6c2d;
}
.cdk-high-contrast-active .bracelit-deep-green-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-deep-green-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-deep-green-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-button, .bracelit-deep-green-theme .mat-icon-button, .bracelit-deep-green-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-deep-green-theme .mat-button.mat-primary, .bracelit-deep-green-theme .mat-icon-button.mat-primary, .bracelit-deep-green-theme .mat-stroked-button.mat-primary {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-button.mat-accent, .bracelit-deep-green-theme .mat-icon-button.mat-accent, .bracelit-deep-green-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-button.mat-warn, .bracelit-deep-green-theme .mat-icon-button.mat-warn, .bracelit-deep-green-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-green-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-green-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-green-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-green-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-green-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-green-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-green-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-green-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-green-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-green-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-green-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-deep-green-theme .mat-button .mat-ripple-element, .bracelit-deep-green-theme .mat-icon-button .mat-ripple-element, .bracelit-deep-green-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-deep-green-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-deep-green-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-flat-button, .bracelit-deep-green-theme .mat-raised-button, .bracelit-deep-green-theme .mat-fab, .bracelit-deep-green-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-deep-green-theme .mat-flat-button.mat-primary, .bracelit-deep-green-theme .mat-raised-button.mat-primary, .bracelit-deep-green-theme .mat-fab.mat-primary, .bracelit-deep-green-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-deep-green-theme .mat-flat-button.mat-accent, .bracelit-deep-green-theme .mat-raised-button.mat-accent, .bracelit-deep-green-theme .mat-fab.mat-accent, .bracelit-deep-green-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-flat-button.mat-warn, .bracelit-deep-green-theme .mat-raised-button.mat-warn, .bracelit-deep-green-theme .mat-fab.mat-warn, .bracelit-deep-green-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-deep-green-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-green-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-green-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-green-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-green-theme .mat-flat-button.mat-primary, .bracelit-deep-green-theme .mat-raised-button.mat-primary, .bracelit-deep-green-theme .mat-fab.mat-primary, .bracelit-deep-green-theme .mat-mini-fab.mat-primary {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-flat-button.mat-accent, .bracelit-deep-green-theme .mat-raised-button.mat-accent, .bracelit-deep-green-theme .mat-fab.mat-accent, .bracelit-deep-green-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-flat-button.mat-warn, .bracelit-deep-green-theme .mat-raised-button.mat-warn, .bracelit-deep-green-theme .mat-fab.mat-warn, .bracelit-deep-green-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-green-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-green-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-green-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-green-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-green-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-green-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-deep-green-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-deep-green-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-deep-green-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-green-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-deep-green-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-deep-green-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-deep-green-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-green-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-deep-green-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-deep-green-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-deep-green-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-green-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-deep-green-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-deep-green-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-deep-green-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-deep-green-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-deep-green-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-deep-green-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-deep-green-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-deep-green-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-deep-green-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-deep-green-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-deep-green-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-deep-green-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-deep-green-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-deep-green-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-deep-green-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-deep-green-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-deep-green-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-deep-green-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-deep-green-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-deep-green-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-deep-green-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-deep-green-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-deep-green-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-deep-green-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-deep-green-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-deep-green-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-deep-green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0a6c2d;
}
.bracelit-deep-green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-deep-green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-deep-green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-deep-green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0a6c2d;
  color: white;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-green-theme .mat-table {
  background: white;
}
.bracelit-deep-green-theme .mat-table thead, .bracelit-deep-green-theme .mat-table tbody, .bracelit-deep-green-theme .mat-table tfoot,
.bracelit-deep-green-theme mat-header-row, .bracelit-deep-green-theme mat-row, .bracelit-deep-green-theme mat-footer-row,
.bracelit-deep-green-theme [mat-header-row], .bracelit-deep-green-theme [mat-row], .bracelit-deep-green-theme [mat-footer-row],
.bracelit-deep-green-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-deep-green-theme mat-row, .bracelit-deep-green-theme mat-header-row, .bracelit-deep-green-theme mat-footer-row,
.bracelit-deep-green-theme th.mat-header-cell, .bracelit-deep-green-theme td.mat-cell, .bracelit-deep-green-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-cell, .bracelit-deep-green-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-datepicker-toggle,
.bracelit-deep-green-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-deep-green-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-calendar-table-header,
.bracelit-deep-green-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-calendar-body-cell-content,
.bracelit-deep-green-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-deep-green-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-deep-green-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-deep-green-theme .mat-calendar-body-in-range::before {
  background: rgba(10, 108, 45, 0.2);
}
.bracelit-deep-green-theme .mat-calendar-body-comparison-identical,
.bracelit-deep-green-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-green-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-green-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(10, 108, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-green-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-green-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(10, 108, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-green-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-green-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-green-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-green-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-green-theme .mat-calendar-body-selected {
  background-color: #0a6c2d;
  color: white;
}
.bracelit-deep-green-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(10, 108, 45, 0.4);
}
.bracelit-deep-green-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-green-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-green-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(10, 108, 45, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-green-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(10, 108, 45, 0.3);
  }
}
.bracelit-deep-green-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-green-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-green-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-deep-green-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-datepicker-toggle-active {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-deep-green-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-deep-green-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-deep-green-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-deep-green-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-expansion-panel-header-description,
.bracelit-deep-green-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-green-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-deep-green-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-deep-green-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-green-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-green-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-deep-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-deep-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-error {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-green-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-green-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-deep-green-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-deep-green-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-green-theme .mat-icon.mat-primary {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-input-element:disabled,
.bracelit-deep-green-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-input-element {
  caret-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-deep-green-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-deep-green-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-list-option:hover, .bracelit-deep-green-theme .mat-list-option:focus,
.bracelit-deep-green-theme .mat-nav-list .mat-list-item:hover,
.bracelit-deep-green-theme .mat-nav-list .mat-list-item:focus,
.bracelit-deep-green-theme .mat-action-list .mat-list-item:hover,
.bracelit-deep-green-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-green-theme .mat-list-single-selected-option, .bracelit-deep-green-theme .mat-list-single-selected-option:hover, .bracelit-deep-green-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-menu-panel {
  background: white;
}
.bracelit-deep-green-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-menu-item[disabled],
.bracelit-deep-green-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-deep-green-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-menu-item .mat-icon-no-color,
.bracelit-deep-green-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-menu-item:hover:not([disabled]),
.bracelit-deep-green-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-deep-green-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-deep-green-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-green-theme .mat-paginator {
  background: white;
}
.bracelit-deep-green-theme .mat-paginator,
.bracelit-deep-green-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-paginator-decrement,
.bracelit-deep-green-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-paginator-first,
.bracelit-deep-green-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-deep-green-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-deep-green-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-deep-green-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-progress-bar-background {
  fill: #bed7c7;
}
.bracelit-deep-green-theme .mat-progress-bar-buffer {
  background-color: #bed7c7;
}
.bracelit-deep-green-theme .mat-progress-bar-fill::after {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-deep-green-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-deep-green-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-deep-green-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-deep-green-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-progress-spinner circle, .bracelit-deep-green-theme .mat-spinner circle {
  stroke: #0a6c2d;
}
.bracelit-deep-green-theme .mat-progress-spinner.mat-accent circle, .bracelit-deep-green-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-deep-green-theme .mat-progress-spinner.mat-warn circle, .bracelit-deep-green-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-deep-green-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-deep-green-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-green-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-green-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-deep-green-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-deep-green-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-green-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-green-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-deep-green-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-deep-green-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-green-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-green-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-deep-green-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-deep-green-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-green-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-select-panel {
  background: white;
}
.bracelit-deep-green-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-deep-green-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-deep-green-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-green-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-green-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(10, 108, 45, 0.54);
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-deep-green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-green-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-deep-green-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-green-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-deep-green-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-deep-green-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-green-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(10, 108, 45, 0.2);
}
.bracelit-deep-green-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-deep-green-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-deep-green-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-green-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-deep-green-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-deep-green-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-green-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-green-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-deep-green-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-deep-green-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-deep-green-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-green-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-green-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-deep-green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-deep-green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-deep-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-deep-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-deep-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-green-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-deep-green-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-green-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-green-theme .mat-step-header.cdk-keyboard-focused, .bracelit-deep-green-theme .mat-step-header.cdk-program-focused, .bracelit-deep-green-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-deep-green-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-green-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-deep-green-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-deep-green-theme .mat-step-header .mat-step-label,
.bracelit-deep-green-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-deep-green-theme .mat-step-header .mat-step-icon-selected,
.bracelit-deep-green-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-deep-green-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #0a6c2d;
  color: white;
}
.bracelit-deep-green-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-deep-green-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-deep-green-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-deep-green-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-deep-green-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-deep-green-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-green-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-deep-green-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-deep-green-theme .mat-stepper-horizontal, .bracelit-deep-green-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-deep-green-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-horizontal-stepper-header::before,
.bracelit-deep-green-theme .mat-horizontal-stepper-header::after,
.bracelit-deep-green-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-deep-green-theme .mat-tab-nav-bar,
.bracelit-deep-green-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-deep-green-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-deep-green-theme .mat-tab-label, .bracelit-deep-green-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-tab-label.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-deep-green-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-deep-green-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(182, 211, 192, 0.3);
}
.bracelit-deep-green-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-green-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-green-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-green-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(182, 211, 192, 0.3);
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-green-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-toolbar.mat-primary {
  background: #0a6c2d;
  color: white;
}
.bracelit-deep-green-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-deep-green-theme .mat-toolbar .mat-form-field-underline,
.bracelit-deep-green-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-deep-green-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-deep-green-theme .mat-toolbar .mat-form-field-label,
.bracelit-deep-green-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-deep-green-theme .mat-toolbar .mat-select-value,
.bracelit-deep-green-theme .mat-toolbar .mat-select-arrow,
.bracelit-deep-green-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-deep-green-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-deep-green-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-deep-green-theme .mat-tree {
  background: white;
}
.bracelit-deep-green-theme .mat-tree-node,
.bracelit-deep-green-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-deep-green-theme .back-container {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-deep-green-theme .warn-text {
  color: #e74737;
}
.bracelit-deep-green-theme .inside-modal-title.primary-colored-title, .bracelit-deep-green-theme .form-title.primary-colored-title {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-deep-green-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-green-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-green-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .box-checkbox.active-box-checkbox {
  background-color: #0a6c2d;
  color: white;
}
.bracelit-deep-green-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .box-radio.active-box-radio {
  background-color: #0a6c2d;
  color: white;
}
.bracelit-deep-green-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .error {
  color: #e74737;
}
.bracelit-deep-green-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-deep-green-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-deep-green-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-deep-green-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-deep-green-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-deep-green-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-deep-green-theme .ql-snow .ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:hover,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:hover,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:focus,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:focus,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-deep-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #0a6c2d;
  height: 38px !important;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-deep-green-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-deep-green-theme .general-container .label-wrapper label {
  color: #0a6c2d !important;
  background-color: white;
}
.bracelit-deep-green-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-deep-green-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #0a6c2d !important;
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #0a6c2d;
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-green-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-deep-green-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #0a6c2d;
}
.bracelit-deep-green-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-green-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-deep-green-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #0a6c2d;
}
.bracelit-deep-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-green-theme .move-image-action-container:hover {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .info-icon {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .email-chip {
  color: #0a6c2d !important;
  background-color: rgba(10, 108, 45, 0.15) !important;
}
.bracelit-deep-green-theme .email-chip .mat-chip-remove {
  color: #0a6c2d !important;
  opacity: 1 !important;
}
.bracelit-deep-green-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-deep-green-theme .primary-color {
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .primary-color-200 {
  color: #85b696 !important;
}
.bracelit-deep-green-theme .primary-fill {
  fill: #0a6c2d !important;
}
.bracelit-deep-green-theme .secondary-fill {
  fill: #73ff89 !important;
}
.bracelit-deep-green-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-deep-green-theme .primary-border-50 {
  border-color: #e2ede6 !important;
}
.bracelit-deep-green-theme .primary-border-100 {
  border-color: #b6d3c0 !important;
}
.bracelit-deep-green-theme .primary-border-200 {
  border-color: #85b696 !important;
}
.bracelit-deep-green-theme .primary-indicator .mat-expansion-indicator:after {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-deep-green-theme .primary-color-small {
  color: rgba(10, 108, 45, 0.6);
}
.bracelit-deep-green-theme .primary-background {
  background-color: #0a6c2d !important;
}
.bracelit-deep-green-theme .primary-background-50 {
  background-color: #e2ede6 !important;
}
.bracelit-deep-green-theme .primary-background-200 {
  background-color: #85b696 !important;
}
.bracelit-deep-green-theme .primary-background-800 {
  background-color: #054f1c !important;
}
.bracelit-deep-green-theme .primary-background-800-2 {
  background-color: #054f1c !important;
  color: white !important;
}
.bracelit-deep-green-theme .primary-background-opacity-01 {
  background-color: rgba(10, 108, 45, 0.1) !important;
}
.bracelit-deep-green-theme .secondary-color {
  color: #73ff89 !important;
}
.bracelit-deep-green-theme .secondary-background {
  background-color: #73ff89 !important;
}
.bracelit-deep-green-theme .secondary-background-opacity-01 {
  background-color: rgba(115, 255, 137, 0.1) !important;
}
.bracelit-deep-green-theme .secondary-border {
  border-color: #73ff89 !important;
}
.bracelit-deep-green-theme .tertiary-color {
  color: #0dff32 !important;
}
.bracelit-deep-green-theme .tertiary-background {
  background-image: linear-gradient(45deg, #73ff89, #0dff32) !important;
}
.bracelit-deep-green-theme .tertiary-border {
  border-color: #0dff32 !important;
}
.bracelit-deep-green-theme .dark-background {
  background-color: black !important;
}
.bracelit-deep-green-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-deep-green-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-deep-green-theme .primary-soft-background {
  background-color: rgba(10, 108, 45, 0.1) !important;
}
.bracelit-deep-green-theme .primary-soft-background:hover {
  background-color: rgba(10, 108, 45, 0.15) !important;
}
.bracelit-deep-green-theme .primary-border {
  border-color: #0a6c2d !important;
}
.bracelit-deep-green-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-deep-green-theme .hover-primary:hover {
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .hover-primary-background:hover {
  background-color: #0a6c2d !important;
}
.bracelit-deep-green-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-deep-green-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-deep-green-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-deep-green-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-deep-green-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-deep-green-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-deep-green-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-deep-green-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-deep-green-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-green-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-deep-green-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-green-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #0a6c2d;
}
.bracelit-deep-green-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-green-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-deep-green-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-deep-green-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-green-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-deep-green-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-green-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .card-table tr:hover .action-icon button {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .card-table tr:hover .action-icon:hover {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-deep-green-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-green-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-deep-green-theme .custom-standard-chip {
  background-color: rgba(10, 108, 45, 0.1) !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .help-dashboard-card .mat-icon {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .help-dashboard-card:hover {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .help-dashboard-card:hover .mat-icon, .bracelit-deep-green-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-deep-green-theme .room-open {
  background-color: #0a6c2d !important;
  color: white !important;
}
.bracelit-deep-green-theme .room-open:hover {
  background-color: #0a6c2d !important;
  color: white !important;
}
.bracelit-deep-green-theme #public-office-page-groups .public-group-button-hover {
  background-color: #0a6c2d !important;
  color: white !important;
}
.bracelit-deep-green-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-deep-green-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-green-theme .notification-container:hover .show-tip {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-green-theme .notification-container .notification-subject .mat-icon {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-deep-green-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-green-theme .message-container:hover .show-tip {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-green-theme .message-container .message-subject .mat-icon {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-green-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-green-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-green-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-deep-green-theme .primary-200-outline input {
  caret-color: #85b696;
  color: #0a6c2d;
}
.bracelit-deep-green-theme .primary-200-outline label {
  color: #85b696;
}
.bracelit-deep-green-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #85b696 !important;
}
.bracelit-deep-green-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #85b696;
}
.bracelit-deep-green-theme mat-form-field .primary-outline label {
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #0a6c2d !important;
}
.bracelit-deep-green-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #85b696 !important;
}
.bracelit-deep-green-theme .input-primary input {
  caret-color: #0a6c2d;
  color: #0a6c2d;
}
.bracelit-deep-green-theme .input-primary label {
  color: #0a6c2d;
}
.bracelit-deep-green-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #0a6c2d !important;
}
.bracelit-deep-green-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-deep-green-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #0a6c2d;
  border-width: 1px;
}
.bracelit-deep-green-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-deep-green-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-deep-green-theme .header-primary-background .mobile-header-container {
  background-color: #0a6c2d;
}
.bracelit-deep-green-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #85b696 !important;
}
.bracelit-deep-green-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-green-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-green-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-green-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-green-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-green-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-green-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-green-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-green-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #85b696 !important;
}
.bracelit-deep-green-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #73ff89 !important;
}
.bracelit-deep-green-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-deep-green-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-deep-green-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #73ff89 !important;
}
.bracelit-deep-green-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #73ff89 !important;
}
.bracelit-deep-green-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #0dff32 !important;
}
.bracelit-deep-green-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #0dff32 !important;
}
.bracelit-deep-green-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-deep-green-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #0a6c2d !important;
}
.bracelit-deep-green-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #0a6c2d !important;
}

.bracelit-green-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-green-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-green-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-green-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-green-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.bracelit-green-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-green-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-green-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-green-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-green-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-green-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.bracelit-green-theme .mat-pseudo-checkbox-checked,
.bracelit-green-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-green-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-green-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-green-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-green-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-green-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-green-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-green-theme .mat-app-background, .bracelit-green-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-green-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-green-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-badge {
  position: relative;
}
.bracelit-green-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-green-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-green-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-green-theme .ng-animate-disabled .mat-badge-content,
.bracelit-green-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-green-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-green-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-green-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-green-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-green-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-green-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-green-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-green-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-green-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-green-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-green-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-green-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-green-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-green-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-green-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-green-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-green-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-green-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-green-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-green-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-green-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-green-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-green-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-green-theme .mat-badge-content {
  color: white;
  background: #43a047;
}
.cdk-high-contrast-active .bracelit-green-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-green-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-green-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-button, .bracelit-green-theme .mat-icon-button, .bracelit-green-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-green-theme .mat-button.mat-primary, .bracelit-green-theme .mat-icon-button.mat-primary, .bracelit-green-theme .mat-stroked-button.mat-primary {
  color: #43a047;
}
.bracelit-green-theme .mat-button.mat-accent, .bracelit-green-theme .mat-icon-button.mat-accent, .bracelit-green-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-green-theme .mat-button.mat-warn, .bracelit-green-theme .mat-icon-button.mat-warn, .bracelit-green-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-green-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-green-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-green-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-green-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-green-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-green-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #43a047;
}
.bracelit-green-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-green-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-green-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-green-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-green-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-green-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-green-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-green-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-green-theme .mat-button .mat-ripple-element, .bracelit-green-theme .mat-icon-button .mat-ripple-element, .bracelit-green-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-green-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-green-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-flat-button, .bracelit-green-theme .mat-raised-button, .bracelit-green-theme .mat-fab, .bracelit-green-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-green-theme .mat-flat-button.mat-primary, .bracelit-green-theme .mat-raised-button.mat-primary, .bracelit-green-theme .mat-fab.mat-primary, .bracelit-green-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-green-theme .mat-flat-button.mat-accent, .bracelit-green-theme .mat-raised-button.mat-accent, .bracelit-green-theme .mat-fab.mat-accent, .bracelit-green-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-flat-button.mat-warn, .bracelit-green-theme .mat-raised-button.mat-warn, .bracelit-green-theme .mat-fab.mat-warn, .bracelit-green-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-green-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-green-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-green-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-green-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-green-theme .mat-flat-button.mat-primary, .bracelit-green-theme .mat-raised-button.mat-primary, .bracelit-green-theme .mat-fab.mat-primary, .bracelit-green-theme .mat-mini-fab.mat-primary {
  background-color: #43a047;
}
.bracelit-green-theme .mat-flat-button.mat-accent, .bracelit-green-theme .mat-raised-button.mat-accent, .bracelit-green-theme .mat-fab.mat-accent, .bracelit-green-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-flat-button.mat-warn, .bracelit-green-theme .mat-raised-button.mat-warn, .bracelit-green-theme .mat-fab.mat-warn, .bracelit-green-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-green-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-green-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-green-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-green-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-green-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-green-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-green-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-green-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-green-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-green-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-green-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-green-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-green-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-green-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-green-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-green-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-green-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-green-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-green-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-green-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-green-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-green-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-green-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-green-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-green-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-green-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-green-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-green-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-green-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-green-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-green-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-green-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-green-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-green-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-green-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-green-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-green-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-green-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-green-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-green-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #43a047;
}
.bracelit-green-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-green-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-green-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-green-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-green-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-green-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-green-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #43a047;
}
.bracelit-green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-green-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-green-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-green-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-green-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-green-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #43a047;
  color: white;
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-green-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-green-theme .mat-table {
  background: white;
}
.bracelit-green-theme .mat-table thead, .bracelit-green-theme .mat-table tbody, .bracelit-green-theme .mat-table tfoot,
.bracelit-green-theme mat-header-row, .bracelit-green-theme mat-row, .bracelit-green-theme mat-footer-row,
.bracelit-green-theme [mat-header-row], .bracelit-green-theme [mat-row], .bracelit-green-theme [mat-footer-row],
.bracelit-green-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-green-theme mat-row, .bracelit-green-theme mat-header-row, .bracelit-green-theme mat-footer-row,
.bracelit-green-theme th.mat-header-cell, .bracelit-green-theme td.mat-cell, .bracelit-green-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-cell, .bracelit-green-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-datepicker-toggle,
.bracelit-green-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-green-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-calendar-table-header,
.bracelit-green-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-calendar-body-cell-content,
.bracelit-green-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-green-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-green-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-green-theme .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.bracelit-green-theme .mat-calendar-body-comparison-identical,
.bracelit-green-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-green-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-green-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-green-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-green-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-green-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-green-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-green-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-green-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-green-theme .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.bracelit-green-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.bracelit-green-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-green-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-green-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 160, 71, 0.3);
}
@media (hover: hover) {
  .bracelit-green-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(67, 160, 71, 0.3);
  }
}
.bracelit-green-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-green-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-green-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-green-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-green-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-green-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-green-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-green-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-green-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-green-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-green-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-datepicker-toggle-active {
  color: #43a047;
}
.bracelit-green-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-green-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-green-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-green-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-green-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-green-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-green-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-expansion-panel-header-description,
.bracelit-green-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-green-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-green-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-green-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-green-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-green-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #43a047;
}
.bracelit-green-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-green-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-green-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-green-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #43a047;
}
.bracelit-green-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #43a047;
}
.bracelit-green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-green-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-green-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-green-theme .mat-error {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-green-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-green-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-green-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-green-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #43a047;
}
.bracelit-green-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-green-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-green-theme .mat-icon.mat-primary {
  color: #43a047;
}
.bracelit-green-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-green-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-input-element:disabled,
.bracelit-green-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-input-element {
  caret-color: #43a047;
}
.bracelit-green-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-green-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-green-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-green-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-green-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-list-option:hover, .bracelit-green-theme .mat-list-option:focus,
.bracelit-green-theme .mat-nav-list .mat-list-item:hover,
.bracelit-green-theme .mat-nav-list .mat-list-item:focus,
.bracelit-green-theme .mat-action-list .mat-list-item:hover,
.bracelit-green-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-green-theme .mat-list-single-selected-option, .bracelit-green-theme .mat-list-single-selected-option:hover, .bracelit-green-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-menu-panel {
  background: white;
}
.bracelit-green-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-menu-item[disabled],
.bracelit-green-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-green-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-menu-item .mat-icon-no-color,
.bracelit-green-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-menu-item:hover:not([disabled]),
.bracelit-green-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-green-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-green-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-green-theme .mat-paginator {
  background: white;
}
.bracelit-green-theme .mat-paginator,
.bracelit-green-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-paginator-decrement,
.bracelit-green-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-paginator-first,
.bracelit-green-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-green-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-green-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-green-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-progress-bar-background {
  fill: #cce4cd;
}
.bracelit-green-theme .mat-progress-bar-buffer {
  background-color: #cce4cd;
}
.bracelit-green-theme .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.bracelit-green-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-green-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-green-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-green-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-green-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-green-theme .mat-progress-spinner circle, .bracelit-green-theme .mat-spinner circle {
  stroke: #43a047;
}
.bracelit-green-theme .mat-progress-spinner.mat-accent circle, .bracelit-green-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-green-theme .mat-progress-spinner.mat-warn circle, .bracelit-green-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-green-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.bracelit-green-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-green-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-green-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-green-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.bracelit-green-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-green-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-green-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-green-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-green-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-green-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-green-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-green-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-green-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-green-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-green-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-green-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-green-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-select-panel {
  background: white;
}
.bracelit-green-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #43a047;
}
.bracelit-green-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-green-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-green-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-green-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-green-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-green-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-green-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-green-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.bracelit-green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.bracelit-green-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.bracelit-green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-green-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-green-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-green-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-green-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-green-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-green-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-green-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #43a047;
}
.bracelit-green-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-green-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.bracelit-green-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-green-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-green-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-green-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-green-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-green-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-green-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-green-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-green-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-green-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-green-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-green-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-green-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-green-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-green-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-green-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-green-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-green-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-green-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-green-theme .mat-step-header.cdk-keyboard-focused, .bracelit-green-theme .mat-step-header.cdk-program-focused, .bracelit-green-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-green-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-green-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-green-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-green-theme .mat-step-header .mat-step-label,
.bracelit-green-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-green-theme .mat-step-header .mat-step-icon-selected,
.bracelit-green-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-green-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #43a047;
  color: white;
}
.bracelit-green-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-green-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-green-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-green-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-green-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-green-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-green-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-green-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-green-theme .mat-stepper-horizontal, .bracelit-green-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-green-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-horizontal-stepper-header::before,
.bracelit-green-theme .mat-horizontal-stepper-header::after,
.bracelit-green-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-green-theme .mat-tab-nav-bar,
.bracelit-green-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-green-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-green-theme .mat-tab-label, .bracelit-green-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-tab-label.mat-tab-disabled, .bracelit-green-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-green-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-green-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(199, 227, 200, 0.3);
}
.bracelit-green-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #43a047;
}
.bracelit-green-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-green-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-green-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-green-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-green-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-green-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-green-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-green-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-green-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-green-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(199, 227, 200, 0.3);
}
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #43a047;
}
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-green-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-green-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-green-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-green-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-green-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-green-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-green-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-green-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-toolbar.mat-primary {
  background: #43a047;
  color: white;
}
.bracelit-green-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-green-theme .mat-toolbar .mat-form-field-underline,
.bracelit-green-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-green-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-green-theme .mat-toolbar .mat-form-field-label,
.bracelit-green-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-green-theme .mat-toolbar .mat-select-value,
.bracelit-green-theme .mat-toolbar .mat-select-arrow,
.bracelit-green-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-green-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-green-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-green-theme .mat-tree {
  background: white;
}
.bracelit-green-theme .mat-tree-node,
.bracelit-green-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-green-theme .back-container {
  color: #43a047;
}
.bracelit-green-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-green-theme .warn-text {
  color: #e74737;
}
.bracelit-green-theme .inside-modal-title.primary-colored-title, .bracelit-green-theme .form-title.primary-colored-title {
  color: #43a047;
}
.bracelit-green-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-green-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-green-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-green-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .box-checkbox.active-box-checkbox {
  background-color: #43a047;
  color: white;
}
.bracelit-green-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .box-radio.active-box-radio {
  background-color: #43a047;
  color: white;
}
.bracelit-green-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .error {
  color: #e74737;
}
.bracelit-green-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-green-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-green-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-green-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-green-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-green-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-green-theme .ql-snow .ql-fill,
.bracelit-green-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .ql-snow.ql-toolbar button:hover,
.bracelit-green-theme .ql-snow .ql-toolbar button:hover,
.bracelit-green-theme .ql-snow.ql-toolbar button:focus,
.bracelit-green-theme .ql-snow .ql-toolbar button:focus,
.bracelit-green-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-green-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-green-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-green-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-green-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-green-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-green-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-green-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-green-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-green-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-green-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-green-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-green-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-green-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-green-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-green-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-green-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #43a047;
  height: 38px !important;
}
.bracelit-green-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-green-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-green-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-green-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-green-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-green-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-green-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-green-theme .general-container .label-wrapper label {
  color: #43a047 !important;
  background-color: white;
}
.bracelit-green-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-green-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #43a047 !important;
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #43a047;
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #43a047;
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #43a047;
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-green-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-green-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #43a047;
}
.bracelit-green-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-green-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-green-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #43a047;
}
.bracelit-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-green-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-green-theme .move-image-action-container:hover {
  color: #43a047;
}
.bracelit-green-theme .info-icon {
  color: #43a047;
}
.bracelit-green-theme .email-chip {
  color: #43a047 !important;
  background-color: rgba(67, 160, 71, 0.15) !important;
}
.bracelit-green-theme .email-chip .mat-chip-remove {
  color: #43a047 !important;
  opacity: 1 !important;
}
.bracelit-green-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-green-theme .primary-color {
  color: #43a047 !important;
}
.bracelit-green-theme .primary-color-200 {
  color: #a1d0a3 !important;
}
.bracelit-green-theme .primary-fill {
  fill: #43a047 !important;
}
.bracelit-green-theme .secondary-fill {
  fill: #b0ffb2 !important;
}
.bracelit-green-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-green-theme .primary-border-50 {
  border-color: #e8f4e9 !important;
}
.bracelit-green-theme .primary-border-100 {
  border-color: #c7e3c8 !important;
}
.bracelit-green-theme .primary-border-200 {
  border-color: #a1d0a3 !important;
}
.bracelit-green-theme .primary-indicator .mat-expansion-indicator:after {
  color: #43a047;
}
.bracelit-green-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-green-theme .primary-color-small {
  color: rgba(67, 160, 71, 0.6);
}
.bracelit-green-theme .primary-background {
  background-color: #43a047 !important;
}
.bracelit-green-theme .primary-background-50 {
  background-color: #e8f4e9 !important;
}
.bracelit-green-theme .primary-background-200 {
  background-color: #a1d0a3 !important;
}
.bracelit-green-theme .primary-background-800 {
  background-color: #2c842f !important;
}
.bracelit-green-theme .primary-background-800-2 {
  background-color: #2c842f !important;
  color: white !important;
}
.bracelit-green-theme .primary-background-opacity-01 {
  background-color: rgba(67, 160, 71, 0.1) !important;
}
.bracelit-green-theme .secondary-color {
  color: #b0ffb2 !important;
}
.bracelit-green-theme .secondary-background {
  background-color: #b0ffb2 !important;
}
.bracelit-green-theme .secondary-background-opacity-01 {
  background-color: rgba(176, 255, 178, 0.1) !important;
}
.bracelit-green-theme .secondary-border {
  border-color: #b0ffb2 !important;
}
.bracelit-green-theme .tertiary-color {
  color: #4aff4e !important;
}
.bracelit-green-theme .tertiary-background {
  background-image: linear-gradient(45deg, #b0ffb2, #4aff4e) !important;
}
.bracelit-green-theme .tertiary-border {
  border-color: #4aff4e !important;
}
.bracelit-green-theme .dark-background {
  background-color: black !important;
}
.bracelit-green-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-green-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-green-theme .primary-soft-background {
  background-color: rgba(67, 160, 71, 0.1) !important;
}
.bracelit-green-theme .primary-soft-background:hover {
  background-color: rgba(67, 160, 71, 0.15) !important;
}
.bracelit-green-theme .primary-border {
  border-color: #43a047 !important;
}
.bracelit-green-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-green-theme .hover-primary:hover {
  color: #43a047 !important;
}
.bracelit-green-theme .hover-primary-background:hover {
  background-color: #43a047 !important;
}
.bracelit-green-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-green-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-green-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-green-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-green-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-green-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-green-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-green-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-green-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-green-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-green-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-green-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #43a047;
}
.bracelit-green-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-green-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-green-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-green-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-green-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-green-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-green-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .card-table tr:hover .action-icon button {
  color: #43a047;
}
.bracelit-green-theme .card-table tr:hover .action-icon:hover {
  color: #43a047;
}
.bracelit-green-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-green-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-green-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #43a047;
}
.bracelit-green-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-green-theme .custom-standard-chip {
  background-color: rgba(67, 160, 71, 0.1) !important;
  color: #43a047 !important;
}
.bracelit-green-theme .help-dashboard-card .mat-icon {
  color: #43a047;
}
.bracelit-green-theme .help-dashboard-card:hover {
  background-color: #43a047;
}
.bracelit-green-theme .help-dashboard-card:hover .mat-icon, .bracelit-green-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-green-theme .room-open {
  background-color: #43a047 !important;
  color: white !important;
}
.bracelit-green-theme .room-open:hover {
  background-color: #43a047 !important;
  color: white !important;
}
.bracelit-green-theme #public-office-page-groups .public-group-button-hover {
  background-color: #43a047 !important;
  color: white !important;
}
.bracelit-green-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-green-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-green-theme .notification-container:hover .show-tip {
  color: #43a047;
}
.bracelit-green-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-green-theme .notification-container .notification-subject .mat-icon {
  color: #43a047;
}
.bracelit-green-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-green-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-green-theme .message-container:hover .show-tip {
  color: #43a047;
}
.bracelit-green-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-green-theme .message-container .message-subject .mat-icon {
  color: #43a047;
}
.bracelit-green-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-green-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-green-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-green-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-green-theme .primary-200-outline input {
  caret-color: #a1d0a3;
  color: #43a047;
}
.bracelit-green-theme .primary-200-outline label {
  color: #a1d0a3;
}
.bracelit-green-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #a1d0a3 !important;
}
.bracelit-green-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #a1d0a3;
}
.bracelit-green-theme mat-form-field .primary-outline label {
  color: #43a047 !important;
}
.bracelit-green-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #43a047 !important;
}
.bracelit-green-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #43a047 !important;
}
.bracelit-green-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #a1d0a3 !important;
}
.bracelit-green-theme .input-primary input {
  caret-color: #43a047;
  color: #43a047;
}
.bracelit-green-theme .input-primary label {
  color: #43a047;
}
.bracelit-green-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #43a047 !important;
}
.bracelit-green-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-green-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #43a047;
  border-width: 1px;
}
.bracelit-green-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-green-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-green-theme .header-primary-background .mobile-header-container {
  background-color: #43a047;
}
.bracelit-green-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #a1d0a3 !important;
}
.bracelit-green-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-green-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-green-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-green-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-green-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-green-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-green-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-green-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-green-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #a1d0a3 !important;
}
.bracelit-green-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #b0ffb2 !important;
}
.bracelit-green-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-green-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-green-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #b0ffb2 !important;
}
.bracelit-green-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #b0ffb2 !important;
}
.bracelit-green-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #4aff4e !important;
}
.bracelit-green-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #4aff4e !important;
}
.bracelit-green-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-green-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #43a047 !important;
}
.bracelit-green-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #43a047 !important;
}

.bracelit-turquoise-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-turquoise-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-turquoise-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-turquoise-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-turquoise-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00897b;
}
.bracelit-turquoise-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-turquoise-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-turquoise-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-turquoise-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00897b;
}
.bracelit-turquoise-theme .mat-pseudo-checkbox-checked,
.bracelit-turquoise-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-turquoise-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-turquoise-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-turquoise-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-turquoise-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-turquoise-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-turquoise-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-turquoise-theme .mat-app-background, .bracelit-turquoise-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-turquoise-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-turquoise-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-badge {
  position: relative;
}
.bracelit-turquoise-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-turquoise-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-turquoise-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-turquoise-theme .ng-animate-disabled .mat-badge-content,
.bracelit-turquoise-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-turquoise-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-turquoise-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-turquoise-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-turquoise-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-turquoise-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-turquoise-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-turquoise-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-turquoise-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-turquoise-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-turquoise-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-turquoise-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-turquoise-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-turquoise-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-turquoise-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-turquoise-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-turquoise-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-turquoise-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-turquoise-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-turquoise-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-turquoise-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-turquoise-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-turquoise-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-turquoise-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-turquoise-theme .mat-badge-content {
  color: white;
  background: #00897b;
}
.cdk-high-contrast-active .bracelit-turquoise-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-turquoise-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-turquoise-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-button, .bracelit-turquoise-theme .mat-icon-button, .bracelit-turquoise-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-turquoise-theme .mat-button.mat-primary, .bracelit-turquoise-theme .mat-icon-button.mat-primary, .bracelit-turquoise-theme .mat-stroked-button.mat-primary {
  color: #00897b;
}
.bracelit-turquoise-theme .mat-button.mat-accent, .bracelit-turquoise-theme .mat-icon-button.mat-accent, .bracelit-turquoise-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-button.mat-warn, .bracelit-turquoise-theme .mat-icon-button.mat-warn, .bracelit-turquoise-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-turquoise-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-turquoise-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-turquoise-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-turquoise-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-turquoise-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-turquoise-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-turquoise-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-turquoise-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-turquoise-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-turquoise-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-turquoise-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-turquoise-theme .mat-button .mat-ripple-element, .bracelit-turquoise-theme .mat-icon-button .mat-ripple-element, .bracelit-turquoise-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-turquoise-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-turquoise-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-flat-button, .bracelit-turquoise-theme .mat-raised-button, .bracelit-turquoise-theme .mat-fab, .bracelit-turquoise-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-turquoise-theme .mat-flat-button.mat-primary, .bracelit-turquoise-theme .mat-raised-button.mat-primary, .bracelit-turquoise-theme .mat-fab.mat-primary, .bracelit-turquoise-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-turquoise-theme .mat-flat-button.mat-accent, .bracelit-turquoise-theme .mat-raised-button.mat-accent, .bracelit-turquoise-theme .mat-fab.mat-accent, .bracelit-turquoise-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-flat-button.mat-warn, .bracelit-turquoise-theme .mat-raised-button.mat-warn, .bracelit-turquoise-theme .mat-fab.mat-warn, .bracelit-turquoise-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-turquoise-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-turquoise-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-turquoise-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-turquoise-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-turquoise-theme .mat-flat-button.mat-primary, .bracelit-turquoise-theme .mat-raised-button.mat-primary, .bracelit-turquoise-theme .mat-fab.mat-primary, .bracelit-turquoise-theme .mat-mini-fab.mat-primary {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-flat-button.mat-accent, .bracelit-turquoise-theme .mat-raised-button.mat-accent, .bracelit-turquoise-theme .mat-fab.mat-accent, .bracelit-turquoise-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-flat-button.mat-warn, .bracelit-turquoise-theme .mat-raised-button.mat-warn, .bracelit-turquoise-theme .mat-fab.mat-warn, .bracelit-turquoise-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-turquoise-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-turquoise-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-turquoise-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-turquoise-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-turquoise-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-turquoise-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-turquoise-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-turquoise-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-turquoise-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-turquoise-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-turquoise-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-turquoise-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-turquoise-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-turquoise-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-turquoise-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-turquoise-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-turquoise-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-turquoise-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-turquoise-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-turquoise-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-turquoise-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-turquoise-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-turquoise-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-turquoise-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-turquoise-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-turquoise-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-turquoise-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-turquoise-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-turquoise-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-turquoise-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-turquoise-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-turquoise-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-turquoise-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-turquoise-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-turquoise-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-turquoise-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-turquoise-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-turquoise-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-turquoise-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-turquoise-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-turquoise-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-turquoise-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-turquoise-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-turquoise-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-turquoise-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-turquoise-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #00897b;
}
.bracelit-turquoise-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-turquoise-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-turquoise-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-turquoise-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #00897b;
  color: white;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-turquoise-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-turquoise-theme .mat-table {
  background: white;
}
.bracelit-turquoise-theme .mat-table thead, .bracelit-turquoise-theme .mat-table tbody, .bracelit-turquoise-theme .mat-table tfoot,
.bracelit-turquoise-theme mat-header-row, .bracelit-turquoise-theme mat-row, .bracelit-turquoise-theme mat-footer-row,
.bracelit-turquoise-theme [mat-header-row], .bracelit-turquoise-theme [mat-row], .bracelit-turquoise-theme [mat-footer-row],
.bracelit-turquoise-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-turquoise-theme mat-row, .bracelit-turquoise-theme mat-header-row, .bracelit-turquoise-theme mat-footer-row,
.bracelit-turquoise-theme th.mat-header-cell, .bracelit-turquoise-theme td.mat-cell, .bracelit-turquoise-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-cell, .bracelit-turquoise-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-datepicker-toggle,
.bracelit-turquoise-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-turquoise-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-calendar-table-header,
.bracelit-turquoise-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-calendar-body-cell-content,
.bracelit-turquoise-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-turquoise-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-turquoise-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-turquoise-theme .mat-calendar-body-in-range::before {
  background: rgba(0, 137, 123, 0.2);
}
.bracelit-turquoise-theme .mat-calendar-body-comparison-identical,
.bracelit-turquoise-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-turquoise-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-turquoise-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 137, 123, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-turquoise-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-turquoise-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 137, 123, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-turquoise-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-turquoise-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-turquoise-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-turquoise-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-turquoise-theme .mat-calendar-body-selected {
  background-color: #00897b;
  color: white;
}
.bracelit-turquoise-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 137, 123, 0.4);
}
.bracelit-turquoise-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-turquoise-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-turquoise-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 137, 123, 0.3);
}
@media (hover: hover) {
  .bracelit-turquoise-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 137, 123, 0.3);
  }
}
.bracelit-turquoise-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-turquoise-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-turquoise-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-turquoise-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-turquoise-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-turquoise-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-datepicker-toggle-active {
  color: #00897b;
}
.bracelit-turquoise-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-turquoise-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-turquoise-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-turquoise-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-turquoise-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-expansion-panel-header-description,
.bracelit-turquoise-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-turquoise-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-turquoise-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-turquoise-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-turquoise-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-turquoise-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #00897b;
}
.bracelit-turquoise-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #00897b;
}
.bracelit-turquoise-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-turquoise-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-turquoise-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-error {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-turquoise-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-turquoise-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-turquoise-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-turquoise-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-turquoise-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #00897b;
}
.bracelit-turquoise-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-turquoise-theme .mat-icon.mat-primary {
  color: #00897b;
}
.bracelit-turquoise-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-input-element:disabled,
.bracelit-turquoise-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-input-element {
  caret-color: #00897b;
}
.bracelit-turquoise-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-turquoise-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-turquoise-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-list-option:hover, .bracelit-turquoise-theme .mat-list-option:focus,
.bracelit-turquoise-theme .mat-nav-list .mat-list-item:hover,
.bracelit-turquoise-theme .mat-nav-list .mat-list-item:focus,
.bracelit-turquoise-theme .mat-action-list .mat-list-item:hover,
.bracelit-turquoise-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-turquoise-theme .mat-list-single-selected-option, .bracelit-turquoise-theme .mat-list-single-selected-option:hover, .bracelit-turquoise-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-menu-panel {
  background: white;
}
.bracelit-turquoise-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-menu-item[disabled],
.bracelit-turquoise-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-turquoise-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-menu-item .mat-icon-no-color,
.bracelit-turquoise-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-menu-item:hover:not([disabled]),
.bracelit-turquoise-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-turquoise-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-turquoise-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-turquoise-theme .mat-paginator {
  background: white;
}
.bracelit-turquoise-theme .mat-paginator,
.bracelit-turquoise-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-paginator-decrement,
.bracelit-turquoise-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-paginator-first,
.bracelit-turquoise-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-turquoise-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-turquoise-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-turquoise-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-progress-bar-background {
  fill: #bcdeda;
}
.bracelit-turquoise-theme .mat-progress-bar-buffer {
  background-color: #bcdeda;
}
.bracelit-turquoise-theme .mat-progress-bar-fill::after {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-turquoise-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-turquoise-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-turquoise-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-turquoise-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-progress-spinner circle, .bracelit-turquoise-theme .mat-spinner circle {
  stroke: #00897b;
}
.bracelit-turquoise-theme .mat-progress-spinner.mat-accent circle, .bracelit-turquoise-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-turquoise-theme .mat-progress-spinner.mat-warn circle, .bracelit-turquoise-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-turquoise-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00897b;
}
.bracelit-turquoise-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-turquoise-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-turquoise-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-turquoise-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-turquoise-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-turquoise-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-turquoise-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-turquoise-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-turquoise-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-turquoise-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-turquoise-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-turquoise-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-turquoise-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-turquoise-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-turquoise-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-select-panel {
  background: white;
}
.bracelit-turquoise-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00897b;
}
.bracelit-turquoise-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-turquoise-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-turquoise-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-turquoise-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-turquoise-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 137, 123, 0.54);
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-turquoise-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-turquoise-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-turquoise-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-turquoise-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-turquoise-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-turquoise-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-turquoise-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 137, 123, 0.2);
}
.bracelit-turquoise-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-turquoise-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-turquoise-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-turquoise-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-turquoise-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-turquoise-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-turquoise-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-turquoise-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-turquoise-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-turquoise-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-turquoise-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-turquoise-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-turquoise-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-turquoise-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-turquoise-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-turquoise-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-turquoise-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-turquoise-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-turquoise-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-turquoise-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-turquoise-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-turquoise-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-turquoise-theme .mat-step-header.cdk-keyboard-focused, .bracelit-turquoise-theme .mat-step-header.cdk-program-focused, .bracelit-turquoise-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-turquoise-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-turquoise-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-turquoise-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-turquoise-theme .mat-step-header .mat-step-label,
.bracelit-turquoise-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-turquoise-theme .mat-step-header .mat-step-icon-selected,
.bracelit-turquoise-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-turquoise-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #00897b;
  color: white;
}
.bracelit-turquoise-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-turquoise-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-turquoise-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-turquoise-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-turquoise-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-turquoise-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-turquoise-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-turquoise-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-turquoise-theme .mat-stepper-horizontal, .bracelit-turquoise-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-turquoise-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-horizontal-stepper-header::before,
.bracelit-turquoise-theme .mat-horizontal-stepper-header::after,
.bracelit-turquoise-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-turquoise-theme .mat-tab-nav-bar,
.bracelit-turquoise-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-turquoise-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-turquoise-theme .mat-tab-label, .bracelit-turquoise-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-tab-label.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-turquoise-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-turquoise-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 220, 215, 0.3);
}
.bracelit-turquoise-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-turquoise-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-turquoise-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-turquoise-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 220, 215, 0.3);
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #00897b;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-turquoise-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-turquoise-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-toolbar.mat-primary {
  background: #00897b;
  color: white;
}
.bracelit-turquoise-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-turquoise-theme .mat-toolbar .mat-form-field-underline,
.bracelit-turquoise-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-turquoise-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-turquoise-theme .mat-toolbar .mat-form-field-label,
.bracelit-turquoise-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-turquoise-theme .mat-toolbar .mat-select-value,
.bracelit-turquoise-theme .mat-toolbar .mat-select-arrow,
.bracelit-turquoise-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-turquoise-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-turquoise-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-turquoise-theme .mat-tree {
  background: white;
}
.bracelit-turquoise-theme .mat-tree-node,
.bracelit-turquoise-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-turquoise-theme .back-container {
  color: #00897b;
}
.bracelit-turquoise-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-turquoise-theme .warn-text {
  color: #e74737;
}
.bracelit-turquoise-theme .inside-modal-title.primary-colored-title, .bracelit-turquoise-theme .form-title.primary-colored-title {
  color: #00897b;
}
.bracelit-turquoise-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-turquoise-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-turquoise-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-turquoise-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .box-checkbox.active-box-checkbox {
  background-color: #00897b;
  color: white;
}
.bracelit-turquoise-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .box-radio.active-box-radio {
  background-color: #00897b;
  color: white;
}
.bracelit-turquoise-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .error {
  color: #e74737;
}
.bracelit-turquoise-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-turquoise-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-turquoise-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-turquoise-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-turquoise-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-turquoise-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-turquoise-theme .ql-snow .ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:hover,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:hover,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:focus,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:focus,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-turquoise-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #00897b;
  height: 38px !important;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-turquoise-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-turquoise-theme .general-container .label-wrapper label {
  color: #00897b !important;
  background-color: white;
}
.bracelit-turquoise-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-turquoise-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #00897b !important;
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #00897b;
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #00897b;
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #00897b;
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-turquoise-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-turquoise-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #00897b;
}
.bracelit-turquoise-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-turquoise-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-turquoise-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-turquoise-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-turquoise-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #00897b;
}
.bracelit-turquoise-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-turquoise-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-turquoise-theme .move-image-action-container:hover {
  color: #00897b;
}
.bracelit-turquoise-theme .info-icon {
  color: #00897b;
}
.bracelit-turquoise-theme .email-chip {
  color: #00897b !important;
  background-color: rgba(0, 137, 123, 0.15) !important;
}
.bracelit-turquoise-theme .email-chip .mat-chip-remove {
  color: #00897b !important;
  opacity: 1 !important;
}
.bracelit-turquoise-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-turquoise-theme .primary-color {
  color: #00897b !important;
}
.bracelit-turquoise-theme .primary-color-200 {
  color: #80c4bd !important;
}
.bracelit-turquoise-theme .primary-fill {
  fill: #00897b !important;
}
.bracelit-turquoise-theme .secondary-fill {
  fill: #8bffe9 !important;
}
.bracelit-turquoise-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-turquoise-theme .primary-border-50 {
  border-color: #e0f1ef !important;
}
.bracelit-turquoise-theme .primary-border-100 {
  border-color: #b3dcd7 !important;
}
.bracelit-turquoise-theme .primary-border-200 {
  border-color: #80c4bd !important;
}
.bracelit-turquoise-theme .primary-indicator .mat-expansion-indicator:after {
  color: #00897b;
}
.bracelit-turquoise-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-turquoise-theme .primary-color-small {
  color: rgba(0, 137, 123, 0.6);
}
.bracelit-turquoise-theme .primary-background {
  background-color: #00897b !important;
}
.bracelit-turquoise-theme .primary-background-50 {
  background-color: #e0f1ef !important;
}
.bracelit-turquoise-theme .primary-background-200 {
  background-color: #80c4bd !important;
}
.bracelit-turquoise-theme .primary-background-800 {
  background-color: #006c5e !important;
}
.bracelit-turquoise-theme .primary-background-800-2 {
  background-color: #006c5e !important;
  color: white !important;
}
.bracelit-turquoise-theme .primary-background-opacity-01 {
  background-color: rgba(0, 137, 123, 0.1) !important;
}
.bracelit-turquoise-theme .secondary-color {
  color: #8bffe9 !important;
}
.bracelit-turquoise-theme .secondary-background {
  background-color: #8bffe9 !important;
}
.bracelit-turquoise-theme .secondary-background-opacity-01 {
  background-color: rgba(139, 255, 233, 0.1) !important;
}
.bracelit-turquoise-theme .secondary-border {
  border-color: #8bffe9 !important;
}
.bracelit-turquoise-theme .tertiary-color {
  color: #25ffd5 !important;
}
.bracelit-turquoise-theme .tertiary-background {
  background-image: linear-gradient(45deg, #8bffe9, #25ffd5) !important;
}
.bracelit-turquoise-theme .tertiary-border {
  border-color: #25ffd5 !important;
}
.bracelit-turquoise-theme .dark-background {
  background-color: black !important;
}
.bracelit-turquoise-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-turquoise-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-turquoise-theme .primary-soft-background {
  background-color: rgba(0, 137, 123, 0.1) !important;
}
.bracelit-turquoise-theme .primary-soft-background:hover {
  background-color: rgba(0, 137, 123, 0.15) !important;
}
.bracelit-turquoise-theme .primary-border {
  border-color: #00897b !important;
}
.bracelit-turquoise-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-turquoise-theme .hover-primary:hover {
  color: #00897b !important;
}
.bracelit-turquoise-theme .hover-primary-background:hover {
  background-color: #00897b !important;
}
.bracelit-turquoise-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-turquoise-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-turquoise-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-turquoise-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-turquoise-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-turquoise-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-turquoise-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-turquoise-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-turquoise-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-turquoise-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-turquoise-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-turquoise-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #00897b;
}
.bracelit-turquoise-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-turquoise-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-turquoise-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-turquoise-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-turquoise-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-turquoise-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-turquoise-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .card-table tr:hover .action-icon button {
  color: #00897b;
}
.bracelit-turquoise-theme .card-table tr:hover .action-icon:hover {
  color: #00897b;
}
.bracelit-turquoise-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-turquoise-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-turquoise-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #00897b;
}
.bracelit-turquoise-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-turquoise-theme .custom-standard-chip {
  background-color: rgba(0, 137, 123, 0.1) !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .help-dashboard-card .mat-icon {
  color: #00897b;
}
.bracelit-turquoise-theme .help-dashboard-card:hover {
  background-color: #00897b;
}
.bracelit-turquoise-theme .help-dashboard-card:hover .mat-icon, .bracelit-turquoise-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-turquoise-theme .room-open {
  background-color: #00897b !important;
  color: white !important;
}
.bracelit-turquoise-theme .room-open:hover {
  background-color: #00897b !important;
  color: white !important;
}
.bracelit-turquoise-theme #public-office-page-groups .public-group-button-hover {
  background-color: #00897b !important;
  color: white !important;
}
.bracelit-turquoise-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-turquoise-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-turquoise-theme .notification-container:hover .show-tip {
  color: #00897b;
}
.bracelit-turquoise-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-turquoise-theme .notification-container .notification-subject .mat-icon {
  color: #00897b;
}
.bracelit-turquoise-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-turquoise-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-turquoise-theme .message-container:hover .show-tip {
  color: #00897b;
}
.bracelit-turquoise-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-turquoise-theme .message-container .message-subject .mat-icon {
  color: #00897b;
}
.bracelit-turquoise-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-turquoise-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-turquoise-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-turquoise-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-turquoise-theme .primary-200-outline input {
  caret-color: #80c4bd;
  color: #00897b;
}
.bracelit-turquoise-theme .primary-200-outline label {
  color: #80c4bd;
}
.bracelit-turquoise-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #80c4bd !important;
}
.bracelit-turquoise-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #80c4bd;
}
.bracelit-turquoise-theme mat-form-field .primary-outline label {
  color: #00897b !important;
}
.bracelit-turquoise-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #00897b !important;
}
.bracelit-turquoise-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #00897b !important;
}
.bracelit-turquoise-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #80c4bd !important;
}
.bracelit-turquoise-theme .input-primary input {
  caret-color: #00897b;
  color: #00897b;
}
.bracelit-turquoise-theme .input-primary label {
  color: #00897b;
}
.bracelit-turquoise-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #00897b !important;
}
.bracelit-turquoise-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-turquoise-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #00897b;
  border-width: 1px;
}
.bracelit-turquoise-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-turquoise-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-turquoise-theme .header-primary-background .mobile-header-container {
  background-color: #00897b;
}
.bracelit-turquoise-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #80c4bd !important;
}
.bracelit-turquoise-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-turquoise-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-turquoise-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-turquoise-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-turquoise-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-turquoise-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-turquoise-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-turquoise-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-turquoise-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #80c4bd !important;
}
.bracelit-turquoise-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #8bffe9 !important;
}
.bracelit-turquoise-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-turquoise-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-turquoise-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #8bffe9 !important;
}
.bracelit-turquoise-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #8bffe9 !important;
}
.bracelit-turquoise-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #25ffd5 !important;
}
.bracelit-turquoise-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #25ffd5 !important;
}
.bracelit-turquoise-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-turquoise-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #00897b !important;
}
.bracelit-turquoise-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #00897b !important;
}

.bracelit-cyan-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-cyan-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-cyan-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-cyan-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-cyan-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #01acc1;
}
.bracelit-cyan-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-cyan-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-cyan-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-cyan-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-cyan-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #01acc1;
}
.bracelit-cyan-theme .mat-pseudo-checkbox-checked,
.bracelit-cyan-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-cyan-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-cyan-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-cyan-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-cyan-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-cyan-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-cyan-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-cyan-theme .mat-app-background, .bracelit-cyan-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-cyan-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-cyan-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-badge {
  position: relative;
}
.bracelit-cyan-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-cyan-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-cyan-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-cyan-theme .ng-animate-disabled .mat-badge-content,
.bracelit-cyan-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-cyan-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-cyan-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-cyan-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-cyan-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-cyan-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-cyan-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-cyan-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-cyan-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-cyan-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-cyan-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-cyan-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-cyan-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-cyan-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-cyan-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-cyan-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-cyan-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-cyan-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-cyan-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-cyan-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-cyan-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-cyan-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-cyan-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-cyan-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-cyan-theme .mat-badge-content {
  color: white;
  background: #01acc1;
}
.cdk-high-contrast-active .bracelit-cyan-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-cyan-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-cyan-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-button, .bracelit-cyan-theme .mat-icon-button, .bracelit-cyan-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-cyan-theme .mat-button.mat-primary, .bracelit-cyan-theme .mat-icon-button.mat-primary, .bracelit-cyan-theme .mat-stroked-button.mat-primary {
  color: #01acc1;
}
.bracelit-cyan-theme .mat-button.mat-accent, .bracelit-cyan-theme .mat-icon-button.mat-accent, .bracelit-cyan-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-button.mat-warn, .bracelit-cyan-theme .mat-icon-button.mat-warn, .bracelit-cyan-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-cyan-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-cyan-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-cyan-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-cyan-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-cyan-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-cyan-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-cyan-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-cyan-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-cyan-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-cyan-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-cyan-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-cyan-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-cyan-theme .mat-button .mat-ripple-element, .bracelit-cyan-theme .mat-icon-button .mat-ripple-element, .bracelit-cyan-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-cyan-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-cyan-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-flat-button, .bracelit-cyan-theme .mat-raised-button, .bracelit-cyan-theme .mat-fab, .bracelit-cyan-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-cyan-theme .mat-flat-button.mat-primary, .bracelit-cyan-theme .mat-raised-button.mat-primary, .bracelit-cyan-theme .mat-fab.mat-primary, .bracelit-cyan-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-cyan-theme .mat-flat-button.mat-accent, .bracelit-cyan-theme .mat-raised-button.mat-accent, .bracelit-cyan-theme .mat-fab.mat-accent, .bracelit-cyan-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-flat-button.mat-warn, .bracelit-cyan-theme .mat-raised-button.mat-warn, .bracelit-cyan-theme .mat-fab.mat-warn, .bracelit-cyan-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-cyan-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-cyan-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-cyan-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-cyan-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-cyan-theme .mat-flat-button.mat-primary, .bracelit-cyan-theme .mat-raised-button.mat-primary, .bracelit-cyan-theme .mat-fab.mat-primary, .bracelit-cyan-theme .mat-mini-fab.mat-primary {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-flat-button.mat-accent, .bracelit-cyan-theme .mat-raised-button.mat-accent, .bracelit-cyan-theme .mat-fab.mat-accent, .bracelit-cyan-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-flat-button.mat-warn, .bracelit-cyan-theme .mat-raised-button.mat-warn, .bracelit-cyan-theme .mat-fab.mat-warn, .bracelit-cyan-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-cyan-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-cyan-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-cyan-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-cyan-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-cyan-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-cyan-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-cyan-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-cyan-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-cyan-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-cyan-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-cyan-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-cyan-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-cyan-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-cyan-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-cyan-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-cyan-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-cyan-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-cyan-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-cyan-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-cyan-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-cyan-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-cyan-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-cyan-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-cyan-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-cyan-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-cyan-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-cyan-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-cyan-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-cyan-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-cyan-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-cyan-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-cyan-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-cyan-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-cyan-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-cyan-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-cyan-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-cyan-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-cyan-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-cyan-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-cyan-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-cyan-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-cyan-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-cyan-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-cyan-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-cyan-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-cyan-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #01acc1;
}
.bracelit-cyan-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-cyan-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-cyan-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-cyan-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #01acc1;
  color: white;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-cyan-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-cyan-theme .mat-table {
  background: white;
}
.bracelit-cyan-theme .mat-table thead, .bracelit-cyan-theme .mat-table tbody, .bracelit-cyan-theme .mat-table tfoot,
.bracelit-cyan-theme mat-header-row, .bracelit-cyan-theme mat-row, .bracelit-cyan-theme mat-footer-row,
.bracelit-cyan-theme [mat-header-row], .bracelit-cyan-theme [mat-row], .bracelit-cyan-theme [mat-footer-row],
.bracelit-cyan-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-cyan-theme mat-row, .bracelit-cyan-theme mat-header-row, .bracelit-cyan-theme mat-footer-row,
.bracelit-cyan-theme th.mat-header-cell, .bracelit-cyan-theme td.mat-cell, .bracelit-cyan-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-cell, .bracelit-cyan-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-datepicker-toggle,
.bracelit-cyan-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-cyan-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-calendar-table-header,
.bracelit-cyan-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-calendar-body-cell-content,
.bracelit-cyan-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-cyan-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-cyan-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-cyan-theme .mat-calendar-body-in-range::before {
  background: rgba(1, 172, 193, 0.2);
}
.bracelit-cyan-theme .mat-calendar-body-comparison-identical,
.bracelit-cyan-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-cyan-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-cyan-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(1, 172, 193, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-cyan-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-cyan-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(1, 172, 193, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-cyan-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-cyan-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-cyan-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-cyan-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-cyan-theme .mat-calendar-body-selected {
  background-color: #01acc1;
  color: white;
}
.bracelit-cyan-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(1, 172, 193, 0.4);
}
.bracelit-cyan-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-cyan-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-cyan-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(1, 172, 193, 0.3);
}
@media (hover: hover) {
  .bracelit-cyan-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(1, 172, 193, 0.3);
  }
}
.bracelit-cyan-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-cyan-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-cyan-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-cyan-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-cyan-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-cyan-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-cyan-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-cyan-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-cyan-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-cyan-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-datepicker-toggle-active {
  color: #01acc1;
}
.bracelit-cyan-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-cyan-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-cyan-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-cyan-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-cyan-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-cyan-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-expansion-panel-header-description,
.bracelit-cyan-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-cyan-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-cyan-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-cyan-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-cyan-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-cyan-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #01acc1;
}
.bracelit-cyan-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-cyan-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #01acc1;
}
.bracelit-cyan-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-cyan-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-cyan-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-error {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-cyan-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-cyan-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-cyan-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-cyan-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-cyan-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #01acc1;
}
.bracelit-cyan-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-cyan-theme .mat-icon.mat-primary {
  color: #01acc1;
}
.bracelit-cyan-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-input-element:disabled,
.bracelit-cyan-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-input-element {
  caret-color: #01acc1;
}
.bracelit-cyan-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-cyan-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-cyan-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-cyan-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-cyan-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-list-option:hover, .bracelit-cyan-theme .mat-list-option:focus,
.bracelit-cyan-theme .mat-nav-list .mat-list-item:hover,
.bracelit-cyan-theme .mat-nav-list .mat-list-item:focus,
.bracelit-cyan-theme .mat-action-list .mat-list-item:hover,
.bracelit-cyan-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-cyan-theme .mat-list-single-selected-option, .bracelit-cyan-theme .mat-list-single-selected-option:hover, .bracelit-cyan-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-menu-panel {
  background: white;
}
.bracelit-cyan-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-menu-item[disabled],
.bracelit-cyan-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-cyan-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-menu-item .mat-icon-no-color,
.bracelit-cyan-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-menu-item:hover:not([disabled]),
.bracelit-cyan-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-cyan-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-cyan-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-cyan-theme .mat-paginator {
  background: white;
}
.bracelit-cyan-theme .mat-paginator,
.bracelit-cyan-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-paginator-decrement,
.bracelit-cyan-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-paginator-first,
.bracelit-cyan-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-cyan-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-cyan-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-cyan-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-progress-bar-background {
  fill: #bce7ec;
}
.bracelit-cyan-theme .mat-progress-bar-buffer {
  background-color: #bce7ec;
}
.bracelit-cyan-theme .mat-progress-bar-fill::after {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-cyan-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-cyan-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-cyan-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-cyan-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-progress-spinner circle, .bracelit-cyan-theme .mat-spinner circle {
  stroke: #01acc1;
}
.bracelit-cyan-theme .mat-progress-spinner.mat-accent circle, .bracelit-cyan-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-cyan-theme .mat-progress-spinner.mat-warn circle, .bracelit-cyan-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-cyan-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #01acc1;
}
.bracelit-cyan-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-cyan-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-cyan-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-cyan-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-cyan-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-cyan-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-cyan-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-cyan-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-cyan-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-cyan-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-cyan-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-cyan-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-cyan-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-cyan-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-cyan-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-select-panel {
  background: white;
}
.bracelit-cyan-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #01acc1;
}
.bracelit-cyan-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-cyan-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-cyan-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-cyan-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-cyan-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-cyan-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-cyan-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-cyan-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(1, 172, 193, 0.54);
}
.bracelit-cyan-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-cyan-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-cyan-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-cyan-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-cyan-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-cyan-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-cyan-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-cyan-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(1, 172, 193, 0.2);
}
.bracelit-cyan-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-cyan-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-cyan-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-cyan-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-cyan-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-cyan-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-cyan-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-cyan-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-cyan-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-cyan-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-cyan-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-cyan-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-cyan-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-cyan-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-cyan-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-cyan-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-cyan-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-cyan-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-cyan-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-cyan-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-cyan-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-cyan-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-cyan-theme .mat-step-header.cdk-keyboard-focused, .bracelit-cyan-theme .mat-step-header.cdk-program-focused, .bracelit-cyan-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-cyan-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-cyan-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-cyan-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-cyan-theme .mat-step-header .mat-step-label,
.bracelit-cyan-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-cyan-theme .mat-step-header .mat-step-icon-selected,
.bracelit-cyan-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-cyan-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #01acc1;
  color: white;
}
.bracelit-cyan-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-cyan-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-cyan-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-cyan-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-cyan-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-cyan-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-cyan-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-cyan-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-cyan-theme .mat-stepper-horizontal, .bracelit-cyan-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-cyan-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-horizontal-stepper-header::before,
.bracelit-cyan-theme .mat-horizontal-stepper-header::after,
.bracelit-cyan-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-cyan-theme .mat-tab-nav-bar,
.bracelit-cyan-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-cyan-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-cyan-theme .mat-tab-label, .bracelit-cyan-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-tab-label.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-cyan-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-cyan-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 230, 236, 0.3);
}
.bracelit-cyan-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-cyan-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-cyan-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-cyan-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-cyan-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-cyan-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-cyan-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-cyan-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 230, 236, 0.3);
}
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #01acc1;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-cyan-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-cyan-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-toolbar.mat-primary {
  background: #01acc1;
  color: white;
}
.bracelit-cyan-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-cyan-theme .mat-toolbar .mat-form-field-underline,
.bracelit-cyan-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-cyan-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-cyan-theme .mat-toolbar .mat-form-field-label,
.bracelit-cyan-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-cyan-theme .mat-toolbar .mat-select-value,
.bracelit-cyan-theme .mat-toolbar .mat-select-arrow,
.bracelit-cyan-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-cyan-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-cyan-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-cyan-theme .mat-tree {
  background: white;
}
.bracelit-cyan-theme .mat-tree-node,
.bracelit-cyan-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-cyan-theme .back-container {
  color: #01acc1;
}
.bracelit-cyan-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-cyan-theme .warn-text {
  color: #e74737;
}
.bracelit-cyan-theme .inside-modal-title.primary-colored-title, .bracelit-cyan-theme .form-title.primary-colored-title {
  color: #01acc1;
}
.bracelit-cyan-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-cyan-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-cyan-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-cyan-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .box-checkbox.active-box-checkbox {
  background-color: #01acc1;
  color: white;
}
.bracelit-cyan-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .box-radio.active-box-radio {
  background-color: #01acc1;
  color: white;
}
.bracelit-cyan-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .error {
  color: #e74737;
}
.bracelit-cyan-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-cyan-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-cyan-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-cyan-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-cyan-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-cyan-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-cyan-theme .ql-snow .ql-fill,
.bracelit-cyan-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .ql-snow.ql-toolbar button:hover,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:hover,
.bracelit-cyan-theme .ql-snow.ql-toolbar button:focus,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:focus,
.bracelit-cyan-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-cyan-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-cyan-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-cyan-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-cyan-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-cyan-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-cyan-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #01acc1;
  height: 38px !important;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-cyan-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-cyan-theme .general-container .label-wrapper label {
  color: #01acc1 !important;
  background-color: white;
}
.bracelit-cyan-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-cyan-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #01acc1 !important;
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #01acc1;
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #01acc1;
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #01acc1;
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-cyan-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-cyan-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #01acc1;
}
.bracelit-cyan-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-cyan-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-cyan-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-cyan-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-cyan-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #01acc1;
}
.bracelit-cyan-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-cyan-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-cyan-theme .move-image-action-container:hover {
  color: #01acc1;
}
.bracelit-cyan-theme .info-icon {
  color: #01acc1;
}
.bracelit-cyan-theme .email-chip {
  color: #01acc1 !important;
  background-color: rgba(1, 172, 193, 0.15) !important;
}
.bracelit-cyan-theme .email-chip .mat-chip-remove {
  color: #01acc1 !important;
  opacity: 1 !important;
}
.bracelit-cyan-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-cyan-theme .primary-color {
  color: #01acc1 !important;
}
.bracelit-cyan-theme .primary-color-200 {
  color: #80d6e0 !important;
}
.bracelit-cyan-theme .primary-fill {
  fill: #01acc1 !important;
}
.bracelit-cyan-theme .secondary-fill {
  fill: #c9f4ff !important;
}
.bracelit-cyan-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-cyan-theme .primary-border-50 {
  border-color: #e1f5f8 !important;
}
.bracelit-cyan-theme .primary-border-100 {
  border-color: #b3e6ec !important;
}
.bracelit-cyan-theme .primary-border-200 {
  border-color: #80d6e0 !important;
}
.bracelit-cyan-theme .primary-indicator .mat-expansion-indicator:after {
  color: #01acc1;
}
.bracelit-cyan-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-cyan-theme .primary-color-small {
  color: rgba(1, 172, 193, 0.6);
}
.bracelit-cyan-theme .primary-background {
  background-color: #01acc1 !important;
}
.bracelit-cyan-theme .primary-background-50 {
  background-color: #e1f5f8 !important;
}
.bracelit-cyan-theme .primary-background-200 {
  background-color: #80d6e0 !important;
}
.bracelit-cyan-theme .primary-background-800 {
  background-color: #0192ab !important;
}
.bracelit-cyan-theme .primary-background-800-2 {
  background-color: #0192ab !important;
  color: white !important;
}
.bracelit-cyan-theme .primary-background-opacity-01 {
  background-color: rgba(1, 172, 193, 0.1) !important;
}
.bracelit-cyan-theme .secondary-color {
  color: #c9f4ff !important;
}
.bracelit-cyan-theme .secondary-background {
  background-color: #c9f4ff !important;
}
.bracelit-cyan-theme .secondary-background-opacity-01 {
  background-color: rgba(201, 244, 255, 0.1) !important;
}
.bracelit-cyan-theme .secondary-border {
  border-color: #c9f4ff !important;
}
.bracelit-cyan-theme .tertiary-color {
  color: #63dfff !important;
}
.bracelit-cyan-theme .tertiary-background {
  background-image: linear-gradient(45deg, #c9f4ff, #63dfff) !important;
}
.bracelit-cyan-theme .tertiary-border {
  border-color: #63dfff !important;
}
.bracelit-cyan-theme .dark-background {
  background-color: black !important;
}
.bracelit-cyan-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-cyan-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-cyan-theme .primary-soft-background {
  background-color: rgba(1, 172, 193, 0.1) !important;
}
.bracelit-cyan-theme .primary-soft-background:hover {
  background-color: rgba(1, 172, 193, 0.15) !important;
}
.bracelit-cyan-theme .primary-border {
  border-color: #01acc1 !important;
}
.bracelit-cyan-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-cyan-theme .hover-primary:hover {
  color: #01acc1 !important;
}
.bracelit-cyan-theme .hover-primary-background:hover {
  background-color: #01acc1 !important;
}
.bracelit-cyan-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-cyan-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-cyan-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-cyan-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-cyan-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-cyan-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-cyan-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-cyan-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-cyan-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-cyan-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-cyan-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-cyan-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #01acc1;
}
.bracelit-cyan-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-cyan-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-cyan-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-cyan-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-cyan-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-cyan-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-cyan-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .card-table tr:hover .action-icon button {
  color: #01acc1;
}
.bracelit-cyan-theme .card-table tr:hover .action-icon:hover {
  color: #01acc1;
}
.bracelit-cyan-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-cyan-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-cyan-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #01acc1;
}
.bracelit-cyan-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-cyan-theme .custom-standard-chip {
  background-color: rgba(1, 172, 193, 0.1) !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .help-dashboard-card .mat-icon {
  color: #01acc1;
}
.bracelit-cyan-theme .help-dashboard-card:hover {
  background-color: #01acc1;
}
.bracelit-cyan-theme .help-dashboard-card:hover .mat-icon, .bracelit-cyan-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-cyan-theme .room-open {
  background-color: #01acc1 !important;
  color: white !important;
}
.bracelit-cyan-theme .room-open:hover {
  background-color: #01acc1 !important;
  color: white !important;
}
.bracelit-cyan-theme #public-office-page-groups .public-group-button-hover {
  background-color: #01acc1 !important;
  color: white !important;
}
.bracelit-cyan-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-cyan-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-cyan-theme .notification-container:hover .show-tip {
  color: #01acc1;
}
.bracelit-cyan-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-cyan-theme .notification-container .notification-subject .mat-icon {
  color: #01acc1;
}
.bracelit-cyan-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-cyan-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-cyan-theme .message-container:hover .show-tip {
  color: #01acc1;
}
.bracelit-cyan-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-cyan-theme .message-container .message-subject .mat-icon {
  color: #01acc1;
}
.bracelit-cyan-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-cyan-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-cyan-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-cyan-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-cyan-theme .primary-200-outline input {
  caret-color: #80d6e0;
  color: #01acc1;
}
.bracelit-cyan-theme .primary-200-outline label {
  color: #80d6e0;
}
.bracelit-cyan-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #80d6e0 !important;
}
.bracelit-cyan-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #80d6e0;
}
.bracelit-cyan-theme mat-form-field .primary-outline label {
  color: #01acc1 !important;
}
.bracelit-cyan-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #01acc1 !important;
}
.bracelit-cyan-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #01acc1 !important;
}
.bracelit-cyan-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #80d6e0 !important;
}
.bracelit-cyan-theme .input-primary input {
  caret-color: #01acc1;
  color: #01acc1;
}
.bracelit-cyan-theme .input-primary label {
  color: #01acc1;
}
.bracelit-cyan-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #01acc1 !important;
}
.bracelit-cyan-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-cyan-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #01acc1;
  border-width: 1px;
}
.bracelit-cyan-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-cyan-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-cyan-theme .header-primary-background .mobile-header-container {
  background-color: #01acc1;
}
.bracelit-cyan-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #80d6e0 !important;
}
.bracelit-cyan-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-cyan-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-cyan-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-cyan-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-cyan-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-cyan-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-cyan-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-cyan-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-cyan-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #80d6e0 !important;
}
.bracelit-cyan-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #c9f4ff !important;
}
.bracelit-cyan-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-cyan-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-cyan-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #c9f4ff !important;
}
.bracelit-cyan-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #c9f4ff !important;
}
.bracelit-cyan-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #63dfff !important;
}
.bracelit-cyan-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #63dfff !important;
}
.bracelit-cyan-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-cyan-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #01acc1 !important;
}
.bracelit-cyan-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #01acc1 !important;
}

.bracelit-blue-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-blue-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-blue-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1e88e5;
}
.bracelit-blue-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-blue-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-blue-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-blue-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-blue-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-blue-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1e88e5;
}
.bracelit-blue-theme .mat-pseudo-checkbox-checked,
.bracelit-blue-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-blue-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-blue-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-blue-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-blue-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-blue-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-blue-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-blue-theme .mat-app-background, .bracelit-blue-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-blue-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-blue-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-badge {
  position: relative;
}
.bracelit-blue-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-blue-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-blue-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-blue-theme .ng-animate-disabled .mat-badge-content,
.bracelit-blue-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-blue-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-blue-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-blue-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-blue-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-blue-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-blue-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-blue-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-blue-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-blue-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-blue-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-blue-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-blue-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-blue-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-blue-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-blue-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-blue-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-blue-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-blue-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-blue-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-blue-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-blue-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-blue-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-blue-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-blue-theme .mat-badge-content {
  color: white;
  background: #1e88e5;
}
.cdk-high-contrast-active .bracelit-blue-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-blue-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-blue-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-button, .bracelit-blue-theme .mat-icon-button, .bracelit-blue-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-blue-theme .mat-button.mat-primary, .bracelit-blue-theme .mat-icon-button.mat-primary, .bracelit-blue-theme .mat-stroked-button.mat-primary {
  color: #1e88e5;
}
.bracelit-blue-theme .mat-button.mat-accent, .bracelit-blue-theme .mat-icon-button.mat-accent, .bracelit-blue-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-blue-theme .mat-button.mat-warn, .bracelit-blue-theme .mat-icon-button.mat-warn, .bracelit-blue-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-blue-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-blue-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-blue-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-blue-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-blue-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-blue-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-blue-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-blue-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-blue-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-blue-theme .mat-button .mat-ripple-element, .bracelit-blue-theme .mat-icon-button .mat-ripple-element, .bracelit-blue-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-blue-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-blue-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-flat-button, .bracelit-blue-theme .mat-raised-button, .bracelit-blue-theme .mat-fab, .bracelit-blue-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-blue-theme .mat-flat-button.mat-primary, .bracelit-blue-theme .mat-raised-button.mat-primary, .bracelit-blue-theme .mat-fab.mat-primary, .bracelit-blue-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-blue-theme .mat-flat-button.mat-accent, .bracelit-blue-theme .mat-raised-button.mat-accent, .bracelit-blue-theme .mat-fab.mat-accent, .bracelit-blue-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-flat-button.mat-warn, .bracelit-blue-theme .mat-raised-button.mat-warn, .bracelit-blue-theme .mat-fab.mat-warn, .bracelit-blue-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-blue-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-blue-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-theme .mat-flat-button.mat-primary, .bracelit-blue-theme .mat-raised-button.mat-primary, .bracelit-blue-theme .mat-fab.mat-primary, .bracelit-blue-theme .mat-mini-fab.mat-primary {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-flat-button.mat-accent, .bracelit-blue-theme .mat-raised-button.mat-accent, .bracelit-blue-theme .mat-fab.mat-accent, .bracelit-blue-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-flat-button.mat-warn, .bracelit-blue-theme .mat-raised-button.mat-warn, .bracelit-blue-theme .mat-fab.mat-warn, .bracelit-blue-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-blue-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-blue-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-blue-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-blue-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-blue-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-blue-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-blue-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-blue-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-blue-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-blue-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-blue-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-blue-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-blue-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-blue-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-blue-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-blue-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-blue-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-blue-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-blue-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-blue-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-blue-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-blue-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-blue-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-blue-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-blue-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-blue-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-blue-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-blue-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-blue-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-blue-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-blue-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-blue-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-blue-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-blue-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-blue-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-blue-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-blue-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-blue-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-blue-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-blue-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-blue-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-blue-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #1e88e5;
}
.bracelit-blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1e88e5;
  color: white;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-blue-theme .mat-table {
  background: white;
}
.bracelit-blue-theme .mat-table thead, .bracelit-blue-theme .mat-table tbody, .bracelit-blue-theme .mat-table tfoot,
.bracelit-blue-theme mat-header-row, .bracelit-blue-theme mat-row, .bracelit-blue-theme mat-footer-row,
.bracelit-blue-theme [mat-header-row], .bracelit-blue-theme [mat-row], .bracelit-blue-theme [mat-footer-row],
.bracelit-blue-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-blue-theme mat-row, .bracelit-blue-theme mat-header-row, .bracelit-blue-theme mat-footer-row,
.bracelit-blue-theme th.mat-header-cell, .bracelit-blue-theme td.mat-cell, .bracelit-blue-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-cell, .bracelit-blue-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-datepicker-toggle,
.bracelit-blue-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-blue-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-calendar-table-header,
.bracelit-blue-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-calendar-body-cell-content,
.bracelit-blue-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-blue-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-blue-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-blue-theme .mat-calendar-body-in-range::before {
  background: rgba(30, 136, 229, 0.2);
}
.bracelit-blue-theme .mat-calendar-body-comparison-identical,
.bracelit-blue-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-blue-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-blue-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 136, 229, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-blue-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 136, 229, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-blue-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-blue-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-blue-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-blue-theme .mat-calendar-body-selected {
  background-color: #1e88e5;
  color: white;
}
.bracelit-blue-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 136, 229, 0.4);
}
.bracelit-blue-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-blue-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-blue-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 136, 229, 0.3);
}
@media (hover: hover) {
  .bracelit-blue-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 136, 229, 0.3);
  }
}
.bracelit-blue-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-blue-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-blue-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-blue-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-blue-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-blue-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-blue-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-blue-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-blue-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-datepicker-toggle-active {
  color: #1e88e5;
}
.bracelit-blue-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-blue-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-blue-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-blue-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-blue-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-blue-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-blue-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-expansion-panel-header-description,
.bracelit-blue-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-blue-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-blue-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #1e88e5;
}
.bracelit-blue-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-blue-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-blue-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-blue-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1e88e5;
}
.bracelit-blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-error {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-blue-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-blue-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-blue-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-blue-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1e88e5;
}
.bracelit-blue-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-blue-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-blue-theme .mat-icon.mat-primary {
  color: #1e88e5;
}
.bracelit-blue-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-blue-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-input-element:disabled,
.bracelit-blue-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-input-element {
  caret-color: #1e88e5;
}
.bracelit-blue-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-blue-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-blue-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-blue-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-blue-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-list-option:hover, .bracelit-blue-theme .mat-list-option:focus,
.bracelit-blue-theme .mat-nav-list .mat-list-item:hover,
.bracelit-blue-theme .mat-nav-list .mat-list-item:focus,
.bracelit-blue-theme .mat-action-list .mat-list-item:hover,
.bracelit-blue-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-theme .mat-list-single-selected-option, .bracelit-blue-theme .mat-list-single-selected-option:hover, .bracelit-blue-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-menu-panel {
  background: white;
}
.bracelit-blue-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-menu-item[disabled],
.bracelit-blue-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-blue-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-menu-item .mat-icon-no-color,
.bracelit-blue-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-menu-item:hover:not([disabled]),
.bracelit-blue-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-blue-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-blue-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-theme .mat-paginator {
  background: white;
}
.bracelit-blue-theme .mat-paginator,
.bracelit-blue-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-paginator-decrement,
.bracelit-blue-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-paginator-first,
.bracelit-blue-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-blue-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-blue-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-blue-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-progress-bar-background {
  fill: #c3def5;
}
.bracelit-blue-theme .mat-progress-bar-buffer {
  background-color: #c3def5;
}
.bracelit-blue-theme .mat-progress-bar-fill::after {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-progress-spinner circle, .bracelit-blue-theme .mat-spinner circle {
  stroke: #1e88e5;
}
.bracelit-blue-theme .mat-progress-spinner.mat-accent circle, .bracelit-blue-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-blue-theme .mat-progress-spinner.mat-warn circle, .bracelit-blue-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-blue-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1e88e5;
}
.bracelit-blue-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-blue-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-blue-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-blue-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-blue-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-blue-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-blue-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-blue-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-blue-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-blue-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-blue-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-blue-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-blue-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-select-panel {
  background: white;
}
.bracelit-blue-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1e88e5;
}
.bracelit-blue-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-blue-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-blue-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-blue-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-blue-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-blue-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-blue-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(30, 136, 229, 0.54);
}
.bracelit-blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-blue-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-blue-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-blue-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-blue-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-blue-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(30, 136, 229, 0.2);
}
.bracelit-blue-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-blue-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-blue-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-blue-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-blue-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-blue-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-blue-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-blue-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-blue-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-blue-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-blue-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-blue-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-blue-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-blue-theme .mat-step-header.cdk-keyboard-focused, .bracelit-blue-theme .mat-step-header.cdk-program-focused, .bracelit-blue-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-blue-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-blue-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-blue-theme .mat-step-header .mat-step-label,
.bracelit-blue-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-blue-theme .mat-step-header .mat-step-icon-selected,
.bracelit-blue-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-blue-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #1e88e5;
  color: white;
}
.bracelit-blue-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-blue-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-blue-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-blue-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-blue-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-blue-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-blue-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-blue-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-blue-theme .mat-stepper-horizontal, .bracelit-blue-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-blue-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-horizontal-stepper-header::before,
.bracelit-blue-theme .mat-horizontal-stepper-header::after,
.bracelit-blue-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-blue-theme .mat-tab-nav-bar,
.bracelit-blue-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-blue-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-blue-theme .mat-tab-label, .bracelit-blue-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-tab-label.mat-tab-disabled, .bracelit-blue-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-blue-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-blue-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(188, 219, 247, 0.3);
}
.bracelit-blue-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-blue-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-blue-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-blue-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-blue-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-blue-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-blue-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-blue-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-blue-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(188, 219, 247, 0.3);
}
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #1e88e5;
}
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-blue-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-blue-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-blue-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-toolbar.mat-primary {
  background: #1e88e5;
  color: white;
}
.bracelit-blue-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-blue-theme .mat-toolbar .mat-form-field-underline,
.bracelit-blue-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-blue-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-blue-theme .mat-toolbar .mat-form-field-label,
.bracelit-blue-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-blue-theme .mat-toolbar .mat-select-value,
.bracelit-blue-theme .mat-toolbar .mat-select-arrow,
.bracelit-blue-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-blue-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-blue-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-blue-theme .mat-tree {
  background: white;
}
.bracelit-blue-theme .mat-tree-node,
.bracelit-blue-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-blue-theme .back-container {
  color: #1e88e5;
}
.bracelit-blue-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-blue-theme .warn-text {
  color: #e74737;
}
.bracelit-blue-theme .inside-modal-title.primary-colored-title, .bracelit-blue-theme .form-title.primary-colored-title {
  color: #1e88e5;
}
.bracelit-blue-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-blue-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .box-checkbox.active-box-checkbox {
  background-color: #1e88e5;
  color: white;
}
.bracelit-blue-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .box-radio.active-box-radio {
  background-color: #1e88e5;
  color: white;
}
.bracelit-blue-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .error {
  color: #e74737;
}
.bracelit-blue-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-blue-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-blue-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-blue-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-blue-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-blue-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-blue-theme .ql-snow .ql-fill,
.bracelit-blue-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .ql-snow.ql-toolbar button:hover,
.bracelit-blue-theme .ql-snow .ql-toolbar button:hover,
.bracelit-blue-theme .ql-snow.ql-toolbar button:focus,
.bracelit-blue-theme .ql-snow .ql-toolbar button:focus,
.bracelit-blue-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-blue-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-blue-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-blue-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-blue-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-blue-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-blue-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-blue-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-blue-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-blue-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-blue-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-blue-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-blue-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #1e88e5;
  height: 38px !important;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-blue-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-blue-theme .general-container .label-wrapper label {
  color: #1e88e5 !important;
  background-color: white;
}
.bracelit-blue-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-blue-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #1e88e5 !important;
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #1e88e5;
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #1e88e5;
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #1e88e5;
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-blue-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-blue-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #1e88e5;
}
.bracelit-blue-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-blue-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #1e88e5;
}
.bracelit-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-theme .move-image-action-container:hover {
  color: #1e88e5;
}
.bracelit-blue-theme .info-icon {
  color: #1e88e5;
}
.bracelit-blue-theme .email-chip {
  color: #1e88e5 !important;
  background-color: rgba(30, 136, 229, 0.15) !important;
}
.bracelit-blue-theme .email-chip .mat-chip-remove {
  color: #1e88e5 !important;
  opacity: 1 !important;
}
.bracelit-blue-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-blue-theme .primary-color {
  color: #1e88e5 !important;
}
.bracelit-blue-theme .primary-color-200 {
  color: #8fc4f2 !important;
}
.bracelit-blue-theme .primary-fill {
  fill: #1e88e5 !important;
}
.bracelit-blue-theme .secondary-fill {
  fill: #fdfeff !important;
}
.bracelit-blue-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-blue-theme .primary-border-50 {
  border-color: #e4f1fc !important;
}
.bracelit-blue-theme .primary-border-100 {
  border-color: #bcdbf7 !important;
}
.bracelit-blue-theme .primary-border-200 {
  border-color: #8fc4f2 !important;
}
.bracelit-blue-theme .primary-indicator .mat-expansion-indicator:after {
  color: #1e88e5;
}
.bracelit-blue-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-blue-theme .primary-color-small {
  color: rgba(30, 136, 229, 0.6);
}
.bracelit-blue-theme .primary-background {
  background-color: #1e88e5 !important;
}
.bracelit-blue-theme .primary-background-50 {
  background-color: #e4f1fc !important;
}
.bracelit-blue-theme .primary-background-200 {
  background-color: #8fc4f2 !important;
}
.bracelit-blue-theme .primary-background-800 {
  background-color: #126bda !important;
}
.bracelit-blue-theme .primary-background-800-2 {
  background-color: #126bda !important;
  color: white !important;
}
.bracelit-blue-theme .primary-background-opacity-01 {
  background-color: rgba(30, 136, 229, 0.1) !important;
}
.bracelit-blue-theme .secondary-color {
  color: #fdfeff !important;
}
.bracelit-blue-theme .secondary-background {
  background-color: #fdfeff !important;
}
.bracelit-blue-theme .secondary-background-opacity-01 {
  background-color: rgba(253, 254, 255, 0.1) !important;
}
.bracelit-blue-theme .secondary-border {
  border-color: #fdfeff !important;
}
.bracelit-blue-theme .tertiary-color {
  color: #97bbff !important;
}
.bracelit-blue-theme .tertiary-background {
  background-image: linear-gradient(45deg, #fdfeff, #97bbff) !important;
}
.bracelit-blue-theme .tertiary-border {
  border-color: #97bbff !important;
}
.bracelit-blue-theme .dark-background {
  background-color: black !important;
}
.bracelit-blue-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-blue-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-blue-theme .primary-soft-background {
  background-color: rgba(30, 136, 229, 0.1) !important;
}
.bracelit-blue-theme .primary-soft-background:hover {
  background-color: rgba(30, 136, 229, 0.15) !important;
}
.bracelit-blue-theme .primary-border {
  border-color: #1e88e5 !important;
}
.bracelit-blue-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-blue-theme .hover-primary:hover {
  color: #1e88e5 !important;
}
.bracelit-blue-theme .hover-primary-background:hover {
  background-color: #1e88e5 !important;
}
.bracelit-blue-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-blue-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-blue-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-blue-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-blue-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-blue-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-blue-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-blue-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-blue-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-blue-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-blue-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #1e88e5;
}
.bracelit-blue-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-blue-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-blue-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-blue-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-blue-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .card-table tr:hover .action-icon button {
  color: #1e88e5;
}
.bracelit-blue-theme .card-table tr:hover .action-icon:hover {
  color: #1e88e5;
}
.bracelit-blue-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-blue-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #1e88e5;
}
.bracelit-blue-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-blue-theme .custom-standard-chip {
  background-color: rgba(30, 136, 229, 0.1) !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .help-dashboard-card .mat-icon {
  color: #1e88e5;
}
.bracelit-blue-theme .help-dashboard-card:hover {
  background-color: #1e88e5;
}
.bracelit-blue-theme .help-dashboard-card:hover .mat-icon, .bracelit-blue-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-blue-theme .room-open {
  background-color: #1e88e5 !important;
  color: white !important;
}
.bracelit-blue-theme .room-open:hover {
  background-color: #1e88e5 !important;
  color: white !important;
}
.bracelit-blue-theme #public-office-page-groups .public-group-button-hover {
  background-color: #1e88e5 !important;
  color: white !important;
}
.bracelit-blue-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-blue-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-theme .notification-container:hover .show-tip {
  color: #1e88e5;
}
.bracelit-blue-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-blue-theme .notification-container .notification-subject .mat-icon {
  color: #1e88e5;
}
.bracelit-blue-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-blue-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-theme .message-container:hover .show-tip {
  color: #1e88e5;
}
.bracelit-blue-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-blue-theme .message-container .message-subject .mat-icon {
  color: #1e88e5;
}
.bracelit-blue-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-blue-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-blue-theme .primary-200-outline input {
  caret-color: #8fc4f2;
  color: #1e88e5;
}
.bracelit-blue-theme .primary-200-outline label {
  color: #8fc4f2;
}
.bracelit-blue-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #8fc4f2 !important;
}
.bracelit-blue-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #8fc4f2;
}
.bracelit-blue-theme mat-form-field .primary-outline label {
  color: #1e88e5 !important;
}
.bracelit-blue-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #1e88e5 !important;
}
.bracelit-blue-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #1e88e5 !important;
}
.bracelit-blue-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #8fc4f2 !important;
}
.bracelit-blue-theme .input-primary input {
  caret-color: #1e88e5;
  color: #1e88e5;
}
.bracelit-blue-theme .input-primary label {
  color: #1e88e5;
}
.bracelit-blue-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #1e88e5 !important;
}
.bracelit-blue-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-blue-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #1e88e5;
  border-width: 1px;
}
.bracelit-blue-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-blue-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-blue-theme .header-primary-background .mobile-header-container {
  background-color: #1e88e5;
}
.bracelit-blue-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #8fc4f2 !important;
}
.bracelit-blue-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-blue-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-blue-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-blue-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-blue-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-blue-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-blue-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-blue-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-blue-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #8fc4f2 !important;
}
.bracelit-blue-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #fdfeff !important;
}
.bracelit-blue-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-blue-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-blue-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #fdfeff !important;
}
.bracelit-blue-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #fdfeff !important;
}
.bracelit-blue-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #97bbff !important;
}
.bracelit-blue-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #97bbff !important;
}
.bracelit-blue-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-blue-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #1e88e5 !important;
}
.bracelit-blue-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #1e88e5 !important;
}

.bracelit-deep-blue-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-blue-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-deep-blue-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-blue-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-blue-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3948ab;
}
.bracelit-deep-blue-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-deep-blue-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-deep-blue-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-deep-blue-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3948ab;
}
.bracelit-deep-blue-theme .mat-pseudo-checkbox-checked,
.bracelit-deep-blue-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-deep-blue-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-deep-blue-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-deep-blue-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-deep-blue-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-deep-blue-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-deep-blue-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-deep-blue-theme .mat-app-background, .bracelit-deep-blue-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-deep-blue-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-deep-blue-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-badge {
  position: relative;
}
.bracelit-deep-blue-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-deep-blue-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-deep-blue-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-deep-blue-theme .ng-animate-disabled .mat-badge-content,
.bracelit-deep-blue-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-deep-blue-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-deep-blue-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-deep-blue-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-deep-blue-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-deep-blue-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-deep-blue-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-deep-blue-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-deep-blue-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-deep-blue-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-deep-blue-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-deep-blue-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-deep-blue-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-deep-blue-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-deep-blue-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-deep-blue-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-deep-blue-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-deep-blue-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-deep-blue-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-deep-blue-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-deep-blue-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-deep-blue-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-deep-blue-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-deep-blue-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-deep-blue-theme .mat-badge-content {
  color: white;
  background: #3948ab;
}
.cdk-high-contrast-active .bracelit-deep-blue-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-deep-blue-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-deep-blue-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-button, .bracelit-deep-blue-theme .mat-icon-button, .bracelit-deep-blue-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-deep-blue-theme .mat-button.mat-primary, .bracelit-deep-blue-theme .mat-icon-button.mat-primary, .bracelit-deep-blue-theme .mat-stroked-button.mat-primary {
  color: #3948ab;
}
.bracelit-deep-blue-theme .mat-button.mat-accent, .bracelit-deep-blue-theme .mat-icon-button.mat-accent, .bracelit-deep-blue-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-button.mat-warn, .bracelit-deep-blue-theme .mat-icon-button.mat-warn, .bracelit-deep-blue-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-blue-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-blue-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-blue-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-blue-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-blue-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-blue-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-blue-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-blue-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-blue-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-blue-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-blue-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-deep-blue-theme .mat-button .mat-ripple-element, .bracelit-deep-blue-theme .mat-icon-button .mat-ripple-element, .bracelit-deep-blue-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-deep-blue-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-deep-blue-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-flat-button, .bracelit-deep-blue-theme .mat-raised-button, .bracelit-deep-blue-theme .mat-fab, .bracelit-deep-blue-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-deep-blue-theme .mat-flat-button.mat-primary, .bracelit-deep-blue-theme .mat-raised-button.mat-primary, .bracelit-deep-blue-theme .mat-fab.mat-primary, .bracelit-deep-blue-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-deep-blue-theme .mat-flat-button.mat-accent, .bracelit-deep-blue-theme .mat-raised-button.mat-accent, .bracelit-deep-blue-theme .mat-fab.mat-accent, .bracelit-deep-blue-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-flat-button.mat-warn, .bracelit-deep-blue-theme .mat-raised-button.mat-warn, .bracelit-deep-blue-theme .mat-fab.mat-warn, .bracelit-deep-blue-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-deep-blue-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-blue-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-blue-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-blue-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-blue-theme .mat-flat-button.mat-primary, .bracelit-deep-blue-theme .mat-raised-button.mat-primary, .bracelit-deep-blue-theme .mat-fab.mat-primary, .bracelit-deep-blue-theme .mat-mini-fab.mat-primary {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-flat-button.mat-accent, .bracelit-deep-blue-theme .mat-raised-button.mat-accent, .bracelit-deep-blue-theme .mat-fab.mat-accent, .bracelit-deep-blue-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-flat-button.mat-warn, .bracelit-deep-blue-theme .mat-raised-button.mat-warn, .bracelit-deep-blue-theme .mat-fab.mat-warn, .bracelit-deep-blue-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-blue-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-blue-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-blue-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-blue-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-blue-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-blue-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-deep-blue-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-deep-blue-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-deep-blue-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-blue-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-deep-blue-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-deep-blue-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-deep-blue-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-blue-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-deep-blue-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-deep-blue-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-deep-blue-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-blue-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-deep-blue-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-deep-blue-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-deep-blue-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-deep-blue-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-deep-blue-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-deep-blue-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-deep-blue-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-deep-blue-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-deep-blue-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-deep-blue-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-deep-blue-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-deep-blue-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-deep-blue-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-deep-blue-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-deep-blue-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-deep-blue-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-deep-blue-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-deep-blue-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-deep-blue-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-deep-blue-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-deep-blue-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-deep-blue-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-deep-blue-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-deep-blue-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-deep-blue-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-deep-blue-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-deep-blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3948ab;
}
.bracelit-deep-blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-deep-blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-deep-blue-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-deep-blue-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3948ab;
  color: white;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-blue-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-blue-theme .mat-table {
  background: white;
}
.bracelit-deep-blue-theme .mat-table thead, .bracelit-deep-blue-theme .mat-table tbody, .bracelit-deep-blue-theme .mat-table tfoot,
.bracelit-deep-blue-theme mat-header-row, .bracelit-deep-blue-theme mat-row, .bracelit-deep-blue-theme mat-footer-row,
.bracelit-deep-blue-theme [mat-header-row], .bracelit-deep-blue-theme [mat-row], .bracelit-deep-blue-theme [mat-footer-row],
.bracelit-deep-blue-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-deep-blue-theme mat-row, .bracelit-deep-blue-theme mat-header-row, .bracelit-deep-blue-theme mat-footer-row,
.bracelit-deep-blue-theme th.mat-header-cell, .bracelit-deep-blue-theme td.mat-cell, .bracelit-deep-blue-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-cell, .bracelit-deep-blue-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-datepicker-toggle,
.bracelit-deep-blue-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-deep-blue-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-calendar-table-header,
.bracelit-deep-blue-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-calendar-body-cell-content,
.bracelit-deep-blue-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-deep-blue-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-deep-blue-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-deep-blue-theme .mat-calendar-body-in-range::before {
  background: rgba(57, 72, 171, 0.2);
}
.bracelit-deep-blue-theme .mat-calendar-body-comparison-identical,
.bracelit-deep-blue-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-blue-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-blue-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(57, 72, 171, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-blue-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-blue-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(57, 72, 171, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-blue-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-blue-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-blue-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-blue-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-blue-theme .mat-calendar-body-selected {
  background-color: #3948ab;
  color: white;
}
.bracelit-deep-blue-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(57, 72, 171, 0.4);
}
.bracelit-deep-blue-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-blue-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-blue-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(57, 72, 171, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-blue-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(57, 72, 171, 0.3);
  }
}
.bracelit-deep-blue-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-blue-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-blue-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-deep-blue-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-datepicker-toggle-active {
  color: #3948ab;
}
.bracelit-deep-blue-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-deep-blue-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-deep-blue-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-deep-blue-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-deep-blue-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-expansion-panel-header-description,
.bracelit-deep-blue-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-blue-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-deep-blue-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-deep-blue-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-blue-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #3948ab;
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3948ab;
}
.bracelit-deep-blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-deep-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-deep-blue-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-error {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-blue-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-blue-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-deep-blue-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3948ab;
}
.bracelit-deep-blue-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-blue-theme .mat-icon.mat-primary {
  color: #3948ab;
}
.bracelit-deep-blue-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-input-element:disabled,
.bracelit-deep-blue-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-input-element {
  caret-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-deep-blue-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-list-option:hover, .bracelit-deep-blue-theme .mat-list-option:focus,
.bracelit-deep-blue-theme .mat-nav-list .mat-list-item:hover,
.bracelit-deep-blue-theme .mat-nav-list .mat-list-item:focus,
.bracelit-deep-blue-theme .mat-action-list .mat-list-item:hover,
.bracelit-deep-blue-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-blue-theme .mat-list-single-selected-option, .bracelit-deep-blue-theme .mat-list-single-selected-option:hover, .bracelit-deep-blue-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-menu-panel {
  background: white;
}
.bracelit-deep-blue-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-menu-item[disabled],
.bracelit-deep-blue-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-deep-blue-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-menu-item .mat-icon-no-color,
.bracelit-deep-blue-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-menu-item:hover:not([disabled]),
.bracelit-deep-blue-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-deep-blue-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-deep-blue-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-blue-theme .mat-paginator {
  background: white;
}
.bracelit-deep-blue-theme .mat-paginator,
.bracelit-deep-blue-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-paginator-decrement,
.bracelit-deep-blue-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-paginator-first,
.bracelit-deep-blue-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-deep-blue-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-deep-blue-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-deep-blue-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-progress-bar-background {
  fill: #cacee6;
}
.bracelit-deep-blue-theme .mat-progress-bar-buffer {
  background-color: #cacee6;
}
.bracelit-deep-blue-theme .mat-progress-bar-fill::after {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-deep-blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-deep-blue-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-deep-blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-deep-blue-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-progress-spinner circle, .bracelit-deep-blue-theme .mat-spinner circle {
  stroke: #3948ab;
}
.bracelit-deep-blue-theme .mat-progress-spinner.mat-accent circle, .bracelit-deep-blue-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-deep-blue-theme .mat-progress-spinner.mat-warn circle, .bracelit-deep-blue-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-deep-blue-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-deep-blue-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-blue-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-blue-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-deep-blue-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-blue-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-blue-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-deep-blue-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-deep-blue-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-blue-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-blue-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-deep-blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-deep-blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-blue-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-select-panel {
  background: white;
}
.bracelit-deep-blue-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3948ab;
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-deep-blue-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-deep-blue-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-blue-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-blue-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(57, 72, 171, 0.54);
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-deep-blue-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-blue-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-deep-blue-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-blue-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-deep-blue-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-deep-blue-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-blue-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(57, 72, 171, 0.2);
}
.bracelit-deep-blue-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-deep-blue-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-deep-blue-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-blue-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-deep-blue-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-deep-blue-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-blue-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-blue-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-deep-blue-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-deep-blue-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-deep-blue-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-blue-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-blue-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-deep-blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-deep-blue-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-deep-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-deep-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-deep-blue-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-blue-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-deep-blue-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-blue-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-blue-theme .mat-step-header.cdk-keyboard-focused, .bracelit-deep-blue-theme .mat-step-header.cdk-program-focused, .bracelit-deep-blue-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-deep-blue-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-blue-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-deep-blue-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-deep-blue-theme .mat-step-header .mat-step-label,
.bracelit-deep-blue-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-deep-blue-theme .mat-step-header .mat-step-icon-selected,
.bracelit-deep-blue-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-deep-blue-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #3948ab;
  color: white;
}
.bracelit-deep-blue-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-deep-blue-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-deep-blue-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-deep-blue-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-deep-blue-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-deep-blue-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-blue-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-deep-blue-theme .mat-stepper-horizontal, .bracelit-deep-blue-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-deep-blue-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-horizontal-stepper-header::before,
.bracelit-deep-blue-theme .mat-horizontal-stepper-header::after,
.bracelit-deep-blue-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-deep-blue-theme .mat-tab-nav-bar,
.bracelit-deep-blue-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-deep-blue-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-deep-blue-theme .mat-tab-label, .bracelit-deep-blue-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-tab-label.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-deep-blue-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(196, 200, 230, 0.3);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(196, 200, 230, 0.3);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-blue-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-blue-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-toolbar.mat-primary {
  background: #3948ab;
  color: white;
}
.bracelit-deep-blue-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-deep-blue-theme .mat-toolbar .mat-form-field-underline,
.bracelit-deep-blue-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-deep-blue-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-deep-blue-theme .mat-toolbar .mat-form-field-label,
.bracelit-deep-blue-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-deep-blue-theme .mat-toolbar .mat-select-value,
.bracelit-deep-blue-theme .mat-toolbar .mat-select-arrow,
.bracelit-deep-blue-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-deep-blue-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-deep-blue-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-deep-blue-theme .mat-tree {
  background: white;
}
.bracelit-deep-blue-theme .mat-tree-node,
.bracelit-deep-blue-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-deep-blue-theme .back-container {
  color: #3948ab;
}
.bracelit-deep-blue-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-deep-blue-theme .warn-text {
  color: #e74737;
}
.bracelit-deep-blue-theme .inside-modal-title.primary-colored-title, .bracelit-deep-blue-theme .form-title.primary-colored-title {
  color: #3948ab;
}
.bracelit-deep-blue-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-deep-blue-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-blue-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-blue-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .box-checkbox.active-box-checkbox {
  background-color: #3948ab;
  color: white;
}
.bracelit-deep-blue-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .box-radio.active-box-radio {
  background-color: #3948ab;
  color: white;
}
.bracelit-deep-blue-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .error {
  color: #e74737;
}
.bracelit-deep-blue-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-deep-blue-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-deep-blue-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-deep-blue-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-deep-blue-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-deep-blue-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-deep-blue-theme .ql-snow .ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:hover,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:hover,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:focus,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:focus,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-deep-blue-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #3948ab;
  height: 38px !important;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-deep-blue-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-deep-blue-theme .general-container .label-wrapper label {
  color: #3948ab !important;
  background-color: white;
}
.bracelit-deep-blue-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-deep-blue-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #3948ab !important;
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #3948ab;
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #3948ab;
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #3948ab;
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-blue-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-deep-blue-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #3948ab;
}
.bracelit-deep-blue-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-blue-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-deep-blue-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #3948ab;
}
.bracelit-deep-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-blue-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-blue-theme .move-image-action-container:hover {
  color: #3948ab;
}
.bracelit-deep-blue-theme .info-icon {
  color: #3948ab;
}
.bracelit-deep-blue-theme .email-chip {
  color: #3948ab !important;
  background-color: rgba(57, 72, 171, 0.15) !important;
}
.bracelit-deep-blue-theme .email-chip .mat-chip-remove {
  color: #3948ab !important;
  opacity: 1 !important;
}
.bracelit-deep-blue-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-deep-blue-theme .primary-color {
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .primary-color-200 {
  color: #9ca4d5 !important;
}
.bracelit-deep-blue-theme .primary-fill {
  fill: #3948ab !important;
}
.bracelit-deep-blue-theme .secondary-fill {
  fill: #b8bdff !important;
}
.bracelit-deep-blue-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-deep-blue-theme .primary-border-50 {
  border-color: #e7e9f5 !important;
}
.bracelit-deep-blue-theme .primary-border-100 {
  border-color: #c4c8e6 !important;
}
.bracelit-deep-blue-theme .primary-border-200 {
  border-color: #9ca4d5 !important;
}
.bracelit-deep-blue-theme .primary-indicator .mat-expansion-indicator:after {
  color: #3948ab;
}
.bracelit-deep-blue-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-deep-blue-theme .primary-color-small {
  color: rgba(57, 72, 171, 0.6);
}
.bracelit-deep-blue-theme .primary-background {
  background-color: #3948ab !important;
}
.bracelit-deep-blue-theme .primary-background-50 {
  background-color: #e7e9f5 !important;
}
.bracelit-deep-blue-theme .primary-background-200 {
  background-color: #9ca4d5 !important;
}
.bracelit-deep-blue-theme .primary-background-800 {
  background-color: #243091 !important;
}
.bracelit-deep-blue-theme .primary-background-800-2 {
  background-color: #243091 !important;
  color: white !important;
}
.bracelit-deep-blue-theme .primary-background-opacity-01 {
  background-color: rgba(57, 72, 171, 0.1) !important;
}
.bracelit-deep-blue-theme .secondary-color {
  color: #b8bdff !important;
}
.bracelit-deep-blue-theme .secondary-background {
  background-color: #b8bdff !important;
}
.bracelit-deep-blue-theme .secondary-background-opacity-01 {
  background-color: rgba(184, 189, 255, 0.1) !important;
}
.bracelit-deep-blue-theme .secondary-border {
  border-color: #b8bdff !important;
}
.bracelit-deep-blue-theme .tertiary-color {
  color: #515fff !important;
}
.bracelit-deep-blue-theme .tertiary-background {
  background-image: linear-gradient(45deg, #b8bdff, #515fff) !important;
}
.bracelit-deep-blue-theme .tertiary-border {
  border-color: #515fff !important;
}
.bracelit-deep-blue-theme .dark-background {
  background-color: black !important;
}
.bracelit-deep-blue-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-deep-blue-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-deep-blue-theme .primary-soft-background {
  background-color: rgba(57, 72, 171, 0.1) !important;
}
.bracelit-deep-blue-theme .primary-soft-background:hover {
  background-color: rgba(57, 72, 171, 0.15) !important;
}
.bracelit-deep-blue-theme .primary-border {
  border-color: #3948ab !important;
}
.bracelit-deep-blue-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-deep-blue-theme .hover-primary:hover {
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .hover-primary-background:hover {
  background-color: #3948ab !important;
}
.bracelit-deep-blue-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-deep-blue-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-deep-blue-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-deep-blue-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-deep-blue-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-deep-blue-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-deep-blue-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-deep-blue-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-deep-blue-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-blue-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-deep-blue-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-blue-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #3948ab;
}
.bracelit-deep-blue-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-blue-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-deep-blue-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-deep-blue-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-blue-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-deep-blue-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-blue-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .card-table tr:hover .action-icon button {
  color: #3948ab;
}
.bracelit-deep-blue-theme .card-table tr:hover .action-icon:hover {
  color: #3948ab;
}
.bracelit-deep-blue-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-deep-blue-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-blue-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #3948ab;
}
.bracelit-deep-blue-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-deep-blue-theme .custom-standard-chip {
  background-color: rgba(57, 72, 171, 0.1) !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .help-dashboard-card .mat-icon {
  color: #3948ab;
}
.bracelit-deep-blue-theme .help-dashboard-card:hover {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .help-dashboard-card:hover .mat-icon, .bracelit-deep-blue-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-deep-blue-theme .room-open {
  background-color: #3948ab !important;
  color: white !important;
}
.bracelit-deep-blue-theme .room-open:hover {
  background-color: #3948ab !important;
  color: white !important;
}
.bracelit-deep-blue-theme #public-office-page-groups .public-group-button-hover {
  background-color: #3948ab !important;
  color: white !important;
}
.bracelit-deep-blue-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-deep-blue-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-blue-theme .notification-container:hover .show-tip {
  color: #3948ab;
}
.bracelit-deep-blue-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-blue-theme .notification-container .notification-subject .mat-icon {
  color: #3948ab;
}
.bracelit-deep-blue-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-deep-blue-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-blue-theme .message-container:hover .show-tip {
  color: #3948ab;
}
.bracelit-deep-blue-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-blue-theme .message-container .message-subject .mat-icon {
  color: #3948ab;
}
.bracelit-deep-blue-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-blue-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-blue-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-blue-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-deep-blue-theme .primary-200-outline input {
  caret-color: #9ca4d5;
  color: #3948ab;
}
.bracelit-deep-blue-theme .primary-200-outline label {
  color: #9ca4d5;
}
.bracelit-deep-blue-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #9ca4d5 !important;
}
.bracelit-deep-blue-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #9ca4d5;
}
.bracelit-deep-blue-theme mat-form-field .primary-outline label {
  color: #3948ab !important;
}
.bracelit-deep-blue-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #3948ab !important;
}
.bracelit-deep-blue-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #9ca4d5 !important;
}
.bracelit-deep-blue-theme .input-primary input {
  caret-color: #3948ab;
  color: #3948ab;
}
.bracelit-deep-blue-theme .input-primary label {
  color: #3948ab;
}
.bracelit-deep-blue-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #3948ab !important;
}
.bracelit-deep-blue-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-deep-blue-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #3948ab;
  border-width: 1px;
}
.bracelit-deep-blue-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-deep-blue-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-deep-blue-theme .header-primary-background .mobile-header-container {
  background-color: #3948ab;
}
.bracelit-deep-blue-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #9ca4d5 !important;
}
.bracelit-deep-blue-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-blue-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-blue-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-blue-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-blue-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-blue-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-blue-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-blue-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-blue-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #9ca4d5 !important;
}
.bracelit-deep-blue-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #b8bdff !important;
}
.bracelit-deep-blue-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-deep-blue-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-deep-blue-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #b8bdff !important;
}
.bracelit-deep-blue-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #b8bdff !important;
}
.bracelit-deep-blue-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #515fff !important;
}
.bracelit-deep-blue-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #515fff !important;
}
.bracelit-deep-blue-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-deep-blue-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #3948ab !important;
}
.bracelit-deep-blue-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #3948ab !important;
}

.bracelit-yellow-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-yellow-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-yellow-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-yellow-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-yellow-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fdd835;
}
.bracelit-yellow-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-yellow-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-yellow-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-yellow-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-yellow-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #fdd835;
}
.bracelit-yellow-theme .mat-pseudo-checkbox-checked,
.bracelit-yellow-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-yellow-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-yellow-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-yellow-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-yellow-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-yellow-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-yellow-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-yellow-theme .mat-app-background, .bracelit-yellow-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-yellow-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-yellow-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-badge {
  position: relative;
}
.bracelit-yellow-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-yellow-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-yellow-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-yellow-theme .ng-animate-disabled .mat-badge-content,
.bracelit-yellow-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-yellow-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-yellow-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-yellow-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-yellow-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-yellow-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-yellow-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-yellow-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-yellow-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-yellow-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-yellow-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-yellow-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-yellow-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-yellow-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-yellow-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-yellow-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-yellow-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-yellow-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-yellow-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-yellow-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-yellow-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-yellow-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-yellow-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-yellow-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-yellow-theme .mat-badge-content {
  color: black;
  background: #fdd835;
}
.cdk-high-contrast-active .bracelit-yellow-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-yellow-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-yellow-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-button, .bracelit-yellow-theme .mat-icon-button, .bracelit-yellow-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-yellow-theme .mat-button.mat-primary, .bracelit-yellow-theme .mat-icon-button.mat-primary, .bracelit-yellow-theme .mat-stroked-button.mat-primary {
  color: #fdd835;
}
.bracelit-yellow-theme .mat-button.mat-accent, .bracelit-yellow-theme .mat-icon-button.mat-accent, .bracelit-yellow-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-button.mat-warn, .bracelit-yellow-theme .mat-icon-button.mat-warn, .bracelit-yellow-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-yellow-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-yellow-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-yellow-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-yellow-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-yellow-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-yellow-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-yellow-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-yellow-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-yellow-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-yellow-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-yellow-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-yellow-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-yellow-theme .mat-button .mat-ripple-element, .bracelit-yellow-theme .mat-icon-button .mat-ripple-element, .bracelit-yellow-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-yellow-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-yellow-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-flat-button, .bracelit-yellow-theme .mat-raised-button, .bracelit-yellow-theme .mat-fab, .bracelit-yellow-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-yellow-theme .mat-flat-button.mat-primary, .bracelit-yellow-theme .mat-raised-button.mat-primary, .bracelit-yellow-theme .mat-fab.mat-primary, .bracelit-yellow-theme .mat-mini-fab.mat-primary {
  color: black;
}
.bracelit-yellow-theme .mat-flat-button.mat-accent, .bracelit-yellow-theme .mat-raised-button.mat-accent, .bracelit-yellow-theme .mat-fab.mat-accent, .bracelit-yellow-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-flat-button.mat-warn, .bracelit-yellow-theme .mat-raised-button.mat-warn, .bracelit-yellow-theme .mat-fab.mat-warn, .bracelit-yellow-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-yellow-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-yellow-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-yellow-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-yellow-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-yellow-theme .mat-flat-button.mat-primary, .bracelit-yellow-theme .mat-raised-button.mat-primary, .bracelit-yellow-theme .mat-fab.mat-primary, .bracelit-yellow-theme .mat-mini-fab.mat-primary {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-flat-button.mat-accent, .bracelit-yellow-theme .mat-raised-button.mat-accent, .bracelit-yellow-theme .mat-fab.mat-accent, .bracelit-yellow-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-flat-button.mat-warn, .bracelit-yellow-theme .mat-raised-button.mat-warn, .bracelit-yellow-theme .mat-fab.mat-warn, .bracelit-yellow-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-yellow-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-yellow-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-yellow-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-yellow-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-yellow-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-yellow-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-yellow-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-yellow-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-yellow-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-yellow-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-yellow-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-yellow-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-yellow-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-yellow-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-yellow-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-yellow-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-yellow-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-yellow-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-yellow-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-yellow-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-yellow-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-yellow-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-yellow-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-yellow-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-yellow-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-yellow-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-yellow-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-yellow-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-yellow-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-yellow-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-yellow-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-yellow-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-yellow-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-yellow-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-yellow-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-yellow-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-yellow-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-yellow-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-yellow-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-yellow-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-yellow-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-yellow-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-yellow-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-yellow-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-yellow-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-yellow-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #fdd835;
}
.bracelit-yellow-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-yellow-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-yellow-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-yellow-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #fdd835;
  color: black;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-yellow-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-yellow-theme .mat-table {
  background: white;
}
.bracelit-yellow-theme .mat-table thead, .bracelit-yellow-theme .mat-table tbody, .bracelit-yellow-theme .mat-table tfoot,
.bracelit-yellow-theme mat-header-row, .bracelit-yellow-theme mat-row, .bracelit-yellow-theme mat-footer-row,
.bracelit-yellow-theme [mat-header-row], .bracelit-yellow-theme [mat-row], .bracelit-yellow-theme [mat-footer-row],
.bracelit-yellow-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-yellow-theme mat-row, .bracelit-yellow-theme mat-header-row, .bracelit-yellow-theme mat-footer-row,
.bracelit-yellow-theme th.mat-header-cell, .bracelit-yellow-theme td.mat-cell, .bracelit-yellow-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-cell, .bracelit-yellow-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-datepicker-toggle,
.bracelit-yellow-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-yellow-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-calendar-table-header,
.bracelit-yellow-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-calendar-body-cell-content,
.bracelit-yellow-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-yellow-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-yellow-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-yellow-theme .mat-calendar-body-in-range::before {
  background: rgba(253, 216, 53, 0.2);
}
.bracelit-yellow-theme .mat-calendar-body-comparison-identical,
.bracelit-yellow-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-yellow-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-yellow-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(253, 216, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-yellow-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-yellow-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(253, 216, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-yellow-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-yellow-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-yellow-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-yellow-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-yellow-theme .mat-calendar-body-selected {
  background-color: #fdd835;
  color: black;
}
.bracelit-yellow-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(253, 216, 53, 0.4);
}
.bracelit-yellow-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.bracelit-yellow-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-yellow-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(253, 216, 53, 0.3);
}
@media (hover: hover) {
  .bracelit-yellow-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(253, 216, 53, 0.3);
  }
}
.bracelit-yellow-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-yellow-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-yellow-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-yellow-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-yellow-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-yellow-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-yellow-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-yellow-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-yellow-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-yellow-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-datepicker-toggle-active {
  color: #fdd835;
}
.bracelit-yellow-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-yellow-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-yellow-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-yellow-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-yellow-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-yellow-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-expansion-panel-header-description,
.bracelit-yellow-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-yellow-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-yellow-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-yellow-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-yellow-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-yellow-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #fdd835;
}
.bracelit-yellow-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-yellow-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #fdd835;
}
.bracelit-yellow-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-yellow-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-error {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-yellow-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-yellow-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-yellow-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-yellow-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-yellow-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #fdd835;
}
.bracelit-yellow-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-yellow-theme .mat-icon.mat-primary {
  color: #fdd835;
}
.bracelit-yellow-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-input-element:disabled,
.bracelit-yellow-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-input-element {
  caret-color: #fdd835;
}
.bracelit-yellow-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-yellow-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-yellow-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-yellow-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-yellow-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-list-option:hover, .bracelit-yellow-theme .mat-list-option:focus,
.bracelit-yellow-theme .mat-nav-list .mat-list-item:hover,
.bracelit-yellow-theme .mat-nav-list .mat-list-item:focus,
.bracelit-yellow-theme .mat-action-list .mat-list-item:hover,
.bracelit-yellow-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-yellow-theme .mat-list-single-selected-option, .bracelit-yellow-theme .mat-list-single-selected-option:hover, .bracelit-yellow-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-menu-panel {
  background: white;
}
.bracelit-yellow-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-menu-item[disabled],
.bracelit-yellow-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-yellow-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-menu-item .mat-icon-no-color,
.bracelit-yellow-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-menu-item:hover:not([disabled]),
.bracelit-yellow-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-yellow-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-yellow-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-yellow-theme .mat-paginator {
  background: white;
}
.bracelit-yellow-theme .mat-paginator,
.bracelit-yellow-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-paginator-decrement,
.bracelit-yellow-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-paginator-first,
.bracelit-yellow-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-yellow-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-yellow-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-yellow-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-progress-bar-background {
  fill: #fbf2c9;
}
.bracelit-yellow-theme .mat-progress-bar-buffer {
  background-color: #fbf2c9;
}
.bracelit-yellow-theme .mat-progress-bar-fill::after {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-yellow-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-yellow-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-yellow-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-yellow-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-progress-spinner circle, .bracelit-yellow-theme .mat-spinner circle {
  stroke: #fdd835;
}
.bracelit-yellow-theme .mat-progress-spinner.mat-accent circle, .bracelit-yellow-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-yellow-theme .mat-progress-spinner.mat-warn circle, .bracelit-yellow-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-yellow-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #fdd835;
}
.bracelit-yellow-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-yellow-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-yellow-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-yellow-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-yellow-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-yellow-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-yellow-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-yellow-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-yellow-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-yellow-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-yellow-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-yellow-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-yellow-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-yellow-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-yellow-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-select-panel {
  background: white;
}
.bracelit-yellow-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #fdd835;
}
.bracelit-yellow-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-yellow-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-yellow-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-yellow-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-yellow-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-yellow-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-yellow-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-yellow-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(253, 216, 53, 0.54);
}
.bracelit-yellow-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-yellow-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-yellow-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-yellow-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-yellow-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-yellow-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-yellow-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: black;
}
.bracelit-yellow-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(253, 216, 53, 0.2);
}
.bracelit-yellow-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-yellow-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-yellow-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-yellow-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-yellow-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-yellow-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-yellow-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-yellow-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-yellow-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-yellow-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-yellow-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-yellow-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-yellow-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-yellow-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-yellow-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-yellow-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-yellow-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-yellow-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-yellow-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-yellow-theme .mat-step-header.cdk-keyboard-focused, .bracelit-yellow-theme .mat-step-header.cdk-program-focused, .bracelit-yellow-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-yellow-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-yellow-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-yellow-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-yellow-theme .mat-step-header .mat-step-label,
.bracelit-yellow-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: black;
}
.bracelit-yellow-theme .mat-step-header .mat-step-icon-selected,
.bracelit-yellow-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-yellow-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #fdd835;
  color: black;
}
.bracelit-yellow-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-yellow-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-yellow-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-yellow-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-yellow-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-yellow-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-yellow-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-yellow-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-yellow-theme .mat-stepper-horizontal, .bracelit-yellow-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-yellow-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-horizontal-stepper-header::before,
.bracelit-yellow-theme .mat-horizontal-stepper-header::after,
.bracelit-yellow-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-yellow-theme .mat-tab-nav-bar,
.bracelit-yellow-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-yellow-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-yellow-theme .mat-tab-label, .bracelit-yellow-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-tab-label.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-yellow-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-yellow-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(254, 243, 194, 0.3);
}
.bracelit-yellow-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-yellow-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.bracelit-yellow-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-yellow-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-yellow-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-yellow-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-yellow-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-yellow-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(254, 243, 194, 0.3);
}
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #fdd835;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-yellow-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-yellow-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-toolbar.mat-primary {
  background: #fdd835;
  color: black;
}
.bracelit-yellow-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-yellow-theme .mat-toolbar .mat-form-field-underline,
.bracelit-yellow-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-yellow-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-yellow-theme .mat-toolbar .mat-form-field-label,
.bracelit-yellow-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-yellow-theme .mat-toolbar .mat-select-value,
.bracelit-yellow-theme .mat-toolbar .mat-select-arrow,
.bracelit-yellow-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-yellow-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-yellow-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-yellow-theme .mat-tree {
  background: white;
}
.bracelit-yellow-theme .mat-tree-node,
.bracelit-yellow-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-yellow-theme .back-container {
  color: #fdd835;
}
.bracelit-yellow-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-yellow-theme .warn-text {
  color: #e74737;
}
.bracelit-yellow-theme .inside-modal-title.primary-colored-title, .bracelit-yellow-theme .form-title.primary-colored-title {
  color: #fdd835;
}
.bracelit-yellow-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-yellow-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-yellow-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-yellow-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .box-checkbox.active-box-checkbox {
  background-color: #fdd835;
  color: white;
}
.bracelit-yellow-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .box-radio.active-box-radio {
  background-color: #fdd835;
  color: white;
}
.bracelit-yellow-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .error {
  color: #e74737;
}
.bracelit-yellow-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-yellow-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-yellow-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-yellow-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-yellow-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-yellow-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-yellow-theme .ql-snow .ql-fill,
.bracelit-yellow-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .ql-snow.ql-toolbar button:hover,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:hover,
.bracelit-yellow-theme .ql-snow.ql-toolbar button:focus,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:focus,
.bracelit-yellow-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-yellow-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-yellow-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-yellow-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-yellow-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-yellow-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-yellow-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #fdd835;
  height: 38px !important;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-yellow-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-yellow-theme .general-container .label-wrapper label {
  color: #fdd835 !important;
  background-color: white;
}
.bracelit-yellow-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-yellow-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #fdd835 !important;
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #fdd835;
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #fdd835;
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #fdd835;
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-yellow-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-yellow-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #fdd835;
}
.bracelit-yellow-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-yellow-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-yellow-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-yellow-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-yellow-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #fdd835;
}
.bracelit-yellow-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-yellow-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-yellow-theme .move-image-action-container:hover {
  color: #fdd835;
}
.bracelit-yellow-theme .info-icon {
  color: #fdd835;
}
.bracelit-yellow-theme .email-chip {
  color: #fdd835 !important;
  background-color: rgba(253, 216, 53, 0.15) !important;
}
.bracelit-yellow-theme .email-chip .mat-chip-remove {
  color: #fdd835 !important;
  opacity: 1 !important;
}
.bracelit-yellow-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .primary-contrast-text-color {
  color: #000000 !important;
}
.bracelit-yellow-theme .primary-color {
  color: #fdd835 !important;
}
.bracelit-yellow-theme .primary-color-200 {
  color: #feec9a !important;
}
.bracelit-yellow-theme .primary-fill {
  fill: #fdd835 !important;
}
.bracelit-yellow-theme .secondary-fill {
  fill: white !important;
}
.bracelit-yellow-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-yellow-theme .primary-border-50 {
  border-color: #fffae7 !important;
}
.bracelit-yellow-theme .primary-border-100 {
  border-color: #fef3c2 !important;
}
.bracelit-yellow-theme .primary-border-200 {
  border-color: #feec9a !important;
}
.bracelit-yellow-theme .primary-indicator .mat-expansion-indicator:after {
  color: #fdd835;
}
.bracelit-yellow-theme .contrast-background {
  background-color: #000000 !important;
}
.bracelit-yellow-theme .primary-color-small {
  color: rgba(253, 216, 53, 0.6);
}
.bracelit-yellow-theme .primary-background {
  background-color: #fdd835 !important;
}
.bracelit-yellow-theme .primary-background-50 {
  background-color: #fffae7 !important;
}
.bracelit-yellow-theme .primary-background-200 {
  background-color: #feec9a !important;
}
.bracelit-yellow-theme .primary-background-800 {
  background-color: #fcc822 !important;
}
.bracelit-yellow-theme .primary-background-800-2 {
  background-color: #fcc822 !important;
  color: white !important;
}
.bracelit-yellow-theme .primary-background-opacity-01 {
  background-color: rgba(253, 216, 53, 0.1) !important;
}
.bracelit-yellow-theme .secondary-color {
  color: white !important;
}
.bracelit-yellow-theme .secondary-background {
  background-color: white !important;
}
.bracelit-yellow-theme .secondary-background-opacity-01 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.bracelit-yellow-theme .secondary-border {
  border-color: white !important;
}
.bracelit-yellow-theme .tertiary-color {
  color: #ffeec5 !important;
}
.bracelit-yellow-theme .tertiary-background {
  background-image: linear-gradient(45deg, white, #ffeec5) !important;
}
.bracelit-yellow-theme .tertiary-border {
  border-color: #ffeec5 !important;
}
.bracelit-yellow-theme .dark-background {
  background-color: black !important;
}
.bracelit-yellow-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-yellow-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-yellow-theme .primary-soft-background {
  background-color: rgba(253, 216, 53, 0.1) !important;
}
.bracelit-yellow-theme .primary-soft-background:hover {
  background-color: rgba(253, 216, 53, 0.15) !important;
}
.bracelit-yellow-theme .primary-border {
  border-color: #fdd835 !important;
}
.bracelit-yellow-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-yellow-theme .hover-primary:hover {
  color: #fdd835 !important;
}
.bracelit-yellow-theme .hover-primary-background:hover {
  background-color: #fdd835 !important;
}
.bracelit-yellow-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-yellow-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-yellow-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-yellow-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-yellow-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-yellow-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-yellow-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-yellow-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-yellow-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-yellow-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-yellow-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-yellow-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #fdd835;
}
.bracelit-yellow-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-yellow-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-yellow-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-yellow-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-yellow-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-yellow-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-yellow-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .card-table tr:hover .action-icon button {
  color: #fdd835;
}
.bracelit-yellow-theme .card-table tr:hover .action-icon:hover {
  color: #fdd835;
}
.bracelit-yellow-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-yellow-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-yellow-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #fdd835;
}
.bracelit-yellow-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-yellow-theme .custom-standard-chip {
  background-color: rgba(253, 216, 53, 0.1) !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .help-dashboard-card .mat-icon {
  color: #fdd835;
}
.bracelit-yellow-theme .help-dashboard-card:hover {
  background-color: #fdd835;
}
.bracelit-yellow-theme .help-dashboard-card:hover .mat-icon, .bracelit-yellow-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-yellow-theme .room-open {
  background-color: #fdd835 !important;
  color: white !important;
}
.bracelit-yellow-theme .room-open:hover {
  background-color: #fdd835 !important;
  color: white !important;
}
.bracelit-yellow-theme #public-office-page-groups .public-group-button-hover {
  background-color: #fdd835 !important;
  color: white !important;
}
.bracelit-yellow-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-yellow-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-yellow-theme .notification-container:hover .show-tip {
  color: #fdd835;
}
.bracelit-yellow-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-yellow-theme .notification-container .notification-subject .mat-icon {
  color: #fdd835;
}
.bracelit-yellow-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-yellow-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-yellow-theme .message-container:hover .show-tip {
  color: #fdd835;
}
.bracelit-yellow-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-yellow-theme .message-container .message-subject .mat-icon {
  color: #fdd835;
}
.bracelit-yellow-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-yellow-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-yellow-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-yellow-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-yellow-theme .primary-200-outline input {
  caret-color: #feec9a;
  color: #fdd835;
}
.bracelit-yellow-theme .primary-200-outline label {
  color: #feec9a;
}
.bracelit-yellow-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #feec9a !important;
}
.bracelit-yellow-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #feec9a;
}
.bracelit-yellow-theme mat-form-field .primary-outline label {
  color: #fdd835 !important;
}
.bracelit-yellow-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #fdd835 !important;
}
.bracelit-yellow-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #fdd835 !important;
}
.bracelit-yellow-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #feec9a !important;
}
.bracelit-yellow-theme .input-primary input {
  caret-color: #fdd835;
  color: #fdd835;
}
.bracelit-yellow-theme .input-primary label {
  color: #fdd835;
}
.bracelit-yellow-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #fdd835 !important;
}
.bracelit-yellow-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-yellow-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #fdd835;
  border-width: 1px;
}
.bracelit-yellow-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-yellow-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-yellow-theme .header-primary-background .mobile-header-container {
  background-color: #fdd835;
}
.bracelit-yellow-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #feec9a !important;
}
.bracelit-yellow-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #000000 !important;
}
.bracelit-yellow-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #000000 !important;
}
.bracelit-yellow-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-yellow-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-yellow-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-yellow-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-yellow-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-yellow-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-yellow-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #feec9a !important;
}
.bracelit-yellow-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: white !important;
}
.bracelit-yellow-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-yellow-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-yellow-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: white !important;
}
.bracelit-yellow-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: white !important;
}
.bracelit-yellow-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ffeec5 !important;
}
.bracelit-yellow-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ffeec5 !important;
}
.bracelit-yellow-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #000000 !important;
}
.bracelit-yellow-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #fdd835 !important;
}
.bracelit-yellow-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #fdd835 !important;
}

.bracelit-amber-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-amber-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-amber-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-amber-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-amber-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cf9307;
}
.bracelit-amber-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-amber-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-amber-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-amber-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-amber-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-amber-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #cf9307;
}
.bracelit-amber-theme .mat-pseudo-checkbox-checked,
.bracelit-amber-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-amber-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-amber-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-amber-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-amber-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-amber-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-amber-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-amber-theme .mat-app-background, .bracelit-amber-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-amber-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-amber-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-badge {
  position: relative;
}
.bracelit-amber-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-amber-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-amber-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-amber-theme .ng-animate-disabled .mat-badge-content,
.bracelit-amber-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-amber-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-amber-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-amber-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-amber-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-amber-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-amber-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-amber-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-amber-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-amber-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-amber-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-amber-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-amber-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-amber-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-amber-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-amber-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-amber-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-amber-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-amber-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-amber-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-amber-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-amber-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-amber-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-amber-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-amber-theme .mat-badge-content {
  color: black;
  background: #cf9307;
}
.cdk-high-contrast-active .bracelit-amber-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-amber-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-amber-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-button, .bracelit-amber-theme .mat-icon-button, .bracelit-amber-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-amber-theme .mat-button.mat-primary, .bracelit-amber-theme .mat-icon-button.mat-primary, .bracelit-amber-theme .mat-stroked-button.mat-primary {
  color: #cf9307;
}
.bracelit-amber-theme .mat-button.mat-accent, .bracelit-amber-theme .mat-icon-button.mat-accent, .bracelit-amber-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-amber-theme .mat-button.mat-warn, .bracelit-amber-theme .mat-icon-button.mat-warn, .bracelit-amber-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-amber-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-amber-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-amber-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-amber-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-amber-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-amber-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-amber-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-amber-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-amber-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-amber-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-amber-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-amber-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-amber-theme .mat-button .mat-ripple-element, .bracelit-amber-theme .mat-icon-button .mat-ripple-element, .bracelit-amber-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-amber-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-amber-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-flat-button, .bracelit-amber-theme .mat-raised-button, .bracelit-amber-theme .mat-fab, .bracelit-amber-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-amber-theme .mat-flat-button.mat-primary, .bracelit-amber-theme .mat-raised-button.mat-primary, .bracelit-amber-theme .mat-fab.mat-primary, .bracelit-amber-theme .mat-mini-fab.mat-primary {
  color: black;
}
.bracelit-amber-theme .mat-flat-button.mat-accent, .bracelit-amber-theme .mat-raised-button.mat-accent, .bracelit-amber-theme .mat-fab.mat-accent, .bracelit-amber-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-flat-button.mat-warn, .bracelit-amber-theme .mat-raised-button.mat-warn, .bracelit-amber-theme .mat-fab.mat-warn, .bracelit-amber-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-amber-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-amber-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-amber-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-amber-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-amber-theme .mat-flat-button.mat-primary, .bracelit-amber-theme .mat-raised-button.mat-primary, .bracelit-amber-theme .mat-fab.mat-primary, .bracelit-amber-theme .mat-mini-fab.mat-primary {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-flat-button.mat-accent, .bracelit-amber-theme .mat-raised-button.mat-accent, .bracelit-amber-theme .mat-fab.mat-accent, .bracelit-amber-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-flat-button.mat-warn, .bracelit-amber-theme .mat-raised-button.mat-warn, .bracelit-amber-theme .mat-fab.mat-warn, .bracelit-amber-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-amber-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-amber-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-amber-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-amber-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-amber-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-amber-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-amber-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-amber-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-amber-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-amber-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-amber-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-amber-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-amber-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-amber-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-amber-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-amber-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-amber-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-amber-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-amber-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-amber-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-amber-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-amber-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-amber-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-amber-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-amber-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-amber-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-amber-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-amber-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-amber-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-amber-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-amber-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-amber-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-amber-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-amber-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-amber-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-amber-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-amber-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-amber-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-amber-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-amber-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-amber-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-amber-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-amber-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-amber-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-amber-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-amber-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #cf9307;
}
.bracelit-amber-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-amber-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-amber-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-amber-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #cf9307;
  color: black;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-amber-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-amber-theme .mat-table {
  background: white;
}
.bracelit-amber-theme .mat-table thead, .bracelit-amber-theme .mat-table tbody, .bracelit-amber-theme .mat-table tfoot,
.bracelit-amber-theme mat-header-row, .bracelit-amber-theme mat-row, .bracelit-amber-theme mat-footer-row,
.bracelit-amber-theme [mat-header-row], .bracelit-amber-theme [mat-row], .bracelit-amber-theme [mat-footer-row],
.bracelit-amber-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-amber-theme mat-row, .bracelit-amber-theme mat-header-row, .bracelit-amber-theme mat-footer-row,
.bracelit-amber-theme th.mat-header-cell, .bracelit-amber-theme td.mat-cell, .bracelit-amber-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-cell, .bracelit-amber-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-datepicker-toggle,
.bracelit-amber-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-amber-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-calendar-table-header,
.bracelit-amber-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-calendar-body-cell-content,
.bracelit-amber-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-amber-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-amber-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-amber-theme .mat-calendar-body-in-range::before {
  background: rgba(207, 147, 7, 0.2);
}
.bracelit-amber-theme .mat-calendar-body-comparison-identical,
.bracelit-amber-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-amber-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-amber-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(207, 147, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-amber-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-amber-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(207, 147, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-amber-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-amber-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-amber-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-amber-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-amber-theme .mat-calendar-body-selected {
  background-color: #cf9307;
  color: black;
}
.bracelit-amber-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(207, 147, 7, 0.4);
}
.bracelit-amber-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.bracelit-amber-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-amber-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(207, 147, 7, 0.3);
}
@media (hover: hover) {
  .bracelit-amber-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(207, 147, 7, 0.3);
  }
}
.bracelit-amber-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-amber-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-amber-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-amber-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-amber-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-amber-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-amber-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-amber-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-amber-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-amber-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-amber-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-datepicker-toggle-active {
  color: #cf9307;
}
.bracelit-amber-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-amber-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-amber-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-amber-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-amber-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-amber-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-amber-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-expansion-panel-header-description,
.bracelit-amber-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-amber-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-amber-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-amber-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-amber-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-amber-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #cf9307;
}
.bracelit-amber-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-amber-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-amber-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-amber-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #cf9307;
}
.bracelit-amber-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-amber-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-amber-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-amber-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-error {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-amber-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-amber-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-amber-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-amber-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-amber-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #cf9307;
}
.bracelit-amber-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-amber-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-amber-theme .mat-icon.mat-primary {
  color: #cf9307;
}
.bracelit-amber-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-amber-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-input-element:disabled,
.bracelit-amber-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-input-element {
  caret-color: #cf9307;
}
.bracelit-amber-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-amber-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-amber-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-amber-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-amber-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-list-option:hover, .bracelit-amber-theme .mat-list-option:focus,
.bracelit-amber-theme .mat-nav-list .mat-list-item:hover,
.bracelit-amber-theme .mat-nav-list .mat-list-item:focus,
.bracelit-amber-theme .mat-action-list .mat-list-item:hover,
.bracelit-amber-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-amber-theme .mat-list-single-selected-option, .bracelit-amber-theme .mat-list-single-selected-option:hover, .bracelit-amber-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-menu-panel {
  background: white;
}
.bracelit-amber-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-menu-item[disabled],
.bracelit-amber-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-amber-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-menu-item .mat-icon-no-color,
.bracelit-amber-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-menu-item:hover:not([disabled]),
.bracelit-amber-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-amber-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-amber-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-amber-theme .mat-paginator {
  background: white;
}
.bracelit-amber-theme .mat-paginator,
.bracelit-amber-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-paginator-decrement,
.bracelit-amber-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-paginator-first,
.bracelit-amber-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-amber-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-amber-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-amber-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-progress-bar-background {
  fill: #efe0bd;
}
.bracelit-amber-theme .mat-progress-bar-buffer {
  background-color: #efe0bd;
}
.bracelit-amber-theme .mat-progress-bar-fill::after {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-amber-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-amber-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-amber-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-amber-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-progress-spinner circle, .bracelit-amber-theme .mat-spinner circle {
  stroke: #cf9307;
}
.bracelit-amber-theme .mat-progress-spinner.mat-accent circle, .bracelit-amber-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-amber-theme .mat-progress-spinner.mat-warn circle, .bracelit-amber-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-amber-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cf9307;
}
.bracelit-amber-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-amber-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-amber-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-amber-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-amber-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-amber-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-amber-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-amber-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-amber-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-amber-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-amber-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-amber-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-amber-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-amber-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-amber-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-select-panel {
  background: white;
}
.bracelit-amber-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #cf9307;
}
.bracelit-amber-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-amber-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-amber-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-amber-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-amber-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-amber-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-amber-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-amber-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(207, 147, 7, 0.54);
}
.bracelit-amber-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-amber-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-amber-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-amber-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-amber-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-amber-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-amber-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: black;
}
.bracelit-amber-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(207, 147, 7, 0.2);
}
.bracelit-amber-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-amber-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-amber-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-amber-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-amber-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-amber-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-amber-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-amber-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-amber-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-amber-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-amber-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-amber-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-amber-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-amber-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-amber-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-amber-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-amber-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-amber-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-amber-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-amber-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-amber-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-amber-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-amber-theme .mat-step-header.cdk-keyboard-focused, .bracelit-amber-theme .mat-step-header.cdk-program-focused, .bracelit-amber-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-amber-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-amber-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-amber-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-amber-theme .mat-step-header .mat-step-label,
.bracelit-amber-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: black;
}
.bracelit-amber-theme .mat-step-header .mat-step-icon-selected,
.bracelit-amber-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-amber-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #cf9307;
  color: black;
}
.bracelit-amber-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-amber-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-amber-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-amber-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-amber-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-amber-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-amber-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-amber-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-amber-theme .mat-stepper-horizontal, .bracelit-amber-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-amber-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-horizontal-stepper-header::before,
.bracelit-amber-theme .mat-horizontal-stepper-header::after,
.bracelit-amber-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-amber-theme .mat-tab-nav-bar,
.bracelit-amber-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-amber-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-amber-theme .mat-tab-label, .bracelit-amber-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-tab-label.mat-tab-disabled, .bracelit-amber-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-amber-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-amber-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(241, 223, 181, 0.3);
}
.bracelit-amber-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-amber-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.bracelit-amber-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-amber-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-amber-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-amber-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-amber-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-amber-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-amber-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(241, 223, 181, 0.3);
}
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #cf9307;
}
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-amber-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-amber-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-amber-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-amber-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-amber-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-amber-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-amber-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-toolbar.mat-primary {
  background: #cf9307;
  color: black;
}
.bracelit-amber-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-amber-theme .mat-toolbar .mat-form-field-underline,
.bracelit-amber-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-amber-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-amber-theme .mat-toolbar .mat-form-field-label,
.bracelit-amber-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-amber-theme .mat-toolbar .mat-select-value,
.bracelit-amber-theme .mat-toolbar .mat-select-arrow,
.bracelit-amber-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-amber-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-amber-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-amber-theme .mat-tree {
  background: white;
}
.bracelit-amber-theme .mat-tree-node,
.bracelit-amber-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-amber-theme .back-container {
  color: #cf9307;
}
.bracelit-amber-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-amber-theme .warn-text {
  color: #e74737;
}
.bracelit-amber-theme .inside-modal-title.primary-colored-title, .bracelit-amber-theme .form-title.primary-colored-title {
  color: #cf9307;
}
.bracelit-amber-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-amber-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-amber-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-amber-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .box-checkbox.active-box-checkbox {
  background-color: #cf9307;
  color: white;
}
.bracelit-amber-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .box-radio.active-box-radio {
  background-color: #cf9307;
  color: white;
}
.bracelit-amber-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .error {
  color: #e74737;
}
.bracelit-amber-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-amber-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-amber-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-amber-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-amber-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-amber-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-amber-theme .ql-snow .ql-fill,
.bracelit-amber-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .ql-snow.ql-toolbar button:hover,
.bracelit-amber-theme .ql-snow .ql-toolbar button:hover,
.bracelit-amber-theme .ql-snow.ql-toolbar button:focus,
.bracelit-amber-theme .ql-snow .ql-toolbar button:focus,
.bracelit-amber-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-amber-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-amber-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-amber-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-amber-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-amber-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-amber-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-amber-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-amber-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-amber-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-amber-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-amber-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-amber-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-amber-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-amber-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #cf9307;
  height: 38px !important;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-amber-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-amber-theme .general-container .label-wrapper label {
  color: #cf9307 !important;
  background-color: white;
}
.bracelit-amber-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-amber-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #cf9307 !important;
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #cf9307;
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #cf9307;
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #cf9307;
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-amber-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-amber-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #cf9307;
}
.bracelit-amber-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-amber-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-amber-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-amber-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-amber-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #cf9307;
}
.bracelit-amber-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-amber-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-amber-theme .move-image-action-container:hover {
  color: #cf9307;
}
.bracelit-amber-theme .info-icon {
  color: #cf9307;
}
.bracelit-amber-theme .email-chip {
  color: #cf9307 !important;
  background-color: rgba(207, 147, 7, 0.15) !important;
}
.bracelit-amber-theme .email-chip .mat-chip-remove {
  color: #cf9307 !important;
  opacity: 1 !important;
}
.bracelit-amber-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .primary-contrast-text-color {
  color: #000000 !important;
}
.bracelit-amber-theme .primary-color {
  color: #cf9307 !important;
}
.bracelit-amber-theme .primary-color-200 {
  color: #e7c983 !important;
}
.bracelit-amber-theme .primary-fill {
  fill: #cf9307 !important;
}
.bracelit-amber-theme .secondary-fill {
  fill: #ffeddb !important;
}
.bracelit-amber-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-amber-theme .primary-border-50 {
  border-color: #f9f2e1 !important;
}
.bracelit-amber-theme .primary-border-100 {
  border-color: #f1dfb5 !important;
}
.bracelit-amber-theme .primary-border-200 {
  border-color: #e7c983 !important;
}
.bracelit-amber-theme .primary-indicator .mat-expansion-indicator:after {
  color: #cf9307;
}
.bracelit-amber-theme .contrast-background {
  background-color: #000000 !important;
}
.bracelit-amber-theme .primary-color-small {
  color: rgba(207, 147, 7, 0.6);
}
.bracelit-amber-theme .primary-background {
  background-color: #cf9307 !important;
}
.bracelit-amber-theme .primary-background-50 {
  background-color: #f9f2e1 !important;
}
.bracelit-amber-theme .primary-background-200 {
  background-color: #e7c983 !important;
}
.bracelit-amber-theme .primary-background-800 {
  background-color: #bd7604 !important;
}
.bracelit-amber-theme .primary-background-800-2 {
  background-color: #bd7604 !important;
  color: white !important;
}
.bracelit-amber-theme .primary-background-opacity-01 {
  background-color: rgba(207, 147, 7, 0.1) !important;
}
.bracelit-amber-theme .secondary-color {
  color: #ffeddb !important;
}
.bracelit-amber-theme .secondary-background {
  background-color: #ffeddb !important;
}
.bracelit-amber-theme .secondary-background-opacity-01 {
  background-color: rgba(255, 237, 219, 0.1) !important;
}
.bracelit-amber-theme .secondary-border {
  border-color: #ffeddb !important;
}
.bracelit-amber-theme .tertiary-color {
  color: #ffba75 !important;
}
.bracelit-amber-theme .tertiary-background {
  background-image: linear-gradient(45deg, #ffeddb, #ffba75) !important;
}
.bracelit-amber-theme .tertiary-border {
  border-color: #ffba75 !important;
}
.bracelit-amber-theme .dark-background {
  background-color: black !important;
}
.bracelit-amber-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-amber-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-amber-theme .primary-soft-background {
  background-color: rgba(207, 147, 7, 0.1) !important;
}
.bracelit-amber-theme .primary-soft-background:hover {
  background-color: rgba(207, 147, 7, 0.15) !important;
}
.bracelit-amber-theme .primary-border {
  border-color: #cf9307 !important;
}
.bracelit-amber-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-amber-theme .hover-primary:hover {
  color: #cf9307 !important;
}
.bracelit-amber-theme .hover-primary-background:hover {
  background-color: #cf9307 !important;
}
.bracelit-amber-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-amber-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-amber-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-amber-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-amber-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-amber-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-amber-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-amber-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-amber-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-amber-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-amber-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-amber-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #cf9307;
}
.bracelit-amber-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-amber-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-amber-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-amber-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-amber-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-amber-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-amber-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .card-table tr:hover .action-icon button {
  color: #cf9307;
}
.bracelit-amber-theme .card-table tr:hover .action-icon:hover {
  color: #cf9307;
}
.bracelit-amber-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-amber-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-amber-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #cf9307;
}
.bracelit-amber-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-amber-theme .custom-standard-chip {
  background-color: rgba(207, 147, 7, 0.1) !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .help-dashboard-card .mat-icon {
  color: #cf9307;
}
.bracelit-amber-theme .help-dashboard-card:hover {
  background-color: #cf9307;
}
.bracelit-amber-theme .help-dashboard-card:hover .mat-icon, .bracelit-amber-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-amber-theme .room-open {
  background-color: #cf9307 !important;
  color: white !important;
}
.bracelit-amber-theme .room-open:hover {
  background-color: #cf9307 !important;
  color: white !important;
}
.bracelit-amber-theme #public-office-page-groups .public-group-button-hover {
  background-color: #cf9307 !important;
  color: white !important;
}
.bracelit-amber-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-amber-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-amber-theme .notification-container:hover .show-tip {
  color: #cf9307;
}
.bracelit-amber-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-amber-theme .notification-container .notification-subject .mat-icon {
  color: #cf9307;
}
.bracelit-amber-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-amber-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-amber-theme .message-container:hover .show-tip {
  color: #cf9307;
}
.bracelit-amber-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-amber-theme .message-container .message-subject .mat-icon {
  color: #cf9307;
}
.bracelit-amber-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-amber-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-amber-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-amber-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-amber-theme .primary-200-outline input {
  caret-color: #e7c983;
  color: #cf9307;
}
.bracelit-amber-theme .primary-200-outline label {
  color: #e7c983;
}
.bracelit-amber-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #e7c983 !important;
}
.bracelit-amber-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #e7c983;
}
.bracelit-amber-theme mat-form-field .primary-outline label {
  color: #cf9307 !important;
}
.bracelit-amber-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #cf9307 !important;
}
.bracelit-amber-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #cf9307 !important;
}
.bracelit-amber-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #e7c983 !important;
}
.bracelit-amber-theme .input-primary input {
  caret-color: #cf9307;
  color: #cf9307;
}
.bracelit-amber-theme .input-primary label {
  color: #cf9307;
}
.bracelit-amber-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #cf9307 !important;
}
.bracelit-amber-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-amber-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #cf9307;
  border-width: 1px;
}
.bracelit-amber-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-amber-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-amber-theme .header-primary-background .mobile-header-container {
  background-color: #cf9307;
}
.bracelit-amber-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #e7c983 !important;
}
.bracelit-amber-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #000000 !important;
}
.bracelit-amber-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #000000 !important;
}
.bracelit-amber-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-amber-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-amber-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-amber-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-amber-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-amber-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-amber-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #e7c983 !important;
}
.bracelit-amber-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffeddb !important;
}
.bracelit-amber-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-amber-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-amber-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ffeddb !important;
}
.bracelit-amber-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ffeddb !important;
}
.bracelit-amber-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ffba75 !important;
}
.bracelit-amber-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ffba75 !important;
}
.bracelit-amber-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #000000 !important;
}
.bracelit-amber-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #cf9307 !important;
}
.bracelit-amber-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #cf9307 !important;
}

.bracelit-orange-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-orange-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-orange-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-orange-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-orange-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d17603;
}
.bracelit-orange-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-orange-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-orange-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-orange-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-orange-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-orange-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #d17603;
}
.bracelit-orange-theme .mat-pseudo-checkbox-checked,
.bracelit-orange-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-orange-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-orange-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-orange-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-orange-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-orange-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-orange-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-orange-theme .mat-app-background, .bracelit-orange-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-orange-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-orange-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-badge {
  position: relative;
}
.bracelit-orange-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-orange-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-orange-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-orange-theme .ng-animate-disabled .mat-badge-content,
.bracelit-orange-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-orange-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-orange-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-orange-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-orange-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-orange-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-orange-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-orange-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-orange-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-orange-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-orange-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-orange-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-orange-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-orange-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-orange-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-orange-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-orange-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-orange-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-orange-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-orange-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-orange-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-orange-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-orange-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-orange-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-orange-theme .mat-badge-content {
  color: black;
  background: #d17603;
}
.cdk-high-contrast-active .bracelit-orange-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-orange-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-orange-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-button, .bracelit-orange-theme .mat-icon-button, .bracelit-orange-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-orange-theme .mat-button.mat-primary, .bracelit-orange-theme .mat-icon-button.mat-primary, .bracelit-orange-theme .mat-stroked-button.mat-primary {
  color: #d17603;
}
.bracelit-orange-theme .mat-button.mat-accent, .bracelit-orange-theme .mat-icon-button.mat-accent, .bracelit-orange-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-orange-theme .mat-button.mat-warn, .bracelit-orange-theme .mat-icon-button.mat-warn, .bracelit-orange-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-orange-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-orange-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-orange-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-orange-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-orange-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-orange-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-orange-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-orange-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-orange-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-orange-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-orange-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-orange-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-orange-theme .mat-button .mat-ripple-element, .bracelit-orange-theme .mat-icon-button .mat-ripple-element, .bracelit-orange-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-orange-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-orange-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-flat-button, .bracelit-orange-theme .mat-raised-button, .bracelit-orange-theme .mat-fab, .bracelit-orange-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-orange-theme .mat-flat-button.mat-primary, .bracelit-orange-theme .mat-raised-button.mat-primary, .bracelit-orange-theme .mat-fab.mat-primary, .bracelit-orange-theme .mat-mini-fab.mat-primary {
  color: black;
}
.bracelit-orange-theme .mat-flat-button.mat-accent, .bracelit-orange-theme .mat-raised-button.mat-accent, .bracelit-orange-theme .mat-fab.mat-accent, .bracelit-orange-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-flat-button.mat-warn, .bracelit-orange-theme .mat-raised-button.mat-warn, .bracelit-orange-theme .mat-fab.mat-warn, .bracelit-orange-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-orange-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-orange-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-orange-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-orange-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-orange-theme .mat-flat-button.mat-primary, .bracelit-orange-theme .mat-raised-button.mat-primary, .bracelit-orange-theme .mat-fab.mat-primary, .bracelit-orange-theme .mat-mini-fab.mat-primary {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-flat-button.mat-accent, .bracelit-orange-theme .mat-raised-button.mat-accent, .bracelit-orange-theme .mat-fab.mat-accent, .bracelit-orange-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-flat-button.mat-warn, .bracelit-orange-theme .mat-raised-button.mat-warn, .bracelit-orange-theme .mat-fab.mat-warn, .bracelit-orange-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-orange-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-orange-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-orange-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-orange-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-orange-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-orange-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-orange-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-orange-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-orange-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-orange-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-orange-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-orange-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-orange-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-orange-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-orange-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-orange-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-orange-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-orange-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-orange-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-orange-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-orange-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-orange-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-orange-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-orange-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-orange-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-orange-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-orange-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-orange-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-orange-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-orange-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-orange-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-orange-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-orange-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-orange-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-orange-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-orange-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-orange-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-orange-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-orange-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-orange-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-orange-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-orange-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-orange-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-orange-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #d17603;
}
.bracelit-orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #d17603;
  color: black;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-orange-theme .mat-table {
  background: white;
}
.bracelit-orange-theme .mat-table thead, .bracelit-orange-theme .mat-table tbody, .bracelit-orange-theme .mat-table tfoot,
.bracelit-orange-theme mat-header-row, .bracelit-orange-theme mat-row, .bracelit-orange-theme mat-footer-row,
.bracelit-orange-theme [mat-header-row], .bracelit-orange-theme [mat-row], .bracelit-orange-theme [mat-footer-row],
.bracelit-orange-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-orange-theme mat-row, .bracelit-orange-theme mat-header-row, .bracelit-orange-theme mat-footer-row,
.bracelit-orange-theme th.mat-header-cell, .bracelit-orange-theme td.mat-cell, .bracelit-orange-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-cell, .bracelit-orange-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-datepicker-toggle,
.bracelit-orange-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-orange-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-calendar-table-header,
.bracelit-orange-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-calendar-body-cell-content,
.bracelit-orange-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-orange-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-orange-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-orange-theme .mat-calendar-body-in-range::before {
  background: rgba(209, 118, 3, 0.2);
}
.bracelit-orange-theme .mat-calendar-body-comparison-identical,
.bracelit-orange-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-orange-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-orange-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(209, 118, 3, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-orange-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-orange-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(209, 118, 3, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-orange-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-orange-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-orange-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-orange-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-orange-theme .mat-calendar-body-selected {
  background-color: #d17603;
  color: black;
}
.bracelit-orange-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(209, 118, 3, 0.4);
}
.bracelit-orange-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.bracelit-orange-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-orange-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(209, 118, 3, 0.3);
}
@media (hover: hover) {
  .bracelit-orange-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(209, 118, 3, 0.3);
  }
}
.bracelit-orange-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-orange-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-orange-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-orange-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-orange-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-orange-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-orange-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-orange-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-orange-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-datepicker-toggle-active {
  color: #d17603;
}
.bracelit-orange-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-orange-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-orange-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-orange-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-orange-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-orange-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-orange-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-expansion-panel-header-description,
.bracelit-orange-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-orange-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-orange-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-orange-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-orange-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-orange-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #d17603;
}
.bracelit-orange-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-orange-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-orange-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-orange-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #d17603;
}
.bracelit-orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-error {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-orange-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-orange-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-orange-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-orange-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #d17603;
}
.bracelit-orange-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-orange-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-orange-theme .mat-icon.mat-primary {
  color: #d17603;
}
.bracelit-orange-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-orange-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-input-element:disabled,
.bracelit-orange-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-input-element {
  caret-color: #d17603;
}
.bracelit-orange-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-orange-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-orange-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-orange-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-orange-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-list-option:hover, .bracelit-orange-theme .mat-list-option:focus,
.bracelit-orange-theme .mat-nav-list .mat-list-item:hover,
.bracelit-orange-theme .mat-nav-list .mat-list-item:focus,
.bracelit-orange-theme .mat-action-list .mat-list-item:hover,
.bracelit-orange-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-orange-theme .mat-list-single-selected-option, .bracelit-orange-theme .mat-list-single-selected-option:hover, .bracelit-orange-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-menu-panel {
  background: white;
}
.bracelit-orange-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-menu-item[disabled],
.bracelit-orange-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-orange-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-menu-item .mat-icon-no-color,
.bracelit-orange-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-menu-item:hover:not([disabled]),
.bracelit-orange-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-orange-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-orange-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-orange-theme .mat-paginator {
  background: white;
}
.bracelit-orange-theme .mat-paginator,
.bracelit-orange-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-paginator-decrement,
.bracelit-orange-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-paginator-first,
.bracelit-orange-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-orange-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-orange-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-orange-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-progress-bar-background {
  fill: #f0d9bc;
}
.bracelit-orange-theme .mat-progress-bar-buffer {
  background-color: #f0d9bc;
}
.bracelit-orange-theme .mat-progress-bar-fill::after {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-progress-spinner circle, .bracelit-orange-theme .mat-spinner circle {
  stroke: #d17603;
}
.bracelit-orange-theme .mat-progress-spinner.mat-accent circle, .bracelit-orange-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-orange-theme .mat-progress-spinner.mat-warn circle, .bracelit-orange-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-orange-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d17603;
}
.bracelit-orange-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-orange-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-orange-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-orange-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-orange-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-orange-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-orange-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-orange-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-orange-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-orange-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-orange-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-orange-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-orange-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-select-panel {
  background: white;
}
.bracelit-orange-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #d17603;
}
.bracelit-orange-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-orange-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-orange-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-orange-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-orange-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-orange-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-orange-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-orange-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(209, 118, 3, 0.54);
}
.bracelit-orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-orange-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-orange-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-orange-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-orange-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-orange-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: black;
}
.bracelit-orange-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(209, 118, 3, 0.2);
}
.bracelit-orange-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-orange-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-orange-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-orange-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-orange-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-orange-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-orange-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-orange-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-orange-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-orange-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-orange-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-orange-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-orange-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-orange-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-orange-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-orange-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-orange-theme .mat-step-header.cdk-keyboard-focused, .bracelit-orange-theme .mat-step-header.cdk-program-focused, .bracelit-orange-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-orange-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-orange-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-orange-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-orange-theme .mat-step-header .mat-step-label,
.bracelit-orange-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: black;
}
.bracelit-orange-theme .mat-step-header .mat-step-icon-selected,
.bracelit-orange-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-orange-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #d17603;
  color: black;
}
.bracelit-orange-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-orange-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-orange-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-orange-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-orange-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-orange-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-orange-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-orange-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-orange-theme .mat-stepper-horizontal, .bracelit-orange-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-orange-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-horizontal-stepper-header::before,
.bracelit-orange-theme .mat-horizontal-stepper-header::after,
.bracelit-orange-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-orange-theme .mat-tab-nav-bar,
.bracelit-orange-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-orange-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-orange-theme .mat-tab-label, .bracelit-orange-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-tab-label.mat-tab-disabled, .bracelit-orange-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-orange-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-orange-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(241, 214, 179, 0.3);
}
.bracelit-orange-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-orange-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.bracelit-orange-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-orange-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-orange-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-orange-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-orange-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-orange-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-orange-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(241, 214, 179, 0.3);
}
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #d17603;
}
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-orange-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-orange-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-orange-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-toolbar.mat-primary {
  background: #d17603;
  color: black;
}
.bracelit-orange-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-orange-theme .mat-toolbar .mat-form-field-underline,
.bracelit-orange-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-orange-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-orange-theme .mat-toolbar .mat-form-field-label,
.bracelit-orange-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-orange-theme .mat-toolbar .mat-select-value,
.bracelit-orange-theme .mat-toolbar .mat-select-arrow,
.bracelit-orange-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-orange-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-orange-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-orange-theme .mat-tree {
  background: white;
}
.bracelit-orange-theme .mat-tree-node,
.bracelit-orange-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-orange-theme .back-container {
  color: #d17603;
}
.bracelit-orange-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-orange-theme .warn-text {
  color: #e74737;
}
.bracelit-orange-theme .inside-modal-title.primary-colored-title, .bracelit-orange-theme .form-title.primary-colored-title {
  color: #d17603;
}
.bracelit-orange-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-orange-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-orange-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-orange-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .box-checkbox.active-box-checkbox {
  background-color: #d17603;
  color: white;
}
.bracelit-orange-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .box-radio.active-box-radio {
  background-color: #d17603;
  color: white;
}
.bracelit-orange-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .error {
  color: #e74737;
}
.bracelit-orange-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-orange-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-orange-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-orange-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-orange-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-orange-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-orange-theme .ql-snow .ql-fill,
.bracelit-orange-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .ql-snow.ql-toolbar button:hover,
.bracelit-orange-theme .ql-snow .ql-toolbar button:hover,
.bracelit-orange-theme .ql-snow.ql-toolbar button:focus,
.bracelit-orange-theme .ql-snow .ql-toolbar button:focus,
.bracelit-orange-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-orange-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-orange-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-orange-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-orange-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-orange-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-orange-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-orange-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-orange-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-orange-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-orange-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-orange-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-orange-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #d17603;
  height: 38px !important;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-orange-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-orange-theme .general-container .label-wrapper label {
  color: #d17603 !important;
  background-color: white;
}
.bracelit-orange-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-orange-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #d17603 !important;
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #d17603;
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #d17603;
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #d17603;
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-orange-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-orange-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #d17603;
}
.bracelit-orange-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-orange-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-orange-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #d17603;
}
.bracelit-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-orange-theme .move-image-action-container:hover {
  color: #d17603;
}
.bracelit-orange-theme .info-icon {
  color: #d17603;
}
.bracelit-orange-theme .email-chip {
  color: #d17603 !important;
  background-color: rgba(209, 118, 3, 0.15) !important;
}
.bracelit-orange-theme .email-chip .mat-chip-remove {
  color: #d17603 !important;
  opacity: 1 !important;
}
.bracelit-orange-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .primary-contrast-text-color {
  color: #000000 !important;
}
.bracelit-orange-theme .primary-color {
  color: #d17603 !important;
}
.bracelit-orange-theme .primary-color-200 {
  color: #e8bb81 !important;
}
.bracelit-orange-theme .primary-fill {
  fill: #d17603 !important;
}
.bracelit-orange-theme .secondary-fill {
  fill: #ffe8de !important;
}
.bracelit-orange-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-orange-theme .primary-border-50 {
  border-color: #f9efe1 !important;
}
.bracelit-orange-theme .primary-border-100 {
  border-color: #f1d6b3 !important;
}
.bracelit-orange-theme .primary-border-200 {
  border-color: #e8bb81 !important;
}
.bracelit-orange-theme .primary-indicator .mat-expansion-indicator:after {
  color: #d17603;
}
.bracelit-orange-theme .contrast-background {
  background-color: #000000 !important;
}
.bracelit-orange-theme .primary-color-small {
  color: rgba(209, 118, 3, 0.6);
}
.bracelit-orange-theme .primary-background {
  background-color: #d17603 !important;
}
.bracelit-orange-theme .primary-background-50 {
  background-color: #f9efe1 !important;
}
.bracelit-orange-theme .primary-background-200 {
  background-color: #e8bb81 !important;
}
.bracelit-orange-theme .primary-background-800 {
  background-color: #c05902 !important;
}
.bracelit-orange-theme .primary-background-800-2 {
  background-color: #c05902 !important;
  color: white !important;
}
.bracelit-orange-theme .primary-background-opacity-01 {
  background-color: rgba(209, 118, 3, 0.1) !important;
}
.bracelit-orange-theme .secondary-color {
  color: #ffe8de !important;
}
.bracelit-orange-theme .secondary-background {
  background-color: #ffe8de !important;
}
.bracelit-orange-theme .secondary-background-opacity-01 {
  background-color: rgba(255, 232, 222, 0.1) !important;
}
.bracelit-orange-theme .secondary-border {
  border-color: #ffe8de !important;
}
.bracelit-orange-theme .tertiary-color {
  color: #ffa278 !important;
}
.bracelit-orange-theme .tertiary-background {
  background-image: linear-gradient(45deg, #ffe8de, #ffa278) !important;
}
.bracelit-orange-theme .tertiary-border {
  border-color: #ffa278 !important;
}
.bracelit-orange-theme .dark-background {
  background-color: black !important;
}
.bracelit-orange-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-orange-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-orange-theme .primary-soft-background {
  background-color: rgba(209, 118, 3, 0.1) !important;
}
.bracelit-orange-theme .primary-soft-background:hover {
  background-color: rgba(209, 118, 3, 0.15) !important;
}
.bracelit-orange-theme .primary-border {
  border-color: #d17603 !important;
}
.bracelit-orange-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-orange-theme .hover-primary:hover {
  color: #d17603 !important;
}
.bracelit-orange-theme .hover-primary-background:hover {
  background-color: #d17603 !important;
}
.bracelit-orange-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-orange-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-orange-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-orange-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-orange-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-orange-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-orange-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-orange-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-orange-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-orange-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-orange-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-orange-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #d17603;
}
.bracelit-orange-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-orange-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-orange-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-orange-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-orange-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-orange-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-orange-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .card-table tr:hover .action-icon button {
  color: #d17603;
}
.bracelit-orange-theme .card-table tr:hover .action-icon:hover {
  color: #d17603;
}
.bracelit-orange-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-orange-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-orange-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #d17603;
}
.bracelit-orange-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-orange-theme .custom-standard-chip {
  background-color: rgba(209, 118, 3, 0.1) !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .help-dashboard-card .mat-icon {
  color: #d17603;
}
.bracelit-orange-theme .help-dashboard-card:hover {
  background-color: #d17603;
}
.bracelit-orange-theme .help-dashboard-card:hover .mat-icon, .bracelit-orange-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-orange-theme .room-open {
  background-color: #d17603 !important;
  color: white !important;
}
.bracelit-orange-theme .room-open:hover {
  background-color: #d17603 !important;
  color: white !important;
}
.bracelit-orange-theme #public-office-page-groups .public-group-button-hover {
  background-color: #d17603 !important;
  color: white !important;
}
.bracelit-orange-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-orange-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-orange-theme .notification-container:hover .show-tip {
  color: #d17603;
}
.bracelit-orange-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-orange-theme .notification-container .notification-subject .mat-icon {
  color: #d17603;
}
.bracelit-orange-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-orange-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-orange-theme .message-container:hover .show-tip {
  color: #d17603;
}
.bracelit-orange-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-orange-theme .message-container .message-subject .mat-icon {
  color: #d17603;
}
.bracelit-orange-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-orange-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-orange-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-orange-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-orange-theme .primary-200-outline input {
  caret-color: #e8bb81;
  color: #d17603;
}
.bracelit-orange-theme .primary-200-outline label {
  color: #e8bb81;
}
.bracelit-orange-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #e8bb81 !important;
}
.bracelit-orange-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #e8bb81;
}
.bracelit-orange-theme mat-form-field .primary-outline label {
  color: #d17603 !important;
}
.bracelit-orange-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #d17603 !important;
}
.bracelit-orange-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #d17603 !important;
}
.bracelit-orange-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #e8bb81 !important;
}
.bracelit-orange-theme .input-primary input {
  caret-color: #d17603;
  color: #d17603;
}
.bracelit-orange-theme .input-primary label {
  color: #d17603;
}
.bracelit-orange-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #d17603 !important;
}
.bracelit-orange-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-orange-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #d17603;
  border-width: 1px;
}
.bracelit-orange-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-orange-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-orange-theme .header-primary-background .mobile-header-container {
  background-color: #d17603;
}
.bracelit-orange-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #e8bb81 !important;
}
.bracelit-orange-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #000000 !important;
}
.bracelit-orange-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #000000 !important;
}
.bracelit-orange-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-orange-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-orange-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-orange-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-orange-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-orange-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #000000 !important;
}
.bracelit-orange-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #e8bb81 !important;
}
.bracelit-orange-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffe8de !important;
}
.bracelit-orange-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-orange-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-orange-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ffe8de !important;
}
.bracelit-orange-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ffe8de !important;
}
.bracelit-orange-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ffa278 !important;
}
.bracelit-orange-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ffa278 !important;
}
.bracelit-orange-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #000000 !important;
}
.bracelit-orange-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #d17603 !important;
}
.bracelit-orange-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #d17603 !important;
}

.bracelit-deep-orange-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-orange-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-deep-orange-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-orange-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-orange-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #bc3308;
}
.bracelit-deep-orange-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-deep-orange-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-deep-orange-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-deep-orange-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #bc3308;
}
.bracelit-deep-orange-theme .mat-pseudo-checkbox-checked,
.bracelit-deep-orange-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-deep-orange-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-deep-orange-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-deep-orange-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-deep-orange-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-deep-orange-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-deep-orange-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-deep-orange-theme .mat-app-background, .bracelit-deep-orange-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-deep-orange-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-deep-orange-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-badge {
  position: relative;
}
.bracelit-deep-orange-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-deep-orange-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-deep-orange-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-deep-orange-theme .ng-animate-disabled .mat-badge-content,
.bracelit-deep-orange-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-deep-orange-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-deep-orange-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-deep-orange-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-deep-orange-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-deep-orange-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-deep-orange-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-deep-orange-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-deep-orange-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-deep-orange-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-deep-orange-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-deep-orange-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-deep-orange-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-deep-orange-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-deep-orange-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-deep-orange-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-deep-orange-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-deep-orange-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-deep-orange-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-deep-orange-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-deep-orange-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-deep-orange-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-deep-orange-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-deep-orange-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-deep-orange-theme .mat-badge-content {
  color: white;
  background: #bc3308;
}
.cdk-high-contrast-active .bracelit-deep-orange-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-deep-orange-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-deep-orange-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-button, .bracelit-deep-orange-theme .mat-icon-button, .bracelit-deep-orange-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-deep-orange-theme .mat-button.mat-primary, .bracelit-deep-orange-theme .mat-icon-button.mat-primary, .bracelit-deep-orange-theme .mat-stroked-button.mat-primary {
  color: #bc3308;
}
.bracelit-deep-orange-theme .mat-button.mat-accent, .bracelit-deep-orange-theme .mat-icon-button.mat-accent, .bracelit-deep-orange-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-button.mat-warn, .bracelit-deep-orange-theme .mat-icon-button.mat-warn, .bracelit-deep-orange-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-orange-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-orange-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-orange-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-orange-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-orange-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-orange-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-orange-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-orange-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-orange-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-orange-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-orange-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-deep-orange-theme .mat-button .mat-ripple-element, .bracelit-deep-orange-theme .mat-icon-button .mat-ripple-element, .bracelit-deep-orange-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-deep-orange-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-deep-orange-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-flat-button, .bracelit-deep-orange-theme .mat-raised-button, .bracelit-deep-orange-theme .mat-fab, .bracelit-deep-orange-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-deep-orange-theme .mat-flat-button.mat-primary, .bracelit-deep-orange-theme .mat-raised-button.mat-primary, .bracelit-deep-orange-theme .mat-fab.mat-primary, .bracelit-deep-orange-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-deep-orange-theme .mat-flat-button.mat-accent, .bracelit-deep-orange-theme .mat-raised-button.mat-accent, .bracelit-deep-orange-theme .mat-fab.mat-accent, .bracelit-deep-orange-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-flat-button.mat-warn, .bracelit-deep-orange-theme .mat-raised-button.mat-warn, .bracelit-deep-orange-theme .mat-fab.mat-warn, .bracelit-deep-orange-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-deep-orange-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-orange-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-orange-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-orange-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-orange-theme .mat-flat-button.mat-primary, .bracelit-deep-orange-theme .mat-raised-button.mat-primary, .bracelit-deep-orange-theme .mat-fab.mat-primary, .bracelit-deep-orange-theme .mat-mini-fab.mat-primary {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-flat-button.mat-accent, .bracelit-deep-orange-theme .mat-raised-button.mat-accent, .bracelit-deep-orange-theme .mat-fab.mat-accent, .bracelit-deep-orange-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-flat-button.mat-warn, .bracelit-deep-orange-theme .mat-raised-button.mat-warn, .bracelit-deep-orange-theme .mat-fab.mat-warn, .bracelit-deep-orange-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-orange-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-orange-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-orange-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-orange-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-orange-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-orange-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-deep-orange-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-deep-orange-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-deep-orange-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-orange-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-deep-orange-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-deep-orange-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-deep-orange-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-orange-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-deep-orange-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-deep-orange-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-deep-orange-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-orange-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-deep-orange-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-deep-orange-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-deep-orange-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-deep-orange-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-deep-orange-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-deep-orange-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-deep-orange-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-deep-orange-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-deep-orange-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-deep-orange-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-deep-orange-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-deep-orange-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-deep-orange-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-deep-orange-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-deep-orange-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-deep-orange-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-deep-orange-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-deep-orange-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-deep-orange-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-deep-orange-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-deep-orange-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-deep-orange-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-deep-orange-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-deep-orange-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-deep-orange-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-deep-orange-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-deep-orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #bc3308;
}
.bracelit-deep-orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-deep-orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-deep-orange-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-deep-orange-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #bc3308;
  color: white;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-orange-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-orange-theme .mat-table {
  background: white;
}
.bracelit-deep-orange-theme .mat-table thead, .bracelit-deep-orange-theme .mat-table tbody, .bracelit-deep-orange-theme .mat-table tfoot,
.bracelit-deep-orange-theme mat-header-row, .bracelit-deep-orange-theme mat-row, .bracelit-deep-orange-theme mat-footer-row,
.bracelit-deep-orange-theme [mat-header-row], .bracelit-deep-orange-theme [mat-row], .bracelit-deep-orange-theme [mat-footer-row],
.bracelit-deep-orange-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-deep-orange-theme mat-row, .bracelit-deep-orange-theme mat-header-row, .bracelit-deep-orange-theme mat-footer-row,
.bracelit-deep-orange-theme th.mat-header-cell, .bracelit-deep-orange-theme td.mat-cell, .bracelit-deep-orange-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-cell, .bracelit-deep-orange-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-datepicker-toggle,
.bracelit-deep-orange-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-deep-orange-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-calendar-table-header,
.bracelit-deep-orange-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-calendar-body-cell-content,
.bracelit-deep-orange-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-deep-orange-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-deep-orange-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-deep-orange-theme .mat-calendar-body-in-range::before {
  background: rgba(188, 51, 8, 0.2);
}
.bracelit-deep-orange-theme .mat-calendar-body-comparison-identical,
.bracelit-deep-orange-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-orange-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-orange-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(188, 51, 8, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-orange-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-orange-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(188, 51, 8, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-orange-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-orange-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-orange-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-orange-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-orange-theme .mat-calendar-body-selected {
  background-color: #bc3308;
  color: white;
}
.bracelit-deep-orange-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(188, 51, 8, 0.4);
}
.bracelit-deep-orange-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-orange-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-orange-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(188, 51, 8, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-orange-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(188, 51, 8, 0.3);
  }
}
.bracelit-deep-orange-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-orange-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-orange-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-deep-orange-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-datepicker-toggle-active {
  color: #bc3308;
}
.bracelit-deep-orange-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-deep-orange-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-deep-orange-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-deep-orange-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-deep-orange-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-expansion-panel-header-description,
.bracelit-deep-orange-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-orange-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-deep-orange-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-deep-orange-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-orange-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #bc3308;
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #bc3308;
}
.bracelit-deep-orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-deep-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-deep-orange-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-error {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-orange-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-orange-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-deep-orange-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #bc3308;
}
.bracelit-deep-orange-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-orange-theme .mat-icon.mat-primary {
  color: #bc3308;
}
.bracelit-deep-orange-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-input-element:disabled,
.bracelit-deep-orange-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-input-element {
  caret-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-deep-orange-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-list-option:hover, .bracelit-deep-orange-theme .mat-list-option:focus,
.bracelit-deep-orange-theme .mat-nav-list .mat-list-item:hover,
.bracelit-deep-orange-theme .mat-nav-list .mat-list-item:focus,
.bracelit-deep-orange-theme .mat-action-list .mat-list-item:hover,
.bracelit-deep-orange-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-orange-theme .mat-list-single-selected-option, .bracelit-deep-orange-theme .mat-list-single-selected-option:hover, .bracelit-deep-orange-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-menu-panel {
  background: white;
}
.bracelit-deep-orange-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-menu-item[disabled],
.bracelit-deep-orange-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-deep-orange-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-menu-item .mat-icon-no-color,
.bracelit-deep-orange-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-menu-item:hover:not([disabled]),
.bracelit-deep-orange-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-deep-orange-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-deep-orange-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-orange-theme .mat-paginator {
  background: white;
}
.bracelit-deep-orange-theme .mat-paginator,
.bracelit-deep-orange-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-paginator-decrement,
.bracelit-deep-orange-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-paginator-first,
.bracelit-deep-orange-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-deep-orange-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-deep-orange-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-deep-orange-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-progress-bar-background {
  fill: #ebc8be;
}
.bracelit-deep-orange-theme .mat-progress-bar-buffer {
  background-color: #ebc8be;
}
.bracelit-deep-orange-theme .mat-progress-bar-fill::after {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-deep-orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-deep-orange-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-deep-orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-deep-orange-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-progress-spinner circle, .bracelit-deep-orange-theme .mat-spinner circle {
  stroke: #bc3308;
}
.bracelit-deep-orange-theme .mat-progress-spinner.mat-accent circle, .bracelit-deep-orange-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-deep-orange-theme .mat-progress-spinner.mat-warn circle, .bracelit-deep-orange-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-deep-orange-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-deep-orange-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-orange-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-orange-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-deep-orange-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-orange-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-orange-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-deep-orange-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-deep-orange-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-orange-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-orange-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-deep-orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-deep-orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-orange-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-select-panel {
  background: white;
}
.bracelit-deep-orange-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #bc3308;
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-deep-orange-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-deep-orange-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-orange-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-orange-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(188, 51, 8, 0.54);
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-deep-orange-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-orange-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-deep-orange-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-orange-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-deep-orange-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-deep-orange-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-orange-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(188, 51, 8, 0.2);
}
.bracelit-deep-orange-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-deep-orange-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-deep-orange-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-orange-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-deep-orange-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-deep-orange-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-orange-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-orange-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-deep-orange-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-deep-orange-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-deep-orange-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-orange-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-orange-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-deep-orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-deep-orange-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-deep-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-deep-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-deep-orange-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-orange-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-deep-orange-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-orange-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-orange-theme .mat-step-header.cdk-keyboard-focused, .bracelit-deep-orange-theme .mat-step-header.cdk-program-focused, .bracelit-deep-orange-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-deep-orange-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-orange-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-deep-orange-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-deep-orange-theme .mat-step-header .mat-step-label,
.bracelit-deep-orange-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-deep-orange-theme .mat-step-header .mat-step-icon-selected,
.bracelit-deep-orange-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-deep-orange-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #bc3308;
  color: white;
}
.bracelit-deep-orange-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-deep-orange-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-deep-orange-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-deep-orange-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-deep-orange-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-deep-orange-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-orange-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-deep-orange-theme .mat-stepper-horizontal, .bracelit-deep-orange-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-deep-orange-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-horizontal-stepper-header::before,
.bracelit-deep-orange-theme .mat-horizontal-stepper-header::after,
.bracelit-deep-orange-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-deep-orange-theme .mat-tab-nav-bar,
.bracelit-deep-orange-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-deep-orange-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-deep-orange-theme .mat-tab-label, .bracelit-deep-orange-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-tab-label.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-deep-orange-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(235, 194, 181, 0.3);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(235, 194, 181, 0.3);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-orange-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-orange-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-toolbar.mat-primary {
  background: #bc3308;
  color: white;
}
.bracelit-deep-orange-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-deep-orange-theme .mat-toolbar .mat-form-field-underline,
.bracelit-deep-orange-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-deep-orange-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-deep-orange-theme .mat-toolbar .mat-form-field-label,
.bracelit-deep-orange-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-deep-orange-theme .mat-toolbar .mat-select-value,
.bracelit-deep-orange-theme .mat-toolbar .mat-select-arrow,
.bracelit-deep-orange-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-deep-orange-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-deep-orange-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-deep-orange-theme .mat-tree {
  background: white;
}
.bracelit-deep-orange-theme .mat-tree-node,
.bracelit-deep-orange-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-deep-orange-theme .back-container {
  color: #bc3308;
}
.bracelit-deep-orange-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-deep-orange-theme .warn-text {
  color: #e74737;
}
.bracelit-deep-orange-theme .inside-modal-title.primary-colored-title, .bracelit-deep-orange-theme .form-title.primary-colored-title {
  color: #bc3308;
}
.bracelit-deep-orange-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-deep-orange-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-orange-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-orange-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .box-checkbox.active-box-checkbox {
  background-color: #bc3308;
  color: white;
}
.bracelit-deep-orange-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .box-radio.active-box-radio {
  background-color: #bc3308;
  color: white;
}
.bracelit-deep-orange-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .error {
  color: #e74737;
}
.bracelit-deep-orange-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-deep-orange-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-deep-orange-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-deep-orange-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-deep-orange-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-deep-orange-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-deep-orange-theme .ql-snow .ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:hover,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:hover,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:focus,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:focus,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-deep-orange-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #bc3308;
  height: 38px !important;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-deep-orange-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-deep-orange-theme .general-container .label-wrapper label {
  color: #bc3308 !important;
  background-color: white;
}
.bracelit-deep-orange-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-deep-orange-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #bc3308 !important;
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #bc3308;
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #bc3308;
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #bc3308;
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-orange-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-deep-orange-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #bc3308;
}
.bracelit-deep-orange-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-orange-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-deep-orange-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #bc3308;
}
.bracelit-deep-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-orange-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-orange-theme .move-image-action-container:hover {
  color: #bc3308;
}
.bracelit-deep-orange-theme .info-icon {
  color: #bc3308;
}
.bracelit-deep-orange-theme .email-chip {
  color: #bc3308 !important;
  background-color: rgba(188, 51, 8, 0.15) !important;
}
.bracelit-deep-orange-theme .email-chip .mat-chip-remove {
  color: #bc3308 !important;
  opacity: 1 !important;
}
.bracelit-deep-orange-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-deep-orange-theme .primary-color {
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .primary-color-200 {
  color: #de9984 !important;
}
.bracelit-deep-orange-theme .primary-fill {
  fill: #bc3308 !important;
}
.bracelit-deep-orange-theme .secondary-fill {
  fill: #ffc6c2 !important;
}
.bracelit-deep-orange-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-deep-orange-theme .primary-border-50 {
  border-color: #f7e7e1 !important;
}
.bracelit-deep-orange-theme .primary-border-100 {
  border-color: #ebc2b5 !important;
}
.bracelit-deep-orange-theme .primary-border-200 {
  border-color: #de9984 !important;
}
.bracelit-deep-orange-theme .primary-indicator .mat-expansion-indicator:after {
  color: #bc3308;
}
.bracelit-deep-orange-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-deep-orange-theme .primary-color-small {
  color: rgba(188, 51, 8, 0.6);
}
.bracelit-deep-orange-theme .primary-background {
  background-color: #bc3308 !important;
}
.bracelit-deep-orange-theme .primary-background-50 {
  background-color: #f7e7e1 !important;
}
.bracelit-deep-orange-theme .primary-background-200 {
  background-color: #de9984 !important;
}
.bracelit-deep-orange-theme .primary-background-800 {
  background-color: #a52004 !important;
}
.bracelit-deep-orange-theme .primary-background-800-2 {
  background-color: #a52004 !important;
  color: white !important;
}
.bracelit-deep-orange-theme .primary-background-opacity-01 {
  background-color: rgba(188, 51, 8, 0.1) !important;
}
.bracelit-deep-orange-theme .secondary-color {
  color: #ffc6c2 !important;
}
.bracelit-deep-orange-theme .secondary-background {
  background-color: #ffc6c2 !important;
}
.bracelit-deep-orange-theme .secondary-background-opacity-01 {
  background-color: rgba(255, 198, 194, 0.1) !important;
}
.bracelit-deep-orange-theme .secondary-border {
  border-color: #ffc6c2 !important;
}
.bracelit-deep-orange-theme .tertiary-color {
  color: #ff685c !important;
}
.bracelit-deep-orange-theme .tertiary-background {
  background-image: linear-gradient(45deg, #ffc6c2, #ff685c) !important;
}
.bracelit-deep-orange-theme .tertiary-border {
  border-color: #ff685c !important;
}
.bracelit-deep-orange-theme .dark-background {
  background-color: black !important;
}
.bracelit-deep-orange-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-deep-orange-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-deep-orange-theme .primary-soft-background {
  background-color: rgba(188, 51, 8, 0.1) !important;
}
.bracelit-deep-orange-theme .primary-soft-background:hover {
  background-color: rgba(188, 51, 8, 0.15) !important;
}
.bracelit-deep-orange-theme .primary-border {
  border-color: #bc3308 !important;
}
.bracelit-deep-orange-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-deep-orange-theme .hover-primary:hover {
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .hover-primary-background:hover {
  background-color: #bc3308 !important;
}
.bracelit-deep-orange-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-deep-orange-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-deep-orange-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-deep-orange-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-deep-orange-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-deep-orange-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-deep-orange-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-deep-orange-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-deep-orange-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-orange-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-deep-orange-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-orange-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #bc3308;
}
.bracelit-deep-orange-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-orange-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-deep-orange-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-deep-orange-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-orange-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-deep-orange-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-orange-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .card-table tr:hover .action-icon button {
  color: #bc3308;
}
.bracelit-deep-orange-theme .card-table tr:hover .action-icon:hover {
  color: #bc3308;
}
.bracelit-deep-orange-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-deep-orange-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-orange-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #bc3308;
}
.bracelit-deep-orange-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-deep-orange-theme .custom-standard-chip {
  background-color: rgba(188, 51, 8, 0.1) !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .help-dashboard-card .mat-icon {
  color: #bc3308;
}
.bracelit-deep-orange-theme .help-dashboard-card:hover {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .help-dashboard-card:hover .mat-icon, .bracelit-deep-orange-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-deep-orange-theme .room-open {
  background-color: #bc3308 !important;
  color: white !important;
}
.bracelit-deep-orange-theme .room-open:hover {
  background-color: #bc3308 !important;
  color: white !important;
}
.bracelit-deep-orange-theme #public-office-page-groups .public-group-button-hover {
  background-color: #bc3308 !important;
  color: white !important;
}
.bracelit-deep-orange-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-deep-orange-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-orange-theme .notification-container:hover .show-tip {
  color: #bc3308;
}
.bracelit-deep-orange-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-orange-theme .notification-container .notification-subject .mat-icon {
  color: #bc3308;
}
.bracelit-deep-orange-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-deep-orange-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-orange-theme .message-container:hover .show-tip {
  color: #bc3308;
}
.bracelit-deep-orange-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-orange-theme .message-container .message-subject .mat-icon {
  color: #bc3308;
}
.bracelit-deep-orange-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-orange-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-orange-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-orange-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-deep-orange-theme .primary-200-outline input {
  caret-color: #de9984;
  color: #bc3308;
}
.bracelit-deep-orange-theme .primary-200-outline label {
  color: #de9984;
}
.bracelit-deep-orange-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #de9984 !important;
}
.bracelit-deep-orange-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #de9984;
}
.bracelit-deep-orange-theme mat-form-field .primary-outline label {
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #bc3308 !important;
}
.bracelit-deep-orange-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #de9984 !important;
}
.bracelit-deep-orange-theme .input-primary input {
  caret-color: #bc3308;
  color: #bc3308;
}
.bracelit-deep-orange-theme .input-primary label {
  color: #bc3308;
}
.bracelit-deep-orange-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #bc3308 !important;
}
.bracelit-deep-orange-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-deep-orange-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #bc3308;
  border-width: 1px;
}
.bracelit-deep-orange-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-deep-orange-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-deep-orange-theme .header-primary-background .mobile-header-container {
  background-color: #bc3308;
}
.bracelit-deep-orange-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #de9984 !important;
}
.bracelit-deep-orange-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-orange-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-orange-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-orange-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-orange-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-orange-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-orange-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-orange-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-orange-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #de9984 !important;
}
.bracelit-deep-orange-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffc6c2 !important;
}
.bracelit-deep-orange-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-deep-orange-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-deep-orange-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ffc6c2 !important;
}
.bracelit-deep-orange-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ffc6c2 !important;
}
.bracelit-deep-orange-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ff685c !important;
}
.bracelit-deep-orange-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ff685c !important;
}
.bracelit-deep-orange-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-deep-orange-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #bc3308 !important;
}
.bracelit-deep-orange-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #bc3308 !important;
}

.bracelit-black-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-black-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-black-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-black-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-black-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1e1e1e;
}
.bracelit-black-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-black-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-black-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-black-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-black-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-black-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1e1e1e;
}
.bracelit-black-theme .mat-pseudo-checkbox-checked,
.bracelit-black-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-black-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-black-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-black-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-black-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-black-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-black-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-black-theme .mat-app-background, .bracelit-black-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-black-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-black-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-badge {
  position: relative;
}
.bracelit-black-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-black-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-black-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-black-theme .ng-animate-disabled .mat-badge-content,
.bracelit-black-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-black-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-black-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-black-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-black-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-black-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-black-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-black-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-black-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-black-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-black-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-black-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-black-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-black-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-black-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-black-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-black-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-black-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-black-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-black-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-black-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-black-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-black-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-black-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-black-theme .mat-badge-content {
  color: white;
  background: #1e1e1e;
}
.cdk-high-contrast-active .bracelit-black-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-black-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-black-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-button, .bracelit-black-theme .mat-icon-button, .bracelit-black-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-black-theme .mat-button.mat-primary, .bracelit-black-theme .mat-icon-button.mat-primary, .bracelit-black-theme .mat-stroked-button.mat-primary {
  color: #1e1e1e;
}
.bracelit-black-theme .mat-button.mat-accent, .bracelit-black-theme .mat-icon-button.mat-accent, .bracelit-black-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-black-theme .mat-button.mat-warn, .bracelit-black-theme .mat-icon-button.mat-warn, .bracelit-black-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-black-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-black-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-black-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-black-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-black-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-black-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-black-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-black-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-black-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-black-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-black-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-black-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-black-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-black-theme .mat-button .mat-ripple-element, .bracelit-black-theme .mat-icon-button .mat-ripple-element, .bracelit-black-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-black-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-black-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-flat-button, .bracelit-black-theme .mat-raised-button, .bracelit-black-theme .mat-fab, .bracelit-black-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-black-theme .mat-flat-button.mat-primary, .bracelit-black-theme .mat-raised-button.mat-primary, .bracelit-black-theme .mat-fab.mat-primary, .bracelit-black-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-black-theme .mat-flat-button.mat-accent, .bracelit-black-theme .mat-raised-button.mat-accent, .bracelit-black-theme .mat-fab.mat-accent, .bracelit-black-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-flat-button.mat-warn, .bracelit-black-theme .mat-raised-button.mat-warn, .bracelit-black-theme .mat-fab.mat-warn, .bracelit-black-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-black-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-black-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-black-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-black-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-black-theme .mat-flat-button.mat-primary, .bracelit-black-theme .mat-raised-button.mat-primary, .bracelit-black-theme .mat-fab.mat-primary, .bracelit-black-theme .mat-mini-fab.mat-primary {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-flat-button.mat-accent, .bracelit-black-theme .mat-raised-button.mat-accent, .bracelit-black-theme .mat-fab.mat-accent, .bracelit-black-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-flat-button.mat-warn, .bracelit-black-theme .mat-raised-button.mat-warn, .bracelit-black-theme .mat-fab.mat-warn, .bracelit-black-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-black-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-black-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-black-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-black-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-black-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-black-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-black-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-black-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-black-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-black-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-black-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-black-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-black-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-black-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-black-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-black-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-black-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-black-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-black-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-black-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-black-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-black-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-black-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-black-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-black-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-black-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-black-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-black-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-black-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-black-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-black-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-black-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-black-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-black-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-black-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-black-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-black-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-black-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-black-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-black-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-black-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-black-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-black-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-black-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-black-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-black-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #1e1e1e;
}
.bracelit-black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-black-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-black-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-black-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1e1e1e;
  color: white;
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-black-theme .mat-table {
  background: white;
}
.bracelit-black-theme .mat-table thead, .bracelit-black-theme .mat-table tbody, .bracelit-black-theme .mat-table tfoot,
.bracelit-black-theme mat-header-row, .bracelit-black-theme mat-row, .bracelit-black-theme mat-footer-row,
.bracelit-black-theme [mat-header-row], .bracelit-black-theme [mat-row], .bracelit-black-theme [mat-footer-row],
.bracelit-black-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-black-theme mat-row, .bracelit-black-theme mat-header-row, .bracelit-black-theme mat-footer-row,
.bracelit-black-theme th.mat-header-cell, .bracelit-black-theme td.mat-cell, .bracelit-black-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-cell, .bracelit-black-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-datepicker-toggle,
.bracelit-black-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-black-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-calendar-table-header,
.bracelit-black-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-calendar-body-cell-content,
.bracelit-black-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-black-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-black-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-black-theme .mat-calendar-body-in-range::before {
  background: rgba(30, 30, 30, 0.2);
}
.bracelit-black-theme .mat-calendar-body-comparison-identical,
.bracelit-black-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-black-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-black-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(30, 30, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-black-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-black-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(30, 30, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-black-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-black-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-black-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-black-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-black-theme .mat-calendar-body-selected {
  background-color: #1e1e1e;
  color: white;
}
.bracelit-black-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(30, 30, 30, 0.4);
}
.bracelit-black-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-black-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-black-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 30, 30, 0.3);
}
@media (hover: hover) {
  .bracelit-black-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(30, 30, 30, 0.3);
  }
}
.bracelit-black-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-black-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-black-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-black-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-black-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-black-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-black-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-black-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-black-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-datepicker-toggle-active {
  color: #1e1e1e;
}
.bracelit-black-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-black-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-black-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-black-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-black-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-black-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-black-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-expansion-panel-header-description,
.bracelit-black-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-black-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-black-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-black-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-black-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-black-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #1e1e1e;
}
.bracelit-black-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-black-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-black-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-black-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1e1e1e;
}
.bracelit-black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-black-theme .mat-error {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-black-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-black-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-black-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-black-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1e1e1e;
}
.bracelit-black-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-black-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-black-theme .mat-icon.mat-primary {
  color: #1e1e1e;
}
.bracelit-black-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-black-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-input-element:disabled,
.bracelit-black-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-input-element {
  caret-color: #1e1e1e;
}
.bracelit-black-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-black-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-black-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-black-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-black-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-list-option:hover, .bracelit-black-theme .mat-list-option:focus,
.bracelit-black-theme .mat-nav-list .mat-list-item:hover,
.bracelit-black-theme .mat-nav-list .mat-list-item:focus,
.bracelit-black-theme .mat-action-list .mat-list-item:hover,
.bracelit-black-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-black-theme .mat-list-single-selected-option, .bracelit-black-theme .mat-list-single-selected-option:hover, .bracelit-black-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-menu-panel {
  background: white;
}
.bracelit-black-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-menu-item[disabled],
.bracelit-black-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-black-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-menu-item .mat-icon-no-color,
.bracelit-black-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-menu-item:hover:not([disabled]),
.bracelit-black-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-black-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-black-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-black-theme .mat-paginator {
  background: white;
}
.bracelit-black-theme .mat-paginator,
.bracelit-black-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-paginator-decrement,
.bracelit-black-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-paginator-first,
.bracelit-black-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-black-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-black-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-black-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-progress-bar-background {
  fill: #c3c3c3;
}
.bracelit-black-theme .mat-progress-bar-buffer {
  background-color: #c3c3c3;
}
.bracelit-black-theme .mat-progress-bar-fill::after {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-black-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-black-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-black-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-black-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-black-theme .mat-progress-spinner circle, .bracelit-black-theme .mat-spinner circle {
  stroke: #1e1e1e;
}
.bracelit-black-theme .mat-progress-spinner.mat-accent circle, .bracelit-black-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-black-theme .mat-progress-spinner.mat-warn circle, .bracelit-black-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-black-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1e1e1e;
}
.bracelit-black-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-black-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-black-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-black-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-black-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-black-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-black-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-black-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-black-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-black-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-black-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-black-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-black-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-black-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-black-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-black-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-select-panel {
  background: white;
}
.bracelit-black-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1e1e1e;
}
.bracelit-black-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-black-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-black-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-black-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-black-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-black-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-black-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-black-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(30, 30, 30, 0.54);
}
.bracelit-black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-black-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-black-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-black-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-black-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-black-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-black-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-black-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(30, 30, 30, 0.2);
}
.bracelit-black-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-black-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-black-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-black-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-black-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-black-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-black-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-black-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-black-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-black-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-black-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-black-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-black-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-black-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-black-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-black-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-black-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-black-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-black-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-black-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-black-theme .mat-step-header.cdk-keyboard-focused, .bracelit-black-theme .mat-step-header.cdk-program-focused, .bracelit-black-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-black-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-black-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-black-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-black-theme .mat-step-header .mat-step-label,
.bracelit-black-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-black-theme .mat-step-header .mat-step-icon-selected,
.bracelit-black-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-black-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #1e1e1e;
  color: white;
}
.bracelit-black-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-black-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-black-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-black-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-black-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-black-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-black-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-black-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-black-theme .mat-stepper-horizontal, .bracelit-black-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-black-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-horizontal-stepper-header::before,
.bracelit-black-theme .mat-horizontal-stepper-header::after,
.bracelit-black-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-black-theme .mat-tab-nav-bar,
.bracelit-black-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-black-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-black-theme .mat-tab-label, .bracelit-black-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-tab-label.mat-tab-disabled, .bracelit-black-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-black-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-black-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(188, 188, 188, 0.3);
}
.bracelit-black-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-black-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-black-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-black-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-black-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-black-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-black-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-black-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-black-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-black-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(188, 188, 188, 0.3);
}
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #1e1e1e;
}
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-black-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-black-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-black-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-toolbar.mat-primary {
  background: #1e1e1e;
  color: white;
}
.bracelit-black-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-black-theme .mat-toolbar .mat-form-field-underline,
.bracelit-black-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-black-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-black-theme .mat-toolbar .mat-form-field-label,
.bracelit-black-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-black-theme .mat-toolbar .mat-select-value,
.bracelit-black-theme .mat-toolbar .mat-select-arrow,
.bracelit-black-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-black-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-black-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-black-theme .mat-tree {
  background: white;
}
.bracelit-black-theme .mat-tree-node,
.bracelit-black-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-black-theme .back-container {
  color: #1e1e1e;
}
.bracelit-black-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-black-theme .warn-text {
  color: #e74737;
}
.bracelit-black-theme .inside-modal-title.primary-colored-title, .bracelit-black-theme .form-title.primary-colored-title {
  color: #1e1e1e;
}
.bracelit-black-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-black-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-black-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-black-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .box-checkbox.active-box-checkbox {
  background-color: #1e1e1e;
  color: white;
}
.bracelit-black-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .box-radio.active-box-radio {
  background-color: #1e1e1e;
  color: white;
}
.bracelit-black-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .error {
  color: #e74737;
}
.bracelit-black-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-black-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-black-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-black-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-black-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-black-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-black-theme .ql-snow .ql-fill,
.bracelit-black-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .ql-snow.ql-toolbar button:hover,
.bracelit-black-theme .ql-snow .ql-toolbar button:hover,
.bracelit-black-theme .ql-snow.ql-toolbar button:focus,
.bracelit-black-theme .ql-snow .ql-toolbar button:focus,
.bracelit-black-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-black-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-black-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-black-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-black-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-black-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-black-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-black-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-black-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-black-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-black-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-black-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-black-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-black-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-black-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-black-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-black-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #1e1e1e;
  height: 38px !important;
}
.bracelit-black-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-black-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-black-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-black-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-black-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-black-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-black-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-black-theme .general-container .label-wrapper label {
  color: #1e1e1e !important;
  background-color: white;
}
.bracelit-black-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-black-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #1e1e1e !important;
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #1e1e1e;
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #1e1e1e;
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #1e1e1e;
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-black-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-black-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #1e1e1e;
}
.bracelit-black-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-black-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-black-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-black-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-black-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #1e1e1e;
}
.bracelit-black-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-black-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-black-theme .move-image-action-container:hover {
  color: #1e1e1e;
}
.bracelit-black-theme .info-icon {
  color: #1e1e1e;
}
.bracelit-black-theme .email-chip {
  color: #1e1e1e !important;
  background-color: rgba(30, 30, 30, 0.15) !important;
}
.bracelit-black-theme .email-chip .mat-chip-remove {
  color: #1e1e1e !important;
  opacity: 1 !important;
}
.bracelit-black-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-black-theme .primary-color {
  color: #1e1e1e !important;
}
.bracelit-black-theme .primary-color-200 {
  color: #8f8f8f !important;
}
.bracelit-black-theme .primary-fill {
  fill: #1e1e1e !important;
}
.bracelit-black-theme .secondary-fill {
  fill: #e07171 !important;
}
.bracelit-black-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-black-theme .primary-border-50 {
  border-color: #e4e4e4 !important;
}
.bracelit-black-theme .primary-border-100 {
  border-color: #bcbcbc !important;
}
.bracelit-black-theme .primary-border-200 {
  border-color: #8f8f8f !important;
}
.bracelit-black-theme .primary-indicator .mat-expansion-indicator:after {
  color: #1e1e1e;
}
.bracelit-black-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-black-theme .primary-color-small {
  color: rgba(30, 30, 30, 0.6);
}
.bracelit-black-theme .primary-background {
  background-color: #1e1e1e !important;
}
.bracelit-black-theme .primary-background-50 {
  background-color: #e4e4e4 !important;
}
.bracelit-black-theme .primary-background-200 {
  background-color: #8f8f8f !important;
}
.bracelit-black-theme .primary-background-800 {
  background-color: #121212 !important;
}
.bracelit-black-theme .primary-background-800-2 {
  background-color: #121212 !important;
  color: white !important;
}
.bracelit-black-theme .primary-background-opacity-01 {
  background-color: rgba(30, 30, 30, 0.1) !important;
}
.bracelit-black-theme .secondary-color {
  color: #e07171 !important;
}
.bracelit-black-theme .secondary-background {
  background-color: #e07171 !important;
}
.bracelit-black-theme .secondary-background-opacity-01 {
  background-color: rgba(224, 113, 113, 0.1) !important;
}
.bracelit-black-theme .secondary-border {
  border-color: #e07171 !important;
}
.bracelit-black-theme .tertiary-color {
  color: #eb0000 !important;
}
.bracelit-black-theme .tertiary-background {
  background-image: linear-gradient(45deg, #e07171, #eb0000) !important;
}
.bracelit-black-theme .tertiary-border {
  border-color: #eb0000 !important;
}
.bracelit-black-theme .dark-background {
  background-color: black !important;
}
.bracelit-black-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-black-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-black-theme .primary-soft-background {
  background-color: rgba(30, 30, 30, 0.1) !important;
}
.bracelit-black-theme .primary-soft-background:hover {
  background-color: rgba(30, 30, 30, 0.15) !important;
}
.bracelit-black-theme .primary-border {
  border-color: #1e1e1e !important;
}
.bracelit-black-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-black-theme .hover-primary:hover {
  color: #1e1e1e !important;
}
.bracelit-black-theme .hover-primary-background:hover {
  background-color: #1e1e1e !important;
}
.bracelit-black-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-black-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-black-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-black-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-black-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-black-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-black-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-black-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-black-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-black-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-black-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-black-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #1e1e1e;
}
.bracelit-black-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-black-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-black-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-black-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-black-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-black-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-black-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .card-table tr:hover .action-icon button {
  color: #1e1e1e;
}
.bracelit-black-theme .card-table tr:hover .action-icon:hover {
  color: #1e1e1e;
}
.bracelit-black-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-black-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-black-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #1e1e1e;
}
.bracelit-black-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-black-theme .custom-standard-chip {
  background-color: rgba(30, 30, 30, 0.1) !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .help-dashboard-card .mat-icon {
  color: #1e1e1e;
}
.bracelit-black-theme .help-dashboard-card:hover {
  background-color: #1e1e1e;
}
.bracelit-black-theme .help-dashboard-card:hover .mat-icon, .bracelit-black-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-black-theme .room-open {
  background-color: #1e1e1e !important;
  color: white !important;
}
.bracelit-black-theme .room-open:hover {
  background-color: #1e1e1e !important;
  color: white !important;
}
.bracelit-black-theme #public-office-page-groups .public-group-button-hover {
  background-color: #1e1e1e !important;
  color: white !important;
}
.bracelit-black-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-black-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-black-theme .notification-container:hover .show-tip {
  color: #1e1e1e;
}
.bracelit-black-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-black-theme .notification-container .notification-subject .mat-icon {
  color: #1e1e1e;
}
.bracelit-black-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-black-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-black-theme .message-container:hover .show-tip {
  color: #1e1e1e;
}
.bracelit-black-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-black-theme .message-container .message-subject .mat-icon {
  color: #1e1e1e;
}
.bracelit-black-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-black-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-black-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-black-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-black-theme .primary-200-outline input {
  caret-color: #8f8f8f;
  color: #1e1e1e;
}
.bracelit-black-theme .primary-200-outline label {
  color: #8f8f8f;
}
.bracelit-black-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #8f8f8f !important;
}
.bracelit-black-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #8f8f8f;
}
.bracelit-black-theme mat-form-field .primary-outline label {
  color: #1e1e1e !important;
}
.bracelit-black-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #1e1e1e !important;
}
.bracelit-black-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #1e1e1e !important;
}
.bracelit-black-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #8f8f8f !important;
}
.bracelit-black-theme .input-primary input {
  caret-color: #1e1e1e;
  color: #1e1e1e;
}
.bracelit-black-theme .input-primary label {
  color: #1e1e1e;
}
.bracelit-black-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #1e1e1e !important;
}
.bracelit-black-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-black-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #1e1e1e;
  border-width: 1px;
}
.bracelit-black-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-black-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-black-theme .header-primary-background .mobile-header-container {
  background-color: #1e1e1e;
}
.bracelit-black-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #8f8f8f !important;
}
.bracelit-black-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-black-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-black-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-black-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-black-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-black-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-black-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-black-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-black-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #8f8f8f !important;
}
.bracelit-black-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #e07171 !important;
}
.bracelit-black-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-black-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-black-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #e07171 !important;
}
.bracelit-black-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e07171 !important;
}
.bracelit-black-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #eb0000 !important;
}
.bracelit-black-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #eb0000 !important;
}
.bracelit-black-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-black-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #1e1e1e !important;
}
.bracelit-black-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #1e1e1e !important;
}

.bracelit-deep-grey-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-grey-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-deep-grey-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-grey-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-grey-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-deep-grey-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-deep-grey-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-deep-grey-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-pseudo-checkbox-checked,
.bracelit-deep-grey-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-deep-grey-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-deep-grey-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-deep-grey-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-deep-grey-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-deep-grey-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-deep-grey-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-deep-grey-theme .mat-app-background, .bracelit-deep-grey-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-deep-grey-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-deep-grey-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-badge {
  position: relative;
}
.bracelit-deep-grey-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-deep-grey-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-deep-grey-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-deep-grey-theme .ng-animate-disabled .mat-badge-content,
.bracelit-deep-grey-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-deep-grey-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-deep-grey-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-deep-grey-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-deep-grey-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-deep-grey-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-deep-grey-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-deep-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-deep-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-deep-grey-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-deep-grey-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-deep-grey-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-deep-grey-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-deep-grey-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-deep-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-deep-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-deep-grey-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-deep-grey-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-deep-grey-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-deep-grey-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-deep-grey-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-deep-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-deep-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-deep-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-deep-grey-theme .mat-badge-content {
  color: white;
  background: #4e4e4e;
}
.cdk-high-contrast-active .bracelit-deep-grey-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-deep-grey-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-deep-grey-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-button, .bracelit-deep-grey-theme .mat-icon-button, .bracelit-deep-grey-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-deep-grey-theme .mat-button.mat-primary, .bracelit-deep-grey-theme .mat-icon-button.mat-primary, .bracelit-deep-grey-theme .mat-stroked-button.mat-primary {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-button.mat-accent, .bracelit-deep-grey-theme .mat-icon-button.mat-accent, .bracelit-deep-grey-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-button.mat-warn, .bracelit-deep-grey-theme .mat-icon-button.mat-warn, .bracelit-deep-grey-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-grey-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-grey-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-grey-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-grey-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-deep-grey-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-grey-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-deep-grey-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-grey-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-deep-grey-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-grey-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-deep-grey-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-deep-grey-theme .mat-button .mat-ripple-element, .bracelit-deep-grey-theme .mat-icon-button .mat-ripple-element, .bracelit-deep-grey-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-deep-grey-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-deep-grey-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-flat-button, .bracelit-deep-grey-theme .mat-raised-button, .bracelit-deep-grey-theme .mat-fab, .bracelit-deep-grey-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-deep-grey-theme .mat-flat-button.mat-primary, .bracelit-deep-grey-theme .mat-raised-button.mat-primary, .bracelit-deep-grey-theme .mat-fab.mat-primary, .bracelit-deep-grey-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-deep-grey-theme .mat-flat-button.mat-accent, .bracelit-deep-grey-theme .mat-raised-button.mat-accent, .bracelit-deep-grey-theme .mat-fab.mat-accent, .bracelit-deep-grey-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-flat-button.mat-warn, .bracelit-deep-grey-theme .mat-raised-button.mat-warn, .bracelit-deep-grey-theme .mat-fab.mat-warn, .bracelit-deep-grey-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-deep-grey-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-grey-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-grey-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-grey-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-grey-theme .mat-flat-button.mat-primary, .bracelit-deep-grey-theme .mat-raised-button.mat-primary, .bracelit-deep-grey-theme .mat-fab.mat-primary, .bracelit-deep-grey-theme .mat-mini-fab.mat-primary {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-flat-button.mat-accent, .bracelit-deep-grey-theme .mat-raised-button.mat-accent, .bracelit-deep-grey-theme .mat-fab.mat-accent, .bracelit-deep-grey-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-flat-button.mat-warn, .bracelit-deep-grey-theme .mat-raised-button.mat-warn, .bracelit-deep-grey-theme .mat-fab.mat-warn, .bracelit-deep-grey-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-grey-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-deep-grey-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-deep-grey-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-deep-grey-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-deep-grey-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-deep-grey-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-deep-grey-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-deep-grey-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-deep-grey-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-grey-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-deep-grey-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-deep-grey-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-deep-grey-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-grey-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-deep-grey-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-deep-grey-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-deep-grey-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-grey-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-deep-grey-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-deep-grey-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-deep-grey-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-deep-grey-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-deep-grey-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-deep-grey-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-deep-grey-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-deep-grey-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-deep-grey-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-deep-grey-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-deep-grey-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-deep-grey-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-deep-grey-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-deep-grey-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-deep-grey-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-deep-grey-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-deep-grey-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-deep-grey-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-deep-grey-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-deep-grey-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-deep-grey-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-deep-grey-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-deep-grey-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-deep-grey-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-deep-grey-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-deep-grey-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-deep-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-deep-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-deep-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-deep-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #4e4e4e;
  color: white;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-deep-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-deep-grey-theme .mat-table {
  background: white;
}
.bracelit-deep-grey-theme .mat-table thead, .bracelit-deep-grey-theme .mat-table tbody, .bracelit-deep-grey-theme .mat-table tfoot,
.bracelit-deep-grey-theme mat-header-row, .bracelit-deep-grey-theme mat-row, .bracelit-deep-grey-theme mat-footer-row,
.bracelit-deep-grey-theme [mat-header-row], .bracelit-deep-grey-theme [mat-row], .bracelit-deep-grey-theme [mat-footer-row],
.bracelit-deep-grey-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-deep-grey-theme mat-row, .bracelit-deep-grey-theme mat-header-row, .bracelit-deep-grey-theme mat-footer-row,
.bracelit-deep-grey-theme th.mat-header-cell, .bracelit-deep-grey-theme td.mat-cell, .bracelit-deep-grey-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-cell, .bracelit-deep-grey-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-datepicker-toggle,
.bracelit-deep-grey-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-deep-grey-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-calendar-table-header,
.bracelit-deep-grey-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-calendar-body-cell-content,
.bracelit-deep-grey-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-deep-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-deep-grey-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-deep-grey-theme .mat-calendar-body-in-range::before {
  background: rgba(78, 78, 78, 0.2);
}
.bracelit-deep-grey-theme .mat-calendar-body-comparison-identical,
.bracelit-deep-grey-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-grey-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-grey-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(78, 78, 78, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-grey-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-grey-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(78, 78, 78, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-grey-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-grey-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-grey-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-grey-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-grey-theme .mat-calendar-body-selected {
  background-color: #4e4e4e;
  color: white;
}
.bracelit-deep-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(78, 78, 78, 0.4);
}
.bracelit-deep-grey-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-grey-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-grey-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(78, 78, 78, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-grey-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(78, 78, 78, 0.3);
  }
}
.bracelit-deep-grey-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-deep-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-deep-grey-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-datepicker-toggle-active {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-deep-grey-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-deep-grey-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-deep-grey-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-deep-grey-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-expansion-panel-header-description,
.bracelit-deep-grey-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-grey-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-deep-grey-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-deep-grey-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-grey-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-deep-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-deep-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-error {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-grey-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-deep-grey-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-deep-grey-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-grey-theme .mat-icon.mat-primary {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-input-element:disabled,
.bracelit-deep-grey-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-input-element {
  caret-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-deep-grey-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-list-option:hover, .bracelit-deep-grey-theme .mat-list-option:focus,
.bracelit-deep-grey-theme .mat-nav-list .mat-list-item:hover,
.bracelit-deep-grey-theme .mat-nav-list .mat-list-item:focus,
.bracelit-deep-grey-theme .mat-action-list .mat-list-item:hover,
.bracelit-deep-grey-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-grey-theme .mat-list-single-selected-option, .bracelit-deep-grey-theme .mat-list-single-selected-option:hover, .bracelit-deep-grey-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-menu-panel {
  background: white;
}
.bracelit-deep-grey-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-menu-item[disabled],
.bracelit-deep-grey-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-deep-grey-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-menu-item .mat-icon-no-color,
.bracelit-deep-grey-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-menu-item:hover:not([disabled]),
.bracelit-deep-grey-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-deep-grey-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-deep-grey-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-grey-theme .mat-paginator {
  background: white;
}
.bracelit-deep-grey-theme .mat-paginator,
.bracelit-deep-grey-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-paginator-decrement,
.bracelit-deep-grey-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-paginator-first,
.bracelit-deep-grey-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-deep-grey-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-deep-grey-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-deep-grey-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-progress-bar-background {
  fill: #cfcfcf;
}
.bracelit-deep-grey-theme .mat-progress-bar-buffer {
  background-color: #cfcfcf;
}
.bracelit-deep-grey-theme .mat-progress-bar-fill::after {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-deep-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-deep-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-deep-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-deep-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-progress-spinner circle, .bracelit-deep-grey-theme .mat-spinner circle {
  stroke: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-progress-spinner.mat-accent circle, .bracelit-deep-grey-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-deep-grey-theme .mat-progress-spinner.mat-warn circle, .bracelit-deep-grey-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-deep-grey-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-deep-grey-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-grey-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-grey-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-deep-grey-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-grey-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-grey-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-deep-grey-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-deep-grey-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-deep-grey-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-deep-grey-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-deep-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-deep-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-grey-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-select-panel {
  background: white;
}
.bracelit-deep-grey-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-deep-grey-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-deep-grey-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-grey-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-deep-grey-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(78, 78, 78, 0.54);
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-deep-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-deep-grey-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-deep-grey-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-grey-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-deep-grey-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-deep-grey-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-grey-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(78, 78, 78, 0.2);
}
.bracelit-deep-grey-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-deep-grey-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-deep-grey-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-deep-grey-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-deep-grey-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-deep-grey-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-deep-grey-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-deep-grey-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-deep-grey-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-deep-grey-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-deep-grey-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-grey-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-grey-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-deep-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-deep-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-deep-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-deep-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-deep-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-deep-grey-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-deep-grey-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-grey-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-deep-grey-theme .mat-step-header.cdk-keyboard-focused, .bracelit-deep-grey-theme .mat-step-header.cdk-program-focused, .bracelit-deep-grey-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-deep-grey-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-grey-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-deep-grey-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-deep-grey-theme .mat-step-header .mat-step-label,
.bracelit-deep-grey-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-deep-grey-theme .mat-step-header .mat-step-icon-selected,
.bracelit-deep-grey-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-deep-grey-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #4e4e4e;
  color: white;
}
.bracelit-deep-grey-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-deep-grey-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-deep-grey-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-deep-grey-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-deep-grey-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-deep-grey-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-deep-grey-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-deep-grey-theme .mat-stepper-horizontal, .bracelit-deep-grey-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-deep-grey-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-horizontal-stepper-header::before,
.bracelit-deep-grey-theme .mat-horizontal-stepper-header::after,
.bracelit-deep-grey-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-deep-grey-theme .mat-tab-nav-bar,
.bracelit-deep-grey-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-deep-grey-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-deep-grey-theme .mat-tab-label, .bracelit-deep-grey-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-tab-label.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-deep-grey-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(202, 202, 202, 0.3);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(202, 202, 202, 0.3);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-deep-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-deep-grey-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-toolbar.mat-primary {
  background: #4e4e4e;
  color: white;
}
.bracelit-deep-grey-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-deep-grey-theme .mat-toolbar .mat-form-field-underline,
.bracelit-deep-grey-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-deep-grey-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-deep-grey-theme .mat-toolbar .mat-form-field-label,
.bracelit-deep-grey-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-deep-grey-theme .mat-toolbar .mat-select-value,
.bracelit-deep-grey-theme .mat-toolbar .mat-select-arrow,
.bracelit-deep-grey-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-deep-grey-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-deep-grey-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-deep-grey-theme .mat-tree {
  background: white;
}
.bracelit-deep-grey-theme .mat-tree-node,
.bracelit-deep-grey-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-deep-grey-theme .back-container {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-deep-grey-theme .warn-text {
  color: #e74737;
}
.bracelit-deep-grey-theme .inside-modal-title.primary-colored-title, .bracelit-deep-grey-theme .form-title.primary-colored-title {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-deep-grey-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-grey-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-deep-grey-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .box-checkbox.active-box-checkbox {
  background-color: #4e4e4e;
  color: white;
}
.bracelit-deep-grey-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .box-radio.active-box-radio {
  background-color: #4e4e4e;
  color: white;
}
.bracelit-deep-grey-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .error {
  color: #e74737;
}
.bracelit-deep-grey-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-deep-grey-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-deep-grey-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-deep-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-deep-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-deep-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-deep-grey-theme .ql-snow .ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:hover,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:hover,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:focus,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:focus,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-deep-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #4e4e4e;
  height: 38px !important;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-deep-grey-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-deep-grey-theme .general-container .label-wrapper label {
  color: #4e4e4e !important;
  background-color: white;
}
.bracelit-deep-grey-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-deep-grey-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #4e4e4e;
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-grey-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-deep-grey-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-deep-grey-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-deep-grey-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-deep-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-deep-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #4e4e4e;
}
.bracelit-deep-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-deep-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-deep-grey-theme .move-image-action-container:hover {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .info-icon {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .email-chip {
  color: #4e4e4e !important;
  background-color: rgba(78, 78, 78, 0.15) !important;
}
.bracelit-deep-grey-theme .email-chip .mat-chip-remove {
  color: #4e4e4e !important;
  opacity: 1 !important;
}
.bracelit-deep-grey-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-deep-grey-theme .primary-color {
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .primary-color-200 {
  color: #a7a7a7 !important;
}
.bracelit-deep-grey-theme .primary-fill {
  fill: #4e4e4e !important;
}
.bracelit-deep-grey-theme .secondary-fill {
  fill: #f18282 !important;
}
.bracelit-deep-grey-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-deep-grey-theme .primary-border-50 {
  border-color: #eaeaea !important;
}
.bracelit-deep-grey-theme .primary-border-100 {
  border-color: #cacaca !important;
}
.bracelit-deep-grey-theme .primary-border-200 {
  border-color: #a7a7a7 !important;
}
.bracelit-deep-grey-theme .primary-indicator .mat-expansion-indicator:after {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-deep-grey-theme .primary-color-small {
  color: rgba(78, 78, 78, 0.6);
}
.bracelit-deep-grey-theme .primary-background {
  background-color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .primary-background-50 {
  background-color: #eaeaea !important;
}
.bracelit-deep-grey-theme .primary-background-200 {
  background-color: #a7a7a7 !important;
}
.bracelit-deep-grey-theme .primary-background-800 {
  background-color: #353535 !important;
}
.bracelit-deep-grey-theme .primary-background-800-2 {
  background-color: #353535 !important;
  color: white !important;
}
.bracelit-deep-grey-theme .primary-background-opacity-01 {
  background-color: rgba(78, 78, 78, 0.1) !important;
}
.bracelit-deep-grey-theme .secondary-color {
  color: #f18282 !important;
}
.bracelit-deep-grey-theme .secondary-background {
  background-color: #f18282 !important;
}
.bracelit-deep-grey-theme .secondary-background-opacity-01 {
  background-color: rgba(241, 130, 130, 0.1) !important;
}
.bracelit-deep-grey-theme .secondary-border {
  border-color: #f18282 !important;
}
.bracelit-deep-grey-theme .tertiary-color {
  color: #ff0e0e !important;
}
.bracelit-deep-grey-theme .tertiary-background {
  background-image: linear-gradient(45deg, #f18282, #ff0e0e) !important;
}
.bracelit-deep-grey-theme .tertiary-border {
  border-color: #ff0e0e !important;
}
.bracelit-deep-grey-theme .dark-background {
  background-color: black !important;
}
.bracelit-deep-grey-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-deep-grey-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-deep-grey-theme .primary-soft-background {
  background-color: rgba(78, 78, 78, 0.1) !important;
}
.bracelit-deep-grey-theme .primary-soft-background:hover {
  background-color: rgba(78, 78, 78, 0.15) !important;
}
.bracelit-deep-grey-theme .primary-border {
  border-color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-deep-grey-theme .hover-primary:hover {
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .hover-primary-background:hover {
  background-color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-deep-grey-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-deep-grey-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-deep-grey-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-deep-grey-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-deep-grey-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-deep-grey-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-deep-grey-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-deep-grey-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-grey-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-deep-grey-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-deep-grey-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #4e4e4e;
}
.bracelit-deep-grey-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-grey-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-deep-grey-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-deep-grey-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-deep-grey-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-deep-grey-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-grey-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .card-table tr:hover .action-icon button {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .card-table tr:hover .action-icon:hover {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-deep-grey-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-grey-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-deep-grey-theme .custom-standard-chip {
  background-color: rgba(78, 78, 78, 0.1) !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .help-dashboard-card .mat-icon {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .help-dashboard-card:hover {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .help-dashboard-card:hover .mat-icon, .bracelit-deep-grey-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-deep-grey-theme .room-open {
  background-color: #4e4e4e !important;
  color: white !important;
}
.bracelit-deep-grey-theme .room-open:hover {
  background-color: #4e4e4e !important;
  color: white !important;
}
.bracelit-deep-grey-theme #public-office-page-groups .public-group-button-hover {
  background-color: #4e4e4e !important;
  color: white !important;
}
.bracelit-deep-grey-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-deep-grey-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-grey-theme .notification-container:hover .show-tip {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-grey-theme .notification-container .notification-subject .mat-icon {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-deep-grey-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-deep-grey-theme .message-container:hover .show-tip {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-deep-grey-theme .message-container .message-subject .mat-icon {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-deep-grey-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-deep-grey-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-deep-grey-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-deep-grey-theme .primary-200-outline input {
  caret-color: #a7a7a7;
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .primary-200-outline label {
  color: #a7a7a7;
}
.bracelit-deep-grey-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #a7a7a7 !important;
}
.bracelit-deep-grey-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #a7a7a7;
}
.bracelit-deep-grey-theme mat-form-field .primary-outline label {
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #4e4e4e !important;
}
.bracelit-deep-grey-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #a7a7a7 !important;
}
.bracelit-deep-grey-theme .input-primary input {
  caret-color: #4e4e4e;
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .input-primary label {
  color: #4e4e4e;
}
.bracelit-deep-grey-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-deep-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #4e4e4e;
  border-width: 1px;
}
.bracelit-deep-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-deep-grey-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-deep-grey-theme .header-primary-background .mobile-header-container {
  background-color: #4e4e4e;
}
.bracelit-deep-grey-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #a7a7a7 !important;
}
.bracelit-deep-grey-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-grey-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-deep-grey-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-grey-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-grey-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-grey-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-grey-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-deep-grey-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-deep-grey-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #a7a7a7 !important;
}
.bracelit-deep-grey-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #f18282 !important;
}
.bracelit-deep-grey-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-deep-grey-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-deep-grey-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #f18282 !important;
}
.bracelit-deep-grey-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #f18282 !important;
}
.bracelit-deep-grey-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ff0e0e !important;
}
.bracelit-deep-grey-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ff0e0e !important;
}
.bracelit-deep-grey-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-deep-grey-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #4e4e4e !important;
}
.bracelit-deep-grey-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #4e4e4e !important;
}

.bracelit-blue-grey-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-blue-grey-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-blue-grey-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-grey-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-grey-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #546e7a;
}
.bracelit-blue-grey-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-blue-grey-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-blue-grey-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-blue-grey-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #546e7a;
}
.bracelit-blue-grey-theme .mat-pseudo-checkbox-checked,
.bracelit-blue-grey-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-blue-grey-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-blue-grey-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-blue-grey-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-blue-grey-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-blue-grey-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-blue-grey-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-blue-grey-theme .mat-app-background, .bracelit-blue-grey-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-blue-grey-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-blue-grey-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-badge {
  position: relative;
}
.bracelit-blue-grey-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-blue-grey-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-blue-grey-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-blue-grey-theme .ng-animate-disabled .mat-badge-content,
.bracelit-blue-grey-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-blue-grey-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-blue-grey-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-blue-grey-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-blue-grey-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-blue-grey-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-blue-grey-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-blue-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-blue-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-blue-grey-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-blue-grey-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-blue-grey-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-blue-grey-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-blue-grey-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-blue-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-blue-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-blue-grey-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-blue-grey-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-blue-grey-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-blue-grey-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-blue-grey-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-blue-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-blue-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-blue-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-blue-grey-theme .mat-badge-content {
  color: white;
  background: #546e7a;
}
.cdk-high-contrast-active .bracelit-blue-grey-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-blue-grey-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-blue-grey-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-button, .bracelit-blue-grey-theme .mat-icon-button, .bracelit-blue-grey-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-blue-grey-theme .mat-button.mat-primary, .bracelit-blue-grey-theme .mat-icon-button.mat-primary, .bracelit-blue-grey-theme .mat-stroked-button.mat-primary {
  color: #546e7a;
}
.bracelit-blue-grey-theme .mat-button.mat-accent, .bracelit-blue-grey-theme .mat-icon-button.mat-accent, .bracelit-blue-grey-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-button.mat-warn, .bracelit-blue-grey-theme .mat-icon-button.mat-warn, .bracelit-blue-grey-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-grey-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-grey-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-grey-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-blue-grey-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-blue-grey-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-blue-grey-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-blue-grey-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-blue-grey-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-blue-grey-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-blue-grey-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-blue-grey-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-blue-grey-theme .mat-button .mat-ripple-element, .bracelit-blue-grey-theme .mat-icon-button .mat-ripple-element, .bracelit-blue-grey-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-blue-grey-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-blue-grey-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-flat-button, .bracelit-blue-grey-theme .mat-raised-button, .bracelit-blue-grey-theme .mat-fab, .bracelit-blue-grey-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-blue-grey-theme .mat-flat-button.mat-primary, .bracelit-blue-grey-theme .mat-raised-button.mat-primary, .bracelit-blue-grey-theme .mat-fab.mat-primary, .bracelit-blue-grey-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-blue-grey-theme .mat-flat-button.mat-accent, .bracelit-blue-grey-theme .mat-raised-button.mat-accent, .bracelit-blue-grey-theme .mat-fab.mat-accent, .bracelit-blue-grey-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-flat-button.mat-warn, .bracelit-blue-grey-theme .mat-raised-button.mat-warn, .bracelit-blue-grey-theme .mat-fab.mat-warn, .bracelit-blue-grey-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-blue-grey-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-grey-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-grey-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-blue-grey-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-grey-theme .mat-flat-button.mat-primary, .bracelit-blue-grey-theme .mat-raised-button.mat-primary, .bracelit-blue-grey-theme .mat-fab.mat-primary, .bracelit-blue-grey-theme .mat-mini-fab.mat-primary {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-flat-button.mat-accent, .bracelit-blue-grey-theme .mat-raised-button.mat-accent, .bracelit-blue-grey-theme .mat-fab.mat-accent, .bracelit-blue-grey-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-flat-button.mat-warn, .bracelit-blue-grey-theme .mat-raised-button.mat-warn, .bracelit-blue-grey-theme .mat-fab.mat-warn, .bracelit-blue-grey-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-grey-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-blue-grey-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-blue-grey-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-blue-grey-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-blue-grey-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-blue-grey-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-blue-grey-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-blue-grey-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-blue-grey-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-blue-grey-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-blue-grey-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-blue-grey-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-blue-grey-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-blue-grey-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-blue-grey-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-blue-grey-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-blue-grey-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-blue-grey-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-blue-grey-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-blue-grey-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-blue-grey-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-blue-grey-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-blue-grey-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-blue-grey-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-blue-grey-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-blue-grey-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-blue-grey-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-blue-grey-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-blue-grey-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-blue-grey-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-blue-grey-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-blue-grey-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-blue-grey-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-blue-grey-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-blue-grey-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-blue-grey-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-blue-grey-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-blue-grey-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-blue-grey-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-blue-grey-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-blue-grey-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-blue-grey-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-blue-grey-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-blue-grey-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-blue-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-blue-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #546e7a;
}
.bracelit-blue-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-blue-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-blue-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-blue-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #546e7a;
  color: white;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-blue-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-blue-grey-theme .mat-table {
  background: white;
}
.bracelit-blue-grey-theme .mat-table thead, .bracelit-blue-grey-theme .mat-table tbody, .bracelit-blue-grey-theme .mat-table tfoot,
.bracelit-blue-grey-theme mat-header-row, .bracelit-blue-grey-theme mat-row, .bracelit-blue-grey-theme mat-footer-row,
.bracelit-blue-grey-theme [mat-header-row], .bracelit-blue-grey-theme [mat-row], .bracelit-blue-grey-theme [mat-footer-row],
.bracelit-blue-grey-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-blue-grey-theme mat-row, .bracelit-blue-grey-theme mat-header-row, .bracelit-blue-grey-theme mat-footer-row,
.bracelit-blue-grey-theme th.mat-header-cell, .bracelit-blue-grey-theme td.mat-cell, .bracelit-blue-grey-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-cell, .bracelit-blue-grey-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-datepicker-toggle,
.bracelit-blue-grey-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-blue-grey-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-calendar-table-header,
.bracelit-blue-grey-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-calendar-body-cell-content,
.bracelit-blue-grey-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-blue-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-blue-grey-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-blue-grey-theme .mat-calendar-body-in-range::before {
  background: rgba(84, 110, 122, 0.2);
}
.bracelit-blue-grey-theme .mat-calendar-body-comparison-identical,
.bracelit-blue-grey-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-blue-grey-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-blue-grey-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(84, 110, 122, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-grey-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-blue-grey-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(84, 110, 122, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-grey-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-blue-grey-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-blue-grey-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-blue-grey-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-blue-grey-theme .mat-calendar-body-selected {
  background-color: #546e7a;
  color: white;
}
.bracelit-blue-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(84, 110, 122, 0.4);
}
.bracelit-blue-grey-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-blue-grey-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-blue-grey-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(84, 110, 122, 0.3);
}
@media (hover: hover) {
  .bracelit-blue-grey-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(84, 110, 122, 0.3);
  }
}
.bracelit-blue-grey-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-blue-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-blue-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-blue-grey-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-datepicker-toggle-active {
  color: #546e7a;
}
.bracelit-blue-grey-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-blue-grey-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-blue-grey-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-blue-grey-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-blue-grey-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-expansion-panel-header-description,
.bracelit-blue-grey-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-grey-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-blue-grey-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-blue-grey-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-grey-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #546e7a;
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #546e7a;
}
.bracelit-blue-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-blue-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-blue-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-error {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-blue-grey-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-blue-grey-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-blue-grey-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #546e7a;
}
.bracelit-blue-grey-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-blue-grey-theme .mat-icon.mat-primary {
  color: #546e7a;
}
.bracelit-blue-grey-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-input-element:disabled,
.bracelit-blue-grey-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-input-element {
  caret-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-blue-grey-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-list-option:hover, .bracelit-blue-grey-theme .mat-list-option:focus,
.bracelit-blue-grey-theme .mat-nav-list .mat-list-item:hover,
.bracelit-blue-grey-theme .mat-nav-list .mat-list-item:focus,
.bracelit-blue-grey-theme .mat-action-list .mat-list-item:hover,
.bracelit-blue-grey-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-grey-theme .mat-list-single-selected-option, .bracelit-blue-grey-theme .mat-list-single-selected-option:hover, .bracelit-blue-grey-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-menu-panel {
  background: white;
}
.bracelit-blue-grey-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-menu-item[disabled],
.bracelit-blue-grey-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-blue-grey-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-menu-item .mat-icon-no-color,
.bracelit-blue-grey-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-menu-item:hover:not([disabled]),
.bracelit-blue-grey-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-blue-grey-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-blue-grey-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-grey-theme .mat-paginator {
  background: white;
}
.bracelit-blue-grey-theme .mat-paginator,
.bracelit-blue-grey-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-paginator-decrement,
.bracelit-blue-grey-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-paginator-first,
.bracelit-blue-grey-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-blue-grey-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-blue-grey-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-blue-grey-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-progress-bar-background {
  fill: #d1d7da;
}
.bracelit-blue-grey-theme .mat-progress-bar-buffer {
  background-color: #d1d7da;
}
.bracelit-blue-grey-theme .mat-progress-bar-fill::after {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-blue-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-blue-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-blue-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-blue-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-progress-spinner circle, .bracelit-blue-grey-theme .mat-spinner circle {
  stroke: #546e7a;
}
.bracelit-blue-grey-theme .mat-progress-spinner.mat-accent circle, .bracelit-blue-grey-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-blue-grey-theme .mat-progress-spinner.mat-warn circle, .bracelit-blue-grey-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-blue-grey-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-blue-grey-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-blue-grey-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-blue-grey-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-blue-grey-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-blue-grey-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-blue-grey-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-blue-grey-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-blue-grey-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-blue-grey-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-blue-grey-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-blue-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-blue-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-blue-grey-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-select-panel {
  background: white;
}
.bracelit-blue-grey-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #546e7a;
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-blue-grey-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-blue-grey-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-blue-grey-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-blue-grey-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(84, 110, 122, 0.54);
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-blue-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-blue-grey-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-blue-grey-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-grey-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-blue-grey-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-blue-grey-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-blue-grey-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(84, 110, 122, 0.2);
}
.bracelit-blue-grey-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-blue-grey-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-blue-grey-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-blue-grey-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-blue-grey-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-blue-grey-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-blue-grey-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-blue-grey-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-blue-grey-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-blue-grey-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-blue-grey-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-grey-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-grey-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-blue-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-blue-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-blue-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-blue-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-blue-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-blue-grey-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-blue-grey-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-blue-grey-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-blue-grey-theme .mat-step-header.cdk-keyboard-focused, .bracelit-blue-grey-theme .mat-step-header.cdk-program-focused, .bracelit-blue-grey-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-blue-grey-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-grey-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-blue-grey-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-blue-grey-theme .mat-step-header .mat-step-label,
.bracelit-blue-grey-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-blue-grey-theme .mat-step-header .mat-step-icon-selected,
.bracelit-blue-grey-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-blue-grey-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #546e7a;
  color: white;
}
.bracelit-blue-grey-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-blue-grey-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-blue-grey-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-blue-grey-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-blue-grey-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-blue-grey-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-blue-grey-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-blue-grey-theme .mat-stepper-horizontal, .bracelit-blue-grey-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-blue-grey-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-horizontal-stepper-header::before,
.bracelit-blue-grey-theme .mat-horizontal-stepper-header::after,
.bracelit-blue-grey-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-blue-grey-theme .mat-tab-nav-bar,
.bracelit-blue-grey-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-blue-grey-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-blue-grey-theme .mat-tab-label, .bracelit-blue-grey-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-tab-label.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-blue-grey-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(204, 212, 215, 0.3);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(204, 212, 215, 0.3);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-blue-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-blue-grey-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-toolbar.mat-primary {
  background: #546e7a;
  color: white;
}
.bracelit-blue-grey-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-blue-grey-theme .mat-toolbar .mat-form-field-underline,
.bracelit-blue-grey-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-blue-grey-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-blue-grey-theme .mat-toolbar .mat-form-field-label,
.bracelit-blue-grey-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-blue-grey-theme .mat-toolbar .mat-select-value,
.bracelit-blue-grey-theme .mat-toolbar .mat-select-arrow,
.bracelit-blue-grey-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-blue-grey-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-blue-grey-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-blue-grey-theme .mat-tree {
  background: white;
}
.bracelit-blue-grey-theme .mat-tree-node,
.bracelit-blue-grey-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-blue-grey-theme .back-container {
  color: #546e7a;
}
.bracelit-blue-grey-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-blue-grey-theme .warn-text {
  color: #e74737;
}
.bracelit-blue-grey-theme .inside-modal-title.primary-colored-title, .bracelit-blue-grey-theme .form-title.primary-colored-title {
  color: #546e7a;
}
.bracelit-blue-grey-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-blue-grey-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-grey-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-blue-grey-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .box-checkbox.active-box-checkbox {
  background-color: #546e7a;
  color: white;
}
.bracelit-blue-grey-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .box-radio.active-box-radio {
  background-color: #546e7a;
  color: white;
}
.bracelit-blue-grey-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .error {
  color: #e74737;
}
.bracelit-blue-grey-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-blue-grey-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-blue-grey-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-blue-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-blue-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-blue-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-blue-grey-theme .ql-snow .ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:hover,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:hover,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:focus,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:focus,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-blue-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #546e7a;
  height: 38px !important;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-blue-grey-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-blue-grey-theme .general-container .label-wrapper label {
  color: #546e7a !important;
  background-color: white;
}
.bracelit-blue-grey-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-blue-grey-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #546e7a !important;
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #546e7a;
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #546e7a;
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #546e7a;
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-blue-grey-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-blue-grey-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #546e7a;
}
.bracelit-blue-grey-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-blue-grey-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-blue-grey-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-blue-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-blue-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #546e7a;
}
.bracelit-blue-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-blue-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-blue-grey-theme .move-image-action-container:hover {
  color: #546e7a;
}
.bracelit-blue-grey-theme .info-icon {
  color: #546e7a;
}
.bracelit-blue-grey-theme .email-chip {
  color: #546e7a !important;
  background-color: rgba(84, 110, 122, 0.15) !important;
}
.bracelit-blue-grey-theme .email-chip .mat-chip-remove {
  color: #546e7a !important;
  opacity: 1 !important;
}
.bracelit-blue-grey-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-blue-grey-theme .primary-color {
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .primary-color-200 {
  color: #aab7bd !important;
}
.bracelit-blue-grey-theme .primary-fill {
  fill: #546e7a !important;
}
.bracelit-blue-grey-theme .secondary-fill {
  fill: #95d9ff !important;
}
.bracelit-blue-grey-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-blue-grey-theme .primary-border-50 {
  border-color: #eaeeef !important;
}
.bracelit-blue-grey-theme .primary-border-100 {
  border-color: #ccd4d7 !important;
}
.bracelit-blue-grey-theme .primary-border-200 {
  border-color: #aab7bd !important;
}
.bracelit-blue-grey-theme .primary-indicator .mat-expansion-indicator:after {
  color: #546e7a;
}
.bracelit-blue-grey-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-blue-grey-theme .primary-color-small {
  color: rgba(84, 110, 122, 0.6);
}
.bracelit-blue-grey-theme .primary-background {
  background-color: #546e7a !important;
}
.bracelit-blue-grey-theme .primary-background-50 {
  background-color: #eaeeef !important;
}
.bracelit-blue-grey-theme .primary-background-200 {
  background-color: #aab7bd !important;
}
.bracelit-blue-grey-theme .primary-background-800 {
  background-color: #3a515d !important;
}
.bracelit-blue-grey-theme .primary-background-800-2 {
  background-color: #3a515d !important;
  color: white !important;
}
.bracelit-blue-grey-theme .primary-background-opacity-01 {
  background-color: rgba(84, 110, 122, 0.1) !important;
}
.bracelit-blue-grey-theme .secondary-color {
  color: #95d9ff !important;
}
.bracelit-blue-grey-theme .secondary-background {
  background-color: #95d9ff !important;
}
.bracelit-blue-grey-theme .secondary-background-opacity-01 {
  background-color: rgba(149, 217, 255, 0.1) !important;
}
.bracelit-blue-grey-theme .secondary-border {
  border-color: #95d9ff !important;
}
.bracelit-blue-grey-theme .tertiary-color {
  color: #2fb5ff !important;
}
.bracelit-blue-grey-theme .tertiary-background {
  background-image: linear-gradient(45deg, #95d9ff, #2fb5ff) !important;
}
.bracelit-blue-grey-theme .tertiary-border {
  border-color: #2fb5ff !important;
}
.bracelit-blue-grey-theme .dark-background {
  background-color: black !important;
}
.bracelit-blue-grey-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-blue-grey-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-blue-grey-theme .primary-soft-background {
  background-color: rgba(84, 110, 122, 0.1) !important;
}
.bracelit-blue-grey-theme .primary-soft-background:hover {
  background-color: rgba(84, 110, 122, 0.15) !important;
}
.bracelit-blue-grey-theme .primary-border {
  border-color: #546e7a !important;
}
.bracelit-blue-grey-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-blue-grey-theme .hover-primary:hover {
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .hover-primary-background:hover {
  background-color: #546e7a !important;
}
.bracelit-blue-grey-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-blue-grey-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-blue-grey-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-blue-grey-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-blue-grey-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-blue-grey-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-blue-grey-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-blue-grey-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-blue-grey-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-blue-grey-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-blue-grey-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-blue-grey-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #546e7a;
}
.bracelit-blue-grey-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-grey-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-blue-grey-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-blue-grey-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-blue-grey-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-blue-grey-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-grey-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .card-table tr:hover .action-icon button {
  color: #546e7a;
}
.bracelit-blue-grey-theme .card-table tr:hover .action-icon:hover {
  color: #546e7a;
}
.bracelit-blue-grey-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-blue-grey-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-grey-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #546e7a;
}
.bracelit-blue-grey-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-blue-grey-theme .custom-standard-chip {
  background-color: rgba(84, 110, 122, 0.1) !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .help-dashboard-card .mat-icon {
  color: #546e7a;
}
.bracelit-blue-grey-theme .help-dashboard-card:hover {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .help-dashboard-card:hover .mat-icon, .bracelit-blue-grey-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-blue-grey-theme .room-open {
  background-color: #546e7a !important;
  color: white !important;
}
.bracelit-blue-grey-theme .room-open:hover {
  background-color: #546e7a !important;
  color: white !important;
}
.bracelit-blue-grey-theme #public-office-page-groups .public-group-button-hover {
  background-color: #546e7a !important;
  color: white !important;
}
.bracelit-blue-grey-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-blue-grey-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-grey-theme .notification-container:hover .show-tip {
  color: #546e7a;
}
.bracelit-blue-grey-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-blue-grey-theme .notification-container .notification-subject .mat-icon {
  color: #546e7a;
}
.bracelit-blue-grey-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-blue-grey-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-blue-grey-theme .message-container:hover .show-tip {
  color: #546e7a;
}
.bracelit-blue-grey-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-blue-grey-theme .message-container .message-subject .mat-icon {
  color: #546e7a;
}
.bracelit-blue-grey-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-blue-grey-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-blue-grey-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-blue-grey-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-blue-grey-theme .primary-200-outline input {
  caret-color: #aab7bd;
  color: #546e7a;
}
.bracelit-blue-grey-theme .primary-200-outline label {
  color: #aab7bd;
}
.bracelit-blue-grey-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #aab7bd !important;
}
.bracelit-blue-grey-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #aab7bd;
}
.bracelit-blue-grey-theme mat-form-field .primary-outline label {
  color: #546e7a !important;
}
.bracelit-blue-grey-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #546e7a !important;
}
.bracelit-blue-grey-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #aab7bd !important;
}
.bracelit-blue-grey-theme .input-primary input {
  caret-color: #546e7a;
  color: #546e7a;
}
.bracelit-blue-grey-theme .input-primary label {
  color: #546e7a;
}
.bracelit-blue-grey-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #546e7a !important;
}
.bracelit-blue-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-blue-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #546e7a;
  border-width: 1px;
}
.bracelit-blue-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-blue-grey-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-blue-grey-theme .header-primary-background .mobile-header-container {
  background-color: #546e7a;
}
.bracelit-blue-grey-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #aab7bd !important;
}
.bracelit-blue-grey-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-blue-grey-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-blue-grey-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-blue-grey-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-blue-grey-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-blue-grey-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-blue-grey-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-blue-grey-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-blue-grey-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #aab7bd !important;
}
.bracelit-blue-grey-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #95d9ff !important;
}
.bracelit-blue-grey-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-blue-grey-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-blue-grey-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #95d9ff !important;
}
.bracelit-blue-grey-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #95d9ff !important;
}
.bracelit-blue-grey-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #2fb5ff !important;
}
.bracelit-blue-grey-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #2fb5ff !important;
}
.bracelit-blue-grey-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-blue-grey-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #546e7a !important;
}
.bracelit-blue-grey-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #546e7a !important;
}

.bracelit-grey-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-grey-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-grey-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-grey-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-grey-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #757575;
}
.bracelit-grey-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-grey-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-grey-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-grey-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-grey-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-grey-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #757575;
}
.bracelit-grey-theme .mat-pseudo-checkbox-checked,
.bracelit-grey-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-grey-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-grey-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-grey-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-grey-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-grey-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-grey-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-grey-theme .mat-app-background, .bracelit-grey-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-grey-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-grey-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-badge {
  position: relative;
}
.bracelit-grey-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-grey-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-grey-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-grey-theme .ng-animate-disabled .mat-badge-content,
.bracelit-grey-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-grey-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-grey-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-grey-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-grey-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-grey-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-grey-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-grey-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-grey-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-grey-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-grey-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-grey-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-grey-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-grey-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-grey-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-grey-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-grey-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-grey-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-grey-theme .mat-badge-content {
  color: white;
  background: #757575;
}
.cdk-high-contrast-active .bracelit-grey-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-grey-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-grey-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-button, .bracelit-grey-theme .mat-icon-button, .bracelit-grey-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-grey-theme .mat-button.mat-primary, .bracelit-grey-theme .mat-icon-button.mat-primary, .bracelit-grey-theme .mat-stroked-button.mat-primary {
  color: #757575;
}
.bracelit-grey-theme .mat-button.mat-accent, .bracelit-grey-theme .mat-icon-button.mat-accent, .bracelit-grey-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-grey-theme .mat-button.mat-warn, .bracelit-grey-theme .mat-icon-button.mat-warn, .bracelit-grey-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-grey-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-grey-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-grey-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-grey-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-grey-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-grey-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #757575;
}
.bracelit-grey-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-grey-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-grey-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-grey-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-grey-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-grey-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-grey-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-grey-theme .mat-button .mat-ripple-element, .bracelit-grey-theme .mat-icon-button .mat-ripple-element, .bracelit-grey-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-grey-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-grey-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-flat-button, .bracelit-grey-theme .mat-raised-button, .bracelit-grey-theme .mat-fab, .bracelit-grey-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-grey-theme .mat-flat-button.mat-primary, .bracelit-grey-theme .mat-raised-button.mat-primary, .bracelit-grey-theme .mat-fab.mat-primary, .bracelit-grey-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-grey-theme .mat-flat-button.mat-accent, .bracelit-grey-theme .mat-raised-button.mat-accent, .bracelit-grey-theme .mat-fab.mat-accent, .bracelit-grey-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-flat-button.mat-warn, .bracelit-grey-theme .mat-raised-button.mat-warn, .bracelit-grey-theme .mat-fab.mat-warn, .bracelit-grey-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-grey-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-grey-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-grey-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-grey-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-grey-theme .mat-flat-button.mat-primary, .bracelit-grey-theme .mat-raised-button.mat-primary, .bracelit-grey-theme .mat-fab.mat-primary, .bracelit-grey-theme .mat-mini-fab.mat-primary {
  background-color: #757575;
}
.bracelit-grey-theme .mat-flat-button.mat-accent, .bracelit-grey-theme .mat-raised-button.mat-accent, .bracelit-grey-theme .mat-fab.mat-accent, .bracelit-grey-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-flat-button.mat-warn, .bracelit-grey-theme .mat-raised-button.mat-warn, .bracelit-grey-theme .mat-fab.mat-warn, .bracelit-grey-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-grey-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-grey-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-grey-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-grey-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-grey-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-grey-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-grey-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-grey-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-grey-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-grey-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-grey-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-grey-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-grey-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-grey-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-grey-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-grey-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-grey-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-grey-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-grey-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-grey-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-grey-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-grey-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-grey-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-grey-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-grey-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-grey-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-grey-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-grey-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-grey-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-grey-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-grey-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-grey-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-grey-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-grey-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-grey-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-grey-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-grey-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-grey-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-grey-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #757575;
}
.bracelit-grey-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-grey-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-grey-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-grey-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-grey-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-grey-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #757575;
}
.bracelit-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-grey-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-grey-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #757575;
  color: white;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-grey-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-grey-theme .mat-table {
  background: white;
}
.bracelit-grey-theme .mat-table thead, .bracelit-grey-theme .mat-table tbody, .bracelit-grey-theme .mat-table tfoot,
.bracelit-grey-theme mat-header-row, .bracelit-grey-theme mat-row, .bracelit-grey-theme mat-footer-row,
.bracelit-grey-theme [mat-header-row], .bracelit-grey-theme [mat-row], .bracelit-grey-theme [mat-footer-row],
.bracelit-grey-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-grey-theme mat-row, .bracelit-grey-theme mat-header-row, .bracelit-grey-theme mat-footer-row,
.bracelit-grey-theme th.mat-header-cell, .bracelit-grey-theme td.mat-cell, .bracelit-grey-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-cell, .bracelit-grey-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-datepicker-toggle,
.bracelit-grey-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-grey-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-calendar-table-header,
.bracelit-grey-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-calendar-body-cell-content,
.bracelit-grey-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-grey-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-grey-theme .mat-calendar-body-in-range::before {
  background: rgba(117, 117, 117, 0.2);
}
.bracelit-grey-theme .mat-calendar-body-comparison-identical,
.bracelit-grey-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-grey-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-grey-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(117, 117, 117, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-grey-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-grey-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(117, 117, 117, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-grey-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-grey-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-grey-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-grey-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-grey-theme .mat-calendar-body-selected {
  background-color: #757575;
  color: white;
}
.bracelit-grey-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(117, 117, 117, 0.4);
}
.bracelit-grey-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-grey-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-grey-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(117, 117, 117, 0.3);
}
@media (hover: hover) {
  .bracelit-grey-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(117, 117, 117, 0.3);
  }
}
.bracelit-grey-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-grey-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-grey-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-grey-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-grey-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-grey-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-grey-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-grey-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-grey-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-grey-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-grey-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-datepicker-toggle-active {
  color: #757575;
}
.bracelit-grey-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-grey-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-grey-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-grey-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-grey-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-grey-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-grey-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-expansion-panel-header-description,
.bracelit-grey-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-grey-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-grey-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-grey-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-grey-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-grey-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #757575;
}
.bracelit-grey-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-grey-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-grey-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-grey-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #757575;
}
.bracelit-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #757575;
}
.bracelit-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-grey-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-grey-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-error {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-grey-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-grey-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-grey-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-grey-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #757575;
}
.bracelit-grey-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-grey-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-grey-theme .mat-icon.mat-primary {
  color: #757575;
}
.bracelit-grey-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-grey-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-input-element:disabled,
.bracelit-grey-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-input-element {
  caret-color: #757575;
}
.bracelit-grey-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-grey-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-grey-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-grey-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-grey-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-list-option:hover, .bracelit-grey-theme .mat-list-option:focus,
.bracelit-grey-theme .mat-nav-list .mat-list-item:hover,
.bracelit-grey-theme .mat-nav-list .mat-list-item:focus,
.bracelit-grey-theme .mat-action-list .mat-list-item:hover,
.bracelit-grey-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-grey-theme .mat-list-single-selected-option, .bracelit-grey-theme .mat-list-single-selected-option:hover, .bracelit-grey-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-menu-panel {
  background: white;
}
.bracelit-grey-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-menu-item[disabled],
.bracelit-grey-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-grey-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-menu-item .mat-icon-no-color,
.bracelit-grey-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-menu-item:hover:not([disabled]),
.bracelit-grey-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-grey-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-grey-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-grey-theme .mat-paginator {
  background: white;
}
.bracelit-grey-theme .mat-paginator,
.bracelit-grey-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-paginator-decrement,
.bracelit-grey-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-paginator-first,
.bracelit-grey-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-grey-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-grey-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-grey-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-progress-bar-background {
  fill: #d9d9d9;
}
.bracelit-grey-theme .mat-progress-bar-buffer {
  background-color: #d9d9d9;
}
.bracelit-grey-theme .mat-progress-bar-fill::after {
  background-color: #757575;
}
.bracelit-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-grey-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-grey-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-progress-spinner circle, .bracelit-grey-theme .mat-spinner circle {
  stroke: #757575;
}
.bracelit-grey-theme .mat-progress-spinner.mat-accent circle, .bracelit-grey-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-grey-theme .mat-progress-spinner.mat-warn circle, .bracelit-grey-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-grey-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #757575;
}
.bracelit-grey-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-grey-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-grey-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-grey-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #757575;
}
.bracelit-grey-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-grey-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-grey-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-grey-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-grey-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-grey-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-grey-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-grey-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-grey-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-grey-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-select-panel {
  background: white;
}
.bracelit-grey-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #757575;
}
.bracelit-grey-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-grey-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-grey-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-grey-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-grey-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-grey-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-grey-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-grey-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #757575;
}
.bracelit-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(117, 117, 117, 0.54);
}
.bracelit-grey-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #757575;
}
.bracelit-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-grey-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-grey-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-grey-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-grey-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-grey-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-grey-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #757575;
}
.bracelit-grey-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-grey-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(117, 117, 117, 0.2);
}
.bracelit-grey-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-grey-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-grey-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-grey-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-grey-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-grey-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-grey-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-grey-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-grey-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-grey-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-grey-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-grey-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-grey-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-grey-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-grey-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-grey-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-grey-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-grey-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-grey-theme .mat-step-header.cdk-keyboard-focused, .bracelit-grey-theme .mat-step-header.cdk-program-focused, .bracelit-grey-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-grey-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-grey-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-grey-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-grey-theme .mat-step-header .mat-step-label,
.bracelit-grey-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-grey-theme .mat-step-header .mat-step-icon-selected,
.bracelit-grey-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-grey-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #757575;
  color: white;
}
.bracelit-grey-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-grey-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-grey-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-grey-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-grey-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-grey-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-grey-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-grey-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-grey-theme .mat-stepper-horizontal, .bracelit-grey-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-grey-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-horizontal-stepper-header::before,
.bracelit-grey-theme .mat-horizontal-stepper-header::after,
.bracelit-grey-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-grey-theme .mat-tab-nav-bar,
.bracelit-grey-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-grey-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-grey-theme .mat-tab-label, .bracelit-grey-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-tab-label.mat-tab-disabled, .bracelit-grey-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-grey-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-grey-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(214, 214, 214, 0.3);
}
.bracelit-grey-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #757575;
}
.bracelit-grey-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-grey-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-grey-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-grey-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-grey-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-grey-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-grey-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-grey-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-grey-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(214, 214, 214, 0.3);
}
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #757575;
}
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-grey-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-grey-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-grey-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-grey-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-grey-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-grey-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-grey-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-toolbar.mat-primary {
  background: #757575;
  color: white;
}
.bracelit-grey-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-grey-theme .mat-toolbar .mat-form-field-underline,
.bracelit-grey-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-grey-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-grey-theme .mat-toolbar .mat-form-field-label,
.bracelit-grey-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-grey-theme .mat-toolbar .mat-select-value,
.bracelit-grey-theme .mat-toolbar .mat-select-arrow,
.bracelit-grey-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-grey-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-grey-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-grey-theme .mat-tree {
  background: white;
}
.bracelit-grey-theme .mat-tree-node,
.bracelit-grey-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-grey-theme .back-container {
  color: #757575;
}
.bracelit-grey-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-grey-theme .warn-text {
  color: #e74737;
}
.bracelit-grey-theme .inside-modal-title.primary-colored-title, .bracelit-grey-theme .form-title.primary-colored-title {
  color: #757575;
}
.bracelit-grey-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-grey-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-grey-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-grey-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .box-checkbox.active-box-checkbox {
  background-color: #757575;
  color: white;
}
.bracelit-grey-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .box-radio.active-box-radio {
  background-color: #757575;
  color: white;
}
.bracelit-grey-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .error {
  color: #e74737;
}
.bracelit-grey-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-grey-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-grey-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-grey-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-grey-theme .ql-snow .ql-fill,
.bracelit-grey-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .ql-snow.ql-toolbar button:hover,
.bracelit-grey-theme .ql-snow .ql-toolbar button:hover,
.bracelit-grey-theme .ql-snow.ql-toolbar button:focus,
.bracelit-grey-theme .ql-snow .ql-toolbar button:focus,
.bracelit-grey-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-grey-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-grey-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-grey-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-grey-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-grey-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-grey-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-grey-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-grey-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-grey-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #757575;
  height: 38px !important;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-grey-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-grey-theme .general-container .label-wrapper label {
  color: #757575 !important;
  background-color: white;
}
.bracelit-grey-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-grey-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #757575 !important;
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #757575;
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #757575;
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #757575;
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-grey-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-grey-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #757575;
}
.bracelit-grey-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-grey-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-grey-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #757575;
}
.bracelit-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-grey-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-grey-theme .move-image-action-container:hover {
  color: #757575;
}
.bracelit-grey-theme .info-icon {
  color: #757575;
}
.bracelit-grey-theme .email-chip {
  color: #757575 !important;
  background-color: rgba(117, 117, 117, 0.15) !important;
}
.bracelit-grey-theme .email-chip .mat-chip-remove {
  color: #757575 !important;
  opacity: 1 !important;
}
.bracelit-grey-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-grey-theme .primary-color {
  color: #757575 !important;
}
.bracelit-grey-theme .primary-color-200 {
  color: #bababa !important;
}
.bracelit-grey-theme .primary-fill {
  fill: #757575 !important;
}
.bracelit-grey-theme .secondary-fill {
  fill: #f6afaf !important;
}
.bracelit-grey-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-grey-theme .primary-border-50 {
  border-color: #eeeeee !important;
}
.bracelit-grey-theme .primary-border-100 {
  border-color: #d6d6d6 !important;
}
.bracelit-grey-theme .primary-border-200 {
  border-color: #bababa !important;
}
.bracelit-grey-theme .primary-indicator .mat-expansion-indicator:after {
  color: #757575;
}
.bracelit-grey-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-grey-theme .primary-color-small {
  color: rgba(117, 117, 117, 0.6);
}
.bracelit-grey-theme .primary-background {
  background-color: #757575 !important;
}
.bracelit-grey-theme .primary-background-50 {
  background-color: #eeeeee !important;
}
.bracelit-grey-theme .primary-background-200 {
  background-color: #bababa !important;
}
.bracelit-grey-theme .primary-background-800 {
  background-color: #585858 !important;
}
.bracelit-grey-theme .primary-background-800-2 {
  background-color: #585858 !important;
  color: white !important;
}
.bracelit-grey-theme .primary-background-opacity-01 {
  background-color: rgba(117, 117, 117, 0.1) !important;
}
.bracelit-grey-theme .secondary-color {
  color: #f6afaf !important;
}
.bracelit-grey-theme .secondary-background {
  background-color: #f6afaf !important;
}
.bracelit-grey-theme .secondary-background-opacity-01 {
  background-color: rgba(246, 175, 175, 0.1) !important;
}
.bracelit-grey-theme .secondary-border {
  border-color: #f6afaf !important;
}
.bracelit-grey-theme .tertiary-color {
  color: #ff4141 !important;
}
.bracelit-grey-theme .tertiary-background {
  background-image: linear-gradient(45deg, #f6afaf, #ff4141) !important;
}
.bracelit-grey-theme .tertiary-border {
  border-color: #ff4141 !important;
}
.bracelit-grey-theme .dark-background {
  background-color: black !important;
}
.bracelit-grey-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-grey-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-grey-theme .primary-soft-background {
  background-color: rgba(117, 117, 117, 0.1) !important;
}
.bracelit-grey-theme .primary-soft-background:hover {
  background-color: rgba(117, 117, 117, 0.15) !important;
}
.bracelit-grey-theme .primary-border {
  border-color: #757575 !important;
}
.bracelit-grey-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-grey-theme .hover-primary:hover {
  color: #757575 !important;
}
.bracelit-grey-theme .hover-primary-background:hover {
  background-color: #757575 !important;
}
.bracelit-grey-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-grey-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-grey-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-grey-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-grey-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-grey-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-grey-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-grey-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-grey-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-grey-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-grey-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-grey-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #757575;
}
.bracelit-grey-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-grey-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-grey-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-grey-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-grey-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-grey-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-grey-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .card-table tr:hover .action-icon button {
  color: #757575;
}
.bracelit-grey-theme .card-table tr:hover .action-icon:hover {
  color: #757575;
}
.bracelit-grey-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-grey-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-grey-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #757575;
}
.bracelit-grey-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-grey-theme .custom-standard-chip {
  background-color: rgba(117, 117, 117, 0.1) !important;
  color: #757575 !important;
}
.bracelit-grey-theme .help-dashboard-card .mat-icon {
  color: #757575;
}
.bracelit-grey-theme .help-dashboard-card:hover {
  background-color: #757575;
}
.bracelit-grey-theme .help-dashboard-card:hover .mat-icon, .bracelit-grey-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-grey-theme .room-open {
  background-color: #757575 !important;
  color: white !important;
}
.bracelit-grey-theme .room-open:hover {
  background-color: #757575 !important;
  color: white !important;
}
.bracelit-grey-theme #public-office-page-groups .public-group-button-hover {
  background-color: #757575 !important;
  color: white !important;
}
.bracelit-grey-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-grey-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-grey-theme .notification-container:hover .show-tip {
  color: #757575;
}
.bracelit-grey-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-grey-theme .notification-container .notification-subject .mat-icon {
  color: #757575;
}
.bracelit-grey-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-grey-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-grey-theme .message-container:hover .show-tip {
  color: #757575;
}
.bracelit-grey-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-grey-theme .message-container .message-subject .mat-icon {
  color: #757575;
}
.bracelit-grey-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-grey-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-grey-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-grey-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-grey-theme .primary-200-outline input {
  caret-color: #bababa;
  color: #757575;
}
.bracelit-grey-theme .primary-200-outline label {
  color: #bababa;
}
.bracelit-grey-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #bababa !important;
}
.bracelit-grey-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #bababa;
}
.bracelit-grey-theme mat-form-field .primary-outline label {
  color: #757575 !important;
}
.bracelit-grey-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #757575 !important;
}
.bracelit-grey-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #757575 !important;
}
.bracelit-grey-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #bababa !important;
}
.bracelit-grey-theme .input-primary input {
  caret-color: #757575;
  color: #757575;
}
.bracelit-grey-theme .input-primary label {
  color: #757575;
}
.bracelit-grey-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #757575 !important;
}
.bracelit-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #757575;
  border-width: 1px;
}
.bracelit-grey-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-grey-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-grey-theme .header-primary-background .mobile-header-container {
  background-color: #757575;
}
.bracelit-grey-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #bababa !important;
}
.bracelit-grey-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-grey-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-grey-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-grey-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-grey-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-grey-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-grey-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-grey-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-grey-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #bababa !important;
}
.bracelit-grey-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #f6afaf !important;
}
.bracelit-grey-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-grey-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-grey-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #f6afaf !important;
}
.bracelit-grey-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #f6afaf !important;
}
.bracelit-grey-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ff4141 !important;
}
.bracelit-grey-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ff4141 !important;
}
.bracelit-grey-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-grey-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #757575 !important;
}
.bracelit-grey-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #757575 !important;
}

.bracelit-brown-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-brown-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-option:hover:not(.mat-option-disabled), .bracelit-brown-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-brown-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-brown-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #6d4c41;
}
.bracelit-brown-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cddc39;
}
.bracelit-brown-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74737;
}
.bracelit-brown-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.bracelit-brown-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bracelit-brown-theme .mat-primary .mat-pseudo-checkbox-checked,
.bracelit-brown-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #6d4c41;
}
.bracelit-brown-theme .mat-pseudo-checkbox-checked,
.bracelit-brown-theme .mat-pseudo-checkbox-indeterminate,
.bracelit-brown-theme .mat-accent .mat-pseudo-checkbox-checked,
.bracelit-brown-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #cddc39;
}
.bracelit-brown-theme .mat-warn .mat-pseudo-checkbox-checked,
.bracelit-brown-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74737;
}
.bracelit-brown-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bracelit-brown-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bracelit-brown-theme .mat-app-background, .bracelit-brown-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bracelit-brown-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bracelit-brown-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-badge {
  position: relative;
}
.bracelit-brown-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bracelit-brown-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bracelit-brown-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bracelit-brown-theme .ng-animate-disabled .mat-badge-content,
.bracelit-brown-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bracelit-brown-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bracelit-brown-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bracelit-brown-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bracelit-brown-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bracelit-brown-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bracelit-brown-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bracelit-brown-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bracelit-brown-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bracelit-brown-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bracelit-brown-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bracelit-brown-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bracelit-brown-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bracelit-brown-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bracelit-brown-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bracelit-brown-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bracelit-brown-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bracelit-brown-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bracelit-brown-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bracelit-brown-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bracelit-brown-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bracelit-brown-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bracelit-brown-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bracelit-brown-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bracelit-brown-theme .mat-badge-content {
  color: white;
  background: #6d4c41;
}
.cdk-high-contrast-active .bracelit-brown-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bracelit-brown-theme .mat-badge-accent .mat-badge-content {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e74737;
}
.bracelit-brown-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-button, .bracelit-brown-theme .mat-icon-button, .bracelit-brown-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bracelit-brown-theme .mat-button.mat-primary, .bracelit-brown-theme .mat-icon-button.mat-primary, .bracelit-brown-theme .mat-stroked-button.mat-primary {
  color: #6d4c41;
}
.bracelit-brown-theme .mat-button.mat-accent, .bracelit-brown-theme .mat-icon-button.mat-accent, .bracelit-brown-theme .mat-stroked-button.mat-accent {
  color: #cddc39;
}
.bracelit-brown-theme .mat-button.mat-warn, .bracelit-brown-theme .mat-icon-button.mat-warn, .bracelit-brown-theme .mat-stroked-button.mat-warn {
  color: #e74737;
}
.bracelit-brown-theme .mat-button.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-button.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-button.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-button.mat-button-disabled.mat-button-disabled, .bracelit-brown-theme .mat-icon-button.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-icon-button.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-icon-button.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bracelit-brown-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-brown-theme .mat-button.mat-primary .mat-button-focus-overlay, .bracelit-brown-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bracelit-brown-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-button.mat-accent .mat-button-focus-overlay, .bracelit-brown-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bracelit-brown-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-button.mat-warn .mat-button-focus-overlay, .bracelit-brown-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bracelit-brown-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-brown-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bracelit-brown-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bracelit-brown-theme .mat-button .mat-ripple-element, .bracelit-brown-theme .mat-icon-button .mat-ripple-element, .bracelit-brown-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bracelit-brown-theme .mat-button-focus-overlay {
  background: black;
}
.bracelit-brown-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-flat-button, .bracelit-brown-theme .mat-raised-button, .bracelit-brown-theme .mat-fab, .bracelit-brown-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.bracelit-brown-theme .mat-flat-button.mat-primary, .bracelit-brown-theme .mat-raised-button.mat-primary, .bracelit-brown-theme .mat-fab.mat-primary, .bracelit-brown-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bracelit-brown-theme .mat-flat-button.mat-accent, .bracelit-brown-theme .mat-raised-button.mat-accent, .bracelit-brown-theme .mat-fab.mat-accent, .bracelit-brown-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-flat-button.mat-warn, .bracelit-brown-theme .mat-raised-button.mat-warn, .bracelit-brown-theme .mat-fab.mat-warn, .bracelit-brown-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bracelit-brown-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-brown-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-brown-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-brown-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-brown-theme .mat-flat-button.mat-primary, .bracelit-brown-theme .mat-raised-button.mat-primary, .bracelit-brown-theme .mat-fab.mat-primary, .bracelit-brown-theme .mat-mini-fab.mat-primary {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-flat-button.mat-accent, .bracelit-brown-theme .mat-raised-button.mat-accent, .bracelit-brown-theme .mat-fab.mat-accent, .bracelit-brown-theme .mat-mini-fab.mat-accent {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-flat-button.mat-warn, .bracelit-brown-theme .mat-raised-button.mat-warn, .bracelit-brown-theme .mat-fab.mat-warn, .bracelit-brown-theme .mat-mini-fab.mat-warn {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-flat-button.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-flat-button.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-flat-button.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bracelit-brown-theme .mat-raised-button.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-raised-button.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-raised-button.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bracelit-brown-theme .mat-fab.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-fab.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-fab.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bracelit-brown-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bracelit-brown-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bracelit-brown-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bracelit-brown-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-flat-button.mat-primary .mat-ripple-element, .bracelit-brown-theme .mat-raised-button.mat-primary .mat-ripple-element, .bracelit-brown-theme .mat-fab.mat-primary .mat-ripple-element, .bracelit-brown-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-brown-theme .mat-flat-button.mat-accent .mat-ripple-element, .bracelit-brown-theme .mat-raised-button.mat-accent .mat-ripple-element, .bracelit-brown-theme .mat-fab.mat-accent .mat-ripple-element, .bracelit-brown-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-brown-theme .mat-flat-button.mat-warn .mat-ripple-element, .bracelit-brown-theme .mat-raised-button.mat-warn .mat-ripple-element, .bracelit-brown-theme .mat-fab.mat-warn .mat-ripple-element, .bracelit-brown-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-brown-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bracelit-brown-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-fab:not([class*=mat-elevation-z]), .bracelit-brown-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bracelit-brown-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bracelit-brown-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bracelit-brown-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bracelit-brown-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bracelit-brown-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.bracelit-brown-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.bracelit-brown-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.bracelit-brown-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.bracelit-brown-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.bracelit-brown-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.bracelit-brown-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bracelit-brown-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.bracelit-brown-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bracelit-brown-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.bracelit-brown-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.bracelit-brown-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.bracelit-brown-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.bracelit-brown-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bracelit-brown-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bracelit-brown-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bracelit-brown-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bracelit-brown-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bracelit-brown-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bracelit-brown-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.bracelit-brown-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bracelit-brown-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #6d4c41;
}
.bracelit-brown-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bracelit-brown-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #cddc39;
}
.bracelit-brown-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bracelit-brown-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74737;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #6d4c41;
  color: white;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74737;
  color: white;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.bracelit-brown-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.bracelit-brown-theme .mat-table {
  background: white;
}
.bracelit-brown-theme .mat-table thead, .bracelit-brown-theme .mat-table tbody, .bracelit-brown-theme .mat-table tfoot,
.bracelit-brown-theme mat-header-row, .bracelit-brown-theme mat-row, .bracelit-brown-theme mat-footer-row,
.bracelit-brown-theme [mat-header-row], .bracelit-brown-theme [mat-row], .bracelit-brown-theme [mat-footer-row],
.bracelit-brown-theme .mat-table-sticky {
  background: inherit;
}
.bracelit-brown-theme mat-row, .bracelit-brown-theme mat-header-row, .bracelit-brown-theme mat-footer-row,
.bracelit-brown-theme th.mat-header-cell, .bracelit-brown-theme td.mat-cell, .bracelit-brown-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-cell, .bracelit-brown-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-datepicker-toggle,
.bracelit-brown-theme .mat-datepicker-content .mat-calendar-next-button,
.bracelit-brown-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-calendar-table-header,
.bracelit-brown-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-calendar-body-cell-content,
.bracelit-brown-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.bracelit-brown-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.bracelit-brown-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.bracelit-brown-theme .mat-calendar-body-in-range::before {
  background: rgba(109, 76, 65, 0.2);
}
.bracelit-brown-theme .mat-calendar-body-comparison-identical,
.bracelit-brown-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-brown-theme .mat-calendar-body-comparison-bridge-start::before,
.bracelit-brown-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(109, 76, 65, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-brown-theme .mat-calendar-body-comparison-bridge-end::before,
.bracelit-brown-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(109, 76, 65, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-brown-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-brown-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-brown-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-brown-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-brown-theme .mat-calendar-body-selected {
  background-color: #6d4c41;
  color: white;
}
.bracelit-brown-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(109, 76, 65, 0.4);
}
.bracelit-brown-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-brown-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-brown-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(109, 76, 65, 0.3);
}
@media (hover: hover) {
  .bracelit-brown-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(109, 76, 65, 0.3);
  }
}
.bracelit-brown-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(205, 220, 57, 0.2);
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bracelit-brown-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bracelit-brown-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 220, 57, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 220, 57, 0.4);
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-brown-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 220, 57, 0.3);
}
@media (hover: hover) {
  .bracelit-brown-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 220, 57, 0.3);
  }
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 71, 55, 0.2);
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bracelit-brown-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bracelit-brown-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 71, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74737;
  color: white;
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 71, 55, 0.4);
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bracelit-brown-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bracelit-brown-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 71, 55, 0.3);
}
@media (hover: hover) {
  .bracelit-brown-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 71, 55, 0.3);
  }
}
.bracelit-brown-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-datepicker-toggle-active {
  color: #6d4c41;
}
.bracelit-brown-theme .mat-datepicker-toggle-active.mat-accent {
  color: #cddc39;
}
.bracelit-brown-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e74737;
}
.bracelit-brown-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bracelit-brown-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bracelit-brown-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .bracelit-brown-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bracelit-brown-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-expansion-panel-header-description,
.bracelit-brown-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.bracelit-brown-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bracelit-brown-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bracelit-brown-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-brown-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-brown-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #6d4c41;
}
.bracelit-brown-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #cddc39;
}
.bracelit-brown-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74737;
}
.bracelit-brown-theme .mat-focused .mat-form-field-required-marker {
  color: #cddc39;
}
.bracelit-brown-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #6d4c41;
}
.bracelit-brown-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #cddc39;
}
.bracelit-brown-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bracelit-brown-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bracelit-brown-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-error {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-brown-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bracelit-brown-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-brown-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.bracelit-brown-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bracelit-brown-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #6d4c41;
}
.bracelit-brown-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #cddc39;
}
.bracelit-brown-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.bracelit-brown-theme .mat-icon.mat-primary {
  color: #6d4c41;
}
.bracelit-brown-theme .mat-icon.mat-accent {
  color: #cddc39;
}
.bracelit-brown-theme .mat-icon.mat-warn {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-input-element:disabled,
.bracelit-brown-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-input-element {
  caret-color: #6d4c41;
}
.bracelit-brown-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #cddc39;
}
.bracelit-brown-theme .mat-form-field.mat-warn .mat-input-element,
.bracelit-brown-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e74737;
}
.bracelit-brown-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74737;
}
.bracelit-brown-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-list-option:hover, .bracelit-brown-theme .mat-list-option:focus,
.bracelit-brown-theme .mat-nav-list .mat-list-item:hover,
.bracelit-brown-theme .mat-nav-list .mat-list-item:focus,
.bracelit-brown-theme .mat-action-list .mat-list-item:hover,
.bracelit-brown-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-brown-theme .mat-list-single-selected-option, .bracelit-brown-theme .mat-list-single-selected-option:hover, .bracelit-brown-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-menu-panel {
  background: white;
}
.bracelit-brown-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-menu-item[disabled],
.bracelit-brown-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bracelit-brown-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-menu-item .mat-icon-no-color,
.bracelit-brown-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-menu-item:hover:not([disabled]),
.bracelit-brown-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bracelit-brown-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bracelit-brown-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-brown-theme .mat-paginator {
  background: white;
}
.bracelit-brown-theme .mat-paginator,
.bracelit-brown-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-paginator-decrement,
.bracelit-brown-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-paginator-first,
.bracelit-brown-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bracelit-brown-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bracelit-brown-theme .mat-icon-button[disabled] .mat-paginator-first,
.bracelit-brown-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-progress-bar-background {
  fill: #d7cfcc;
}
.bracelit-brown-theme .mat-progress-bar-buffer {
  background-color: #d7cfcc;
}
.bracelit-brown-theme .mat-progress-bar-fill::after {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #eff3ca;
}
.bracelit-brown-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #eff3ca;
}
.bracelit-brown-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cdc9;
}
.bracelit-brown-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cdc9;
}
.bracelit-brown-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-progress-spinner circle, .bracelit-brown-theme .mat-spinner circle {
  stroke: #6d4c41;
}
.bracelit-brown-theme .mat-progress-spinner.mat-accent circle, .bracelit-brown-theme .mat-spinner.mat-accent circle {
  stroke: #cddc39;
}
.bracelit-brown-theme .mat-progress-spinner.mat-warn circle, .bracelit-brown-theme .mat-spinner.mat-warn circle {
  stroke: #e74737;
}
.bracelit-brown-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #6d4c41;
}
.bracelit-brown-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bracelit-brown-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-brown-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-brown-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cddc39;
}
.bracelit-brown-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bracelit-brown-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-brown-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-brown-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74737;
}
.bracelit-brown-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bracelit-brown-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bracelit-brown-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bracelit-brown-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bracelit-brown-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bracelit-brown-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.bracelit-brown-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-select-panel {
  background: white;
}
.bracelit-brown-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #6d4c41;
}
.bracelit-brown-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #cddc39;
}
.bracelit-brown-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74737;
}
.bracelit-brown-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bracelit-brown-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-brown-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.bracelit-brown-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bracelit-brown-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 220, 57, 0.54);
}
.bracelit-brown-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(109, 76, 65, 0.54);
}
.bracelit-brown-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 71, 55, 0.54);
}
.bracelit-brown-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.bracelit-brown-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.bracelit-brown-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-brown-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bracelit-brown-theme .mat-slider.mat-primary .mat-slider-thumb,
.bracelit-brown-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-brown-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(109, 76, 65, 0.2);
}
.bracelit-brown-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bracelit-brown-theme .mat-slider.mat-accent .mat-slider-thumb,
.bracelit-brown-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(205, 220, 57, 0.2);
}
.bracelit-brown-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bracelit-brown-theme .mat-slider.mat-warn .mat-slider-thumb,
.bracelit-brown-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bracelit-brown-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 71, 55, 0.2);
}
.bracelit-brown-theme .mat-slider:hover .mat-slider-track-background,
.bracelit-brown-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bracelit-brown-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bracelit-brown-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-brown-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-brown-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bracelit-brown-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bracelit-brown-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.bracelit-brown-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.bracelit-brown-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bracelit-brown-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bracelit-brown-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.bracelit-brown-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.bracelit-brown-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-brown-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.bracelit-brown-theme .mat-step-header.cdk-keyboard-focused, .bracelit-brown-theme .mat-step-header.cdk-program-focused, .bracelit-brown-theme .mat-step-header:hover:not([aria-disabled]), .bracelit-brown-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.bracelit-brown-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bracelit-brown-theme .mat-step-header:hover {
    background: none;
  }
}
.bracelit-brown-theme .mat-step-header .mat-step-label,
.bracelit-brown-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.bracelit-brown-theme .mat-step-header .mat-step-icon-selected,
.bracelit-brown-theme .mat-step-header .mat-step-icon-state-done,
.bracelit-brown-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #6d4c41;
  color: white;
}
.bracelit-brown-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bracelit-brown-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bracelit-brown-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bracelit-brown-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bracelit-brown-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bracelit-brown-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74737;
  color: white;
}
.bracelit-brown-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74737;
}
.bracelit-brown-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74737;
}
.bracelit-brown-theme .mat-stepper-horizontal, .bracelit-brown-theme .mat-stepper-vertical {
  background-color: white;
}
.bracelit-brown-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-horizontal-stepper-header::before,
.bracelit-brown-theme .mat-horizontal-stepper-header::after,
.bracelit-brown-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-sort-header-arrow {
  color: #757575;
}
.bracelit-brown-theme .mat-tab-nav-bar,
.bracelit-brown-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bracelit-brown-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.bracelit-brown-theme .mat-tab-label, .bracelit-brown-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-tab-label.mat-tab-disabled, .bracelit-brown-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bracelit-brown-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bracelit-brown-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(211, 201, 198, 0.3);
}
.bracelit-brown-theme .mat-tab-group.mat-primary .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-brown-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-brown-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-brown-theme .mat-tab-group.mat-accent .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-brown-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-brown-theme .mat-tab-group.mat-warn .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-brown-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bracelit-brown-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bracelit-brown-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(211, 201, 198, 0.3);
}
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #6d4c41;
}
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-brown-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-brown-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 244, 195, 0.3);
}
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #cddc39;
}
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-brown-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.bracelit-brown-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 200, 195, 0.3);
}
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74737;
}
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-brown-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bracelit-brown-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bracelit-brown-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-toolbar.mat-primary {
  background: #6d4c41;
  color: white;
}
.bracelit-brown-theme .mat-toolbar.mat-accent {
  background: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-toolbar.mat-warn {
  background: #e74737;
  color: white;
}
.bracelit-brown-theme .mat-toolbar .mat-form-field-underline,
.bracelit-brown-theme .mat-toolbar .mat-form-field-ripple,
.bracelit-brown-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bracelit-brown-theme .mat-toolbar .mat-form-field-label,
.bracelit-brown-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bracelit-brown-theme .mat-toolbar .mat-select-value,
.bracelit-brown-theme .mat-toolbar .mat-select-arrow,
.bracelit-brown-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bracelit-brown-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bracelit-brown-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.bracelit-brown-theme .mat-tree {
  background: white;
}
.bracelit-brown-theme .mat-tree-node,
.bracelit-brown-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .mat-simple-snackbar-action {
  color: #cddc39;
}
.bracelit-brown-theme .back-container {
  color: #6d4c41;
}
.bracelit-brown-theme .back-container:hover {
  opacity: 0.54;
}
.bracelit-brown-theme .warn-text {
  color: #e74737;
}
.bracelit-brown-theme .inside-modal-title.primary-colored-title, .bracelit-brown-theme .form-title.primary-colored-title {
  color: #6d4c41;
}
.bracelit-brown-theme .full-height-container form .button-container {
  background: white;
}
.bracelit-brown-theme label {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-brown-theme .datepicker-clear-button {
  color: rgba(0, 0, 0, 0.6);
}
.bracelit-brown-theme .box-checkbox {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .box-checkbox.active-box-checkbox {
  background-color: #6d4c41;
  color: white;
}
.bracelit-brown-theme .box-radio {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .box-radio.active-box-radio {
  background-color: #6d4c41;
  color: white;
}
.bracelit-brown-theme .help-section {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .error {
  color: #e74737;
}
.bracelit-brown-theme .ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background: white;
}
.bracelit-brown-theme .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bracelit-brown-theme .quill-editor-container:hover .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .quill-editor-container:hover .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .ql-snow a {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .ql-snow .ql-picker {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .ql-snow .ql-picker-options {
  background: white;
}
.bracelit-brown-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background: white;
}
.bracelit-brown-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  background: white;
}
.bracelit-brown-theme .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  background: white;
}
.bracelit-brown-theme .ql-snow .ql-fill,
.bracelit-brown-theme .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .ql-snow.ql-toolbar button:hover,
.bracelit-brown-theme .ql-snow .ql-toolbar button:hover,
.bracelit-brown-theme .ql-snow.ql-toolbar button:focus,
.bracelit-brown-theme .ql-snow .ql-toolbar button:focus,
.bracelit-brown-theme .ql-snow.ql-toolbar button.ql-active,
.bracelit-brown-theme .ql-snow .ql-toolbar button.ql-active,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.bracelit-brown-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.bracelit-brown-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.bracelit-brown-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.bracelit-brown-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.bracelit-brown-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.bracelit-brown-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.bracelit-brown-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.bracelit-brown-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.bracelit-brown-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.bracelit-brown-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-brown-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.bracelit-brown-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.bracelit-brown-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .ql-snow .ql-stroke-miter {
  stroke: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme bracelit-form-item {
  margin-right: 0 !important;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field ::placeholder {
  color: #0f223e !important;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  color: #6d4c41;
  height: 38px !important;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field .mat-input-element {
  color: #0f223e;
  font-size: 16px;
  letter-spacing: -0.43px;
  line-height: 23px;
  margin-top: -0.3em;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field mat-label {
  color: white !important;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.39px;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field .mat-form-field-label {
  color: white !important;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field .mat-form-field-infix {
  padding: 0 !important;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field .mat-form-field-underline {
  background-color: white !important;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field .mat-form-field-ripple {
  background-color: #fedd93 !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  width: 0;
}
.bracelit-brown-theme bracelit-form-item .mat-form-field .mat-error {
  font-size: 12px !important;
  text-align: right !important;
}
.bracelit-brown-theme .general-container .label-wrapper label {
  color: #6d4c41 !important;
  background-color: white;
}
.bracelit-brown-theme .general-container .label-wrapper label.disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .general-container .label-wrapper label.selector-error {
  color: #e74737;
}
.bracelit-brown-theme .general-container .label-wrapper .background-label {
  background-color: #fafafa;
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container {
  border-color: #6d4c41 !important;
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.placeholder {
  color: #0f223e !important;
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear,
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.clear:hover,
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container > div.single > div.toggle:hover {
  color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container:focus, .bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container.open {
  border-color: #6d4c41;
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle, .bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle {
  color: #6d4c41;
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container:focus > div.single > div.toggle:hover, .bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container.open > div.single > div.toggle:hover {
  color: #6d4c41;
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-brown-theme .general-container .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .general-container .bracelit-dropdown {
  background: white;
}
.bracelit-brown-theme .general-container .bracelit-dropdown .selected {
  background: rgba(0, 0, 0, 0.12);
  color: #6d4c41;
}
.bracelit-brown-theme .general-container .bracelit-dropdown .selected.highlighted {
  background: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .general-container .bracelit-dropdown .highlighted {
  background: rgba(0, 0, 0, 0.04);
}
.bracelit-brown-theme .general-container .selector-error {
  color: #e74737;
}
.bracelit-brown-theme .general-container .disabled {
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .general-container.standard .bracelit-selector .bracelit-selector-container {
  border-color: rgba(0, 0, 0, 0.42);
}
.bracelit-brown-theme .general-container.standard .bracelit-selector .bracelit-selector-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .general-container.standard .bracelit-selector .bracelit-selector-container:focus, .bracelit-brown-theme .general-container.standard .bracelit-selector .bracelit-selector-container.open {
  border-color: #6d4c41;
}
.bracelit-brown-theme .general-container.standard .bracelit-selector .bracelit-selector-container.error-border {
  border-color: #e74737;
}
.bracelit-brown-theme .general-container.standard .bracelit-selector .bracelit-selector-container.disabled {
  border-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.38);
}
.bracelit-brown-theme .move-image-action-container:hover {
  color: #6d4c41;
}
.bracelit-brown-theme .info-icon {
  color: #6d4c41;
}
.bracelit-brown-theme .email-chip {
  color: #6d4c41 !important;
  background-color: rgba(109, 76, 65, 0.15) !important;
}
.bracelit-brown-theme .email-chip .mat-chip-remove {
  color: #6d4c41 !important;
  opacity: 1 !important;
}
.bracelit-brown-theme .files-tip {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .primary-contrast-text-color {
  color: #ffffff !important;
}
.bracelit-brown-theme .primary-color {
  color: #6d4c41 !important;
}
.bracelit-brown-theme .primary-color-200 {
  color: #b6a6a0 !important;
}
.bracelit-brown-theme .primary-fill {
  fill: #6d4c41 !important;
}
.bracelit-brown-theme .secondary-fill {
  fill: #ff9a81 !important;
}
.bracelit-brown-theme .tertiary-fill {
  fill: #cddc39 !important;
}
.bracelit-brown-theme .primary-border-50 {
  border-color: #edeae8 !important;
}
.bracelit-brown-theme .primary-border-100 {
  border-color: #d3c9c6 !important;
}
.bracelit-brown-theme .primary-border-200 {
  border-color: #b6a6a0 !important;
}
.bracelit-brown-theme .primary-indicator .mat-expansion-indicator:after {
  color: #6d4c41;
}
.bracelit-brown-theme .contrast-background {
  background-color: #ffffff !important;
}
.bracelit-brown-theme .primary-color-small {
  color: rgba(109, 76, 65, 0.6);
}
.bracelit-brown-theme .primary-background {
  background-color: #6d4c41 !important;
}
.bracelit-brown-theme .primary-background-50 {
  background-color: #edeae8 !important;
}
.bracelit-brown-theme .primary-background-200 {
  background-color: #b6a6a0 !important;
}
.bracelit-brown-theme .primary-background-800 {
  background-color: #50332a !important;
}
.bracelit-brown-theme .primary-background-800-2 {
  background-color: #50332a !important;
  color: white !important;
}
.bracelit-brown-theme .primary-background-opacity-01 {
  background-color: rgba(109, 76, 65, 0.1) !important;
}
.bracelit-brown-theme .secondary-color {
  color: #ff9a81 !important;
}
.bracelit-brown-theme .secondary-background {
  background-color: #ff9a81 !important;
}
.bracelit-brown-theme .secondary-background-opacity-01 {
  background-color: rgba(255, 154, 129, 0.1) !important;
}
.bracelit-brown-theme .secondary-border {
  border-color: #ff9a81 !important;
}
.bracelit-brown-theme .tertiary-color {
  color: #ff491b !important;
}
.bracelit-brown-theme .tertiary-background {
  background-image: linear-gradient(45deg, #ff9a81, #ff491b) !important;
}
.bracelit-brown-theme .tertiary-border {
  border-color: #ff491b !important;
}
.bracelit-brown-theme .dark-background {
  background-color: black !important;
}
.bracelit-brown-theme .white-opacity-background {
  background-color: rgba(255, 255, 255, 0.94);
}
.bracelit-brown-theme .white-background {
  background-color: rgb(255, 255, 255);
}
.bracelit-brown-theme .primary-soft-background {
  background-color: rgba(109, 76, 65, 0.1) !important;
}
.bracelit-brown-theme .primary-soft-background:hover {
  background-color: rgba(109, 76, 65, 0.15) !important;
}
.bracelit-brown-theme .primary-border {
  border-color: #6d4c41 !important;
}
.bracelit-brown-theme .default-background {
  background-color: #fafafa !important;
}
.bracelit-brown-theme .hover-primary:hover {
  color: #6d4c41 !important;
}
.bracelit-brown-theme .hover-primary-background:hover {
  background-color: #6d4c41 !important;
}
.bracelit-brown-theme .hover-background:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}
.bracelit-brown-theme .warn-color {
  color: #e74737 !important;
}
.bracelit-brown-theme .warn-border {
  border-color: #e74737 !important;
}
.bracelit-brown-theme .accent-color {
  color: #cddc39 !important;
}
.bracelit-brown-theme .accent-background {
  background-color: #cddc39 !important;
}
.bracelit-brown-theme .accent-background-800 {
  background-color: #9e9d24 !important;
}
.bracelit-brown-theme .foreground-text-color {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bracelit-brown-theme .foreground-secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .foreground-secondary-text-color-soft {
  color: rgba(0, 0, 0, 0.36);
}
.bracelit-brown-theme .divider-color {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .dialog-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .dialog-table tr .action-icon {
  color: transparent;
}
.bracelit-brown-theme .dialog-table tr .dialog-custom-input {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bracelit-brown-theme .dialog-table tr .dialog-custom-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.bracelit-brown-theme .dialog-table tr .dialog-custom-input:focus {
  border-bottom-color: #6d4c41;
}
.bracelit-brown-theme .dialog-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-brown-theme .dialog-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .dialog-table tr:hover .dialog-custom-input {
  background: transparent;
}
.bracelit-brown-theme .dialog-div-container .dialog-div-container-remove {
  color: transparent;
}
.bracelit-brown-theme .dialog-div-container:hover .dialog-div-container-remove {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .card-table tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .card-table tr .action-icon {
  color: transparent;
}
.bracelit-brown-theme .card-table tr .action-icon button {
  color: transparent;
}
.bracelit-brown-theme .card-table tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-brown-theme .card-table tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .card-table tr:hover .action-icon button {
  color: #6d4c41;
}
.bracelit-brown-theme .card-table tr:hover .action-icon:hover {
  color: #6d4c41;
}
.bracelit-brown-theme .dashboard-card-content tr {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .dashboard-card-content tr .action-icon {
  color: transparent;
}
.bracelit-brown-theme .dashboard-card-content tr:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-brown-theme .dashboard-card-content tr:hover .action-icon {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .dashboard-card-content tr:hover .action-icon:hover {
  color: #6d4c41;
}
.bracelit-brown-theme .dashboard-card-content .resume-table {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .no-hover-table tr:hover {
  background: white;
}
.bracelit-brown-theme .custom-standard-chip {
  background-color: rgba(109, 76, 65, 0.1) !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .help-dashboard-card .mat-icon {
  color: #6d4c41;
}
.bracelit-brown-theme .help-dashboard-card:hover {
  background-color: #6d4c41;
}
.bracelit-brown-theme .help-dashboard-card:hover .mat-icon, .bracelit-brown-theme .help-dashboard-card:hover .help-dashboard-card-text {
  color: white;
}
.bracelit-brown-theme .room-open {
  background-color: #6d4c41 !important;
  color: white !important;
}
.bracelit-brown-theme .room-open:hover {
  background-color: #6d4c41 !important;
  color: white !important;
}
.bracelit-brown-theme #public-office-page-groups .public-group-button-hover {
  background-color: #6d4c41 !important;
  color: white !important;
}
.bracelit-brown-theme .notification-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .notification-container .show-tip {
  color: transparent;
}
.bracelit-brown-theme .notification-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-brown-theme .notification-container:hover .show-tip {
  color: #6d4c41;
}
.bracelit-brown-theme .notification-container .notification-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-brown-theme .notification-container .notification-subject .mat-icon {
  color: #6d4c41;
}
.bracelit-brown-theme .notification-container .notification-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .message-container {
  border-color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .message-container .show-tip {
  color: transparent;
}
.bracelit-brown-theme .message-container:hover {
  background: rgba(0, 0, 0, 0.03);
}
.bracelit-brown-theme .message-container:hover .show-tip {
  color: #6d4c41;
}
.bracelit-brown-theme .message-container .message-subject {
  color: rgba(0, 0, 0, 0.75);
}
.bracelit-brown-theme .message-container .message-subject .mat-icon {
  color: #6d4c41;
}
.bracelit-brown-theme .message-container .message-date {
  color: rgba(0, 0, 0, 0.54);
}
.bracelit-brown-theme .no-results-container .no-results-icon-container {
  background: rgba(0, 0, 0, 0.06);
}
.bracelit-brown-theme .no-results-container .no-results-icon-container .mat-icon {
  color: rgba(0, 0, 0, 0.12);
}
.bracelit-brown-theme .no-results-container .no-results-title {
  color: rgba(0, 0, 0, 0.22);
}
.bracelit-brown-theme .primary-200-outline input {
  caret-color: #b6a6a0;
  color: #6d4c41;
}
.bracelit-brown-theme .primary-200-outline label {
  color: #b6a6a0;
}
.bracelit-brown-theme .primary-200-outline input:-webkit-autofill {
  -webkit-text-fill-color: #b6a6a0 !important;
}
.bracelit-brown-theme .primary-200-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #b6a6a0;
}
.bracelit-brown-theme mat-form-field .primary-outline label {
  color: #6d4c41 !important;
}
.bracelit-brown-theme mat-form-field .primary-outline input:-webkit-autofill {
  -webkit-text-fill-color: #6d4c41 !important;
}
.bracelit-brown-theme mat-form-field .primary-outline .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #6d4c41 !important;
}
.bracelit-brown-theme .input-primary .mat-form-field-wrapper .mat-form-field-underline {
  background-color: #b6a6a0 !important;
}
.bracelit-brown-theme .input-primary input {
  caret-color: #6d4c41;
  color: #6d4c41;
}
.bracelit-brown-theme .input-primary label {
  color: #6d4c41;
}
.bracelit-brown-theme .input-primary input:-webkit-autofill {
  -webkit-text-fill-color: #6d4c41 !important;
}
.bracelit-brown-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}
.bracelit-brown-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: #6d4c41;
  border-width: 1px;
}
.bracelit-brown-theme .bracelit-primary-checkbox label .mat-checkbox-inner-container .mat-checkbox-ripple .mat-checkbox-persistent-ripple {
  background-color: transparent !important;
}
.bracelit-brown-theme .bracelit-primary-checkbox label .mat-checkbox-label {
  padding-top: 3px;
}
.bracelit-brown-theme .header-primary-background .mobile-header-container {
  background-color: #6d4c41;
}
.bracelit-brown-theme .light-14px-left-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.34px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-14px-right-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.34px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-14px-center-primary {
  font-size: 14px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.34px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .bold-16px-left-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .bold-16px-right-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .bold-16px-center-primary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .regular-16px-left-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .regular-16px-center-primary {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-16px-left-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-16px-right-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.39px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-16px-left-primary-200 {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #b6a6a0 !important;
}
.bracelit-brown-theme .light-16px-center-primary {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-16px-left-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-brown-theme .light-16px-center-white {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.39px !important;
  color: #ffffff !important;
}
.bracelit-brown-theme .regular-16px-center-white {
  font-size: 16px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-brown-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .bold-18px-left-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .bold-400-18px-left-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .bold-400-18px-center-primary {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-18px-left-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-18px-left-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-brown-theme .light-18px-right-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-brown-theme .bold-18px-right-primary {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .regular-18px-center-accent {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-brown-theme .light-18px-center-accent {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #cddc39 !important;
}
.bracelit-brown-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .regular-18px-center-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .regular-18px-left-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-18px-center-primary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .regular-18px-center-white {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-18px-center-white {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #ffffff !important;
}
.bracelit-brown-theme .regular-18px-left-primary-200 {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #b6a6a0 !important;
}
.bracelit-brown-theme .regular-18px-right-primary {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: right !important;
  letter-spacing: -0.43px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-18px-left-secondary {
  font-size: 18px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.43px !important;
  color: #ff9a81 !important;
}
.bracelit-brown-theme .bold-20px-center-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .bold-20px-center-warn {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #e74737 !important;
}
.bracelit-brown-theme .regular-18px-center-warn {
  font-size: 18px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.43px !important;
  color: #e74737 !important;
}
.bracelit-brown-theme .bold-20px-left-primary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-20px-left-primary {
  font-size: 20px !important;
  font-weight: 300 !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .regular-20px-left-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ff9a81 !important;
}
.bracelit-brown-theme .regular-20px-center-secondary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ff9a81 !important;
}
.bracelit-brown-theme .regular-20px-center-tertiary {
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center !important;
  letter-spacing: -0.48px !important;
  color: #ff491b !important;
}
.bracelit-brown-theme .bold-20px-left-tertiary {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.48px !important;
  color: #ff491b !important;
}
.bracelit-brown-theme .bold-22px-left-primary {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: left !important;
  letter-spacing: -0.53px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-22px-center-primary {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-22px-center-white {
  font-size: 22px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.53px !important;
  line-height: 30px !important;
  color: #ffffff !important;
}
.bracelit-brown-theme .light-30px-right-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-30px-center-primary {
  font-size: 30px !important;
  font-weight: 300 !important;
  text-align: center !important;
  letter-spacing: -0.96px !important;
  color: #6d4c41 !important;
}
.bracelit-brown-theme .light-40px-right-primary {
  font-size: 40px !important;
  font-weight: 300 !important;
  text-align: right !important;
  letter-spacing: -0.96px !important;
  color: #6d4c41 !important;
}