@media only screen and (max-width: 360px) {
  .bracelit-card-2 {
    width: 300px;
  }
  .empty-state-container {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 360px) {
  .bracelit-card-2 {
    width: 340px;
  }
  .empty-state-container {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 414px) {
  .bracelit-card-wristbands, {
    width: 125px;
  }
}

@media only screen and (min-width: 414px) {
  .bracelit-card-wristbands {
    width: 150px;
  }
}

@media only screen and (max-width: 414px) {
  .bracelit-card, .bracelit-shape-card, .bracelit-card-width {
    width: 300px;
  }
}

@media only screen and (min-width: 414px) {
  .bracelit-card, .bracelit-shape-card, .bracelit-card-width {
    width: 340px;
  }
  .bracelit-card-2 {
    width: 384px;
  }
}

@media only screen and (max-height: 570px) {
  .vouchers-card {
    height: 360px;
  }
}

@media only screen and (min-height: 570px) {
  .vouchers-card {
    height: 460px;
  }
}
