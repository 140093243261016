/* PROVISIONAL CLASS */
.bigger-padding-fix {
  padding: 26px 16px !important;
}

/* PROVISIONAL CLASS */
.lesser-padding-right-fix {
  padding-right: 0 !important;
}

.mat-card-title, .card-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: 20px !important;
}

.stacked-card {
  width: 100%;
  padding: 0 !important;
  .mat-card-title, .card-title {
    padding: 16px 16px 0 16px;
  }
  .stacked-content-padding {
    padding: 0 16px;
  }
  .checkbox-with-image {
    display: flex;
    align-items: center;
    margin-bottom: 20px !important;
    img {
      margin-left: 18px;
      max-height: 16px;
      cursor: pointer;
    }
    .additional-text {
      margin-left: 10px;
    }
  }
}

.card-title-multiple {
  display: flex !important;
  align-items: center;
  place-content: center space-between;
}

.card-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  tr {
    border-top-width: 1px;
    border-top-style: solid;
    cursor: pointer;
    td {
      padding-left: 16px;
      height: 45px;
      img {
        max-height: 28px;
      }
      &.action-icon {
        padding-left: 0;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.no-cursor-table {
  tr {
    cursor: default;
  }
}

.no-hover-table {
  tr {
    cursor: default;
  }
}

.card-margin-top {
  margin-top: 16px;
}

.card-margin-bottom {
  margin-bottom: 16px;
}

.card-margin-right {
  margin-right: 16px;
}

.card-margin-auto {
  margin-right: 16px;
}

@media only screen and (max-width: 992px) {
  .card-margin-auto {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
