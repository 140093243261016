@use "scss/auth" as *;
@use "scss/modals" as *;
@use "scss/cards" as *;
@use "scss/water-mark" as *;
@use "scss/help" as *;
@use "scss/forms" as *;
@use "scss/common" as *;
@use "scss/bracelit-text-classes" as *;
@use "scss/bracelit-classes" as *;
@use "scss/bracelit-responsive" as *;
@use "assets/fonts/fonts.css";
@use '@angular/cdk/overlay-prebuilt.css';
@use 'theming.scss';

.stat-container {
  height: auto;
  width: auto;
}

.small-chart {
  max-height: 160px !important;
}

.medium-chart {
  min-height: 180px !important;
  max-height: 220px !important;
}

.large-chart {
  min-height: 240px !important;
}

.chart-card {
  padding: 24px 13px !important;
}

.chart-container-min-height {
  min-height: 300px;
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.responsive-img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.red {
  color: #DE5948;
}

.orange {
  color: #B65E26;
}

.yellow {
  color: #818100;
}

.green {
  color: #359964;
}

// Forms
:host-context {
  .button-container {
    place-content: center !important;
  }

  .save-button {
    margin: 0 !important;
  }
}

.mdc-dialog__surface {
  border-radius: 7px !important;
}


 .mat-mdc-option{
  font-size: 12px !important;
}

 .mdc-button__label {
  letter-spacing: normal !important;
}


.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__checkmark {
  --mdc-checkbox-selected-checkmark-color: rgb(255, 255, 255) !important; 
}
