.mat-dialog-container {
  h1.with-subtitle {
    margin-bottom: 10px;
  }
  h2.subtitle {
    font-size: 1.1em;
    font-weight: 300;
    margin: 0 0 20px 0;
  }
}

.no-padding-dialog {
  .mat-dialog-container {
    padding: 0;
    .mat-dialog-content {
      margin: 0;
      padding: 0;
    }
  }
}

.overflow-dialog {
  overflow: auto;
}

.visible-overflow {
  .mat-dialog-container {
    overflow: visible;
  }
}

.hidden-overflow {
  .mat-dialog-container {
    overflow: visible;
  }
}

.close-container {
  position: relative;
  z-index: 1000;
  .close-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    .mat-icon {
      font-size: 24px;
      cursor: pointer;
      &:hover {
        opacity: .54;
      }
    }
  }
}

.redsys-dialog {
  .close-container {
    position: absolute;
    right: 24px;
    top: 24px;
  }
  .mat-dialog-container {
    position: relative;
    padding: 0 !important;
  }
}

@media screen and (max-width: 800px) {
  .visible-overflow {
    .mat-dialog-container {
      overflow: auto;
    }
  }
}
