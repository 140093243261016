.auth-background {
  height: 100%;
  width: 100%;
  background-color: #F6F6F6;
  margin-top: -64px;
  padding-top: 64px;
  .auth-container {
    background-color: white;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    width: 52%;
    min-width: 360px;
    height: 60%;
    min-height: 640px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .auth-form-container {
      //height: 100%;
      padding: 0 40px;
      flex-flow: row wrap;
      box-sizing: border-box;
      display: flex;
      place-content: center;
      align-items: center;
      flex: 1 1 46%;
      max-width: 46%;
      .mat-tab-nav-bar, .mat-tab-header {
        border-bottom: none;
        .mat-tab-link {
          padding: 0 12px;
          min-width: 100px;
        }
        .mat-ink-bar {
          min-width: 100px;
        }
      }

      .auth-logo-container {
        flex: 1 1 100%;
        box-sizing: border-box;
        display: flex;
        place-content: center;
        align-items: center;
        max-width: 100%;
        margin-bottom: 20px;
        img {
          width: 38%;
          min-width: 100px;
          max-width: 160px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      form {
        margin-top: 20px;
      }
      button {
        // position: absolute;
        // bottom: 20px;
        &.forgot-button-relative {
          position: relative;
          margin-top: 2.5rem;
        }
        &.back-button {
          padding: 0 2px;
          font-weight: 400;
          .mat-icon {
            margin-right: 0;
          }
        }
      }
      .auth-links {
        min-height: 16px;
        text-align: center;
        a {
          color: #3897A6;
        }
      }
    }

    .auth-message-container {
      text-align: center;
      height: 100%;
      padding: 0 50px;
      color: #fff;
      background-color: #3897A6;
      flex-flow: row wrap;
      box-sizing: border-box;
      display: flex;
      place-content: center;
      align-items: center;
      flex: 1 1 54%;
      max-width: 54%;
      h1 {
        margin-top: 50px;
        font-size: 30px;
      }
      h2 {
        font-size: 20px;
        margin: 0;
      }
    }
    .warn-color {
      color: #DE5948 !important;
    }
  }
  @media screen and (max-width: 1400px) {
    .auth-container {
      width: 60%;
    }
  }

  @media screen and (max-width: 1200px) {
    .auth-container {
      width: 75%;
      .auth-form-container {
        flex: 1 1 60%;
        max-width: 60%;
      }
      .auth-message-container {
        flex: 1 1 40%;
        max-width: 40%;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .auth-container {
      width: 100%;
      height: 100%;
      margin: 0;
      .auth-form-container {
        padding: 10px;
        flex: 1 1 100%;
        max-width: 100%;
      }
      .auth-message-container {
        flex: 1 1 100%;
        max-width: 100%;
        height: 20%;
        h1 {
          margin-top: 0;
          font-size: 24px;
        }
        h2 {
          font-size: 16px;
        }
        padding: 0 25px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .auth-container {
      .auth-form-container {
        padding: 10px;
      }
      .auth-message-container {
        h1 {
          margin-top: 0;
          font-size: 24px;
        }
        h2 {
          font-size: 16px;
        }
        padding: 0 15px;
      }
    }
  }
}
