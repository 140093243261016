@use '@angular/material' as mat;
@use "sass:map";

// mixin name will be used in main style.scss
@mixin bracelit-form-item-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);
  $is-dark-theme: map.get($theme, is-dark);

  // Label colors. Required is used for the `*` star shown in the label.
  $label-color: mat.m2-get-color-from-palette($foreground, secondary-text, if($is-dark-theme, 0.7, 0.6));
  $focused-label-color: mat.m2-get-color-from-palette($primary);
  $required-label-color: mat.m2-get-color-from-palette($accent);

  // Underline colors.
  $underline-color-base: mat.m2-get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
  $underline-color-accent: mat.m2-get-color-from-palette($accent);
  $underline-color-warn: mat.m2-get-color-from-palette($warn);
  $underline-focused-color: mat.m2-get-color-from-palette($primary);

  label {
    color: $label-color;
  }

  .datepicker-clear-button {
    color: $label-color;
  }

  .box-checkbox {
    background-color: mat.m2-get-color-from-palette($background, 'card');
    border-color: mat.m2-get-color-from-palette($foreground, divider);
    &.active-box-checkbox {
      background-color: mat.m2-get-color-from-palette($primary);
      color: mat.m2-get-color-from-palette($background, 'card');
    }
  }

  .box-radio {
    background-color: mat.m2-get-color-from-palette($background, 'card');
    border-color: mat.m2-get-color-from-palette($foreground, divider);
    &.active-box-radio {
      background-color: mat.m2-get-color-from-palette($primary);
      color: mat.m2-get-color-from-palette($background, 'card');
    }
  }

  .help-section {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }

  .error {
    color: mat.m2-get-color-from-palette($warn);
  }

  .ql-snow .ql-color-picker.ql-background .ql-picker-item {
    background: mat.m2-get-color-from-palette($background, 'card');
  }
  .ql-snow .ql-color-picker.ql-color .ql-picker-item {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
  .ql-toolbar.ql-snow {
    border-color: mat.m2-get-color-from-palette($foreground, divider);
  }
  .ql-container.ql-snow {
    border-color: mat.m2-get-color-from-palette($foreground, divider);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .quill-editor-container {
    &:hover {
      .ql-toolbar.ql-snow {
        border-color: mat.m2-get-color-from-palette($foreground, text);
      }
      .ql-container.ql-snow {
        border-color: mat.m2-get-color-from-palette($foreground, text);
      }
    }
  }
  .ql-snow a {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
  .ql-snow .ql-picker {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
  .ql-snow .ql-picker-options {
    background: mat.m2-get-color-from-palette($background, 'card');
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    background: mat.m2-get-color-from-palette($background, 'card');
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    background: mat.m2-get-color-from-palette($background, 'card');
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    background: mat.m2-get-color-from-palette($background, 'card');
  }

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: mat.m2-get-color-from-palette($foreground, text);
  }
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: mat.m2-get-color-from-palette($foreground, text);
  }
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: mat.m2-get-color-from-palette($foreground, text);
  }

  .ql-snow .ql-stroke {
    stroke: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
  .ql-snow .ql-stroke-miter {
    stroke: mat.m2-get-color-from-palette($foreground, secondary-text);
  }

  bracelit-form-item {
    margin-right: 0 !important;

    .mat-form-field {
      ::placeholder {
        color: #0f223e !important;
      }

      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-outline {
            color: mat.m2-get-color-from-palette($primary);
            height: 38px !important;
          }
        }
      }

      .mat-input-element {
        color: #0f223e;
        font-size: 16px;
        letter-spacing: -0.43px;
        line-height: 23px;
        margin-top: -0.3em;
      }

      mat-label {
        color: white !important;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.39px
      }

      .mat-form-field-label {
        color: white !important;
      }

      .mat-form-field-infix {
        padding: 0 !important;
      }

      .mat-form-field-underline {
        background-color: white !important;
      }

      .mat-form-field-ripple {
        background-color: #fedd93 !important;
        //transform: scaleX(1) !important;
        transform: translate(-50%, -50%) !important;
        top: 50%;
        left: 50%;
        width: 0;
      }

      .mat-error {
        font-size: 12px !important;
        text-align: right !important;
      }
    }
  }
}
