.help-page {
  width: 100%;
  min-height: 100%;
  line-height: 20px;

  .mat-icon {
    transform: scale(0.8) translateY(4px);
  }

  hr {
    margin: 0;
    border: 1px solid;
    border-top-width: 0;
  }
  h1 {
    padding: 24px 20px 12px 20px;
    font-weight: 300;
    font-size: 28px;
    margin: 0;
  }

  p {
    padding: 0 20px;
    font-size: 14px;
    text-align: justify;
    max-width: 950px;
  }

  ol {
    padding-left: 30px;
    max-width: 950px;
    &.main-ol {
      li {
        cursor: pointer;
      }
    }
  }

  ul {
    padding-left: 30px;
    max-width: 950px;
    list-style: none;
  }

  img {
    height: 500px;
  }

  .help-index {
    padding: 0 20px;
    margin: 12px 0;
    max-width: 950px;
    h2 {
      text-transform: uppercase;
      font-size: 14px;
    }
    p {
      padding: 0;
      font-size: 14px;
      text-align: justify;
    }
    ul.iconos {
      li {
        margin-bottom: 4px;
      }
    }
  }

  .mat-icon svg {
    height: 22px;
  }

  .help-accordion {
    .mat-expansion-panel {
      padding: 0 20px;
      box-shadow: none;
      .mat-expansion-panel-header {
        padding: 0 20px;
        font-size: 14px;
        max-width: 1050px;
      }
      .mat-expansion-panel-body {
        padding: 0 20px 16px 20px;
        max-width: 950px;
        p {
          padding: 0;
        }
        ul {
          li {
            span {
              font-size: 16px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }

  /*    .pointer {
      cursor: pointer;
      }*/
}
