@use '@angular/material' as mat;

// mixin name will be used in main style.scss
@mixin bracelit-confirm-navigation-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .info-icon {
    color: mat.get-color-from-palette($primary);
  }
}
